import "rc-time-picker/assets/index.css";

import React from "react";
import { required } from "redux-form-validators";
import { Field } from "redux-form";
import timeSelect from "../../../Components/Forms/time";
import dateSelect from "../../../Components/Forms/date";
class PeriodesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields } = this.props;
    return (
      <tbody>
        {fields.map((singledates, index) => (
          <tr key={index}>
            <td>
              <Field validate={required({ msg: "Datum is verplicht" })} component={dateSelect} key={index} name={`${singledates}.date`} />
            </td>
            <td>
              <Field component={timeSelect} key={index} name={`${singledates}.starttime`} onChange={this.handleSelect} />
            </td>
            <td>
              <Field component={timeSelect} key={index} name={`${singledates}.endtime`} onChange={this.handleSelect} />
            </td>
            <td>
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <button type="button" onClick={() => fields.push({})}>
              Dag Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default PeriodesForm;
