import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Rodal from "rodal";
import { message } from "../../../Reducers/loading/actions";
import { SetOnline, SetOffline, DelItem } from "../Reducers/items/actions";

const Publiceren = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const item = useSelector((state) => state.items.item);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const publiceer = () => {
    if (item._id) {
      dispatch(SetOnline(item._id, item)).then(() => {
        if (item._id) {
          message(2000, "success", "Het item is succesvol gepubliceerd");
        }
      });
    }
  };

  const setOffline = () => {
    if (item._id) {
      dispatch(SetOffline(item._id, item)).then(() => {
        if (item._id) {
          navigate("/items/edit/waar/" + item._id);
        }
      });
    }
  };

  const delItem = () => {
    if (item._id) {
      dispatch(DelItem(item._id)).then(() => {
        hide();
        navigate("/items/");
      });
    }
  };

  const isVeluweDB = (app) => {
    const statusImages = {
      readyforvalidation: "/img/veluwewok.png",
      approved: "/img/veluweok.png",
      rejected: "/img/veluwenotok.png",
    };

    const imageSrc = statusImages[app.veluwedb?.status?.wfstatus];

    return imageSrc ? <span className="label"><img src={imageSrc} alt="VeluweDB" style={{ height: "16px" }} /></span> : null;
  };

  const actionItems = (item) => (
    <div className="btn-group">
      <button type="button" className="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <span className="caret"></span>
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu" role="menu">
        <li>
          <button className="dropdown-item" onClick={setOffline}>
            Offline Plaatsen
          </button>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        {item.metadata.online === false && (
          <li>
            <button className="dropdown-item" onClick={show}>
              Verwijderen
            </button>
          </li>
        )}
      </ul>
      <Rodal visible={visible} onClose={hide}>
        <div className="card">
          <div className="card-header bg-danger text-white">
            <h3 className="card-title">Let op</h3>
          </div>
          <div className="card-body">Weet je zeker dat je dit item wil verwijderen? Het item wordt permanent verwijderd.</div>
          <div className="card-footer">
            <button className="btn btn-info me-2" onClick={hide}>
              Annuleren
            </button>
            <button className="btn btn-danger" onClick={delItem}>
              Verwijderen
            </button>
          </div>
        </div>
      </Rodal>
    </div>
  );

  if (item._id) {
    if (item.metadata.online && !item.metadata.newupdate) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="mx-2">{isVeluweDB(item)}</div>
          <div>
            <span className="label label-primary">Gepubliceerd: {moment(item.metadata.onlinedate).format("DD-MM-YYYY")}</span>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <button type="button" className="btn btn-info">
              Action
            </button>
            {actionItems(item)}
          </div>
        </div>
      );
    }
    if (item.metadata.online && item.metadata.newupdate) {
      return (
        <div>
          <button type="button" onClick={publiceer} className="btn-danger btn btn-box btn-mg">
            <i className="fa fa-globe"></i>
            &nbsp; Nieuwe update publiceren
          </button>
          <span>{actionItems(item)}</span>
        </div>
      );
    }

    if (!item.metadata.online) {
      return (
        <div>
          <button type="button" onClick={publiceer} className="btn-danger btn btn-box btn-mg">
            <i className="fa fa-globe"></i>
            &nbsp; Publiceren
          </button>
          <span>{actionItems(item)}</span>
        </div>
      );
    }
  }

  return null;
};

export default Publiceren;
