import React, { useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
const AuthContext = createContext();

export const RequireAuthAdmin = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();


  useEffect(() => {

    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, params]);

  const checkAuthentication = () => {
    if(!localStorage.getItem("gast")) {
      navigate.replace({ pathname: "/public/" + params.id ,  state: { from: params.location.pathname }});
    } else {
      return true;
    }
  };

  return children;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
