import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../../Components/Forms/renders";
import MultiSelect from "../../../../Components/Forms/multiselect";
import UploadFrom from "../../../../Components/Forms/upload";
const selector = formValueSelector("bedrijfi_edit"); //

const BedrijvenForm = (props) => {
  const { handleSubmit, change } = props;
  const [showMollie, setShowMollie] = useState(false);
  const [showPlattegrond, setShowPlattegrond] = useState(false);
  const modulesValue = useSelector((state) => selector(state, "modules"));
  // Deze functie was eerst in de render-methode, maar aangezien we geen klassencomponent gebruiken, kunnen we deze direct in het functionele component definiëren.
  const createslug = (event, value) => {
    var slug = value
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w]+/g, "")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
    change("slug", slug); // 'this.props' is niet nodig in een functioneel component.
  };

  const checkslug = (value) => {
    return value
      .toString()
      .replace(/ /g, "-")
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w]+/g, "-")
      .replace(/ /g, "-");
  };
  useEffect(() => {
    if (modulesValue && modulesValue.includes("ervaringen")) {
      setShowMollie(true);
    } else {
      setShowMollie(false);
    }
    if (modulesValue && modulesValue.includes("sensoren")) {
      setShowPlattegrond(true);
    } else {
      setShowPlattegrond(false);
    }
  }, [modulesValue]);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="naam" onChange={createslug} type="input" validate={[required()]} component={renderField} label="Naam Bedrijf" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Vul korte bedrijving van je bedrijf" />
              <Field name="group" type="input" component={renderField} label="Vul een groupnaam in als nodig" />
              <Field name="slug" normalize={checkslug} type="input" component={renderField} label="Korte naam bedrijf (slug)" />
              <Field name="website" type="input" component={renderField} label="Website waar activiteiten staan" />
              <Field name="email" type="email" component={renderField} label="E-mailadres van de hoofdgebruiker" />
              <Field
                name="modules"
                type="input"
                allowCreate="false"
                component={MultiSelect}
                options={["horecas", "recreatie", "logboeken", "trainingen", "faciliteiten", "sensoren", "biepp", "activiteiten", "gastenverbruik", "items", "thema", "arangementen", "ervaringen", "cms", "vacatures", "accommodaties", "kampeerplaatsen", "EBS", "arrangementen"]}
                label="Modules die je wil activeren"
              />
              {showMollie && (
                <span>
                  <Field name="mollie" type="input" component={renderField} label="Mollie API Key" />
                  <Field name="homepage" type="input" component={renderField} label="Website bedrijf" />
                </span>
              )}
              {showPlattegrond && <Field name="plattegrond" type="input" component={UploadFrom} label="Upload hier de plattengrond van het bedrijf" />}
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updates" />
          </div>
        </div>
      </form>
    </div>
  );
};
let ReduxBedrijvenForm = reduxForm({
  form: "bedrijfi_edit", // een unieke identifier voor dit formulier
})(BedrijvenForm);

ReduxBedrijvenForm = connect(
  (state) => ({
    initialValues: state.bedrijven.item, // haal initiële waarden uit account reducer
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxBedrijvenForm);

export default ReduxBedrijvenForm;
