import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getconnections, editconnections, getAccessTokenInstagram } from "../Reducers/actions";
import Cookies from "js-cookie";
function CallbackConnections({ connections, getconnections, editconnections, getAccessTokenInstagram }) {
  const [loaded, setLoaded] = useState(false);
  const id = Cookies.get("myconnectionid"); // De cookie vervalt over 7 dagen
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const code = queryString.get("code");
  let navigate = useNavigate();
  var soort = "instagram";
  useEffect(() => {
    if (id && code) {
      setLoaded(false);
      editconnections(id, { accessToken: code, redirect_uri: window.location.origin + "/admin/connections/callback/" + soort }).then((data) => {
        const bodyValues = {
          client_id: data.results.client_id,
          client_secret: data.results.client_secret,
          grant_type: "authorization_code",
          redirect_uri: window.location.origin + "/admin/connections/callback/" + soort,
          code: code,
          id: id,
        };

        getAccessTokenInstagram(bodyValues).then((dts) => {
          navigate("/admin/connections");
        });

        setLoaded(true);
      });
    } else {
      navigate("/admin/connections");
    }
  }, [getconnections, id]);

  return <div className="box box-default">Data wordt geladen</div>;
}

const mapStateToProps = (state) => {
  return { connections: state.connections.item };
};

export default connect(mapStateToProps, { editconnections, getconnections, editconnections, getAccessTokenInstagram })(CallbackConnections);
