import React, { useState } from "react";
import SubMenu from "./Components/submenu";

const App = () => {
  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Content Manager</h3>
        </div>
        <div className="box-body d-flex">
          <div className="ms-auto " style={{ width: "200px" }}>
            <SubMenu />
          </div>
          <div className="flex-grow-1">
            <div className="content-wrappers">
              <div className="container-fluid">
                <div>
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">Settings</h3>
                    </div>
                    <div className="box-body">{/* Your main content here */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
