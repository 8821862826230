import React, { useState, useEffect } from "react";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const TimePickerSelect = (props) => {
  const [time, Settime] = useState(null);

  useEffect(() => {
    if (props.input.value) {
        Settime(moment(props.input.value, 'HH:mm'));
      }
  }, []);

  const handleSelect = (val) => {
    props.input.onChange(val);
  };

  const { label, error, touched, warning } = props;

  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <TimePicker
          value={time}
          showSecond={false}
          placeholder={label}
          onChange={(selectedDate) => {
            Settime(selectedDate);
            handleSelect(selectedDate);
          }}
          use24Hours
        />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default TimePickerSelect;
