import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField,renderTextField, } from "../../../../Components/Forms/renders";

const InsertForm = ({ handleSubmit }) => {
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
            
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam standplaats categorie" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving standplaats categorie (optioneel)" />
              <Field name="prijs" type="number" component={renderField} label="Prijs per nacht per kWH" />
              <Field name="voorschot" type="number" component={renderField} label="Voorschot per nacht in kWh" />
            </div>
          </div>
          <div className="pull-left submit">
            <input
              type="submit"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Opslaan"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: "kampeerplaatscategorieinsert",
  })(InsertForm)
);
