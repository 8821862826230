import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateeduurzaamtips, fechoneduurzaamtips } from "../Reducers/actions";
import EditForm from "./Components/edit";

const DuurzaamTipsUpdate = ({updateeduurzaamtips, fechoneduurzaamtips, tip }) => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const submit = (vals) => {
    updateeduurzaamtips(params.id, vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/gastenverbruik/duurzaam");
      }
    });
  };

  useEffect(() => {
    fechoneduurzaamtips(params.id).then(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Duurzaam Tips toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <EditForm onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, tip: state.gastenverbruik.tip};
}

export default connect(mapStateToProps, { updateeduurzaamtips, fechoneduurzaamtips })(DuurzaamTipsUpdate);
