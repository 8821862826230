import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { translate } from "../../../Components/Lib/Languages/language";

class InschrijvingenComponent extends React.Component {
  render() {
    const { activiteit } = this.props;

    function datum(cell) {
      return moment(cell).format("DD-MM-YYYY HH:mm");
    }

    function datumact(cell) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "aanwezig",
        text: "A",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "80px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          if (cellContent) {
            return <i className="fa fa-check" />;
          }
        },
      },
      {
        dataField: "betaald",
        text: "€",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "80px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          if (cellContent) {
            return <i className="fa fa-check" />;
          }
        },
      },
      {
        dataField: "gasten.naam",
        text: "Naam",
        sort: true,
      },
      {
        dataField: "personen",
        text: "Personen",
      },
    ];

    return (
      <div className="box-header with-border">
        <h2 className="page-header">
          {translate(activiteit.activiteit)}
          <small className="pull-right">{datumact(activiteit.datum) + " " + activiteit.tijd}</small>
        </h2>

        <div className="box-body box-profile inschrijving">
          Starten <span>{datum(activiteit.startinschrijving)}</span>
          Eindigen <span>{datum(activiteit.eindinschrijving)}</span>
          Maximaal aantal personen <span>{activiteit.maxdeelnemers}</span>
        </div>
        <div className="box-body box-profile inschrijving">
          <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={activiteit.inschrijvingen} defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]} columns={columns} />
        </div>
      </div>
    );
  }
}
export default InschrijvingenComponent;
