import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { connect } from "react-redux";
import { renderField, renderselapi, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import RichTextMarkdown from "../../../../Components/Forms/wysiwyg";
import UploadFormSingle from "../../../../Components/Forms/uploadsingle";

const EditForm = ({ handleSubmit }) => {
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <Field
              className="form"
              name="status"
              validate={required({ msg: "Type item is verplicht" })}
              label="Status bericht"
              component={renderselect}
              options={{
                true: "Publiceren",
                false: "Concept",
              }}
            />
            <Field name="title" type="input" validate={[required()]} component={renderField} label="Vul de titel on" />
            <Field name="subtitle" validate={[required()]} type="input" inputvalue="_id" inputnaam="naam" component={renderField} label="Vul de subtitel in" />
            <Field name="bericht" type="input" validate={[required()]}   component={RichTextMarkdown} label="Schhrijf het bericht" />
            <Field name="image" type="input" validate={[required()]}  component={UploadFormSingle} label="Header foto" />
          </div>
        </div>
        <div className="pull-left submit">
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "insertduurzametips",
  })(EditForm)
);
