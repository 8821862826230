import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updatevacatursforbedrijf, fetchvacatures, fetchvacatursbedrijf, fetchvacatursforbedrijven, fetchvacatursforbedrijf } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { connect } from "react-redux";
import BedrijvenForm from "./Components/edit";

const VacaturesEditBedrijven = ({ vacature, vacatures, vacatures_items, fetchvacatursforbedrijf, updatevacatursforbedrijf, bedrijf, fetchvacatures, fetchvacatursforbedrijven }) => {
  const [loaded, setLoaded] = useState(false);
  const [new_vacatures, setNew_vacatures] = useState([]);
  let navigate = useNavigate();
  const { vitems, id } = useParams();
  useEffect(() => {
    fetchvacatursforbedrijf(vitems, id).then((ddata) => {
      if (ddata.results) {
        fetchvacatures(vitems).then((sdata) => {
          if (sdata.results) {
            fetchvacatursforbedrijven(vitems).then((vdata) => {
              if (vdata.results) {
                setLoaded(true);
              }
            });
          }
        });
      }
    });
  }, []);
  useEffect(() => {
    if (vacatures && vacatures.length > 0 && vacatures_items && vacatures_items.length > 0) {
      const bedrijfsVacatureIds = vacatures.map((vture) => vture.vacature._id);
      const beschikbareVacatures = vacatures_items.filter((vture) => !bedrijfsVacatureIds.includes(vture._id) || vture._id == vacature.vacature._id);
      setNew_vacatures(beschikbareVacatures);

    }
  }, [vacatures, vacature]);

  const submit = (vals) => {
    updatevacatursforbedrijf(vitems, id, vals).then((data) => {
      if (data.type === "ERROR_VACATURES") {
        alert(data.payload);
        return false;
      } else {
        navigate("/vacatures/bedrijven/" + vitems + "/vacatures");
      }
    });
  };
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3>Vacatures voor {loaded && vacature.bedrijven.promotie.title} toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm vacatures={new_vacatures} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, vacatures_items: state.vacatures.items, bedrijf: state.vacatures.bedrijf, vacatures: state.vacatures.vacatures, vacature: state.vacatures.vacature };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  updatevacatursforbedrijf,
  fetchvacatursbedrijf,
  fetchvacatures,
  fetchvacatursforbedrijf,
  fetchvacatursforbedrijven,
})(VacaturesEditBedrijven);
