import { SET_SENSOREN, ADD_SENSOREN, SENSOR_ERROR, SET_SENSOR, SET_SENSOR_DAG, SET_SENSOR_UUR, SET_SENSOR_MAAND, SET_SENSOR_LAST, SET_INSTELLINGEN, SET_SCHAKELEN, SET_WATER_LEKKAGE, SET_SENSOR_ANALAYSE, GET_SENSOR_ANALYSE, SET_VOORSPELLINGEN } from "./actions";
export default function cats(
  state = {
    item: [],
    items: [],
    uur: [],
    maand: [],
    dag: [],
    last: [],
    instellingen: {},
    lekkage:{},
    analayse:[],
    voorspelling: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_SENSOREN:
      return { ...state, items: [...state.items, action.results] };
    case SET_SENSOR:
      return { ...state, item: action.results };
    case SET_SENSOR_UUR:
      return { ...state, uur: action.results };
    case SET_SENSOR_DAG:
      return { ...state, dag: action.results };
    case SET_SENSOR_MAAND:
      return { ...state, maand: action.results };
    case SENSOR_ERROR:
      return { ...state, error: action.payload };
    case SET_SENSOREN:
      return { ...state, items: action.results };
    case SET_SENSOR_LAST:
      return { ...state, last: action.results };
    case SET_WATER_LEKKAGE:
      return { ...state, lekkage: action.results };
    case GET_SENSOR_ANALYSE:
      return { ...state, analayse: action.results };
    case SET_SENSOR_ANALAYSE:
      return { ...state, analayse: action.results };
    case SET_SCHAKELEN:
      return {
        ...state,
        item: {
          ...state.item,
          schakelen: action.results,
        },
      };
    case SET_INSTELLINGEN:
      return { ...state, instellingen: action.results };
    case SET_VOORSPELLINGEN:
      return { ...state, voorspelling: action.results };
    default:
      return state;
  }
}
