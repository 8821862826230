import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { saveboekingen, fetchvacatures } from "./Reducers/actions";
import { message, fixmessage } from "../../Reducers/loading/actions";
import BoekingenTabel from "./Components/vacatures_tabel";
const BoekingenPage = ({ ervaring, fetchvacatures, vacatures }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchvacatures().then((sdata) => {
      if (sdata.results) {
        setLoaded(true);
      }
    });
  }, []);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>Vacatures</h2>
        </div>
        <div className="box-tools pull-right">
          <Link to="/vacatures/toevoegen" className="btn btn-primary btn-md float-right" role="group">
            Vacatures Toevoegen
          </Link>
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">


          <div className="box-body">
            <BoekingenTabel vacatures={vacatures} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.ervaringen.item, vacatures: state.vacatures.items };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  fetchvacatures,
})(BoekingenPage);
