import React from "react";
import { Routes, Route } from "react-router-dom";
import ItemStart from './add/index';
import ItemsZoek from './items';
import EditStart from './edit/index';
import EditItemWanneer from './edit/wanneer';
import EditItemWaar from './edit/waar';
import EditItemMedia from './edit/media';
import EditItemContact from './edit/contact';
import ItemsSetting from './Settings';
import NDTRCItems from './Ndtrc';
import ItemToppage from './top';
import ItemCorona from "./corona/";
import EditItemEigenschappen from './edit/eigenschappen';
import ArrangementenPage from "./arrangementen/index";
import ArrangementenAddPage from "./arrangementen/add";
import ArrangementenEdit from "./arrangementen/edit";
import ItemsZoekTags from './zoektags';
import ItemsTags from "./tags";

const ItemRouter = () => {
  return (
    <Routes>
      <Route path="/ndtrc" element={<NDTRCItems />} />
      <Route path="/top" element={<ItemToppage />} />
      <Route path="/tags" element={<ItemsZoekTags />} />
      <Route path="/list/tags" element={<ItemsTags />} />
      <Route path="/arrangementen" element={<ArrangementenPage />} />
      <Route path="/arrangementen/add" element={<ArrangementenAddPage />} />
      <Route path="/arrangementen/edit/:trcid" element={<ArrangementenEdit />} />
      <Route path="/corona" element={<ItemCorona />} />
      <Route path="/" element={<ItemsZoek />} />
      <Route path="/settings" element={<ItemsSetting />} />
      <Route path="/add" element={<ItemStart />} />
      <Route path="/edit/:trcid" element={<EditStart />} />
      <Route path="/edit/waar/:trcid" element={<EditItemWaar />} />
      <Route path="/edit/wanneer/:trcid" element={<EditItemWanneer />} />
      <Route path="/edit/media/:trcid" element={<EditItemMedia />} />
      <Route path="/edit/contact/:trcid" element={<EditItemContact />} />
      <Route path="/edit/eigenschappen/:trcid" element={<EditItemEigenschappen />} />
    </Routes>
  );
};

export default ItemRouter;
