import React, { useEffect } from "react";
import { required } from "redux-form-validators";
import { FieldArray, Field } from "redux-form";
import { renderField } from "../../../../../Components/Forms/renders";
import switcbutton from "../../../../../Components/Forms/switch";
import WebsiteFormSub from "./websiteformsubsub";

const TalenForm = ({ fields }) => {
  return (
    <div>
      <table className="table table-bordered websubtable">
        <tbody>
          {fields.map((website, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <Field name={`${website}.naam`} type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Naam van de pagina " />
                </td>
                <td>
                  <Field name={`${website}.slug`} type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Slug van de pagina" />
                </td>
                <td>
                  <Field name={`${website}.actief`} type="input" component={switcbutton} label="Pagina actief" />
                </td>
                <td className="align-middle text-end">
                  <button onClick={() => fields.remove(index)}>
                    <i className="fa fa-minus-circle"></i>
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td colSpan="4">
              <button type="button" onClick={() => fields.push({})}>
                Subpagina Toevoegen
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TalenForm;
