import React, { useEffect } from "react";
import { required } from "redux-form-validators";

import { Field } from "redux-form";
import locales from "../../../../../Components/Lib/Languages/locales.json";
import SelectBox from "../../../../../Components/Forms/selectbox";
import switcbutton from "../../../../../Components/Forms/switch";

const TalenForm = ({ fields }) => {
  const outputData = Object.keys(locales).map((key) => ({
    id: key,
    value: locales[key],
  }));

  return (
    <tbody>
      {fields.map((talen, index) => (
        <tr key={index}>
          <td>
            <Field validate={required({ msg: "Dit veld is verplicht" })} dateformat="DD-MM-YYYY" inputvalue="id" inputnaam="value" options={outputData} component={SelectBox} name={`${talen}.taal`} />
          </td>
          <td>
            <Field name={`${talen}.default`} type="input" component={switcbutton} />
          </td>
          <td>
            <button onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Pagina Toevoegen
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default TalenForm;
