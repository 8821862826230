import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
class dateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: moment(),
      date: null,
      startdate: moment(),
    };
  }
  componentWillMount() {
    if (this.props.input.value) {
      if (this.props.dateformat) {
        this.setState({ date: moment(this.props.input.value, this.props.dateformat).utc() });
      } else {
        this.setState({ date: moment(this.props.input.value).utc() });
      }
    } else if (this.props.defaultValue) {
      this.setState({ date: moment(this.props.defaultValue, "DD/MM/YYYY")})
      this.props.input.onChange({value: this.props.defaultValue});
    }

  }

  handleSelect = (val) => {
    const value = new moment(val, "DD/MM/YYYY");
    if (value.isValid()) {
      this.setState({ value });
      this.props.input.onChange(value);
    } 
  };


  render() {
    const { label, error, touched, warning } = this.props;
    return (
      <div>
        {label && <label>{label}</label>}
        <div>
          <SingleDatePicker
            displayFormat={() => "DD/MM/YYYY"}
            numberOfMonths={1}
            date={this.state.date}
            placeholder={label}
            isOutsideRange={(date) => date > new Date()}
            onDateChange={(date) => {
              this.setState({ date });
              this.handleSelect(date);
            }}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
          />{" "}
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default dateSelect;
