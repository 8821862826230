import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editsensor, getsensor } from '../Reducers/actions';
import { fetchalertprofiles } from "../Profiles/Reducers/actions";
import { fetchsensorid } from "../Instellingen/Reducers/actions";
import { useNavigate, useParams } from 'react-router-dom';

import SensorEditForm from './Components/edit';

const SensorEditPage = ({ editsensor, getsensor, fetchalertprofiles, alertprofile, fetchsensorid, devices }) => {
  const [loaded, setLoaded] = useState(false);
let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      await getsensor(id);
      await fetchalertprofiles();
      await fetchsensorid();
      setLoaded(true);
    };

    fetchData();
  }, [id, getsensor, fetchalertprofiles]);

  const submit = async (vals) => {
    delete vals['data'];
    try {
      const data = await editsensor(id, vals);
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/sensoren');
      }
    } catch (error) {
      // Foutafhandeling, indien nodig
    }
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Sensor wijzigen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded &&
                <SensorEditForm devices={devices} alertprofile={alertprofile} onSubmit={submit}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, sensoren: state.sensoren.item, alertprofile: state.alertprofile.items,   devices: state.sensorinstellingen.devices, };
}

export default connect(mapStateToProps, { editsensor, getsensor, fetchalertprofiles, fetchsensorid })(SensorEditPage);
