import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchvacatursbedrijf, updatevacatursbedrijf } from "../Reducers/actions";
import BedrijvenForm from "./Components/edit";

const BedrijfEditPage = () => {
  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoaded(false);
    dispatch(fetchvacatursbedrijf(id)).then((data) => {
      if (data.result) setLoaded(true);
    });
  }, [dispatch, id]);

  const submit = (vals) => {
    dispatch(updatevacatursbedrijf(id, vals)).then((data) => {
      if (data.type === "ERROR_VACATURES") {
        alert(data.payload);
        return false;
      } else {
        navigate("/vacatures/bedrijven/");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Bedrijven wijzigen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BedrijfEditPage;
