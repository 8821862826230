import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fechoneduurzaamtips } from "../Reducers/actions";

const DuurzameTipShow = ({ match, fechoneduurzaamtips, tip }) => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();

  useEffect(() => {
    fechoneduurzaamtips(params.id).then(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <div className="box box-default">
     <div className="box-header with-border">
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to={"/gastenverbruik/duurzaam/edit/"+tip._id}>
            <i className="fa fa-edit"></i>Wijzigen
          </Link>
        </div>
      </div>

      <div className="row">
        {loaded && (
          <div className="col-md-8 no-float">
            <div className="box box-primary">
              <div className="col-lg-12">
                <img src={tip.image.thumbnail ? tip.image.thumbnail.medium : tip.image.image} className="img-responsive custom-img" />
              </div>
              <div className="box-body box-profile">
                <h3 className="box-title"> {tip.title}</h3>
                <h5 className="box-title"> {tip.subtitle}</h5>
              </div>
              <div
            
                className="box-body"
                dangerouslySetInnerHTML={{
                  __html: tip.bericht,
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, tip: state.gastenverbruik.tip };
}

export default connect(mapStateToProps, { fechoneduurzaamtips })(DuurzameTipShow);
