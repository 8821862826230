import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { update, getone } from "./Reducers/actions";
import BedrijvenForm from "./Components/edit";
import { fetchbedrijven } from "../Bedrijven/Reducers/actions";
import { fetchervaringen } from "../../Ervaringen/Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";

const GebruikersEditPage = ({ username,  getone, fetchbedrijven, update, bedrijven, fetchervaringen, ervaringen }) => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
const navigate = useNavigate()
  useEffect(() => {
    setLoaded(false);
    getone(params.id).then(() => {
      fetchbedrijven().then(() => {
        fetchervaringen().then(() => {
          setLoaded(true);
        });
      });
    });
  }, [getone, fetchbedrijven, params.id]);

  const submit = (vals) => {
    update(params.id, vals).then((data) => {
      if (data.type === "GEBRUIKER_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/admin/gebruikers/");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Gebruiker wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm bedrijven={bedrijven} ervaringen={ervaringen} username={username} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  gebruiker: state.gebruikers.item,
  bedrijven: state.bedrijven.items,
  ervaringen: state.ervaringen.items,
});

export default connect(mapStateToProps, { update, getone, fetchbedrijven, fetchervaringen })(GebruikersEditPage);
