import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import SubMenu from "../Components/submenu";
import ComponentElements from "../Components/componentElements";
import { useNavigate } from "react-router-dom";

import { savepages, fetchcomponents, getonepagesbyslug, set } from "../../Reducers/actions";
import { connect } from "react-redux";
import { message, fixmessage } from "../../../../Reducers/loading/actions";

const TemplateNew = ({ savepages, message, fetchcomponents, templates, getonepagesbyslug, page }) => {
  const [sourceItems, setSourceItems] = useState([]);
  const location = useLocation();
  const [targetItems, setTargetItems] = useState([]);
  const [editType, setEditType] = useState({}); // Type dat bewerkt wordt in de modal
  const [lastId, setLastId] = useState(sourceItems.length + 1); // Start teller na laatste ID van bronItems
  const [selectedOption, setSelectedOption] = useState("");
  const [pageTemplate, setPageTemplate] = useState(null);
  const [pageSlug, setPageSlug] = useState(null);
  const [pageNaam, setPageNaam] = useState(null);
  const [pageType, setPageType] = useState(null);

  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // Als er geen bestemming is of als iemand probeert een item terug te zetten in de bronlijst, doe dan niets.
    if (!destination || (source.droppableId === "target" && destination.droppableId === "source")) return;

    if (source.droppableId === "source" && destination.droppableId === "target") {
      const draggedItem = sourceItems[source.index];

      var divid = Math.floor(Math.random() * 1000087670) + 1;
      setLastId(`target-${divid}`);
      // Nieuw item toevoegen aan doellijst
      setTargetItems((prevItems) => [...prevItems, { componentId: { ...draggedItem }, id: `target-${divid}` }]);
      setEditType({ ...draggedItem, id: `target-${divid}` });
    } else if (source.droppableId === "target" && destination.droppableId === "target") {
      // Herschikken binnen de doellijst
      const updatedTargetItems = Array.from(targetItems);
      const [removed] = updatedTargetItems.splice(source.index, 1);
      updatedTargetItems.splice(destination.index, 0, removed);

      setTargetItems(updatedTargetItems);
    }
  };

  const handleDelete = (id) => {
    const updatedTargetItems = Array.from(targetItems);
    const index = updatedTargetItems.findIndex((item) => item.id === id);
    updatedTargetItems.splice(index, 1);
    setTargetItems(updatedTargetItems);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionPageChange = (event) => {
    setPageTemplate(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    savepages({ components: targetItems, pagetemmplate: pageTemplate, slug: pageSlug, pageType: pageType, type: selectedOption, naam: pageNaam }).then((data) => {
      if (data.type === "GET_CMS_PAGE") {
        message(2000, "success", "De component is succesvol opgelsagen").then(() => {
          navigate("/cms/types/");
        });
      } else {
        message(4000, "danger", "Er is wat fout gegaan");
      }
    });
  };

  useEffect(() => {
    setLoaded(false);
    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get("slug");
    setPageSlug(queryParams.get("slug"));
    setPageType(queryParams.get("pageType"));
    setPageNaam(queryParams.get("naam"));
    fetchcomponents().then((data) => {
      const itemsWithIds = data.results.map((item, index) => ({
        ...item,
        id: (index + 1).toString(), // Hier voegen we een ID toe op basis van de index (plus 1 om te starten bij 1)
      }));
      setSourceItems(itemsWithIds);

      getonepagesbyslug(slug).then((data) => {

        if (data.type === "GET_CMS_PAGE" && data.results) {
          const itemsWithIds = data.results.components.map((item, index) => ({
            ...item,
            id: (index + 1).toString(), // Hier voegen we een ID toe op basis van de index (plus 1 om te starten bij 1)
          }));
          setTargetItems(itemsWithIds);
          setSelectedOption(data.results.type);
          if (data.results.pagetemmplate) setPageTemplate(data.results.pagetemmplate._id);
        }
        setLoaded(true);
      });
    });
  }, [fetchcomponents, setSourceItems, getonepagesbyslug, location]);
  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Types Templates</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              {loaded === true && (
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="box box-info">
                        <div className="box-header with-border">
                          <h3 className="box-title">
                            Nieuw Template voor <strong>{pageNaam}</strong>
                          </h3>
                        </div>
                        <div className="box-body">
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <label htmlFor="optionSelect">Selecteer een optie:</label>
                                <select className="form-control" id="optionSelect" value={selectedOption} onChange={handleOptionChange}>
                                  <option selected>Open this select menu</option>
                                  <option value="component">Component</option>
                                  <option value="page">Page Template</option>
                                </select>
                              </div>
                              {selectedOption == "page" && (
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label htmlFor="naam">Kies een Page Template</label>
                                    <select className="form-control" id="optionSelect" value={pageTemplate} onChange={handleOptionPageChange}>
                                      <option selected>Open this select menu</option>
                                      {templates.map((item, index) => (
                                        <option value={item._id}>{item.naam}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {selectedOption == "component" && (
                          <div className="box-body container-content">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <div className="row">
                                <div className="col-md-8 col-12">
                                  <div className="box">
                                    <div className="box-header with-border">
                                      <h3 className="box-title">Pagina Template</h3>
                                    </div>
                                    <div className="box-body">
                                      <Droppable droppableId="target" type="ITEM">
                                        {(provided) => (
                                          <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {targetItems.map((item, index) => {
                                

                                              return (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                  {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 box p-2 container-item">
                                                      <div className="box-header with-border">
                                                        <h3 className="box-title">{item.componentId.naam} </h3>
                                                        <div className="box-tools pull-right">
                                                          <div className="icon-container">
                                                            <button type="button" className="btn btn-sm btn-danger m-2" onClick={() => handleDelete(item.id)}>
                                                              <i className="fa fa-trash"></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="box-body">
                                                        <div className="row align-items-center">
                                                          {item.componentId.content.map((item, index) => (
                                                            <div className="col-8 box container-sub-item" key={index}>
                                                              <strong>{item.naam} </strong>
                                                            </div>
                                                          ))}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              );
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-12 ">
                                  <ComponentElements sourceItems={sourceItems} />
                                </div>
                              </div>
                            </DragDropContext>
                          </div>
                        )}

                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                  Opslaan
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return { components: state.cms.components.items, templates: state.cms.templates.items, page: state.cms.pages.item };
}

export default connect(mapStateToProps, {
  savepages,
  fetchcomponents,
  message,
  getonepagesbyslug,
})(TemplateNew);
