import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { inserteduurzaamtips } from "../Reducers/actions";
import { useNavigate } from "react-router-dom";
import Form from "./Components/form";

const DuurzaamTipsInsert = ({inserteduurzaamtips }) => {
  const navigate = useNavigate();
  const submit = (vals) => {
    inserteduurzaamtips(vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/gastenverbruik/duurzaam");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Duurzaam Tips toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <Form onSubmit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user };
}

export default connect(mapStateToProps, { inserteduurzaamtips })(DuurzaamTipsInsert);
