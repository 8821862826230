import React, { useEffect } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import moment from "moment";

const BoekingenTabelDetail = ({ vacatures }) => {
  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: (cell, row) => {
        return moment(cell).fromNow();
      },
    },
    {
      dataField: "titel",
      text: "Titel",
    },
    {
      dataField: "beschrijving",
      text: "beschrijving",
      formatter: (cell, row) => {
        const cleanText = cell.replace(/<[^>]+>/g, ""); // Verwijder HTML-tags
        return cleanText.substring(0, 50) + "...";
      },
    },
    {
      dataField: "afdeling",
      text: "Afdeling",
      formatter: (cell, row) => {
        return cell.join(", ");
      },
    },

    {
      dataField: "status",
      text: "Status",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },

    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning" to={"/vacatures/edit/" + row._id}>
              Wijzigen
            </Link>{" "}
            <Link className="btn btn-info" to={"/vacatures/bedrijven/" + row._id}>
              Bedrijven
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];

  return (
    <ToolkitProvider
      keyField="_id"
      data={vacatures}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
    >
      {(props) => (
        <div>
          <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
          <hr />
          <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {})(BoekingenTabelDetail);
