import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf, getactiviteit, savinschrijving } from "./Reducers/actions";
import InschrijfForm from "./Components/inschrijfform";
import Activiteit from "./Components/activiteiten";
import Loading from "../../../Components/loading/load";
import { checkgast, Sendmail, gast } from "../Gasten/Reducers/actions";
import { lang, datumlang } from "../../../Components/Lib/Languages/language";

function Inschrijven() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendmail, setSendmail] = useState(false);
  const [inschrijven, setInschrijven] = useState(false);
  const [aanmeldingen, setAanmeldingen] = useState(0);
  const [maxdeelnemers, setMaxdeelnemers] = useState(0);
  const [openstand, setOpenstand] = useState(0);

  const { activiteit, bedrijf } = useSelector((state) => ({
    activiteit: state.pubred.activiteit,
    bedrijf: state.pubred.bedrijf,
  }));

  useEffect(() => {
    dispatch(getactiviteit(id)).then((data) => {
      if (data.results) {
        setMaxdeelnemers(data.results.maxdeelnemers);
        if (data.results.aanmeldingen.length > 0) {
          var openstaand = Math.round(data.results.maxdeelnemers - data.results.aanmeldingen[0].personen);
          if (openstaand < 1) {
            dispatch(
              fixmessage(
                "danger",
                lang("Het maximaal aantal personen is bereikt. Je kan je helaas niet meer inschrijven")
              )
            );
          }
          setAanmeldingen(data.results.aanmeldingen[0].personen);
          setOpenstand(openstaand);
        } else {
          setOpenstand(data.results.maxdeelnemers);
        }
        if (!bedrijf._id) {
          dispatch(getbedrijf(data.results.bedrijf)).then((bdata) => {
            setLoading(true);
          });
        } else {
          setLoading(true);
        }
      }
    });

    return () => {
      dispatch(clearmessage());
    };
  }, [dispatch, id, bedrijf._id]);

  const submit = (vals) => {
    setLoading(false);
    vals.datumactiviteit = activiteit.datum;
    if (parseInt(vals.personen, 0) <= openstand) {
      dispatch(savinschrijving(id, vals)).then((data) => {
        if (data.type === "FACILITEIT_ERROR") {
          alert(data.payload);
          setLoading(true);
          return false;
        } else {
          dispatch(message(2000, "success", lang("Je inschrijving is succesvol verwerkt"))).then((data) => {
            navigate("/public/activiteiten/" + bedrijf._id);
          });
        }
      });
    } else {
      setLoading(true);
      dispatch(
        message(
          4000,
          "danger",
          lang("Het maximaal aantal personen is bereikt. Je kan nog") +
            " " +
            openstand +
            " " +
            lang("personen aanmelden")
        )
      );
    }
  };

  function datum(cell) {
    return <h4 className="activiteit-datum">{datumlang(cell, "dddd DD MMMM YYYY")}</h4>;
  }

  return (
    <div className="public-box-body">
      <Loading loading={loading} />
      {loading && (
        <div>
          {datum(activiteit.datum)}
          <Activiteit activiteit={activiteit} />

          <h2 className="headline text-yellow"> {lang("Inschrijven")}</h2>
          {openstand > 0 && (
            <div className="inschrijf-content">
              <InschrijfForm onSubmit={submit} />
            </div>
          )}
        </div>
      )}
      <Link
        to={{
          pathname: "/public/activiteiten/" + activiteit.bedrijf,
        }}
        className="back-arrow"
      >
        <i className="fa fa-chevron-left"></i> {lang("Terug")}
      </Link>
    </div>
  );
}

export default Inschrijven;
