import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Animated } from "react-animated-css";
import { fetchreserveringen, gethoreca, deletereservering, savereververing } from "./Reducers/actions";
import { translate } from "../../Components/Lib/Languages/language";
import { message } from "../../Reducers/loading/actions";
import DatumForm from "./Components/dateformres";
import InschrijfForm from "./Components/inschrijven";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ComponentToPrint from "./Components/print";
import Bar from "./Components/bar";
import moment from "moment";

function InschrijfPageHoreca() {
  const [loaded, setLoaded] = useState(false);
  const [inschrijven, setInschrijven] = useState(false);
  const [tijden, setTijden] = useState([]);
  const [datum, setDatum] = useState(moment());
  const [tijd, setTijd] = useState("");
  const [aanwezig, setAanwezig] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    gethoreca(id).then((sdata) => {
      if (sdata.results) {
        setDatum(moment().toISOString());
      }
    });
  }, [id]);

  const submit = (vals) => {
    setTijd(vals.tijd);
    loadreserveringen(moment(vals.datum).toISOString(), vals.tijd, vals.aanwezig);
  };

  const reserveringenupdate = (vals) => {
    vals.faciliteit = id;
    savereververing(vals).then((data) => {
      if (data.type === "HORECA_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(4000, "success", "Je reservering is succesvol verwerkt").then((data) => {
          loadreserveringen(moment(vals.datum).toISOString(), tijd, aanwezig);
          setInschrijven(false);
        });
      }
    });
  };

  const loadreserveringen = (datum, tijd, aanwezig) => {
    fetchreserveringen({ datum: datum, horeca: id, tijd: tijd, aanwezig: aanwezig }).then((data) => {
      setLoaded(true);
      setDatum(datum);
      setAanwezig(aanwezig);
    });
  };

  const setdate = (datum) => {
    loadreserveringen(moment(datum).toISOString(), tijd, aanwezig);
  };

  const deletein = (id) => {
    deletereservering(id).then((deln) => {
      message(4000, "success", "De inschrijving is succesvol verwijderd");
      loadreserveringen(datum, tijd, aanwezig);
    });
  };

  const setdatum = (datum) => {
    gethoreca(id).then((sdata) => {
      if (sdata.results) {
        setdate(datum);
      }
    });
  };

  const showrrser = () => {
    setInschrijven(true);
  };

  const nodatainfo = (info) => {
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-warning"}>
            <p>{info}</p>
          </div>
        </Animated>
      );
  };

  const { horeca, reserveringen } = this.props;

  function priceFormatter(cell, row) {
    return moment(cell).format("DD-MM-YYYY HH:mm");
  }
  function tafel(cell, row) {
    if (cell === 0) {
      return "Terras";
    } else if (cell === 1) {
      return "Binnenlocatie";
    } else {
      return cell;
    }
  }
  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: priceFormatter,
    },
    {
      dataField: "tafel",
      text: "Tafel",
      formatter: tafel,
    },
    {
      dataField: "gasten.naam",
      text: "Naam",
      sort: true,
    },
    {
      dataField: "gasten.email",
      text: "E-mailadres",
    },
    {
      dataField: "personen",
      text: "Personen",
    },
    {
      text: "Toevoegen",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <button className="btn btn-danger pull-right" onClick={deletein.bind(this, row._id)}>
              X
            </button>
          </span>
        );
      },
    },
  ];

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>{translate(horeca.naam)}</h2>
        </div>
        <button className="btn btn-primary btn-sm float-right" onClick={() => navigate("/horeca")} role="group">
          Overzicht horeca
        </button>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div>
            <DatumForm tijden={tijden} changedate={setdate} onSubmit={submit} />
          </div>
          <div>
            <Bar reserveringen={reserveringen} datumres={datum} tijd={tijd} />
          </div>
          <div className="pull-right">
            <ReactToPrint content={() => this.componentRef}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button className="btn btn-info btn" onClick={handlePrint}>
                    Printen
                  </button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </div>
          {inschrijven && (
            <div className="inschrijform">
              <InschrijfForm tijden={tijden} setdate={setdatum} onSubmit={reserveringenupdate} />
              {nodatainfo("Het is helaas niet mogelijk een reservering te maken op deze datum")}
            </div>
          )}
          <div className="box-body">
            <div style={{ display: "none" }}>
              <ComponentToPrint reserveringen={reserveringen} horeca={horeca} datum={datum} tijden={tijden} tijd={tijd} ref={(el) => (this.componentRef = el)} />
            </div>
            <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={reserveringen} columns={columns} pagination={paginationFactory({ sizePerPage: 25 })} defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]} />
          </div>
        </div>
      )}
    </div>
  );
}

export default InschrijfPageHoreca;
