import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { savevacatures } from "./Reducers/actions";
import BedrijvenForm from "./Components/form";

const VacatureInsert = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const submit = (vals) => {
    dispatch(savevacatures(vals)).then((data) => {
      if (data.type === "ERROR_VACATURES") {
        alert(data.payload);
        return false;
      } else {
        navigate("/vacatures");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Vacature Toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <BedrijvenForm onSubmit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacatureInsert;
