import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment, { min } from "moment";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
const MeterFormatter = ({ cellContent }) => {
  if (cellContent.length > 0) {
    return (
      <div style={{ minWidth: "500px" }}>
        <table className="table table-sm tb_th_orange">
          <tbody>
            {cellContent.map((meter, index) => {
              if (meter.meter_data.length > 0) {
                return (
                  <tr style={{ height: "46px" }}>
                    <td>{moment(meter.meter_data[0].datum).format("DD-MM-yyyy HH:mm")}</td>
                    <td>{meter.soort.charAt(0).toUpperCase() + meter.soort.slice(1)}</td>
                    <td>
                      {meter.sensor_info.correctie ? (
                        <span className="badge badge-success">
                          {Number(meter.meter_data[0].value + meter.sensor_info.correctie)} <i className="fa-solid fa-triangle-exclamation fa-xs"></i>
                        </span>
                      ) : (
                        <span className="badge badge-success">{Number(meter.meter_data[0].value)}</span>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
  return null; // Retourneer null als er geen meters zijn
};

const MeterFormatterGraph = ({ cellContent }) => {
  if (cellContent.length > 0) {
    return (
      <table className="table table-sm tb_th_orange">
        <tbody>
          {cellContent.map((meter, index) => {
            const data = [];
            if (meter.meter_data.length > 0) {
              meter.meter_data.forEach((a) => {
                data.push(a.value);
              });
            }
            return (
              <tr style={{ height: "46px" }}>
                <td>
                  <Sparklines data={data} margin={5} height={29}>
                    <SparklinesLine style={{ strokeWidth: 1, stroke: "#d96a29", fill: "none" }} />
                    <SparklinesSpots size={2} style={{ stroke: "#d96a29", strokeWidth: 2, fill: "white" }} />
                  </Sparklines>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return null; // Retourneer null als er geen meters zijn
};

const MeterFormatterLink = ({ cellContent, row }) => {
  if (cellContent.length > 0) {
    return (
      <table className="table table-sm tb_th_orange">
        <tbody>
          {cellContent.map((meter, index) => (
            <tr style={{ height: "46px" }}>
              <td>
                <Link className="btn btn-success btn-sm" to={{ pathname: "/standplaatsen/accommodaties/show/" + row._id + "/" + meter.sensor_id }}>
                  {meter.soort.charAt(0).toUpperCase() + meter.soort.slice(1)} Bekijken
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return null; // Retourneer null als er geen meters zijn
};

const KampeerplaatsLijst = ({ accommodaties }) => {
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "naam",
      text: "Naam Sensor",
      style: {
        alignContent: "center",
        width: "180px",
      },
      headerStyle: { width: "180px"},  
      formatter: (cellContent, row) => {
        if (cellContent.NL) {
          return cellContent.NL;
        } else {
          return cellContent;
        }
      },
    },
    {
      dataField: "meters",
      text: "Meterstand",
      style: { padding: "0px", width: "500px" },
      headerStyle: { width: "500px"},  
      formatter: (cellContent, row) => <MeterFormatter cellContent={cellContent} />,
    },
    {
      text: "Bekijken",
      dataField: "meters",
      headerAlign: "center",
      align: "center",
      style: { padding: "0px"},

      formatter: (cellContent, row) => <MeterFormatterLink cellContent={cellContent} row={row} />,
    },
  ];

  return (
    <ToolkitProvider
      keyField="_id"
      data={accommodaties}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
    >
      {(props) => (
        <div>
          <SearchBar placeholder="Zoeken" {...props.searchProps} />
          <hr />
          <BootstrapTable wrapperClasses="table-responsive-lg accommodatietabel" pagination={paginationFactory({ sizePerPage: 20 })} {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default KampeerplaatsLijst;
