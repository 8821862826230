function laatstedatafunctie(row) {

  if (row.sensordatas.length > 0) {
    return (row.sensordatas[0].value).toFixed(2);
  } else if (row.electradatas.length > 0) {
    return (Number(row.electradatas[0].value) - Number(row.electradatas[1].value)).toFixed(2);
  }
  else {
    return "Geen data";
  }
}

export default laatstedatafunctie;
