import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getconnections, editconnections, getinstapost } from "../Reducers/actions";
import Cookies from "js-cookie";
function ShowInstgram({ connections, getconnections, getinstapost, posts }) {
  const [loaded, setLoaded] = useState(false);
  const [loadPhoto, setLoadPhoto] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    setLoaded(false);
    getconnections(id).then((data) => {
      if (data.results.user_id) {
        getinstapost(id).then(() => {
          setLoadPhoto(true);
        });
      }
      setLoaded(true);
    });
  }, [getconnections, id]);

  const connectto = () => {
    if (connections.soort === "Instagram") {
      const redirect_uri = window.location.origin + "/admin/connections/callback/instagram";
      Cookies.set("myconnectionid", id, { expires: 1 }); // De cookie vervalt over 7 dagen
      const url = `https://api.instagram.com/oauth/authorize?client_id=${connections.client_id}&redirect_uri=${redirect_uri}&scope=user_profile,user_media&response_type=code`;
      window.open(url, "_self");
    }
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht connections</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/admin/connections/insert">
            <i className="fa fa-plus"></i>&nbsp; Connections Toevoegen
          </Link>
        </div>
      </div>
      {loaded && (
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile" />
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0">Gegevens</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Naam</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.naam} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Soort</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.soort} </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Client ID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.client_id} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Client Secret</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.client_secret} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Refresh Auth code</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.refresh_token} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Auth token</h6>
                  </div>
                  <div className="col-sm-7 text-secondary"> {connections.accessToken} </div>
                  <div className="col-sm-2 text-secondary">
                    <button onClick={connectto} className="btn btn-warning">
                      Maak een connectie
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div className="box-body box-profile" />
          <div className="card">
            <div className="card-header">
              <h4 className="mb-0">Foto's</h4>
            </div>
            <div className="card-body">
              {loadPhoto && (
                <div className="row">
                  {posts.map((post, index) => (
                    <div key={index} className="col-sm-3" style={{ padding: "10px" }}>
                      <div className="image-container" style={{ width: "100%", paddingBottom: "100%", position: "relative", overflow: "hidden" }}>
                        <img
                          src={post.media_url}
                          className="img-thumbnail img-fluid"
                          alt="post"
                          style={{
                            position: "absolute",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { connections: state.connections.item, posts: state.connections.posts };
};

export default connect(mapStateToProps, { editconnections, getconnections, getinstapost })(ShowInstgram);
