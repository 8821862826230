import React from "react";
import { Field, reduxForm, useReduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../../Components/Forms/renders";
import UploadFormSingle from "../../../../Components/Forms/uploadsingle";
import Autosuggest from "../../../../Components/Forms/autocomplete";
import RichTextMarkdown from "../../../../Components/Forms/wysiwyg";

let BedrijvenVacatureForm = (props) => {
  const { handleSubmit, change } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <Field name="items" type="input" component={Autosuggest} label="Kies een bedrijf" validate={[required()]} />
          <div className="box box-default p-2 m-3">
            <h3 className="box-header">Promotie</h3>
            <Field name="promotie.title" type="input" component={renderField} label="Kies een title" validate={[required()]} />
            <Field name="promotie.discription" type="input" component={RichTextMarkdown} label="Vul korte bedrijving van je bedrijf" validate={[required()]} />
            <Field name="promotie.image" type="input" component={UploadFormSingle} label="Kies een foto" />
          </div>
          <div className="box box-default p-3 m-3">
            <h3 className="box-header">Contactpersoon</h3>
            <Field name="contactpersoon.naam" type="input" component={renderField} label="Naam" />
            <Field name="contactpersoon.email" type="input" component={renderField} label="Email" />
            <Field name="contactpersoon.telefoon" type="input" component={renderField} label="Telefoon" />
          </div>
        </div>
      </div>
      <div className="pull-left submit">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
      </div>
    </form>
  );
};

BedrijvenVacatureForm = reduxForm({
  form: "bedrijfinsert", // a unique identifier for this form
})(BedrijvenVacatureForm);

BedrijvenVacatureForm = connect()(BedrijvenVacatureForm);

export default BedrijvenVacatureForm;
