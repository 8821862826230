import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { fetchhoreca } from "./Reducers/actions";
import Horeca from "./Components/horeca";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";

const FacilitetenPage = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const horeca = useSelector((state) => state.horecap.items);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getbedrijf(params.id));
      if (!data.results) {
        navigate("/404");
      } else {
        await dispatch(fetchhoreca(data.results._id));
        setLoading(true);
      }
    };

    fetchData();
  }, [dispatch, params.id, navigate]);

  return (
    <div className="public-box-body">
      <h2 className="headline text-yellow facheadline"> {lang("Horeca gelegenheden")}</h2>
      <Loading loading={loading} />
      {loading && (
        <div className="activiteit-content">
          <div className="gastinfo">
            {lang(
               "Om gebruik te maken van onze horeca is het verplicht om je per tafel te registeren. Hier vind je de horecagelegenheid"
            )}
          </div>
          {horeca.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
          {horeca.map((horec) => (
            <div key={horec._id} className="activiteit-content">
              <Horeca horeca={horec} link={true} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FacilitetenPage;
