import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updatefaciliteit, getfaciliteit, delfaciliteit } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import LogboekEditForm from "./Components/edit";

function LogboekEditPage({ getfaciliteit, updatefaciliteit, delfaciliteit, message }) {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getfaciliteit(id).then((data) => {
      if (data.results) {
        // Do something
      } else {
        message(2000, "warning", "Er is een fout opgetreden bij het laden van de activiteit").then((data) => {
          // Do something
        });
      }
    });
  }, [getfaciliteit, id, message]);

  const submit = (vals) => {
    updatefaciliteit(id, vals).then((data) => {
      if (data.type === "FACILIEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De faciliteit is met succes aangepast").then((data) => {
          navigate("/faciliteiten");
        });
      }
    });
  };

  const verwijderen = () => {
    delfaciliteit(id).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De faciliteit is met succes verwijderd").then((data) => {
          navigate("/faciliteiten");
        });
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Faciliteit wijzigen</h3>
        <div className="pull-right">
          <button onClick={verwijderen} className="btn btn-next btn-fill btn-danger btn-wd btn-sm">
            Verwijderen
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <LogboekEditForm onSubmit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { username: state.auth.user, faciliteit: state.faciliteiten.item };
}

export default connect(mapStateToProps, { updatefaciliteit, getfaciliteit, message, delfaciliteit })(LogboekEditPage);
