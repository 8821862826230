import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { PeriodeJaarlijks } from "./Reducers/events/actions";

const EventPageJaarlijks = ({ events, PeriodeJaarlijks }) => {
  const [zoeken, setZoeken] = useState(false);

  useEffect(() => {
    PeriodeJaarlijks();
    setZoeken(true);
  }, []);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Jaarlijkse Evenementen ({zoeken && events.length})</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Evenement Toevoegen
          </Link>
        </div>
      </div>
      <div className="box-body">
        <div className="box-body">
          <div className="box-body table-responsive no-padding">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>Titel</th>
                  <th>Beschrijving</th>
                  <th>Eigenschappen</th>
                </tr>
                {zoeken &&
                  events.map((app) => (
                    <tr key={app._id}>
                      <td>{app.what.title.value}</td>
                      <td>{app.what.shortdescription.value.substr(0, 30)}</td>
                      <td>{app.event.everyyear && <i className="fa-solid fa-repeat"></i>}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.auth.error,
  events: state.events.events,
});

export default connect(mapStateToProps, { PeriodeJaarlijks })(EventPageJaarlijks);
