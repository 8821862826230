import React, { useState } from "react";
import { Link } from "react-router-dom";
const EvenementenMenu = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("items")) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fas fa-calendar-alt"></i> <span>VVV Evenementen</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
            <li>
              <Link to="/evenementen" onClick={() => setIsActive(!isActive)}>
                <i className="fas fa-calendar-alt"></i> <span>Evenementen</span>
              </Link>
            </li>
            <li>
              <Link to="/evenementen/jaarlijks" onClick={() => setIsActive(!isActive)}>
                <i className="fas fa-calendar-alt"></i> <span>Jaarlijks</span>
              </Link>
            </li>
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default EvenementenMenu;
