import React, { useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const AuthContext = createContext();

export const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authenticated = useSelector((state) => state.auth.authenticated);

  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, location, params]);

  const checkAuthentication = () => {
    if (!authenticated) {
      navigate("/login");
    } else {
      return true;
    }
  };



  return authenticated ? children : null;
};

export const useAuth = () => {
  return useContext(AuthContext);
}
