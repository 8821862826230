import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetcharrangementen } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

const ArrangementenPage = ({ fetcharrangementen, arrangementen, user }) => {
  useEffect(() => {
    fetcharrangementen();
  }, [fetcharrangementen]);

  const getstatus = (actief) => {
    if (actief) {
      return <small className="label bg-green">Actief</small>;
    } else {
      return <small className="label bg-orange">Non Actief</small>;
    }
  };

  const columns = [
    {
      dataField: "naam",
      text: "Naam",
    },
    {
      dataField: "beschrijving.short",
      text: "Beschrijving",
    },
    {
      dataField: "actief",
      text: "Status",
      formatter: getstatus,
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            {user.role === "admin" || user.role === "manager" || user.role === "medewerker" ? (
              <Link className="btn btn-warning ml-2" to={"/arrangementen/edit/" + row._id}>
                Wijzigen
              </Link>
            ) : null}{" "}
            <Link className="btn btn-success" to={"/arrangementen/beschikbaarheid/" + row._id}>
              Beschikbaarheid
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "300px" };
      },
    },
  ];

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht Arrangementen</h3>
            <div className="box-tools pull-right">
            {user.role === "admin" || user.role === "manager" || user.role === "medewerker" ? (
              <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/arrangementen/toevoegen">
                <i className="fa fa-plus"></i>&nbsp; Arrangement Toevoegen
              </Link>
                 ) : null}{" "}
            </div>
 
      </div>
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile" />
          <ToolkitProvider
            keyField="_id"
            data={arrangementen}
            columns={columns}
            search={{
              searchFormatted: true,
            }}
          >
            {(props) => (
              <div>
                <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
                <hr />
                <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, arrangementen: state.arrangementen.items };
}

export default connect(mapStateToProps, { fetcharrangementen })(ArrangementenPage);
