import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import moment from "moment";

const BoekingenTabelDetail = ({ beschikbaarheid, loadbeschikbaarheid }) => {
  const getstatus = (actief) => {
    if (actief === "Nieuw") {
      return <small className="badge bg-primary">Nieuw</small>;
    } else if (actief === "actief") {
      return <small className="badge bg-success">Actief</small>;
    } else if (actief === "cancel") {
      return <small className="badge bg-danger text-dark">Geweigerd</small>;
    } else if (actief === "annuleren") {
      return <small className="badge bg-warning text-dark">Geannuleerd</small>;
    } else {
      return <small className="badge bg-warning text-dark">{actief}</small>;
    }
  };

  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: (cell, row) => {
        return moment(cell).format("DD-MM-YYYY hh:mm");
      },
    },
    {
      dataField: "starttime",
      text: "Starttijd",
    }, 
    {
      dataField: "endtime",
      text: "Eindtijd",
    },
    {
      dataField: "aantalreserveringen",
      text: "Aantal reserveringen",
    },
    {
      dataField: "aantalpersonen",
      text: "Aantal personen",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: getstatus,
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <button
              className="btn btn-warning ml-2"
              onClick={() => {
                loadbeschikbaarheid(row._id);
              }}
            >
              Wijzigen
            </button>{" "}
            <Link className="btn btn-info" to={"/boekingen/ervaring/" + row._id}>
              Boekingen
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];

  return (
    <ToolkitProvider
      keyField="_id"
      data={beschikbaarheid}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
    >
      {(props) => (
        <div>
          <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
          <hr />
          <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {})(BoekingenTabelDetail);
