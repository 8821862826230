import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
  render() {
    const { sensor } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY HH:mm");
    }
    function getdata(data) {
      const transformed = data.data
        .map((value, key) => {
          if (key > 0) {
            return {
              value: (data.data[key - 1].value - value.value),
              orgvalue: (data.data[key - 1].value.toFixed(2)),
              datum: data.data[key-1].datum,

            };
          } else {
            return {};
          }
        })
        .slice(1);

      return transformed;
    }

    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "value",
        text: "KwH",
        formatter: (cellContent, row) => {
          if (cellContent) {
              return <span className="badge badge-success">{cellContent.toFixed(2)}</span>;
          
          } else {
            return null;
          }
        },
      },
      {
        dataField: "orgvalue",
        text: "Meterstand",
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="datum" data={getdata(sensor)} columns={columns} pagination={paginationFactory()} />;
  }
}
