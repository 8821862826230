import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../Components/Forms/renders";
import MultiSelect from "../../../Components/Forms/multiselect";
import RichTextMarkdown from "../../../Components/Forms/wysiwyg";

const BedrijvenForm = (props) => {
  const { handleSubmit, change } = props;

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="titel" type="input" component={renderField} label="Kies een title voor de vacature" validate={[required()]}  />
              <Field name="beschrijving" type="input" component={RichTextMarkdown} label="Vul korte bedrijving van je de vacature" validate={[required()]}  />
              <Field
                name="afdeling"
                type="input"
                component={MultiSelect}
                options={["recreatie","horeca","groen","techniek","schoonmaak","administratie","beveiliging","zorg","overig","animatie"]}
                label="Kies een afdeling"
              />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
          </div>
        </div>
      </form>
    </div>
  );
};
let ReduxBedrijvenForm = reduxForm({
  form: "vacatures_edit", // een unieke identifier voor dit formulier
})(BedrijvenForm);

ReduxBedrijvenForm = connect(
  (state) => ({
    initialValues: state.vacatures.item, // haal initiële waarden uit account reducer
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxBedrijvenForm);

export default ReduxBedrijvenForm;
