import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import SimpleForm from '../wizzard/startnew';
import { SaveItem, UpdateItem, itemError, Clear } from '../Reducers/items/actions';
import Publiceren from '../Components/Publiceren';
import ErrorMessage from '../../../Components/loading/error';

const ItemStart = ({ item, error }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (item._id) {
      dispatch(Clear());
    }
  }, [dispatch, item._id]);

  const submit = (values) => {
    if (!item._id) {
      dispatch(SaveItem(values)).then((result) => {
        if (result._id) {
          navigate("/items/edit/waar/" + result.metadata.trcid);
        }
      });
    } else {
      update(values);
    }
  };

  const update = (vals) => {
    const values = Object.assign({}, item, vals);
    dispatch(UpdateItem(item._id, values)).then(() => {
      if (item._id) {
        navigate("/items/edit/waar");
      }
    });
  };

  return (
    <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Nieuw item</h3>
          <div className="box-tools pull-right">
            <Publiceren />
          </div>
        </div>
        <ErrorMessage fout={error} />
        <div className="box-body wizard">
          <ul className="nav nav-justified thumbnail setup-panel">
            <li className="active flex-item">
              <Link to="/items/add">
                Wat
              </Link>
            </li>
            <li className="flex-item disabled">
              <span>Waar</span>
            </li>
            <li className="flex-item disabled">
              <span>Wanneer</span>
            </li>
            <li className="flex-item disabled">
              <span>Media</span>
            </li>
            <li className="flex-item disabled">
              <span>Eigenschappen</span>
            </li>
          </ul>
          <SimpleForm onSubmit={submit} />
        </div>
      </div>
    );
  }


function mapStateToProps(state) {
  return { errors: state.auth.error, item: state.items.item, error: state.items.error };
}
export default connect(mapStateToProps, { SaveItem, itemError, UpdateItem, Clear })(ItemStart);
