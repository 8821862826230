import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { fetchopenfaciliteiten } from "./Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import Faciliteiten from "./Components/faciliteit";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";

const FacilitetenPage = ({ fetchopenfaciliteiten }) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const bedrijf = useSelector((state) => state.pubred.bedrijf);
  const faciliteiten = useSelector((state) => state.openfaciliteiten.items);

  useEffect(() => {
    getbedrijf(params.id).then((data) => {
      if (!data.results) {
        navigate("/404");
      } else {
        fetchopenfaciliteiten(data.results._id).then(() => {
          setLoading(true);
        });
      }
    });
  }, [params.id, navigate, fetchopenfaciliteiten]);

  return (
    <div className="public-box-body">
      <Link
        className="btn btn-success pull-right bt-inschrijvingen"
        to={`/public/reserveringen/${bedrijf._id}`}
      >
        {lang("Mijn reserveringen")}
      </Link>
      <h2 className="headline text-yellow facheadline">{lang("Faciliteiten")}</h2>
      <Loading loading={loading} />
      {loading && (
        <div className="activiteit-content">
          <div className="gastinfo">
            {lang("Hieronder staan de faciliteiten waarvoor je een plekje kunt reserveren op een rijtje.\nVia de knop ‘mijn reserveringen’ zie je terug voor welke faciliteiten je een reservering hebt gemaakt. Hier kun je eventueel ook je reservering annuleren.")}
          </div>
          {faciliteiten.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")}</h3>}
          {faciliteiten.map((faciliteit) => (
            <div key={faciliteit._id} className="activiteit-content">
              <Faciliteiten faciliteit={faciliteit} link={true} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  message,
  getbedrijf,
  fetchopenfaciliteiten,
})(FacilitetenPage);
