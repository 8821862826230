export const SET_GASTEN_VERBRUIK = "SET_GASTEN_VERBRUIK";
export const ADD_GASTEN_VERBRUIK = "ADD_GASTEN_VERBRUIK";
export const GASTEN_VERBRUIK_ERROR = "GASTEN_VERBRUIK_ERROR";
export const SET_GAST_VERBRUIK = "SET_GAST_VERBRUIK";
export const SET_VERBRUIK = "SET_VERBRUIK";
export const SET_GASTS = "SET_GASTS";
export const SET_GAST = "SET_GAST";
export const SET_TIPS = "SET_TIPS";
export const SET_TIP = "SET_TIP";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then((errorData) => {
      let error = new Error(response.statusText);
      error.data = errorData.errors;
      throw error;
    });
  }
}

export function set(results) {
  return {
    type: SET_GASTEN_VERBRUIK,
    results,
  };
}

export function get(results) {
  return {
    type: SET_GAST_VERBRUIK,
    results,
  };
}

export function setstam(results) {
  return {
    type: SET_GAST,
    results,
  };
}

export function getstam(results) {
  return {
    type: SET_GASTS,
    results,
  };
}

export function setverbruik(results) {
  return {
    type: SET_VERBRUIK,
    results,
  };
}

export function setone(results) {
  return {
    type: SET_GAST_VERBRUIK,
    results,
  };
}
export function add(results) {
  return {
    type: ADD_GASTEN_VERBRUIK,
    results,
  };
}

export function gettip(results) {
  return {
    type: SET_TIP,
    results,
  };
}

export function settips(results) {
  return {
    type: SET_TIPS,
    results,
  };
}

export function itemError(error) {
  return {
    type: GASTEN_VERBRUIK_ERROR,
    error,
  };
}

export function addgastengebruik(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results));
      return true;
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchgastenverbruik() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchgastenverbruikone(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(get(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function deletegastverbruik(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/" + id, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(get(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function updategastengebruik(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(get(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function addgasten(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/stamgegevens", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });

      const results = await handleResponse(response);
      return dispatch(setstam(results));
    } catch (err) {
      // Hier kun je meer gedetailleerde informatie uit err.data halen, indien beschikbaar
      return dispatch(itemError(err));
    }
  };
}

export function fechduurzaamtips() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/duurzaamtips", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(settips(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fechoneduurzaamtips(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/duurzaamtips/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(gettip(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function inserteduurzaamtips(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/duurzaamtips/", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(gettip(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function updateeduurzaamtips(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/gastenverbuik/duurzaamtips/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(gettip(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
