import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensor } from "../Reducers/actions";
import Tempratuur from "./Components/tempratuur";
import { fixmessage } from "../../../Reducers/loading/actions";
import moment from "moment";
import LineGraph from "./Components/linegraps";
import { Link, useParams } from "react-router-dom";
import Tabel from "./Components/tabel";
import FilterSensor from "./Components/filter";
import LogTabel from "./Components/logtabel";
import Voorspellen from "../Components/voorspellen";
const SensorItem = ({ sensor, getsensor, fixmessage, match }) => {
  const [datum, setDatum] = useState({
    startdate: moment().subtract(7, "days"),
    enddate: new Date(),
  });
  const [load, setLoad] = useState(false);
  const [gemiddelde, setGemiddelde] = useState(0);
  const params = useParams();

  useEffect(() => {
    getvals();
    const timerID = setInterval(() => getvals(), 60000);
    return () => clearInterval(timerID); // Cleanup function
  }, [params.id, datum]);

  const getvals = () => {
    getsensor(params.id, datum).then((data) => {
      if (data.results) {
        setLoad(true);
        setGemiddelde(data.results.data.reduce((a, b) => a + b.value, 0) / data.results.data.length);
      } else {
        fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  };

  const getdatum = (datum) => {
    setDatum(datum.datum);
    getvals();
  };

  function battery(value) {
    if (value && value.decoded_payload && value.decoded_payload.Bat_status) {
      if (value.decoded_payload.Bat_status === 3) {
        return (
          <div className="p2">
            Batterij:<i className="fas fa-battery-full green"></i>{" "}
          </div>
        );
      } else if (value.decoded_payload.Bat_status === 2) {
        return (
          <div className="p2">
            Batterij:<i className="fas fa-battery-half orange"></i>{" "}
          </div>
        );
      } else if (value.decoded_payload.Bat_status === 1) {
        return (
          <div className="p2">
            Batterij:<i className="fas fa-battery-quarter red"></i>{" "}
          </div>
        );
      }
    }
  }
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> {sensor.naam}</h3>
        <div className="box-tools pull-right">
        <Voorspellen sensor={sensor} sensor_id={sensor._id} />
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/sensoren/sensor/edit/" + sensor._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor alerttemp={sensor.status} getvals={getdatum} gemiddelde={gemiddelde} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row">
              <div className="col-12 col-md-4 col-lg-2 ">
                <Tempratuur sensor={sensor} />
              </div>
              <div className="col-12 col-md-8 col-lg-10 ">
                <LineGraph sensor={sensor} kleur="#D96A29" />
              </div>
              <div className="col-12 m-2">{battery(sensor.data[0])}</div>
              <div className="col-12">
                <div id="accordion" className="accordion">
                  <div className="box box-info">
                    <div className="box-header with-border" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Log gegevens <span className="badge badge-secondary">{sensor.logs.length}</span>
                      </button>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="box-header with-border">
                        <LogTabel sensor={sensor} />
                      </div>
                    </div>
                  </div>
                  <div className="box box-info">
                    <div className="box-header with-border" id="headingTwo">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Gegevens <span className="badge badge-secondary">{sensor.data.length}</span>
                      </button>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div className="card-body">
                        <Tabel sensor={sensor} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren.item };
}

export default connect(mapStateToProps, { getsensor, fixmessage })(SensorItem);
