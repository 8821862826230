import React from "react";
import { Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import timeSelect from "../../../Components/Forms/time";
import RenderDaterange from "../../../Components/Forms/daterange";
import MultiSelectDagen from "../../../Components/Forms/multiselect";

const BeschikbaarheidForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="box-body">
        <Field label="Kies een periode" validate={required({ msg: "Datum is verplicht" })} maxdate={true} mindate={false} component={RenderDaterange} name="periode" />
        <Field label="Kies de dagen" validate={required({ msg: "Datum is verplicht" })} component={MultiSelectDagen} name="dagen" options={["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"]} />

        <Field label="Kies starttijd" component={timeSelect} name="starttime" />
        <Field label="Kies en einddtijd" component={timeSelect} name="endtime" />
      </div>
      <button type="submit" className="btn btn-primary">
        Opslaan
      </button>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "insert_bschrikbaarheid", // a unique identifier for this form
})(BeschikbaarheidForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues("insert_bschrikbaarheid")(state), // get current form values
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
