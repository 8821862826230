import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Daterange from "../../Components/Parts/Daterange";
import moment from "moment";
import { Periode, ExportExcel, ExportWord } from "./Reducers/events/actions";

const EventPage = ({ events, Periode, ExportExcel, ExportWord }) => {
  const [query, setQuery] = useState([]);
  const [zoeken, setZoeken] = useState(true);

  const findevents = () => {
    Periode(query);
  };

  const export2excel = () => {
    ExportExcel(query);
  };

  const export2word = () => {
    ExportWord(query);
  };

  const onChangeDate = (value) => {
    if (value.startDate && value.endDate) {
      setZoeken(false);
      setQuery(value);
    } else {
      setZoeken(true);
    }
  };

  const einddatum = (datum) => {
    return datum ? moment(datum).format("DD-MM-YYYY") : "";
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Evenementen</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Evenement Toevoegen
          </Link>
        </div>
      </div>
      <div className="box-body">
        <h3 className="box-title">Welke periode</h3>
        <div className="box-body">
          <Daterange onChangeDate={onChangeDate} />
          <button onClick={findevents} type="button" disabled={zoeken} className="btn-primary btn btn-box-too btn-sm">
            Zoek de evenementen
          </button>{" "}
          <span>&nbsp;</span>
          <button onClick={export2excel} type="button" disabled={zoeken} className="btn-danger btn btn-box-too btn-sm">
            Exporteer naar Excel
          </button>{" "}
          <span>&nbsp;</span>
          <button onClick={export2word} type="button" disabled={zoeken} className="btn-warning btn btn-box-too btn-sm">
            Exporteer naar Word
          </button>
        </div>

        <div className="box-header with-border">
          <h3 className="box-title">Evenementen</h3>
        </div>
        <div className="box-body">
          <div className="box-body table-responsive no-padding">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>Datum</th>
                  <th>Tot</th>
                  <th>Titel</th>
                  <th>Beschrijving</th>
                  <th>Eigenschappen</th>
                </tr>
                {events.map((app) => (
                  <tr key={app._id}>
                    <td>{moment(app.event.datum).format("DD-MM-YYYY")}</td>
                    <td>{einddatum(app.event.einddatum)}</td>

                    <td>{app.what.title.value}</td>
                    <td>{app.what.shortdescription.value.substr(0, 30)}</td>
                    <td>{app.event.everyyear && <i className="fa-solid fa-repeat"></i> }</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.auth.error,
  events: state.events.events,
});

export default connect(mapStateToProps, { Periode, ExportExcel, ExportWord })(EventPage);
