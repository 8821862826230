import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Animated } from "react-animated-css";
import moment from "moment";

import { fetchreserveringen, getfaciliteit, deletereservering, getbeschikbaarheid, savereververing, setaanwezig } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import { translate } from "../../Components/Lib/Languages/language";
import DatumForm from "./Components/dateformres";
import InschrijfForm from "./Components/inschrijven";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ComponentToPrint from "./Components/print";
import Bar from "./Components/bar";

const InschrijPage = () => {
  const { id } = useParams();
  const faciliteit = useSelector((state) => state.faciliteiten.item);
  const reserveringen = useSelector((state) => state.faciliteiten.reserveringen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datum, setDatum] = useState(moment().toISOString());
  const [loaded, setLoaded] = useState(false);
  const [tijden, setTijden] = useState([]);
  const [tijd, setTijd] = useState("");
  const [inschrijven, setInschrijven] = useState(false);
  const [nodata, setNodata] = useState(false);
  const [aanwezig, setAanwezig] = useState(false);

  useEffect(() => {
    dispatch(getfaciliteit(id));
  }, [dispatch, id]);

  const submit = (vals) => {
    const { datum, tijd, aanwezig } = vals;
    dispatch(fetchreserveringen({ datum, faciliteit: faciliteit._id, tijd, aanwezig }));
  };

  const savereserv = (vals) => {
    vals.faciliteit = id;
    dispatch(savereververing(vals)).then((data) => {
      if (data.type === "FACILITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        dispatch(message(4000, "success", "Je reservering is succesvol verwerkt")).then((data) => {
          dispatch(fetchreserveringen(moment(vals.datum).toISOString(), tijd, aanwezig));
        });
      }
    });
  };

  const deletein = (id) => {
    dispatch(deletereservering(id)).then((deln) => {
      dispatch(message(4000, "success", "De inschrijving is succesvol verwijderd"));
      dispatch(fetchreserveringen(datum, tijd, aanwezig));
    });
  };

  const setaanwezig = (id) => {
    dispatch(setaanwezig({ id, aanwezig: true })).then(() => {
      dispatch(message(4000, "success", "De gast is succesvol op aanwezig gezet"));
      dispatch(fetchreserveringen(datum, tijd, aanwezig));
    });
  };

  const setdate = (datum) => {
    dispatch(getfaciliteit(id)).then((sdata) => {
      if (sdata.results) {
        dispatch(fetchreserveringen(moment(datum).toISOString(), tijd, aanwezig));
      }
    });
  };

  const showrrser = () => {
    // Show reservation form
  };

  const nodatainfo = (info) => {
    if (nodata)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-warning"}>
            <p>{info}</p>
          </div>
        </Animated>
      );
  };

  function priceFormatter(cell, row) {
    return moment(cell).format("DD-MM-YYYY");
  }
  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: priceFormatter,
    },
    {
      dataField: "tijd",
      text: "Tijd",
    },
    {
      dataField: "gasten.naam",
      text: "Naam",
      sort: true,
    },
    {
      dataField: "gasten.email",
      text: "E-mailadres",
    },
    {
      dataField: "personen",
      text: "Personen",
    },
    {
      text: "Toevoegen",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <button className="btn btn-success" onClick={setaanwezig.bind(this, row._id)}>
              Aanwezig
            </button>
            <button className="btn btn-danger pull-right" onClick={deletein.bind(this, row._id)}>
              X
            </button>
          </span>
        );
      },
    },
  ];

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>{translate(faciliteit.naam)}</h2>
        </div>
        <Link to="/faciliteiten" className="btn btn-primary btn-sm float-right" role="group">
          Overzicht faciliteiten
        </Link>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div>
            <DatumForm tijden={tijden} changedate={setdate} onSubmit={submit} />
          </div>
          <div>
            <Bar reserveringen={reserveringen} datumres={datum} tijden={tijden} tijd={tijd} />
          </div>
          <div className="pull-right">
            <button className="btn btn-success btn" onClick={showrrser}>
              Maak reserveringen
            </button>{" "}
            <ReactToPrint content={() => this.componentRef}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button className="btn btn-info btn" onClick={handlePrint}>
                    Printen
                  </button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </div>
          {inschrijven && (
            <div className="inschrijform">
              <InschrijfForm tijden={tijden} setdate={setdate} onSubmit={savereserv} />
              {nodatainfo("Het is helaas niet mogelijk een reservering te maken op deze datum")}
            </div>
          )}
          <div className="box-body">
            <div style={{ display: "none" }}>
              <ComponentToPrint reserveringen={reserveringen} faciliteit={faciliteit} datum={datum} tijden={tijden} tijd={tijd} ref={(el) => (this.componentRef = el)} />
            </div>
            <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={reserveringen} columns={columns} pagination={paginationFactory({ sizePerPage: 25 })} defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InschrijPage;
