import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import Rodal from "rodal";
import { renderField } from "../../../Components/Forms/renders";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import RichTextMarkdown from "../../../Components/Forms/wysiwyg";
import MultiSelectDagen from "../../../Components/Forms/multiselect";

const MailDeelnemers = ({ handleSubmit, beschikbaarheid, dispatch, boekingen }) => {
  const [visible, setVisible] = useState(false);
  const [aanValue, setAanValue] = useState([]);
  const [status, setStatus] = useState("accept");
  useEffect(() => {
    // Stel mail.onderwerp in zodra beschikbaarheid.naam beschikbaar is
    if (beschikbaarheid?.ervaring?.naam) {
      dispatch(change("terugstortform", "mail.onderwerp", beschikbaarheid.ervaring.naam));
    }
    const emailAdressen = boekingen
      .filter((order) => order.status === status) // Filter op basis van status
      .map((order) => order.klanten.email); // Haal de e-mailadressen op
    dispatch(change("terugstortform", "mail.aan", emailAdressen));
    setAanValue(emailAdressen);
  }, [beschikbaarheid, dispatch, status]);

  return (
    <>
      <button className="btn btn-info mx-2" onClick={() => setVisible(true)}>
        Mail de deelnemers
      </button>

      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-lg">
        <div className="card actionmodal">
          <div className="card-header">
            <h4 className="mb-0">Mail de deelnemers</h4>
          </div>
          <div className="box box-default">
            <form onSubmit={handleSubmit}>
              <Field name="mail.aan" type="input" validate={required({ msg: "Aan is verplicht" })} options={aanValue} component={MultiSelectDagen} label="Aan:" />
              <Field name="mail.onderwerp" type="input" validate={required({ msg: "Onderwerp is verplicht" })} component={renderField} label="Onderwerp" />
              <Field name="mail.bericht" type="input" validate={required({ msg: "Bericht is verplicht" })} component={RichTextMarkdown} label="Bericht" />

              <div className="box-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Versturen
                </button>
              </div>
            </form>
          </div>
        </div>
      </Rodal>
    </>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "terugstortform", // a unique identifier for this form
})(MailDeelnemers);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
