import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { fetchaccommodatiesbydate, exportExcel } from "../Reducers/actions";

import Loading from "../../../Components/loading/load";
import KampeerplaatsLijst from "./Components/widget";
import Modaldatum from "./Components/modaldatum";
function IndexAccommodaties({ accommodaties, fetchaccommodatiesbydate, exportExcel }) {
  const [load, setLoad] = useState(false);
  const [filters, setFilters] = useState({});
  const [datum, setDatum] = useState(moment().toDate());
  const [soort, setSoort] = useState(null);
  useEffect(() => {
    setLoad(false);
    fetchaccommodatiesbydate({ datum, soort, filters }).then((data) => {
      setLoad(true);
    }, []);
  }, [fetchaccommodatiesbydate, datum, soort, filters]);
  const submit = (values) => {
    setDatum(values.datum);
    if (values.soort) setSoort(values.soort);
    if (values.accommodaties) {
      const filter = values.accommodaties.map((item) => item._id);
      setFilters(filter);
    }
  };
  const showalles = () => {
    setDatum(moment().toDate());
    setSoort(null);
    setFilters({});
  };

  const exortData = () => {
    exportExcel({ data: accommodaties });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Accommodaties</h3>
        <div className="box-tools pull-right">
          <div className="d-flex">
            <button className="btn-secondary btn btn-box-too btn-sm mx-2" onClick={exortData}>
              Exporteer naar Excel
            </button>
            <Modaldatum showalles={showalles} accommodaties={accommodaties} onSubmit={submit} />
          </div>
        </div>
      </div>
      <Loading loadings={load} />
      {load && <KampeerplaatsLijst accommodaties={accommodaties} />}
    </div>
  );
}
function mapStateToProps(state) {
  return { user: state.auth.user, accommodaties: state.standplaatsen.accommodaties };
}
export default connect(mapStateToProps, { fetchaccommodatiesbydate, exportExcel })(IndexAccommodaties);
