import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { message, clearmessage, fixmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { gethoreca } from "./Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
import { savetafel } from "./Reducers/actions";
import Tafelform from "./Components/insert";

const FacilitetenPage = () => {
  const [state, setState] = useState({
    loaded: false,
    loadform: true,
  });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bedrijf = useSelector((state) => state.pubred.bedrijf);
  const horeca = useSelector((state) => state.horeca.item);

  useEffect(() => {
    dispatch(gethoreca(params.id)).then((data) => {
      if (data.results) {
        if (!bedrijf._id) {
          dispatch(getbedrijf(data.results.bedrijf)).then(() => {
            setState((prevState) => ({ ...prevState, loaded: true }));
          });
        } else {
          setState((prevState) => ({ ...prevState, loaded: true }));
        }
      }
    });

    return () => {
      dispatch(clearmessage());
    };
  }, [dispatch, params.id, bedrijf._id]);

  const submit = (vals) => {
    setState((prevState) => ({ ...prevState, loadform: false }));
    dispatch(savetafel(params.id, vals)).then((data) => {
      if (data.type === "PUBLIC_ERROR") {
        alert(data.payload);
        setState((prevState) => ({ ...prevState, loaded: true }));
      } else {
        dispatch(fixmessage("success", lang("Je registratie is succesvol verwerkt"))).then(() => {
          navigate(`/public/horeca/${bedrijf._id}`);
        });
      }
    });
  };

  return (
    <div className="public-box-body">
      <h2 className="headline text-yellow facheadline"> {lang("Tafel aanmelden")}</h2>
      <Loading loading={state.loaded} />
      {state.loaded && (
        <div className="inschrijf-content">
          <div className="gastinfo">
            <h4>{horeca.naam}</h4>
            {horeca.beschrijving}
          </div>
          {state.loadform && <Tafelform onSubmit={submit} />}
        </div>
      )}
    </div>
  );
};

export default FacilitetenPage;
