import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import { terugstoren } from "../Reducers/actions";
import Terugstortform from "./terugstortform";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { connect } from "react-redux";

const TerugstortModal = ({ terugstoren, visible, close, boeking, message, fixmessage }) => {
  const submit = (values) => {
    const vals = {
      bedrag: values.bedrag,
      beschrijving: values.beschrijving,
      id: boeking._id,
    };
    terugstoren(vals).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          fixmessage("danger", data.payload.error.msg);
        } else {
          fixmessage("danger", "De boeking is niet succesvol verwerkt");
        }
      } else {
        message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
          close(false);
        });
      }
    });
  };

  return (
    <Rodal
      visible={visible}
      onClose={() => {
        close(false);
      }}
      className="rodal-md"
    >
      <div className="card actionmodal">
        <div className="card-header">
          <h4 className="mb-0">Terugstoren bedrag</h4>
        </div>
        <div className="card-body">
          <Terugstortform boeking={boeking} onSubmit={submit} />
        </div>
      </div>
    </Rodal>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, { terugstoren, message, fixmessage })(TerugstortModal);
