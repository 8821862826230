import React, { useState, useEffect } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { required, numericality } from "redux-form-validators";
import { renderselect } from "../../../../Components/Forms/renders";
import DateTimeInput from "../../../../Components/Forms/datetime";

const TerugstortForm = ({ handleSubmit, inschakelen }) => {
  return (
    <>
      <div className="box box-default m-2">
        <form onSubmit={handleSubmit}>
          <div className="box-header with-border">
            <h3 className="box-title">Uitschakelen</h3>
          </div>
          <Field name="datum" minDate={true} minTime={true} component={DateTimeInput} type="input" validate={required({ msg: "Dit item is verplicht" })} label="Uitschakelen tot" />
          <button type="submit" className="btn btn-warning btn-md m-2">
            Uitschakelen meter
          </button>
        </form>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Inschakelen</h3>
        </div>
        <button type="button" className="btn btn-success btn-md m-2" onClick={() => inschakelen({ datum: new Date() })}>
          Inschakelen meter
        </button>
      </div>
    </>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "schakelform", // a unique identifier for this form
})(TerugstortForm);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
