import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { update, getone } from './Reducers/actions';
import BedrijvenForm from './Components/edit';

function GebruikersEditPage({ update, getone }) {
  const [loaded, setLoaded] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getone(id).then(() => {
      setLoaded(true);
    });
  }, [getone, id]);

  const submit = (vals) => {
    update(id, vals).then((data) => {
      if (data.type === 'GEBRUIKER_ERROR') {
        alert(data.payload);
      } else {
        navigate('/gebruikers/');
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Gebruiker wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded && <BedrijvenForm onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { username: state.auth.user, gebruiker: state.mgebruukers.item };
}

export default connect(mapStateToProps, { update, getone })(GebruikersEditPage);
