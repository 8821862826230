import React from "react";
import { required } from "redux-form-validators";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderField, renderselect, renderselectobject } from "../../../../../Components/Forms/renders";
import Wysiwyg from "../../../../../Components/Forms/wysiwyg";
import MediaForm from "./media";
import Tags from "../../.././../../Components/Forms/tags";
import ArrayVeldenSub from "./subcontent";

export default function Velden({ subitem, taal, itemindex, transLate, fieldnaam }) {
  if (subitem.soort === "text") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" component={renderField} label={`${subitem.desc} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam} onClick={transLate(fieldnaam)}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  } else if (subitem.soort === "media") {
    return <Field name={fieldnaam} type="input" component={MediaForm} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "richttext") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" component={Wysiwyg} label={`${subitem.desc} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" id={fieldnaam} className="btn btn-secondary btn-sm " onClick={transLate(fieldnaam)}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  } else if (subitem.soort === "button") {
    return (
      <div className="card websubtable ">
        <div className="box-header">{`${subitem.desc} met naam ${subitem.naam}`}</div>
        <div className="box-body">
          <div className="fieldfortranslate">
            <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Button" />
            {taal && (
              <div className="translatebutton float-right">
                <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam + ".title"} onClick={transLate(fieldnaam + ".title")}>
                  Vertaal via ChatGPT
                </button>
              </div>
            )}
          </div>

          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Button" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
        </div>
      </div>
    );
  } else if (subitem.soort === "link") {
    return (
      <div className="card">
        <div className="card-header ">{`${subitem.desc} met naam ${subitem.naam}`}</div>
        <div className="card-body">
          <div className="fieldfortranslate">
            <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Button" />
            {taal && (
              <div className="translatebutton float-right">
                <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam + ".title"} onClick={transLate(fieldnaam + ".title")}>
                  Vertaal via ChatGPT
                </button>
              </div>
            )}
          </div>
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Link" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
          <Field name={fieldnaam + ".class"} type="input" component={renderField} label="css class" />
        </div>
      </div>
    );
  } else if (subitem.soort === "enumeration") {
    return <Field name={fieldnaam} type="input" options={subitem.options} component={renderselectobject} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "groep") {
    return (
      <div className="card">
        <div className="card-header ">{`${subitem.desc} met naam ${subitem.naam}`}</div>
        <div className="card-body">
          <FieldArray taal={taal} transLate={transLate} name={fieldnaam} component={ArrayVeldenSub} subitem={subitem} />
        </div>
      </div>
    );
  } else if (subitem.soort === "tag") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" options={subitem.options ? subitem.options : []} component={Tags} label={`${subitem.soort} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam} onClick={transLate(fieldnaam, "tag")}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  } else if (subitem.soort === "video") {
    return (
      <div className="card websubtable ">
        <div className="box-header">{`${subitem.desc} met naam ${subitem.naam}`}</div>
        <div className="box-body">
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="UL van de Video" />
          <Field name={fieldnaam + ".tumbnail"} type="input" component={renderField} label="Thumbnail URL" />

          <Field
            className="form"
            name={fieldnaam + ".autplay"}
            label="Autoplay Video"
            component={renderselect}
            options={{
              false: "Ja",
              true: "Nee",
            }}
          />
          <Field
            className="form"
            name={fieldnaam + ".source"}
            label="Waar komt de video vandaan"
            component={renderselect}
            options={{
              local: "Lokaal",
              youtube: "Youtube",
            }}
          />
        </div>
      </div>
    );
  }
}
