import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { getbeschikbaarheid, getarrangementen, controleerbeschikbaarheid } from "../Arrangementen/Reducers/actions";
import { saveboekingen, fetchboekingenbyarrangementen, annuleerboekingen, verzendlijst, mail } from "./Reducers/actions";
import { message, fixmessage } from "../../Reducers/loading/actions";
import Bar from "./Components/bar";
import Rodal from "rodal";
import ReserveringForm from "./Components/reservering";
import ReserveringsOverzicht from "./Components/overzicht";
import BoekingenArrangementTabel from "./Components/boekingen";
import MailDeelnemers from "./Components/mail";

const InschrijPage = ({ annuleerboekingen, beschikbaarheid, saveboekingen, message, fixmessage, fetchboekingenbyarrangementen, boekingen, getbeschikbaarheid, verzendlijst, controleerbeschikbaarheid, mail }) => {
  const [loaded, setLoaded] = useState(false);
  const [prijzen, setPrijzen] = useState([]);
  const [opties, setOpties] = useState([]);
  const [datum, setDatum] = useState();
  const [visible, setVisible] = useState(false);
  const [annuleermodal, setAnnuleermodal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getbeschikbaarheid(params.id).then(() => {
      fetchboekingenbyarrangementen({ arrangementdatum: params.id }).then((sdata) => {
  
        if (sdata.results) {
          setLoaded(true);
        }
      });
    });
  }, [getbeschikbaarheid, params.id]);

  const submit = (vals) => {
    vals.arrangementdatum = params.id;
    saveboekingen(vals).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          fixmessage("danger", data.payload.error.msg);
        } else {
          fixmessage("danger", "De boeking is niet succesvol verwerkt");
        }
      } else {
        message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
          navigate("/boekingen/ervaring/" + params.id);
          setVisible(false);
        });
      }
    });
  };

  const maildeelnemers = (vals) => {
    mail(vals).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          message(2000, "danger", data.payload.error.msg);
        }
      } else {
        message(2000, "success", "De mail is succesvol verstuurd");
      }
    });
  };

  const handleOnchange = (e) => {
    const prijzenrow = [];
    const optiesrow = [];
    if (e.deelnemers) {
      Object.keys(e.deelnemers).map((key, index) => {
        if (beschikbaarheid.ervaring.prijzen) {
          const gezochtObject = beschikbaarheid.ervaring.prijzen.find((prijs) => prijs.naam === key);
          prijzenrow.push({
            ...gezochtObject,
            aantal: e.deelnemers[key],
          });
        } else {
          prijzenrow.push({
            aantal: e.deelnemers.personen,
            naam: "personen",
            beschrijving: "Personen",
            prijs: 0,
            aantal: e.deelnemers[key],
          });
        }
      });
      setPrijzen(prijzenrow);
    }
    if (e.opties) {
      if (beschikbaarheid.ervaring.opties) {
        Object.keys(e.opties).map((key, index) => {
          const gezochtObject = beschikbaarheid.ervaring.opties.find((optie) => optie.naam === key);
          if (gezochtObject.optie === "vraag" && e.opties[key] === "ja") {
            optiesrow.push({
              ...gezochtObject,
              aantal: 1,
            });
          } else {
            if (gezochtObject && e.opties[key] > 0) {
              optiesrow.push({
                ...gezochtObject,
                aantal: e.opties[key],
              });
            }
          }
        });
      }
      setOpties(optiesrow);
    }
  };

  const senddeelnemerslijst = () => {
    verzendlijst({ ervaring: params.id, status: "accept" }).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          fixmessage("danger", data.payload.error.msg);
        }
      } else {
        message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
          navigate("/boekingen/ervaring/" + params.id);
          setAnnuleermodal(false);
        });
      }
    });
  };

  const annullerAlleBoekingen = () => {
    annuleerboekingen({ ervaring: params.id, status: "cancel" }).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        if (data.payload.error && data.payload.error.msg) {
          fixmessage("danger", data.payload.error.msg);
        }
      } else {
        message(2000, "success", "De boeking is succesvol verwerkt").then(() => {
          navigate("/boekingen/ervaring/" + params.id);
          setAnnuleermodal(false);
        });
      }
    });
  };

  const controleerreserveringen = () => {
    setLoaded(false);
    controleerbeschikbaarheid(params.id).then(() => {
      fetchboekingenbyarrangementen({ arrangementdatum: params.id }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    });
  };

  const showbuttons = () => {
    const tomorrow = moment().add(-1, "days");
    if (moment(beschikbaarheid.datum).isAfter(tomorrow.toISOString())) {
      return (
        <>
          <div className="m-2">
            <button
              className="btn btn-success btn"
              onClick={() => {
                setVisible(true);
              }}
            >
              Maak reserveringen
            </button>
          </div>
          <div className="m-2">
            <button
              className="btn btn-info btn"
              onClick={() => {
                senddeelnemerslijst(true);
              }}
            >
              Verstuur deelnemerslijst
            </button>
          </div>
          <div className="m-2">
            <button
              className="btn btn-danger btn"
              onClick={() => {
                setAnnuleermodal(true);
              }}
            >
              Annuleer alle reserveringen
            </button>
          </div>
          <div className="m-2">
            <button
              className="btn btn-success btn"
              onClick={() => {
                controleerreserveringen();
              }}
            >
              Controleer reserveringen
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      {loaded && (
        <div className="box box-default">
          <div className="box-body box-profile">
            <div></div>
            <div>
              <Bar beschikbaarheid={beschikbaarheid} boekingen={boekingen} />
            </div>
            <div className="box-tools float-start d-flex m-2 flex-wrap">
              {showbuttons()}{" "}
              <div className="m-2">
                <MailDeelnemers onSubmit={maildeelnemers} beschikbaarheid={beschikbaarheid} boekingen={boekingen} />
              </div>
            </div>
            <Rodal
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              className="rodal-lg"
            >
              <div className="box box-default">
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-7  col-sm-12">
                      <div className="box-title">
                        <h3>Maak een boeking</h3>
                      </div>
                      <ReserveringForm beschikbaarheid={beschikbaarheid} onChange={handleOnchange} onSubmit={submit} />
                    </div>
                    <div className="col-md-5  col-sm-12">
                      <ReserveringsOverzicht beschikbaarheid={beschikbaarheid} opties={opties} prijzen={prijzen} datum={datum} />
                    </div>
                  </div>
                </div>
              </div>
            </Rodal>

            <Rodal
              visible={annuleermodal}
              onClose={() => {
                setAnnuleermodal(false);
              }}
              className="rodal-sm"
            >
              <div className="box box-default">
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <div className="box-title">
                        <h3>Annulleer alle boekingen</h3>
                      </div>
                    </div>
                    <div className="p-4">
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          annullerAlleBoekingen();
                        }}
                      >
                        Annuleer alle boekingen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Rodal>
            <div className="box-body">
              <BoekingenArrangementTabel boekingen={boekingen} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items, beschikbaarheid: state.ervaringen.beschikbaarheid, ervaring: state.ervaringen.item };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getarrangementen,
  saveboekingen,
  fetchboekingenbyarrangementen,
  getbeschikbaarheid,
  annuleerboekingen,
  verzendlijst,
  controleerbeschikbaarheid,
  mail,
})(InschrijPage);
