import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { confirmationPost } from '../../../Reducers/auth/actions';

const VerificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const errorMessage = useSelector((state) => state.auth.error);
  const signupmsg = useSelector((state) => state.auth.signupmsg);

  useEffect(() => {
    dispatch(confirmationPost({ code })).then((whatever) => {
      if (whatever === true) {
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    });
  }, [dispatch, code, navigate]);

  const renderAlert = () => {
    if (errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {errorMessage}
        </div>
      );
    }
  };

  const renderOKAlert = () => {
    if (signupmsg) {
      return (
        <div className="alert alert-success">
          <strong>Super!</strong> {signupmsg}
        </div>
      );
    }
  };

  return (
    <div className="login-box-body">
      <p className="login-box-msg">Verification van je account</p>
      {renderAlert()}
      {renderOKAlert()}
      <div className="span12">
        <Link to="/login" className="text-center">
          Ik heb al een account
        </Link>
      </div>
    </div>
  );
};

export default VerificationPage;
