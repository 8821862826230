import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updatelogboek, getlogboek, dellogboek } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import LogboekEditForm from "./Components/editform";

const LogboekEditPage = ({ getlogboek, updatelogboek, dellogboek, message }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    getlogboek(id).then((data) => {
      if (data.results) {
        setLoaded(true);
      } else {
        message(2000, "warning", "Er is een fout opgetreden bij het laden van de activiteit").then((data) => {
          navigate("/logboek");
        });
      }
    });
  }, [getlogboek, id, message, navigate]);

  const submit = (vals) => {
    updatelogboek(id, vals).then((data) => {
      if (data.type === "LOGBOEK_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De activiteit is met succes aangepast").then((data) => {
          navigate("/logboek");
        });
      }
    });
  };

  const verwijderen = () => {
    dellogboek(id).then((data) => {
      if (data.type === "LOGBOEK_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De activiteit is met succes verwijderd").then((data) => {
          navigate("/logboek");
        });
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Logboek wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded && <LogboekEditForm verwijderen={verwijderen} onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, logboek: state.logboeken.item };
}

export default connect(mapStateToProps, { updatelogboek, getlogboek, dellogboek, message })(LogboekEditPage);
