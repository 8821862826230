import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderDaterange from "../../../../Components/Forms/date";
import TimePicker from "../../../../Components/Forms/timepicker";
import MultiSelectData from "../../../../Components/Forms/multiselectdata";
import moment from "moment";

const FilterSensor = ({ changedata, sensor, formValues }) => {
  const [sensoritem, setSensoritem] = useState([]);
  const prevReturndata = useRef({});

  useEffect(() => {
    if (sensor.items) {
      setSensoritem(sensor.items);
    }
  }, [sensor.items]);

  useEffect(() => {
    onchangeitem(formValues);
  }, [formValues]);

  const onchangeitem = (vals) => {
    setTimeout(() => {
      const returndata = {};
      if (vals.vandatum && vals.vantijd) {
        returndata.startdate = moment(`${vals.vandatum.format("YYYY-MM-DD")} ${moment(vals.vantijd, "HH:mm").format("HH:mm")}`, "YYYY-MM-DD HH:mm").toISOString();
      }
      if (vals.totdatum && vals.tottijd) {

        returndata.enddate = moment(`${vals.totdatum.format("YYYY-MM-DD")} ${moment(vals.tottijd, "HH:mm").format("HH:mm")}`, "YYYY-MM-DD HH:mm").toISOString();
      }
      if (vals.sensoren) {
        returndata.sensoren = vals.sensoren.map((item) => item._id);
      }
      
      if (JSON.stringify(prevReturndata.current) !== JSON.stringify(returndata)) {
        changedata(returndata);
        prevReturndata.current = returndata; // Update de vorige waarde
      }
    }, 200);
  };

  return (
    <form>
      <div className="row container filter">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Field
            name="sensoren"
            type="input"
            dataKey="_id"
            textField="naam"
            component={MultiSelectData}
            options={sensoritem}
            label="Vul het de sensoren in"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <Field
            name="vandatum"
            type="number"
            mindate={true}
            maxdate={false}
            component={renderDaterange}
            label="Start datum"
          />
        </div>
        <div className="col-md-1 col-sm-6 col-xs-12">
          <Field
            name="vantijd"
            type="number"
            component={TimePicker}
            label="Start tijd"
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <Field
            name="totdatum"
            type="number"
            mindate={true}
            maxdate={false}
            component={renderDaterange}
            label="Eind datum"
          />
        </div>
        <div className="col-md-1 col-sm-6 col-xs-12">
          <Field
            name="tottijd"
            type="number"
            component={TimePicker}
            label="Eind tijd"
          />
        </div>
      </div>
    </form>
  );
};

const reduxConnectedForm = reduxForm({ form: "filtersensor" })(FilterSensor);

const selector = formValueSelector("filtersensor");

const mapStateToProps = (state) => ({
  initialValues: {
    vandatum: moment().subtract(7, "days"),
    totdatum: moment(),
    vantijd: "12:00",
    tottijd: "12:00",
  },
  formValues: selector(state, "sensoren", "vandatum", "totdatum", "vantijd", "tottijd"),
});

export default connect(mapStateToProps)(reduxConnectedForm);