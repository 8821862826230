export const GET_THEME = 'GET_THEME';
export const ADD_THEME = 'ADD_THEME';
export const SET_THEME = 'SET_THEME';
export const ERROR_THEME = 'ERROR_THEME';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function add(results) {
  return {
    type: ADD_THEME,
    results
  }
}
export function get(results) {
  return {
    type: GET_THEME,
    results
  }
}
export function set(results) {
  return {
    type: SET_THEME,
    results
  }
}
export function themeError(error) {
  return {
    type: ERROR_THEME,
    payload: error
  };
}
export function Save(data) {
  return dispatch => {
    return fetch('/api/admin/theme', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      }).then(handleResponse).then(results => {
        dispatch(add(results.results));
        return results;
      }).catch((err) => {
        dispatch(themeError(err));
      });
    };
  }

  export function Update(data, id) {
    return dispatch => {
      return fetch('/api/admin/theme/'+id, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem('token')
          }
        }).then(handleResponse).then(results => {
          dispatch(add(results.results));
          return results;
        }).catch((err) => {
          dispatch(themeError(err));
        });
      };
    }

export function Fetchtheme() {
  return dispatch => {
    fetch('/api/theme', {
        method: 'get',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(get(data.results)))
      .catch(err =>
        dispatch(themeError(err)));
  };
}

export function GetTheme(id) {
  return dispatch => {
    return fetch('/api/theme/'+id, {
        method: 'get',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(themeError(err)));
  };
}
export function DeleteTheme(id) {
  return dispatch => {
    return fetch('/api/admin/theme/'+id, {
        method: 'delete',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(themeError(err)));
  };
}

export function SetThemetoEdit(data) {
  return dispatch => {
    dispatch(set(data));
  }
};
