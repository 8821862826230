import React, { useState, useEffect } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { required, numericality } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";

const TerugstortForm = ({ handleSubmit, boeking, formValues }) => {
  const tgbedrag = formValues && formValues.bedrag ? Number(formValues.bedrag).toFixed(2) : 0;
  const totaal = Number(boeking.totaalprijs).toFixed(2);

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-header with-border">
          <h3 className="box-title"> Maximaal bedrag: € {totaal}</h3>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="bedrag" type="number" validate={numericality({ "<=": boeking.totaalprijs, msg: "Het bedrag is verplicht en moet lager dan " + boeking.totaalprijs + " zijn" })} component={renderField} label="Welk bedrag moet teruggestort worden" />
              <Field name="beschrijving" type="input" validate={required({ msg: "Beschrijving is verplicht" })} component={renderField} label="Vul de beschrijving in" />
            </div>
          </div>
          <div className="totaal m-4">
            <table className="table table-responsive-md">
              <tbody>
                <tr>
                  <th scope="col warning">Bedrag teruggestort</th>
                  <th scope="col" className="text-end">
                    € - {tgbedrag}
                  </th>
                </tr>
                <tr>
                  <th scope="col warning">Verschil</th>
                  <th scope="col" className="text-end">
                    € {Number(totaal - tgbedrag).toFixed(2)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          {Number(totaal - tgbedrag) < totaal && (
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Terugstorten" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "terugstortform", // a unique identifier for this form
})(TerugstortForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues("terugstortform")(state), // get current form values
  initialValues: state.boekingen.item ? { beschrijving: "Refund: " + state.boekingen.item.betaling.description } : {} // Set initialValues
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);

