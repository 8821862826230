import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';

const Autosugform = ({ input, label, meta:{ touched, error, warning } }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (input.value) {
      setValue(input.value.value);
    }
  }, [input.value]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    input.onChange({ id: suggestion.metadata.trcid, value: suggestion.what.title.value, _id: suggestion._id  });
  };

  const clearInput = () => {
    input.onChange({});
    setValue('');
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const response = await fetch('/api/items/search/locations', {
      method: 'post',
      body: JSON.stringify({ query: value }),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    });
    const data = await response.json();
    setSuggestions(data.results);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion.what.title.value;

  const renderSuggestion = suggestion => (
    <div>
      {suggestion.what.title.value}
    </div>
  );

  const inputProps = {
    placeholder: "Maak een keuze",
    value,
    onChange
  };

  const { meta } = input;

  return (
    <div>
      <label>{label}</label>
      <div className="inputs">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          className="form-control"
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps} />
        <span onClick={clearInput} id="searchclear" className="usericonbox glyphicon glyphicon-remove-circle"></span>
      </div>
      {error && <span>{error}</span>}
    </div>
  );
};

export default Autosugform;
