import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NewPassword, authError } from "../../../Reducers/auth/actions";
import { message } from "../../../Reducers/loading/actions";
import { useNavigate, useParams, Link } from "react-router-dom";

function NewPasswordPage() {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const { code } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(state => state.auth.error);
  const signupMsg = useSelector(state => state.auth.signupmsg);

  useEffect(() => {
    dispatch(authError(null));
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(message(5000, "danger", errorMessage));
    }
    if (signupMsg) {
      dispatch(message(5000, "success", signupMsg));
    }
  }, [dispatch, errorMessage, signupMsg]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordCheck") {
      setPasswordCheck(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === passwordCheck) {
      dispatch(NewPassword({ code, password }))
        .then(whatever => {
          if (whatever === true) {
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        });
    } else {
      dispatch(authError("De wachtwoorden zijn niet gelijk aan elkaar"));
    }
  };

  return (
    <div className="login-box-body">
      <p className="login-box-msg">Kies een nieuw Wachtwoord</p>
      <form method="post">
        <div className="row-fluid">
          <div className="form-group has-feedback col-xs-6">
            <input
              value={password}
              onChange={handleChange}
              name="password"
              type="password"
              className="form-control"
              placeholder="Wachtwoord"
            />
            <span className="glyphicon glyphicon-lock form-control-feedback" />
          </div>
          <div className="form-group has-feedback col-xs-6">
            <input
              value={passwordCheck}
              onChange={handleChange}
              name="passwordCheck"
              type="password"
              className="form-control"
              placeholder="Herhaal wachtwoord"
            />
            <span className="glyphicon glyphicon-log-in form-control-feedback" />
          </div>
          <div className=" form-group has-feedback row">
            <div className="col-xs-8"></div>
            <div className="col-xs-4">
              <button
                onClick={handleSubmit}
                type="submit"
                className="loginbutton btn btn-primary btn-block btn-flat"
              >
                Verzenden
              </button>
            </div>
          </div>
          <div className="span12">
            <Link to="/login" className="text-center">
              Terug
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewPasswordPage;
