import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GebruiksInsert from './Components/insert';
import { update, getone } from './Reducers/actions';
import { signupUser } from '../../Reducers/auth/actions';
import { message } from '../../Reducers/loading/actions';

function GebruikersInsertPage() {
    const [errors, setErrors] = useState({});
    const [loaded, setLoaded] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const username = useSelector(state => state.auth.user);
    const bedrijf = useSelector(state => state.bedrijven.item);
    const gebruiker = useSelector(state => state.mgebruikers.item);

 

    const submit = (vals) => {
        const values = { ...vals, bedrijf: username.bedrijf };
        values.telefoonnummer = values.telefoonnummer.replace(" ", "").replace("+", "");
        dispatch(signupUser(values)).then((data) => {
            if (data.type === 'auth_error') {
                dispatch(message("3000", "danger", data.payload));
            } else {
                dispatch(message("3000", "success", "Het account is met succes aangemaakt. De nieuwe gebruiker ontvangt nu een e-mail")).then(() => {
                    navigate('/gebruikers/');
                });
            }
        });
    };

    return (
        <div className="box box-default">
            <div className="box-header with-border">
                <h3 className="box-title">Gebruiker toevoegen</h3>
            </div>
            <div className="row">
                <div className="col-md-12 no-float">
                    <div className="box box-primary">
                        <div className="box-body box-profile">
                            {loaded && <GebruiksInsert onSubmit={submit} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GebruikersInsertPage;
