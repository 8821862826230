import React, { useState } from "react";
import { Link } from "react-router-dom";
const ItemsMenu = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("items")) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fa fa-window-restore" />
            <span>VVV Items</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
            <li>
              <Link to="/items" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" />
                <span>VVV Items</span>
              </Link>
            </li>
            <li>
              <Link to="/items/add" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" /> <span>VVV Items Toevoegen</span>
              </Link>
            </li>
            <li>
              <Link to="/items/top" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" /> <span>VVV Items TOP</span>
              </Link>
            </li>
            <li>
              <Link to="/items/tags" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" /> <span>VVV Items Tags</span>
              </Link>
            </li>
            <li>
              <Link to="/items/arrangementen" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" /> <span>Arrangementen</span>
              </Link>
            </li>
            <li>
              <Link to="/items/list/tags" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" /> <span>Lijst Tags</span>
              </Link>
            </li>
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default ItemsMenu;
