import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../Components/Forms/renders";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import Multiselect from "../../../Components/Forms/multiselectdata";
import switcbutton from "../../../Components/Forms/switch";

const Item = ({ fields, initialValues }) => {
  return (
    <div className="card">
      {fields.map((item, index) => (
        <div className="row p-4" key={index}>
          <div className="col-sm-7">
            <h4 className="info-text">Vul hier de items van het arrangement in</h4>

            <Field name={`${item}.naam`} type="input" component={renderField} label="Titel" validate={required({ msg: "Dit veld is verplicht" })} />
            <Field name={`${item}.beschrijving.short`} type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderTextField} label="Korte omschrijving van de arrangement" />
            <Field name={`${item}.beschrijving.long`} type="textarea" component={Wysiwyg} label="Lange Omschrijving" className="form-control" normalize={(value) => value.toString("html")} validate={required({ msg: "Dit veld is verplicht" })} />

            <Field name={`${item}.prijs`} type="number" component={renderField} label="Prijs" validate={required({ msg: "Dit veld is verplicht" })} />
            <Field className="form" name={`${item}.locaties`} validate={required({ msg: "Type item is verplicht" })} label="Aan welke locatie(s) is dit item gekoppeld?" component={Multiselect} options={initialValues.location} dataKey="_id" textField="label" />
          
            <hr className="mt-4" />
          </div>
        </div>
      ))}
      <div className="card-footer">
        <button type="button" className="btn btn-success" onClick={() => fields.push({})}>
          Item Toevoegen
        </button>
      </div>
    </div>
  );
};
export default Item;
