import React from "react";
import { Routes, Route } from "react-router-dom";
import BieppInsert from "./insert";
import BiepShow from "./show";
import Biepp from ".";
import BieppEdit from "./edit";

const BieppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Biepp />} />
      <Route path="/insert" element={<BieppInsert />} />
      <Route path="/edit/:id/" element={<BieppEdit />} />
      <Route path="/edit/:id/:step" element={<BieppEdit />} />
      <Route path="/show/:id" element={<BiepShow />} />
    </Routes>
  );
};

export default BieppRouter;
