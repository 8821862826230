import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchervaringenaankoemende } from "../Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Progress } from "react-sweet-progress";

import moment from "moment";

const ErvaringenWidgetAankomende = ({ fetchervaringenaankoemende, beschikbaarheid, user }) => {
  useEffect(() => {
    fetchervaringenaankoemende();
  }, [fetchervaringenaankoemende]);
  const getstatus = (actief) => {
    if (actief) {
      return <small className="label bg-green">Actief</small>;
    } else {
      return <small className="label bg-orange">Non Actief</small>;
    }
  };

  const columns = [
    {
      dataField: "datum",
      text: "datum",
      formatter: (cell, row) => {
        return moment(cell).format("DD-MM-YYYY");
      },
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "ervaring.naam",
      text: "Naam",
    },
    {
      dataField: "aantalpersonen",
      text: "Aantal Personen",
      formatter: (cellContent, row) => {
        if (row?.beschikbaarheid?.personenmax) {
          const aantal = (Number(row.aantalpersonen / row.beschikbaarheid.personenmax) * 100).toFixed(0);
          return <Progress percent={aantal} status={aantal == 100 && "success"} />;

        } 
      },
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <Link className="btn btn-info" to={"/boekingen/ervaring/" + row._id}>
            Boekingen
          </Link>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
  ];

  return (
    <div className="box-body box-profile">
      <ToolkitProvider
        keyField="_id"
        data={beschikbaarheid}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
            <hr />
            <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, beschikbaarheid: state.ervaringen.beschikbaarheden };
}

export default connect(mapStateToProps, { fetchervaringenaankoemende })(ErvaringenWidgetAankomende);
