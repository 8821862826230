import React, {  } from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { connect } from "react-redux";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import SelectBox from "../../../../Components/Forms/selectbox";

const BedrijvenForm = ({ handleSubmit, sensoren, categorien }) => {
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field
                className="form"
                name="active"
                validate={required({ msg: "Type item is verplicht" })}
                label="Standplaats actief"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam Kampeerplaats" />
              <Field name="categorie" validate={[required()]} type="input" options={categorien} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Vul de categorie van de kampeerplaats in" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving kampeerplaats (optioneel)" />
              <Field name="device_id" validate={[required()]} type="input" options={sensoren} inputvalue="device_id" inputnaam="naam" component={SelectBox} label="Vul de Sensor ID in" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: "bedrijfinsert",
  })(BedrijvenForm)
);
