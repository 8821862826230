import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FooterForm from "../../../Components/Lib/FooterForm";
import { renderAField, renderTextField } from "../../../Components/Forms/renders";
import switcbutton from "../../../Components/Forms/switch";
import renderDaterange from "../../../Components/Forms/daterange";
import { fetchervaringen } from "../../Ervaringen/Reducers/actions";
import SelectBox from "../../../Components/Forms/selectbox";

const SyncValidationForm = ({ handleSubmit, item, fetchervaringen }) => {
  const [ervaringen, setervaringen] = useState([]);
  useEffect(() => {
    fetchervaringen().then((data) => {
      setervaringen(data.results);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-sm-12">
        <h4 className="info-text">Vul hier de eigenschappen in </h4>
      </div>
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Vul hier de prijzen in </h3>
        </div>
        <div className="box-body">
          <Field name="priceinformation.extrapriceinformation.value" type="input" component={renderTextField} label="Vul hier de informatie in over de prijzen" />
        </div>
      </div>
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Externe partners </h3>
        </div>
        <div className="box-body">
          <Field name="veluwedb.publiceren" type="input" component={switcbutton} label="Evenement publiceren naar Visit Veluwe" />
        </div>
      </div>
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Toegankelijk </h3>
        </div>
        <div className="box-body">
          <Field name="location.toegankeijkheid.hondtoestaan" type="input" component={switcbutton} label="Zijn honden toegestaan?" />
          <Field name="location.toegankeijkheid.rolstoeltoegankelijk" type="input" component={switcbutton} label="Is de locatie rolstoeltoegankelijk?" />
          <Field name="location.toegankeijkheid.toegankelijkvoormindervaliden" type="input" component={switcbutton} label="Is de locatie toegankelijk voor mindervaliden?" />
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Duurzaamheid </h3>
        </div>
        <div className="box-body">
          <Field name="location.duurzaamheid.greenkey" type="input" component={switcbutton} label="Heeft de locatie een greenkey?" />
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Markeer als top item</h3>
        </div>
        <div className="box-body">
          <Field name="top.active" type="input" component={switcbutton} label="Zet dit items als top item actief" />
          <Field name="top.datum" component={renderDaterange} mindate={true} maxdate={true} label="In welke periode wil je het item laten zien" />
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Dit is een webshop item</h3>
        </div>
        <div className="box-body">
          <Field name="metadata.product" component={renderAField} label="Vul de url van de webshop in (webshop/379/klompenmakerspad)" />
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Is dit item online boekbaar</h3>
          </div>
          <div className="box-body">
            <Field name="metadata.boekbaarheid.status" type="input" component={switcbutton} label="Is dit item online boekbaar?" />
            <Field name="metadata.boekbaarheid.id" inputvalue="_id" inputnaam="naam" options={ervaringen} type="input" component={SelectBox} label="Kies om het item aan een locatie te koppelen" />
          </div>
        
      </div>

      <FooterForm />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "eigenschappen_edit", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.items.item, // pull initial values from account reducer
});

export default connect(mapStateToProps, { fetchervaringen })(ReduxFormSensorEdit);
