import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const AddressForm = ({ ontvanggegevs, input, label,  meta: { asyncValidating, touched, error }  }) => {
  const [address, setAddress] = useState("");

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const getAddressParts = (object) => {
    let address = {};
    const address_components = object.address_components;
    address_components.forEach((element) => {
      address[element.types[0]] = element.short_name;
    });
    return address;
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const adressParts = getAddressParts(results[0]);
      const latLng = await getLatLng(results[0]);
      const imputname = input.name;
      ontvanggegevs(selectedAddress, adressParts, latLng, imputname);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const inputProps = {
    value: address,
    onChange: handleChange,
  };

  return (
    <div>
      <label>{label}</label>
      <div className={asyncValidating ? "async-validating" : ""}>
        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Zoek het adres",
                  className: "location-search-input form-control",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, key) => {
                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                  const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={key}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );
};

export default AddressForm;
