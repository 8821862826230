import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { insertstanplaatscategorie } from "../Reducers/actions";
import { useNavigate } from "react-router-dom";

import InsertForm from "./Components/form";

const KampeerplaatsEditPage = ({ match, insertstanplaatscategorie }) => {
  const navigate = useNavigate();

  const submit = (vals) => {
    delete vals["data"];
    insertstanplaatscategorie(vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/standplaatsen/categorien");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Kampeerplaats Catgeorie toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{<InsertForm onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  categorie: state.standplaatsen.categorie,
});

export default connect(mapStateToProps, { insertstanplaatscategorie })(KampeerplaatsEditPage);
