import React, { useEffect } from "react";
import moment from "moment";

const BarComponent = ({ beschikbaarheid, boekingen }) => {

  function datum(cell) {
    if (cell) return moment(cell).format("DD-MM-YYYY");
  }

  return (
    <div className="barback">
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
            <i className="fa-solid fa-calendar-days"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Datum</span>
              <span className="info-box-number">{datum(beschikbaarheid.datum)}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
            <i className="fa-solid fa-list-check"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Aantal reserveringen</span>
              <span className="info-box-number">{beschikbaarheid.aantalreserveringen}</span>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
              <i className="fa fa-users"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Aantal personen</span>
              <span className="info-box-number">{beschikbaarheid.aantalpersonen}</span>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
            <i className="fa-solid fa-user-lock"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Maximaal aantal personen</span>
              <span className="info-box-number">{beschikbaarheid && beschikbaarheid.beschikbaarheid && beschikbaarheid.beschikbaarheid.personenmax}</span>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
            <i className="fa-solid fa-user-clock"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Minimaal aantal personen</span>
              <span className="info-box-number">{beschikbaarheid && beschikbaarheid.beschikbaarheid && beschikbaarheid.beschikbaarheid.personenmin}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarComponent;
