import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { message } from "../../../Reducers/loading/actions";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { fetchreserveringen, deletereservering } from "./Reducers/actions";
import Loading from "../../../Components/loading/load";
import { connect } from "react-redux";

const Inschrijven = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bedrijf = useSelector((state) => state.pubred.bedrijf);
  const reserveringen = useSelector((state) => state.openfaciliteiten.reserveringen);

  useEffect(() => {
    const fetchDetails = async () => {
      if (!bedrijf._id) {
        const data = await dispatch(getbedrijf(params.id));
        if (!data.results) {
          navigate("/404");
        } else {
          dispatch(fetchreserveringen(bedrijf._id));
          setLoading(true);
        }
      } else {
        dispatch(fetchreserveringen(bedrijf._id));
        setLoading(true);
      }
    };
    fetchDetails();
  }, [params.id, navigate, dispatch, bedrijf._id]);

  const deletein = async (id) => {
    await dispatch(deletereservering(id));
    await dispatch(fetchreserveringen(bedrijf._id));
    dispatch(message(4000, "success", lang("Je inschrijving is succesvol verwijderd")));
  };

  function priceFormatter(cell, row) {
    return moment(cell).format("dddd DD MMMM");
  }
  const columns = [
    {
      dataField: "datum",
      text: lang("Datum"),
      formatter: priceFormatter,
    },
    {
      dataField: "tijd",
      text: lang("Tijd"),
    },
    {
      dataField: "faciliteit.naam",
      text: lang("Faciliteit"),
      formatter: (cellContent, row) => {
        return translate(cellContent);
      },
    },
    {
      dataField: "personen",
      text: lang("Personen"),
    },
    {
      text: lang("Uitschrijven"),
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <button className="btn btn-danger" onClick={this.deletein.bind(this, row._id)}>
              {lang("X")}
            </button>
          </span>
        );
      },
    },
  ];
  return (
    <div className="public-box-body">
      <Link
        className="btn btn-success pull-right bt-inschrijvingen"
        to={{
          pathname: "/public/faciliteiten/" + this.props.bedrijf._id,
        }}
      >
        {lang("Faciliteiten")}
      </Link>
      <h2 className="headline text-yellow">{lang("Reserveringen")}</h2>
      <div className="gastinfo">{lang("Wil je je uitschrijven voor een faciliteit? Klik dan op het rode kruisje.")}</div>
      <Loading loading={loading} />
      {loading && (
        <div className="activiteit-inschrijvingen">
          {reserveringen.length === 0 && <h3>{lang("Er zijn geen reserveringen")}</h3>}
          <BootstrapTable keyField="_id" data={reserveringen} columns={columns} wrapperClasses="table-responsive-md" />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    reserveringen: state.openfaciliteiten.reserveringen,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchreserveringen,
  deletereservering,
})(Inschrijven);
