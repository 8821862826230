import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import moment from "moment";

const FilterSensor = (props) => {
  const [loading, setLoading] = useState(true);
  const [gemiddelde, setGemiddelde] = useState(0);
  const [verbruik, setVerbruik] = useState(0);
  const [max, setMax] = useState(0);
  const [stand, setStand] = useState(0);
  const [correctie, setCorrectie] = useState(0);

  useEffect(() => {
    if (!props.loading) {
      setLoading(props.loading);
      setGemiddelde(props.gemiddelde.toFixed(2));
      setMax(props.max.toFixed(2));
      setStand(props.sensor.lastvalue);
      if (props.sensor.correctie) setCorrectie(props.sensor.correctie);
      setVerbruik(props.verbruik);
    }
  }, [props.loading, props.gemiddelde, props.max, props.stand, props.verbruik]);

  const onSubmit = (values) => {
    props.changedata(values);
  };

  const onChangeItem = () => {
    setLoading(true);
    setTimeout(() => props.handleSubmit(onSubmit)(), 0);
  };

  const renderLoading = () => {
    if (loading) {
      return <div className="col-md-3 col-sm-6 col-xs-12 loadingsearch">Gegevens worden geladen ...</div>;
    }
  };

  const welkemeter = (waarde) => {
    if (props.meterSoort === "water") {
      return (waarde / 1000).toFixed(2) + " Kub";
    }
    return waarde + " KwH";
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)}>
      <div className="row container filter ">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <Field name="datum" type="number" mindate={true} maxdate={false} component={renderDaterange} label="Kies een datum" onChange={onChangeItem} />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Totaal Verbruik<span className="gemiddelde">{welkemeter(verbruik)}</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Gemiddelde (per dag)<span className="gemiddelde">{welkemeter(gemiddelde)}</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Hoogste Piek (per dag)<span className="gemiddelde">{welkemeter(max)}</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Laatste Meterstand
          <span className="gemiddelde">
            {welkemeter(stand + correctie)} {correctie != 0 && <i className="fa-solid fa-triangle-exclamation fa-xs"></i>}
          </span>
        </div>
      </div>
      {renderLoading()}
    </form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(14, "days"), enddate: new Date() },
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "filtersensor", // a unique identifier for this form
  })(FilterSensor)
);
