export const SET_CURSISTEN = 'SET_CURSISTEN';
export const ADD_CURSISTEN = 'ADD_CURSISTEN';
export const CURSIST_ERROR = 'CURSIST_ERROR';
export const SET_CURSIST = 'SET_CURSIST'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_CURSISTEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_CURSIST,
    results
  }
}
export function add(results) {
  return {
    type: ADD_CURSISTEN,
    results
  }
}
export function itemError(error) {
  return {
    type: CURSIST_ERROR,
    payload: error
  };
}

export function savecursisten(data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursisten', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results.results));
    }).catch((err) => {
      console.log(err);
      
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function updatecursisten(id, data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursisten/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results.results));
    }).catch((err) => {
      console.log(err);
      
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function upload_cursist_image(id, data) {
  return dispatch => {
    var formDatacurs = new FormData();    
    formDatacurs.append('file', data.file);

    return fetch('/api/admin/trainingen/cursist/upload/'+id, {
      method: 'post',
      body: formDatacurs,
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(handleResponse)
      .then(data => dispatch(set(data.results)))
      .catch(err =>
        dispatch(itemError(err)));
  };
  }

export function fetchcuristen() {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursisten', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function fetchcurist(id) {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursisten/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
