import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderselectapi } from "../../../../Components/Forms/renders";

class SensorEditForm extends React.Component {
  componentDidUpdate(prevProps) {
    const { correctie, lastvalue, dispatch } = this.props;
    if (correctie !== prevProps.correctie || lastvalue !== prevProps.lastvalue) {
      const werkelijk = (parseFloat(correctie) || 0) + (parseFloat(lastvalue) || 0);
      dispatch(change("sensor_edit_from", "werkelijk", werkelijk));
    }
  }

  transformedObject = () =>
    this.props.devices && this.props.devices.end_devices && this.props.devices.end_devices.length > 0
      ? this.props.devices.end_devices.map((device) => ({
          _id: device.ids.device_id,
          naam: device.ids.device_id,
        }))
      : [];

  render() {
    const { handleSubmit, alertprofile, devices } = this.props;

    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-6">
                <Field
                  className="form"
                  name="active"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Status van de sensor"
                  component={renderselect}
                  options={{
                    true: "Actief",
                    false: "Uitgeschakeld",
                  }}
                />
                <Field
                  className="form"
                  name="favo"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Maak je sensor favoriet"
                  component={renderselect}
                  options={{
                    true: "Ja",
                    false: "Nee",
                  }}
                />
                <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam sensor" />
                {this.transformedObject().length > 0 ? <Field name="device_id" type="input" component={renderselectapi} options={this.transformedObject()} label="Kies de Sensor ID in" /> : <Field name="device_id" type="input" component={renderField} label="Kies de Sensor ID in" />}
                <div className="divider">test</div>
                <Field
                  className="form"
                  name="sensor"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Type Sensor"
                  component={renderselect}
                  options={{
                    electra: "Electra",
                    "EM300-DI": "Milesigt EM300-DI Pulse Sensor",
                    "em300-th": "Milesigt EM300-TH Temperature & Humidity Sensor",
                    UC300: "Milesigt UC300",
                    "M114039-02": "M114039-02",
                    energy_emu: "EMU PROFESSIONAL II",
                    "SDM230-LoRaWAN": "SDM230-LoRaWAN",
                    nke_watteco: "Sens'O - LoRaWAN Pulse",
                    temperatuur: "Temperatuur externe Sensor",
                    temperatuurint: "Temperatuur",
                  }}
                />
                <Field
                  className="form"
                  name="soort"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Soort Sensor"
                  component={renderselect}
                  options={{
                    ampere: "Ampere Meter",
                    electra: "Electra",
                    gas: "Gas",
                    temperatuur: "temperatuur",
                    water: "Water",
                  }}
                />
                <Field name="label" type="input" component={renderField} label="Vul label in" />
                <Field name="group" type="input" component={renderField} label="Vul een groep in" />
                <div className="divider">test</div>
                <Field name="lastvalue" type="number" component={renderField} disabled={true} label="Laatste waarde" />
                <div className="row">
                  <div className="col-8">
                    <Field name="correctie" type="number" component={renderField} label="Correctie" />
                  </div>
                  <div className="col-4">
                    <Field name="werkelijk" type="number" disabled={true} component={renderField} label="Werkelijk" />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Field name="minvalue" type="number" component={renderField} label="Minimale waarde correct" />
                <Field name="maxvalue" type="number" component={renderField} label="Maximale waarde correct" />
                <Field name="minvaluetemp" type="number" component={renderField} label="Minimale sensor" />
                <Field name="maxvaluetemp" type="number" component={renderField} label="Maximale waarde sensor" />
                <Field name="alerts.sendminvalue" type="number" component={renderField} label="Minimale waarde stuur alert" />
                <Field name="alerts.sendmaxvalue" type="number" component={renderField} label="Maximale waarde stuur alert" />
                <div className="divider">test</div>
                <Field
                  className="form"
                  name="alerts.uur"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Interval van alerts"
                  component={renderselect}
                  options={{
                    0: "Elke afwijking",
                    1: "Maximaal 1 keer uur",
                    2: "Maximaal 1 keer per dag",
                    3: "Slim opgebouwd ",
                  }}
                />
                <Field name="alertprofile" type="input" component={renderselectapi} options={alertprofile} label="Kies een alert profiel" />
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const ReduxFormSensorEdit = reduxForm({
  form: "bedrijfinsert", // a unique identifier for this form
})(SensorEditForm);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);