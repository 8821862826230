import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getconnections, editconnections, getAccessTokenInstagram } from "../Reducers/actions";
import Cookies from "js-cookie";
function CallBackGoolgeBusisness({ connections, getconnections, editconnections }) {
  const id = Cookies.get("myconnectionid"); // De cookie vervalt over 7 dagen
  const location = useLocation();
let navigate = useNavigate();
  useEffect(() => {
    // De query parameters van de huidige URL verkrijgen.
    const queryParams = new URLSearchParams(location.search);

    // De 'code' parameter uit de query parameters extraheren.
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    if (code) {
      getAccessToken(code);
    }
  }, [location]);

  const getAccessToken = (code) => {
    try {
      getconnections(id).then((data) => {
        fetch("https://oauth2.googleapis.com/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            code: code,
            client_id: data.results.client_id,
            client_secret: data.results.client_secret,
            redirect_uri: window.location.origin + "/admin/connections/callback/googlebusiness",
            grant_type: "authorization_code",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            const accessToken = data.access_token;
            const refreshToken = data.refresh_token; // Het ontvangen refresh token
            fetch("https://mybusinessaccountmanagement.googleapis.com/v1/accounts", {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then((response) => response.json())
              .then((datas) => {
                if (datas && datas.accounts && datas.accounts.length > 0) {
                  editconnections(id, {
                    accessToken: accessToken,
                    refresh_token: refreshToken, // Sla ook het refresh token op
                    extra: { accounts: datas.accounts[0] },
                    redirect_uri: window.location.origin + "/admin/connections/callback/googlebusiness",
                  }).then((data) => {
                    if (data.results) {
                      navigate("/admin/connections/GoogleBusiness/" + id);
                    }
                  });
                } else {
                  console.error("Geen bedrijven gevonden");
                }
              });
          })
          .catch((error) => {
            console.error("Error tijdens het verkrijgen van access token", error);
          });
      });
    } catch (error) {
      console.error("Error tijdens het ophalen van connectie data", error);
    }
  };

  return <div className="box box-default">Data wordt geladen</div>;
}

const mapStateToProps = (state) => {
  return { connections: state.connections.item };
};

export default connect(mapStateToProps, { editconnections, getconnections, editconnections })(CallBackGoolgeBusisness);
