import "rc-time-picker/assets/index.css";

import React from "react";
import { required } from "redux-form-validators";
import { Field } from "redux-form";
import { renderAFieldSimple, renderselectsimple, rendersimpleTextField, renderTextField } from "../../../Components/Forms/renders";
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { fields } = this.props;
    return (
      <tbody>
        {fields.map((beheerders, index) => (
          <tr key={index}>
            <td>
              <Field
                key={index}
                name={`${beheerders}.soort`}
                component={renderselectsimple}
                options={{
                  textfield: "Tekstveld",
                  text: "Tekst",
                }}
                label="Wat voor soort veld"
                validate={required({ msg: "Dit item is verplicht" })}
              />
            </td>
            <td>
              <Field validate={required({ msg: "Dit item is verplicht" })} key={index} name={`${beheerders}.naam`} onChange={this.handleSelect} type="text" component={renderAFieldSimple} label="Naam" />
            </td>
            <td>
              <Field key={index} name={`${beheerders}.beschrijving`} onChange={this.handleSelect} type="text" component={rendersimpleTextField} label="Extra beschrijving" />
            </td>
            <td>
              <Field
                key={index}
                name={`${beheerders}.verplicht`}
                component={renderselectsimple}
                options={{
                  true: "Ja",
                  false: "Niet",
                }}
                label="Is dit veld verplicht?"
                validate={required({ msg: "Dit item is verplicht" })}
              />
            </td>

            <td>
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <button type="button" onClick={() => fields.push({})}>
              Checkout veld Toevoegen
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
}

export default CheckoutForm;
