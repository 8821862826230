import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchaccommodaties } from "../../Reducers/actions";
import { Link } from "react-router-dom";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
function FavSesnorenWidget({ accommodaties, fetchaccommodaties }) {
  const [load, setLoad] = useState(true);
  const [search, setSearch] = useState("");
  useEffect(() => {
    fetchaccommodaties(6).then(() => {
      setLoad(false);
    });
  }, []);
  function isalert(sensor) {
    if (sensor.active === false) return "card-header switchoff";
    if (sensor.status === "false") return "card-header alerttemp";
    else return "card-header";
  }

  function graph(row) {
    const data = [];
    if (row.meter_data.length > 0) {
      row.meter_data.map(function (a, key) {
        if (key > 0) {
          data.push(Number(row.meter_data[key - 1].value - Number(a.value)));
        }
        return a;
      });
    }
    return (
      <Sparklines height={50} data={data.reverse()}>
        <SparklinesLine color="#848c45" limit={20} />
        <SparklinesSpots />
      </Sparklines>
    );
  }
  function lastvalue(row) {
    if (row.meter_data.length > 0) {
      return Number(row.meter_data[0].value).toFixed(0);
    } else {
      return null;
    }
  }
  const handleSearchChange = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearch(e.target.value);
      fetchaccommodaties(6, e.target.value).then(() => {
        setLoad(false);
      });
    } else {
      setSearch(e.target.value);
    }
  };

  const getMeterIcon = (meter, size) => {
    switch (meter) {
      case "water":
        return <i style={{ fontSize: size + "rem", color: "#3baa35" }} className="fa-solid fa-droplet"></i>;
      case "gas":
        return <i style={{ fontSize: size + "rem", color: "#3baa35" }} className="fa-solid fa-fire"></i>;
      case "electra":
        return <i style={{ fontSize: size + "rem", color: "#3baa35" }} className="fa-solid fa-bolt"></i>;
      default:
        return null;
    }
  };

  function getKwh(meter, kWh) {
    switch (meter) {
      case "water":
        return (kWh/1000).toFixed(2) + " Kub";
      case "gas":
        return kWh + " Kub";
      case "electra":
        return kWh + " kWh";
      default:
        return null;
    }
  }

  return (
    <div className="row tempsensor">
      <div className="col-12 mb-3">
        <label htmlFor="search-bar-0" className="search-label">
          <span id="search-bar-0-label" className="sr-only">
            Zoek de accommodatie
          </span>
          <input id="search-bar-0" onChange={handleSearchChange} type="text" aria-labelledby="search-bar-0-label" className="form-control" placeholder="Zoeken" value={search} />
        </label>
      </div>

      {load ? (
        <div className="col-lg-12">
          <div className="alert alert-info">Laden...</div>
        </div>
      ) : accommodaties.length > 0 ? (
        accommodaties.map((sensor, key) => (
          <div key={key} className="col-lg-4 p-4">
            <div className="card card-primary">
              <div className={isalert(sensor)}>
                <h3 className="card-title">
                  <i className="fa-solid fa-house"></i> {sensor.naam}
                </h3>
              </div>

              <div className="card-body">
                {sensor.active ? <div className="valuekwh"></div> : <div className="valueswitchoff">Uitgeschakeld</div>}

                {sensor.meters.map((meter, key) => (
                  <div key={key} className="box p-4">
                    <Link to={{ pathname: "/standplaatsen/accommodaties/show/" + sensor._id + "/" + meter.sensor_id }}>
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-auto">{getMeterIcon(meter.soort, 2)}</div>
                        <div className="col-auto valuekwh">{getKwh(meter.soort, lastvalue(meter))}</div>
                      </div>
                    </Link>
                    <div className="graph">{graph(meter)}</div>
                  </div>
                ))}
              </div>
              <div className="card-footer">
                <Link className="small-box-footer" to={`/standplaatsen/accommodaties/show/${sensor._id}`}>
                  Bekijk de accommodatie <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-lg-12">
          <div className="alert alert-info">Er zijn geen accommodaties gevonden.</div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user, accommodaties: state.standplaatsen.accommodaties };
}
export default connect(mapStateToProps, { fetchaccommodaties })(FavSesnorenWidget);
