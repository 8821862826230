import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getarrangementen, savebeschikbaarheid, getbeschikbaarheid, updatebeschikbaarheid, getarrangementenall, setalledagen, savebeschikbaarheidregels } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import Bar from "../Components/bar";
import BoekingenTabelArrangementDetail from "../Components/bschikbaarheidtabel";
import BeschikbaarheidForm from "../Components/beschikbaarheidregels";
import BeschikbaarheidFormRegels from "../Components/beschikbaarheidregels";
import Beschikbaarheideditform from "../Components/beschikbaarheideditform";
import Rodal from "rodal";
import Loading from "../../../Components/loading/load";
import { useParams } from "react-router-dom";

const BeschikbaarheidPage = ({ getarrangementen, alledagen, setalledagen, ervaring, fixmessage, message, boekingen, savebeschikbaarheid, getbeschikbaarheid, beschikbaarheid, updatebeschikbaarheid, getarrangementenall, savebeschikbaarheidregels }) => {
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleedit, setVisibleedit] = useState(false);
  const [visibleregels, setVisibleregels] = useState(false);
  const params = useParams();
  const submit = (vals) => {
    vals.ervaring = params.id;
    vals.beschikbaarheid = ervaring.beschikbaarheid;
    vals.location = ervaring.location;
    vals.prijzen = ervaring.prijzen;
    vals.betaald = ervaring.betaald;
    savebeschikbaarheid(vals).then((data) => {
      if (!data.results) {
        fixmessage("danger", data.payload.error.msg);
      } else {
        message(2000, "success", "De beschikbaarheid is succesvol toegevoegd").then((data) => {});
        loadarrangementen();
        setVisible(false);
      }
    });
  };

  const submitregels = (vals) => {
    vals.ervaring = params.id;
    vals.beschikbaarheid = ervaring.beschikbaarheid;
    vals.location = ervaring.location;
    vals.prijzen = ervaring.prijzen;
    vals.betaald = ervaring.betaald;
    savebeschikbaarheidregels(vals).then((data) => {
      if (!data.results) {
        fixmessage("danger", data.payload.error.msg);
      } else {
        message(2000, "success", "De beschikbaarheid is succesvol toegevoegd").then((data) => {});
        loadarrangementen();
        setVisible(false);
      }
    });
  };

  const update = (vals) => {
    updatebeschikbaarheid(vals._id, vals).then((data) => {
      if (!data.results) {
        fixmessage("danger", data.payload.error.msg);
      } else {
        message(2000, "success", "De beschikbaarheid is succesvol gewijzigd").then((data) => {});
        loadarrangementen();
        setVisibleedit(false);
      }
    });
  };


  useEffect(() => {
    if (alledagen) {
      getarrangementenall(params.id).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    } else {
      loadarrangementen();
    }
  }, [alledagen]);

  const loadarrangementen = () => {
    getarrangementen(params.id).then((sdata) => {
      if (sdata.results) {
        setLoaded(true);
      }
    });
  };

  const loadbeschikbaarheid = (id) => {
    setVisibleedit(false);
    getbeschikbaarheid(id).then((sdata) => {
      if (sdata.results) {
        setTimeout(() => {
          setVisibleedit(true);
        }, 100); // 1000 milliseconden = 1 seconde
      }
    });
  };

  return (
    <React.Fragment>
      <Loading loadings={loaded} />
      {loaded && (
        <div className="box box-default">
          <div className="box-header with-border">
            <div className="box-title">
              <h2>{ervaring.naam}</h2>
            </div>
            <div className="box-tools pull-right">
              <button onClick={() => setVisibleregels(true)} className="btn btn-primary btn-sm float-right" role="group">
                Beschikbaarheid regels toevoegen
              </button>
              <button onClick={() => setVisible(true)} className="btn btn-primary btn-sm float-right" role="group">
                Beschikbaarheid toevoegen
              </button>
              <button onClick={() => setalledagen(!alledagen)} className="mx-2 btn btn-info btn-sm float-right" role="group">
                {alledagen ? "Toekomstige dagen" : "Alle dagen"}
              </button>
            </div>
          </div>
          <Rodal visible={visibleregels} onClose={() => setVisibleregels(false)} className="rodal-md">
            <div className="box box-default">
              <div className="box-title">
                <h3>Beschikbaarheid regels toevoegen</h3>
              </div>
              <BeschikbaarheidFormRegels ervaring={ervaring} onSubmit={submitregels} />
            </div>
          </Rodal>

          <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-md">
            <div className="box box-default">
              <div className="box-title">
                <h3>Beschikbaarheid toevoegen</h3>
              </div>
              <BeschikbaarheidForm ervaring={ervaring} onSubmit={submit} />
            </div>
          </Rodal>


          <Rodal visible={visibleedit} onClose={() => setVisibleedit(false)} className="rodal-md">
            <div className="box box-default">
              <div className="box-title">
                <h3>Beschikbaarheid wijzigen</h3>
              </div>
              {visibleedit && <Beschikbaarheideditform beschikbaarheid={beschikbaarheid} onSubmit={update} />}
            </div>
          </Rodal>

          <div className="box-body box-profile">
            <div>
              <Bar ervaring={ervaring} boekingen={boekingen} />
            </div>
            <div className="box-body">
              <BoekingenTabelArrangementDetail loadbeschikbaarheid={loadbeschikbaarheid} beschikbaarheid={ervaring.ervaringdatums} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.arrangementen.item, boekingen: state.boekingen.items, beschikbaarheid: state.arrangementen.beschikbaarheid, alledagen: state.arrangementen.alledagen };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getarrangementen,
  savebeschikbaarheid,
  getbeschikbaarheid,
  updatebeschikbaarheid,
  getarrangementenall,
  setalledagen,
  savebeschikbaarheidregels
})(BeschikbaarheidPage);
