import React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { email, url } from "redux-form-validators";
import FooterForm from "../../../Components/Lib/FooterForm";
import { renderField } from "../../../Components/Forms/renders";
class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { onSubmit, item } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <h4 className="info-text">Vul de contactgegevens in</h4>
              </div>
            </div>
            <Field name="contactinformation.urls[0].value" validate={url({ allowBlank: true })} type="input" component={renderField} label="Website" />
            <Field name="contactinformation.phonenumbers[0].value" type="input" component={renderField} label="Telefoonnummer" />
            <Field name="contactinformation.emailaddresses[0].value" validate={email({ allowBlank: true })} type="input" component={renderField} label="E-mailadres" />
            <Field name="contactinformation.reseveerurl" validate={url({ allowBlank: true })} type="input" component={renderField} label="Reserverings website" />
            <FooterForm />
          </form>
        )}
      />
    );
  }
}

// You have to connect() to any Reducers that you wish to connect to yourself
SyncValidationForm = connect(
  (state) => ({
    item: state.items.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(SyncValidationForm);

export default SyncValidationForm;
