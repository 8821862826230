import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { DateRangePicker } from "react-dates";

const RenderDaterange = ({ input, label, mindate, maxdate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const orientation = window.matchMedia("(max-width: 700px)").matches ? "vertical" : "horizontal";
  useEffect(() => {
    if (input.value) {
      if (input.value.startdate) {
        setStartDate(controleerdatum(input.value.startdate));
      }
      if (input.value.enddate) {
        setEndDate(controleerdatum(input.value.enddate));
      }
    }
  }, [input.value]);

  const controleerdatum = (datum) => {
    if (moment(datum, "DD/MM/YYYY").isValid()) {
      return moment(datum, "DD/MM/YYYY");
    }
    return moment(datum);
  };

  const isOutsideRange = (day) => {
    if (mindate && day.isBefore(moment())) {
      return false;
    }
    if (maxdate && day.isAfter(moment())) {
      return false;
    }
    return true;
  };

  return (
    <div className="date-range-picker">
      <label>{label}</label>
      <div>
        <DateRangePicker
          enableOutsideDays
          displayFormat="DD/MM/YYYY"
          startDatePlaceholderText={"van"}
          endDatePlaceholderText={"tot"}
          startDate={startDate}
          startDateId="startDate"
          endDate={endDate}
          orientation={orientation}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
            const newValue = {
              ...input.value,
              startdate: startDate ? startDate.toISOString() : null,
              enddate: endDate ? endDate.toISOString() : null,
            };
            input.onChange(newValue);
          }}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          isOutsideRange={isOutsideRange}
        />
      </div>
    </div>
  );
};

export default RenderDaterange;
