import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveberichten } from "../Reducers/actions";
import BerichtenForm from "./Components/form";
import { message } from "../../../Reducers/loading/actions";

const BerichtenInsert = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const submit = (vals) => {
    dispatch(saveberichten(vals)).then((data) => {
      if (data.type === "CMS_ERROR") {
        dispatch(message(2000, "warning", data.payload));
        return false;
      } else {
        navigate("/cms/berichten");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Bericht toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <BerichtenForm onSubmit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BerichtenInsert;
