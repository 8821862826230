import React from "react";
import { Routes, Route } from "react-router-dom";
import EventPage from './index';
import EventPageJaarlijks from './jaarlijks';

const EventRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<EventPage />} />
      <Route path='/jaarlijks' element={<EventPageJaarlijks />} />
    </Routes>
  );
};

export default EventRouter;
