import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getbiepactiviteit, delbiepactiviteit } from "./Reducers/actions";
import { fixmessage, message } from "../../Reducers/loading/actions";
import handleDownload from "../../Components/Lib/download";

const BiepShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const biepp = useSelector((state) => state.biepp.item);

  React.useEffect(() => {
    dispatch(getbiepactiviteit(id)).then((data) => {
      if (data.results) {
        // Do something
      } else {
        dispatch(fixmessage("warning", "Er is een fout opgetreden bij het laden van de activiteit"));
      }
    });
  }, [dispatch, id]);

  const DownloadFile = (url, filename) => {
    handleDownload(url, filename);
  };

  const verwijderen = (id) => {
    dispatch(delbiepactiviteit(id)).then((data) => {
      dispatch(message(2000, "warning", "De activiteit is succesvol verwijderd")).then((data) => {
        navigate("/biepp");
      });
    });
  };

  const listItems = (items) => {
    return (
      <ul className="item_eigenschappen">
        {items.map((item, key) => (
          <li className="border" key={key}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  const bestanden = (items) => {
    return (
      <ul className="item_bestanden p-4">
        {items.map((item, key) => (
          <li className="" key={key}>
            {item.originalname}{" "}
            <button className="float-right" onClick={() => handleDownload("/files/" + item.filename, item.originalname)}>
              Downloaden
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const slechtweer = (item) => {
    return item ? "Ja" : "Nee";
  };

  return (
    <React.Fragment>
      {biepp && (
        <div className="box box-default fullheight">
          <div className="box-header with-border">
            <div className="float-right">
              <button onClick={() => verwijderen(biepp._id)} className="btn btn-next btn-fill btn-danger btn-wd btn-sm">
                Verwijderen
              </button>
            </div>
            <h1> {biepp.naam}</h1>
          </div>
          <div className="box box-default">
            <div className="row p-4">
              <div className="col-8 ">
                <div className="beschrijving"> {biepp.beschrijving}</div>
                <div className="inhoudsopgaven p-4">
                  <h5>Inhoudsopgaven</h5>
                  <ul>
                    <li>Voorbereiding activiteit</li>
                    <li>Uitleg activiteit</li>
                    <li>Tips voor de activiteit</li>
                  </ul>
                </div>
                <div className="onderdelen m-2">
                  <h5>Voorbereiding</h5>
                  <div dangerouslySetInnerHTML={{ __html: biepp.voorbereiding }}></div>
                </div>
                <div className="onderdelen m-2">
                  <h5>Uitleg</h5>
                  <div dangerouslySetInnerHTML={{ __html: biepp.uitleg }}></div>
                </div>
                <div className="onderdelen m-2">
                  <h5>Tips</h5>
                  <div dangerouslySetInnerHTML={{ __html: biepp.tips }}></div>
                </div>
                <div className="onderdelen p-4 border">
                  <h5>Materialen</h5>
                  {listItems(biepp.materialen)}
                </div>

                <div className="onderdelen p-4 mt-4 border">
                  <h5>Bijlagen</h5>
                  {bestanden(biepp.file)}
                </div>
              </div>
              <div className="col-4 border">
                <h3>Eigenschappen</h3>
                <div className="eigenschappen p-2">
                  <h6>Categorie</h6>
                  {listItems(biepp.categorie)}
                </div>
                <div className="eigenschappen p-2">
                  <h6>Doelgroep</h6>
                  {listItems(biepp.doelgroep)}
                </div>
                <div className="eigenschappen p-2">
                  <h6>Locatie</h6>
                  {listItems(biepp.plaats)}
                </div>
                <div className="eigenschappen p-2">
                  <h6>Minimaal aantal deelnemers</h6>
                  {biepp.mindeelnemers}
                </div>
                <div className="eigenschappen p-2">
                  <h6>Duur</h6>
                  {biepp.duur.min} - {biepp.duur.max} minuten
                </div>
                <div className="eigenschappen p-2">
                  <h6>Slechtweer activiteit</h6>
                  {slechtweer(biepp.slechtweer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default BiepShow;
