import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { saveberichten, getoneberichten, updateberichten } from "../Reducers/actions";
import BerichtenForm from "./Components/edit";
import { message } from "../../../Reducers/loading/actions";

const BerichtenEdit = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  const submit = (vals) => {
    dispatch(updateberichten(id, vals)).then((data) => {
      if (data.type === "CMS_ERROR") {
        dispatch(message(2000, "warning", data.payload));
        return false;
      } else {
        navigate("/cms/berichten");
      }
    });
  };

  useEffect(() => {
    dispatch(getoneberichten(id)).then((data) => {
      if (data.type === "CMS_ERROR") {
        dispatch(message(2000, "error", data.payload));
        return false;
      } else {
        setLoaded(true);
      }
    });
  }, [dispatch, id]);

  return (
    <div className="box box-default">
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BerichtenForm onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BerichtenEdit;
