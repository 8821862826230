import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { getfaciliteit, getbeschikbaarheidweek, getweek, saveweek, startweekvalues } from "./Reducers/actions";
import Beschikbaarheid from "./Components/beschikbaarheid";
import DatumForm from "./Components/datumform";
import OmschrijvingFrom from "./Components/omschrijving";
import WeekForm from "./Components/weekform";
import { message } from "../../Reducers/loading/actions";
import { translate } from "../../Components/Lib/Languages/language";

const ProfilePage = ({ faciliteit, week, getfaciliteit, getbeschikbaarheidweek, getweek, saveweek, message, startweekvalues }) => {
  const [loaded, setLoaded] = useState(false);
  const [tijden, setTijden] = useState([]);
  const [starttijd, setStarttijd] = useState("");
  const [dagen, setDagen] = useState([]);
  const [loadbschikbaar, setLoadbschikbaar] = useState(false);
  const [weekloaded, setWeekloaded] = useState(false);
  const [startdatum, setStartdatum] = useState("");
  const [einddatum, setEindatum] = useState("");
  const [weken, setWeek] = useState([]);
  const [eindtijd, setEindtijd] = useState("");
  const [datum, setDatum] = useState(moment().format("WW-YYYY"));

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getfaciliteit(id).then((sdata) => {
      if (sdata.results) {
        setDatum(moment().format("WW-YYYY"));
        loadweek(moment().format("WW-YYYY"));
      }
    });
  }, [getfaciliteit, id]);

  const submit = (vals) => {
    setLoadbschikbaar(false);
    setWeekloaded(true);
    setDatum(moment(vals.datum).format("WW-YYYY"));
    loadweek(moment(vals.datum).format("WW-YYYY"));
  };

  const loadweek = (datum) => {
    getweek(id, { datum }).then((data) => {
      if (data.results) {
        setbeschikbaarheid(data.results, datum);
        setDatum(datum);
        setWeek(data.results);
        setWeekloaded(true);
      } else {
        setDatum(datum);
        setWeek(faciliteit);
        setLoadbschikbaar(false);
      }
      setLoaded(true);
    });
  };

  const submitweek = (vals) => {
    const values = {
      datum: datum,
      faciliteiten: id,
      ...vals,
    };
    saveweek(values).then((data) => {
      setLoadbschikbaar(false);
      message(2000, "success", "De week is opgeslagen wordt nu geladen").then((mes) => {
        setbeschikbaarheid(data.results.results, datum);
        setLoadbschikbaar(true);
      });
    });
  };

  const startweek = (vals) => {
    const values = {
      datum: datum,
      faciliteiten: id,
      ...vals,
    };
    startweekvalues(values).then((data) => {
      setLoadbschikbaar(false);
      message(2000, "success", "De week is opgeslagen wordt nu geladen").then((mes) => {
        loadweek(datum);
      });
    });
  };

  const setbeschikbaarheid = (data, datum) => {
    const startdatum = moment(datum, "WW-YYYY").startOf("week").toISOString();
    const eindatum = moment(datum, "WW-YYYY").endOf("week").toISOString();
    setStartdatum(startdatum);
    setEindatum(eindatum);
    setStarttijd(data.starttijd);
    setEindtijd(data.eindtijd);
    const start = moment.duration(data.starttijd, "HH:mm");
    const diff = Math.round(moment.duration(data.eindtijd, "HH:mm").subtract(start).as("minutes") / data.tijdsloten);
    const arr = [];
    for (let i = 0; i < diff; i++) {
      arr.push({
        tijd: moment(data.starttijd, "HH:mm")
          .add(data.tijdsloten * i, "minutes")
          .format("HH:mm"),
      });
    }
    setTijden(arr);

    const dag = [];
    for (let i = 0; i < 7; i++) {
      dag.push(moment(startdatum).add(i, "days").toISOString());
    }
    setDagen(dag);
    getbeschikbaarheidweek({
      startdatum: startdatum,
      einddatum: eindatum,
      faciliteit: faciliteit._id,
    }).then((data) => {
      setLoadbschikbaar(true);
    });
  };

  const setweek = (week) => {
    loadweek(moment(datum, "WW-YYYY").add(week, "weeks").format("WW-YYYY"));
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>{translate(faciliteit.naam)}</h2>
        </div>
        <button onClick={() => navigate("/faciliteiten")} className="btn btn-primary btn-sm float-right" role="group">
          Overzicht
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          {loaded && (
            <div className="box-body box-profile">
              <DatumForm week={week} onSubmit={submit} />
              {weekloaded && (
                <div>
                  <WeekForm datum={datum} week={week} onSubmit={startweek} />
                  <OmschrijvingFrom datum={datum} week={week} onSubmit={submitweek} />
                </div>
              )}
              <div>{loadbschikbaar && <Beschikbaarheid faciliteit={faciliteit} startdatum={startdatum} einddatum={einddatum} tijden={tijden} starttijd={starttijd} eindtijd={eindtijd} dagen={dagen} />}</div>
              <div className="box-body">
                <button onClick={() => setweek(-1)} className="btn btn-next btn-fill btn-success btn-wd btn-sm pull-left">
                  Vorige week
                </button>
                <button onClick={() => setweek(1)} className="btn btn-next btn-fill btn-success btn-wd btn-sm pull-right">
                  Volgende week
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  faciliteit: state.faciliteiten.item,
  week: state.faciliteiten.week,
});

export default connect(mapStateToProps, {
  getfaciliteit,
  getbeschikbaarheidweek,
  getweek,
  saveweek,
  message,
  startweekvalues,
})(ProfilePage);
