export const SET_SENINSTELLINGEN = 'SET_SENINSTELLINGEN';
export const SET_SENINSTELLINGEN_DEVICES = 'SET_SENINSTELLINGEN_DEVICES';
export const SENINSTELLING_ERROR = 'SENINSTELLING_ERROR';


function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_SENINSTELLINGEN,
    results
  }
}

export function setdevices(results) {
  return {
    type: SET_SENINSTELLINGEN_DEVICES,
    results
  }
}


export function itemError(error) {
  return {
    type: SENINSTELLING_ERROR,
    payload: error
  };
}




export function editinstellinngen(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/sensors/instellingen/', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      return dispatch(set(results));
    } catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function fetchinstellingen() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/sensors/instellingen/', {
        method: 'get',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      return dispatch(set(results));
    } catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function fetchsensorid() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/sensors/instellingen/sensorid', {
        method: 'get',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      return dispatch(setdevices(results));
    } catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

