import {
  SET_PUSH,
  ADD_PUSH,
} from './actions';
export default function cats(state = {
  pushe: '',
  pushberichten: []
}, action = {}) {
  switch (action.type) {
    case ADD_PUSH:
      return {
        ...state,
        pushe: action.results
      };
    case SET_PUSH:
      return {
        ...state,
        pushberichten: action.results
      };
    default:
      return state;
  }
}
