import { SET_SENINSTELLINGEN, SENINSTELLING_ERROR, SET_SENINSTELLINGEN_DEVICES } from "./actions";
export default function sensorinstellingen(
  state = {
    item: {},
    devices: [],
  },
  action = {}
) {
  switch (action.type) {
    case SENINSTELLING_ERROR:
      return { ...state, error: action.payload };
    case SET_SENINSTELLINGEN:
      return { ...state, item: action.results };
    case SET_SENINSTELLINGEN_DEVICES:
      return { ...state, devices: action.results };

    default:
      return state;
  }
}
