import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchvacatursbedrijven,  } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import BoekingenTabel from "../Components/bedrijven_tabel";
const BedrijvenVacatures = ({ bedrijven,fetchvacatursbedrijven }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchvacatursbedrijven().then((sdata) => {

      if (sdata.results) {
        setLoaded(true);
      }
    });
  }, [fetchvacatursbedrijven]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>Bedrijven</h2>
        </div>
        <div className="box-tools pull-right">
          <Link to="/vacatures/bedrijf/toevoegen" className="btn btn-primary btn-md float-right" role="group">
            Bedrijf Toevoegen
          </Link>
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">


          <div className="box-body">
            <BoekingenTabel bedrijven={bedrijven} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, bedrijven: state.vacatures.bedrijven };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  fetchvacatursbedrijven,
})(BedrijvenVacatures);
