import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Rodal from "rodal";
import SimpleForm from "./form/edit";
import { Update, DeleteArrangementen, GetArrangementen } from "../Reducers/arrangementen/actions";
import ErrorMessage from "../../../Components/loading/error";
import { useParams, useNavigate } from "react-router-dom";

const ArrangementenEdit = ({ errors, arrangementen, error }) => {
  const [state, setState] = useState({ errors: {}, id: "", visible: false });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetArrangementen(params.trcid)).then((data) => {
      if (!data.results || !data.results._id) {
        navigate("/items/arrangementen");
        alert("Kan item niet vinden");
      } else {
        setState((prevState) => ({ ...prevState, id: data.results._id }));
      }
    });
  }, [dispatch, navigate, params.trcid]);

  const submit = (values) => {
    dispatch(Update(values, state.id)).then(() => {
      navigate("/items/arrangementen");
    });
  };

  const show = () => {
    setState((prevState) => ({ ...prevState, visible: true }));
  };

  const hide = () => {
    setState((prevState) => ({ ...prevState, visible: false }));
  };

  const delitem = () => {
    dispatch(DeleteArrangementen(state.id)).then(() => {
      navigate("/items/arrangementen");
    });
  };

  const actionitems = (item) => (
    <div className="btn-group">
      <button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
        Acties
        <span className="caret"></span>
        <span className="sr-only">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu" role="menu">
        <li>
          <a onClick={(e) => show()}>Verwijderen</a>
        </li>
      </ul>
      <Rodal visible={state.visible} onClose={hide.bind(this)}>
        <div className="box box-danger">
          <div className="box-header">
            <h3 className="box-title">Let op</h3>
          </div>
          <div className="box-body">Weet je zeker dat je dit item wil verwijderen. Het item wordt permanent verwijderd</div>
          <div className="box-footer">
            <button className="btn btn-info pull-left" onClick={hide.bind(this)}>
              Annuleren
            </button>
            <button className="btn btn-danger pull-right" onClick={delitem.bind(this)}>
              Verwijderen
            </button>
          </div>
        </div>
      </Rodal>
    </div>
  );

  if (arrangementen._id) {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-tools pull-right">{actionitems(arrangementen)}</div>
        </div>
        <div className="box-body">
          <ErrorMessage fout={state.errors} />
          <SimpleForm onSubmit={submit} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    errors: state.auth.error,
    arrangementen: state.arrangementen_wild.item,
    error: state.theme.error,
  };
}
export default connect(mapStateToProps, {
  Update,
  DeleteArrangementen,
  GetArrangementen,
})(ArrangementenEdit);
