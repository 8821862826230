import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchberichten } from "../Reducers/actions";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const Berichten = () => {
  const bedrijven = useSelector((state) => state.cms.berichten.items);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchberichten());
    // Voeg hier eventuele andere effecten toe
  }, [dispatch]);


  const columns = [
    {
      dataField: "post_title",
      text: "titel",
    },
    {
      dataField: "post_excerpt",
      text: "Samenvatting",
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning" to={"/cms/berichten/edit/" + row._id}>
              Wijzigen
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];
  return (
    <div className="content-page types">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht bedrijven</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/cms/berichten/new">
            <i className="fa fa-plus"></i>&nbsp; Bericht Toveoegen
          </Link>
        </div>
      </div>
      <div className="box-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Berichten</h3>
                </div>

        <div className="box box-primary">
          <div className="box-body box-profile" />
          <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={bedrijven} columns={columns} pagination={paginationFactory()} />
        </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Berichten;
