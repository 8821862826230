import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { update, getone } from "./Reducers/actions";
import GebruiksInsert from "./Components/insert";
import { signupUser } from "../../../Reducers/auth/actions";
import { message } from "../../../Reducers/loading/actions";
import { fetchbedrijven } from "../Bedrijven/Reducers/actions";
import { fetchervaringen } from "../../Ervaringen/Reducers/actions";

const GebruikersInsertPage = ({ username, fetchbedrijven, bedrijven, signupUser, message, fetchervaringen, ervaringen }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoaded(false);
    fetchbedrijven().then(() => {
      fetchervaringen().then(() => {
        setLoaded(true);
      });
    });
  }, [fetchbedrijven]);

  const submit = (vals) => {
    const values = Object.assign({}, { bedrijf: username.bedrijf }, vals);
    values.telefoonnummer.replace(" ", "").replace("+", "");
    signupUser(values).then((data) => {
      if (data.type === "auth_error") {
        message("3000", "danger", data.payload);
      } else {
        message("3000", "success", "Het account is met succes aangemaakt. De nieuwe gebruiker ontvangt nu een e-mail").then(() => {
          navigate("/admin/gebruikers/");
        });
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Gebruiker toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <GebruiksInsert ervaringen={ervaringen} bedrijven={bedrijven} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  bedrijven: state.bedrijven.items,
  gebruiker: state.mgebruukers.item,
  ervaringen: state.ervaringen.items,
});

export default connect(mapStateToProps, { update, getone, signupUser, message, fetchbedrijven, fetchervaringen })(GebruikersInsertPage);
