import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SubMenu from "./Components/submenu";
import { findmediaindir } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";

const MediaIndex = ({ findmediaindir, message }) => {
  const submit = (values) => {
    findmediaindir(values).then((res) => {
      message(4000, "success", "De settings is succesvol verwerkt");
    });
  };
  return (
    <div className="content-page">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Settings - Media</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">Zoeken naar media</h3>
                    </div>
                    <div className="box-body">
                      <button onClick={() => submit({})} className="btn btn-primary">
                        Zoeken
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return { settings: state.cms.settings };
}

export default connect(mapStateToProps, {
  findmediaindir,
  message,
})(MediaIndex);
