import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';



class BedrijvenForm extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
      
    };
    componentDidMount() {
        if(this.props.input.value) {
            
          this.setState({value: this.props.input.value.value})
      }
      }
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>{
        this.props.input.onChange({id: suggestion._id, value:suggestion.what.title.value });
        
    }
    clearinput  = ({ value }) => {
        this.props.input.onChange({});
        this.setState({value: ""})

    }
    onSuggestionsFetchRequested = ({ value }) => {
        fetch('/api/items/search/locations', {
            method: 'post',
            body: JSON.stringify({query:value}),
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem('token')
            }
          })
            .then(response => response.json())
            .then(data => this.setState({ suggestions: data.results }))
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const getSuggestionValue = suggestion => suggestion.what.title.value;

        // Use your imagination to render suggestions.
        const renderSuggestion = suggestion => (
            <div>
                {suggestion.what.title.value}
            </div>
        );
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Maak een keuze",
            value,
            onChange: this.onChange
        };
        const fields = this.props
        const { label } = this.props;
        return (
            <div>
                <label>{label}</label>
                <div className="inputs">
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        onSuggestionSelected={this.onSuggestionSelected}
                        inputProps={inputProps} />
                        <span onClick={this.clearinput} id="searchclear" className="usericonbox glyphicon glyphicon-remove-circle"></span>
                </div>
                <div>{fields.meta.error && <span>{fields.meta.error}</span>}</div>
            </div>);
    }
}

export default BedrijvenForm;

