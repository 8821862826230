import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

const SearchDropdown = ({ items, Search }) => {
  const [query, setQuery] = useState("");
  const [dropdownSearch, setDropdownSearch] = useState(false);
  const dropdownRef = useRef(null);

  const handleChange = (event) => {
    setQuery(event.target.value);
    if (event.target.value.length > 2) {
      const query = event.target.value;
      Search({ query }).then(() => {
        setDropdownSearch(true);
      });
    } else {
      setDropdownSearch(false);
    }
  };

  const handleLinkClick = () => {
    setDropdownSearch(false);
    setQuery("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownSearch(false);
        setQuery("");
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="app-search dropdown d-none d-lg-block" ref={dropdownRef}>
      <form>
        <div className="input-group custom-input-group">
          <input type="search" className="form-control dropdown-toggle" name="query" value={query} onClick={handleChange} onChange={handleChange} placeholder="Zoek in items..." id="top-search" />
        </div>
      </form>
      <div
        id="searchDropdownMenu"
        className={`hs-form-search-menu-content dropdown-menu dropdown-menu-form-search navbar-dropdown-menu-borderless animated hs-form-search-menu-initialized ${dropdownSearch ? "show" : ""}`}
      >
        <div className="card">
          <div className="card-body-height">
            <span className="dropdown-header">Zoek Resultaten ({items.length})</span>

            <div className="dropdown-item bg-transparent text-wrap">
              {items.slice(0, 10).map((app) => (
                <Link
                  key={app.metadata.trcid}
                  className="dropdown-item"
                  to={"/items/edit/" + app.metadata.trcid}
                  onClick={handleLinkClick}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 text-truncate ms-2">
                      <span> {app.what.title.value}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            <div className="dropdown-divider"></div>
          </div>

          <Link className="card-footer text-center" to="/items" onClick={handleLinkClick}>
            Bekijk alle resultaten<i className="bi-chevron-right small"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;
