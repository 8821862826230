import React, { useEffect, useState } from "react";
import Multiselect from "react-widgets/Multiselect";
import "react-widgets/scss/styles.scss";

const MultiSelectDagen = (props) => {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.input.value) {
      setValue(props.input.value);
    } else {
      setValue([]);
    }
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.input.value, props.options]);

  const handleCreate = (name) => {
    setValue([...value, name]);
    setOptions([...options, name]);
  };

  const handleSearch = (evt) => {
    // handle search logic here
  };

  const handleSelect = (selectedValue) => {
    setValue(selectedValue);
    props.input.onChange(selectedValue);
  };

  const {
    label,
    meta: { error, touched, warning },
  } = props;

  return (
    <div>
      <label>{label}</label>
      <div>
        <Multiselect allowCreate={props.allowCreate ? props.allowCreate : "onFilter"} onCreate={(name) => handleCreate(name)} data={options} onSearch={handleSearch} onChange={handleSelect} value={value} defaultValue={[]} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default MultiSelectDagen;
