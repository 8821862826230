import React from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      tijden: [],
    };
  }

  render() {
    const { handleSubmit, tijden, dag } = this.props;
  

    function datum(cell) {
      if (cell) return moment(cell).format("DD-MM-YYYY");
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="flex-row">
          <div className="flex-cell">{datum(dag)}</div>
          {tijden.map((tijd, i) => (
            <div className="flex-cell" key={i}>
              <Field name={`tijden[${i}].personen`} type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} />
            </div>
          ))}
          <div className="flex-cell">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-sm" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({ enableReinitialize: true })(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect((state, props) => {
  let values = props.bevals.filter(function (person) {
    return moment(person.datum).format("DDMMYYYY") === moment(props.dag).format("DDMMYYYY");
  });

  if (values[0]) {
    return {
      initialValues: values[0],
    };
  } else {
    return {
      initialValues: {
        datum: props.dag,
        tijden: props.tijden,
      },
    };
  }
})(LogboekFormWebsite);

export default LogboekFormWebsite;
