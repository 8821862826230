import React from "react";
import SubMenu from "./Components/submenu";
import { Link } from "react-router-dom";
function Index(props) {
  return (
    <div className="content-page">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Settings</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">Settings</h3>
                    </div>
                    <div className="box-body">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
