import React from "react";
import { connect } from "react-redux";
import { Field,reduxForm } from "redux-form";
import renderDaterange from "../../../Components/Forms/daterange";
import { renderselect } from "../../../Components/Forms/renders";
import multiSelectDagen from "../../../Components/Forms/multiselect";
class SerachLogboekPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: true
    };
  }
  componentWillReceiveProps(nextprops) {
    if (!nextprops.loading) {
      this.setState({ loading: nextprops.loading });
    }
  }
  onSubmit(values) {
    this.props.getvals(values);
  }


  onchangeitem = vals => {
    const { handleSubmit } = this.props;
    this.setState({ loading: true });
    setTimeout(
      handleSubmit(params => {
        this.onSubmit(params);
      })
    );
  };

  deleteform = vals => {
    const { reset } = this.props;
        this.props.getvals({});
    reset();

  };
  bedrijvensearch(token, bedrijven, showbedrijf) {
      if (token.role === "root" && showbedrijf) {
        return (
          <Field
            name='bedrijven'
            type="input"
            options={bedrijven}
            onChange={this.onchangeitem}
            mvalue="_id"
            mtextfield="naam"
            component={multiSelectDagen}
            label="Kies je bedrijf"
          />
        )
      }
    }
loading () {
      if (this.state.loading) {
      return (
        <div className="col-md-3 col-sm-6 col-xs-12 loadingsearch">
        Gegevens worden geladen ...
        </div>
      )
    }
    }
  render() {
    const {  bedrijven, token, showbedrijf } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="container row ">
        <div className="col-md-12 col-sm-12 col-xs-12">
          {this.bedrijvensearch(token, bedrijven, showbedrijf)}
        </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Field
              name="datum"
              type="number"
              component={renderDaterange}
              label="Kies een datum"
              mindate={true} maxdate={true}
              onChange={this.onchangeitem}
            />
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12">
            <Field
              className="form"
              name="soortactiviteit"
              label="Soorten Activiteiten"
              component={renderselect}
              onChange={this.onchangeitem}
              options={{
                Creativiteit: "Creativiteit",
                Spel: "Spel",
                Sport: "Sport",
                Tochten: "Tochten",
                "Show/amusement": " Show/amusement",
                "Theater (amusement)": "Theater (amusement)",
                "Minidisco (amusement)": "Minidisco (amusement)",
                "Kerkdienst": "Kerkdienst"
              }}
            />
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12">
            <Field
              className="form"
              label="Doelgroep"
              name="doelgroep"
              component={renderselect}
              onChange={this.onchangeitem}
              options={{
                "Kinderen 6-": "Kinderen 6-",
                "Kinderen 12-": "Kinderen 12-",
                "Jeugd 12-15": "Jeugd 12-15",
                "16+": "16+",
                Volwassenen: "Volwassenen",
                Gezinnen: "Gezinnen",
                Senioren: "Senioren"
              }}
            />
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12">
            <Field
              className="form"
              name="vakantie"
              label="Vakantie periode"
              component={renderselect}
              onChange={this.onchangeitem}
              options={{
                Weekenden: "Weekenden",
                Vakantie: "Vakantie",
                "Door de weeks (buiten de vakantie)":
                  "Door de weeks (buiten de vakantie)"
              }}
            />
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12">
            <Field
              className="form"
              name="betalend"
              label="Betalend"
              component={renderselect}
              onChange={this.onchangeitem}
              options={{
                true: "Ja",
                false: "Nee"

              }}
            />
          </div>
            <div className="col-md-1 col-sm-2 col-xs-12 delbotnleeg">
<button type="button" className=" btn btn-next btn-warning btn-wd btn-mg" onClick={() => {
        this.deleteform()
      }}>Leegmaken</button>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <Field
                className="form"
                name="alexsammy"
                label="Alex en Sammy"
                component={renderselect}
                onChange={this.onchangeitem}
                options={{
                  true: "Ja",
                  false: "Nee"

                }}
              />
            </div>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <Field
                className="form"
                name="samenwerkinghoreca"
                label="Samwerking Horeca"
                component={renderselect}
                onChange={this.onchangeitem}
                options={{
                  true: "Ja",
                  false: "Nee"

                }}
              />
            </div>
      {this.loading()}
        </div>
      </form>
    );
  }
}

SerachLogboekPage = reduxForm({
  form: "filterlogboek" // a unique identifier for this form
})(SerachLogboekPage);

// You have to connect() to any reducers that you wish to connect to yourself
SerachLogboekPage = connect(
)(SerachLogboekPage);

export default SerachLogboekPage;
