import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
  render() {
    const { sensor, meterSoort, data } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }

    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "value",
        text: meterSoort === "water" ? "Kub" : "KwH",
        formatter: (cellContent, row) => {
          if (cellContent) {
            return <span className="badge badge-success">{cellContent.toFixed(2)}</span>;
          } else {
            return null;
          }
        },
      },
      {
        dataField: "meterstand",
        text: "Meterstand",
        formatter: (cellContent, row) => {
          if (sensor.correctie) {
            return (cellContent + sensor.correctie)
          } else {
            return cellContent
          }
        },
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="datum" data={data} columns={columns} pagination={paginationFactory()} />;
  }
}
