import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchkampeerplaatsen } from "../../Reducers/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
function FavSesnorenWidget({ kampeerplaatsen, fetchkampeerplaatsen }) {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    fetchkampeerplaatsen().then((data) => {
      setLoad(false);
    }, []);
  }, []);
  function isalert(sensor) {
    if (sensor.active == false) return "card-header switchoff";
    if (sensor.status == "false") return "card-header alerttemp";
    else return "card-header";
  }
  function luodate(momn) {
    if (momn.data && momn.data[0]) return moment(momn.data[0].datum).fromNow();

  }
  function graph(row) {
    const data = [];
    if (row.data.length > 0) {
      row.data.map(function (a, key) {
        if (key > 0) {
          data.push(Number(row.data[key - 1].value - Number(a.value)));
        }
        return a;
      });
    }
    return (
      <Sparklines height={100} data={data.reverse()}>
        <SparklinesLine color="#848c45" limit={20} />
        <SparklinesSpots />
      </Sparklines>
    );
  }
  function lastvalue(row) {
    if (row.data.length > 0) {
        return Number(row.data[0].value).toFixed(2);
    } else {
      return null;
    }
  }

  return (
    <div className="row">
      {load ? (
        <div className="col-lg-12">
          <div className="alert alert-info">Laden...</div>
        </div>
      ) : kampeerplaatsen.length > 0 ? (
        kampeerplaatsen.map((sensor, key) => (
          <div key={key} className="col-lg-4 p-4">
            <div className="tempsensor card card-primary">
              <div className={isalert(sensor)}>
                <h3 className="card-title">{sensor.naam}</h3>
              </div>
              {graph(sensor)}
              <div className="details">
                {sensor.active ? <div className="valuekwh">{lastvalue(sensor)}</div> : <div className="valueswitchoff">Uitgeschakeld</div>}
                <div className="meeting">Laatste meting {luodate(sensor)}</div>
              </div>
              <div className="card-footer">
                <Link className="small-box-footer" to={{ pathname: "/standplaatsen/kampeerplaatsen/show/" + sensor._id }}>
                  Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-lg-12">
          <div className="alert alert-info">Er zijn geen sensoren gevonden.</div>
        </div>
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return { user: state.auth.user, kampeerplaatsen: state.standplaatsen.kampeerplaatsen };
}
export default connect(mapStateToProps, { fetchkampeerplaatsen })(FavSesnorenWidget);
