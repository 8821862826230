import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Header = ({ boekingen, ervaringen, user }) => {
  function ismodule(val) {
    if (user.bedrijf && user.bedrijf.modules && user.bedrijf.modules.includes(val)) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div className="barback">
      <div className="row">
        {ismodule("activiteiten") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/activiteiten"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-volleyball-ball"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Activiteiten</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("biepp") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/biepp"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-swatchbook"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Biepp</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("logboeken") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/logboek"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-book"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Logboeken</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("sensoren") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/sensoren"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-project-diagram"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Sensoren</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("standplaatsen") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/standplaatsen/kampeerplaatsen/"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-campground"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Standplaatsen</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("kamperplaatsen") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/standplaatsen/kampeerplaatsen/"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-campground"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Kampeerplaatsen</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("accommodaties") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/standplaatsen/accommodaties/"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-house"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Accommodaties</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("gastenverbruik") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/gastenverbruik"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-user-check"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Gasten verbruik</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("vvvitems") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/gastenverbruik"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fas fa-user-check"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Gasten verbruik</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("ervaringen") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/ervaringen"} className="info-box ">
              <span className="info-box-icon bg-aqua">
                <i className="fa-solid fa-torii-gate"></i>
              </span>

              <div className="info-box-content position-relative">
                <span className="info-box-text">Ervaringen</span>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{ervaringen.length}</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("ervaringen") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/boekingen"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa-regular fa-folder-closed"></i>
              </span>

              <div className="info-box-content position-relative">
                <span className="info-box-text">Boekingen</span>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{boekingen.length}</span>
              </div>
            </Link>
          </div>
        )}
        {ismodule("items") && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Link to={"/items"} className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa-solid fa-folder-open"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">VVV Items</span>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, ervaringen: state.ervaringen.items, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {})(Header);
