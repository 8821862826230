import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { initializeFirebase, onMessageListener } from "./Auth/pushnotification";
import Header from "./Themes/Main/Header.jsx";
import Sitebar from "./Themes/Main/Sitebar.jsx";
import Footer from "./Themes/Main/Footer.jsx";
import Account from "./Themes/Account";
import LoginPage from "./Components/Account/Login/Login.jsx";
import ForgetPage from "./Components/Account/Login/Forget";
import NewPasswordPage from "./Components/Account/Login/NewPassword";
import SmsPage from "./Components/Account/Sms/";
import AdminRouter from "./Pages/Admin/router";

import VerficationPage from "./Components/Account/SignUp/Confirmation.jsx";
import {RequireAuth} from "./Auth/require_auth";
import {RequireAuthAdmin} from "./Auth/require_auth_admin";

import NoMatch from "./Themes/Main/404";

import Loading from "./Components/loading/messages";
import LogboekPages from "./Pages/Logboeken/router";
import Activiteiten from "./Pages/Activiteiten/router";
import FaciliteitenPages from "./Pages/Faciliteiten/router";
import Public from "./Themes/Public/index";
import PublicPages from "./Pages/Public/router";
import GebruikersPages from "./Pages/Gebruikers/router";
import MainPage from "./Pages/index";
import { settranslate } from "./Components/Lib/Languages/language";
import HorecaRouter from "./Pages/Horeca/router.js";
import BieppRouter from "./Pages/Biepp/router.js";
import VerhuurRouter from "./Pages/Verhuur/router";
import SensorRouter from "./Pages/Sensors/router.js";
import StandplaatsRouter from "./Pages/Standplaatsen/router.js";
import GastenverbruikRouter from "./Pages/GastenVerbruik/router.js";
import ItemRouter from "./Pages/Items/router.js";
import EventRouter from "./Pages/Evemenenten/router.js";
import ErvaringenRouter from "./Pages/Ervaringen/router.js";
import BoekingenRouter from "./Pages/Boekingen/router.js";
import CMSRouter from "./Pages/CMS/router";
import VacaturesRouter from "./Pages/Vacatures/router.js";
import ArrangementenRouter from "./Pages/Arrangementen/router.js"; 

const App = () => {
  const location = useLocation();
  const loc = location.pathname.split("/");
  initializeFirebase();
  onMessageListener()
    .then((payload) => {
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    settranslate();
    if (location.key) {
      document.body.classList.remove("sidebar-open");
    }
  }, [location]);

  if (loc[1] === "login" || loc[1] === "signup" || loc[1] === "404") {
    return (
      <Account>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/login/sms/:token" element={<SmsPage />} />
          <Route exact path="/login/forget" element={<ForgetPage />} />
          <Route exact path="/login/forget/:code" element={<NewPasswordPage />} />
          <Route exact path="/signup/verify/:code" element={<VerficationPage />} />
          <Route exact path="/404" element={<NoMatch />} />
        </Routes>
      </Account>
    );
  } else if (loc[1] === "public") {
    return (
      <Public>
        <Routes>
          <Route path="/public" component={PublicPages} />
        </Routes>
      </Public>
    );
  } else {
    return (
      <React.Fragment>
        <div className="content-page">
          <Header />
          <Sitebar />
          <div className="content-wrapper">
            <Loading />
            <section className="content">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <RequireAuth>
                      <MainPage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/admin/*"
                  element={
                    <RequireAuth>
                      <AdminRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/logboek/*"
                  element={
                    <RequireAuth>
                      <LogboekPages />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/activiteiten/*"
                  element={
                    <RequireAuth>
                      <Activiteiten />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/faciliteiten/*"
                  element={
                    <RequireAuth>
                      <FaciliteitenPages />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/gebruikers/*"
                  element={
                    <RequireAuthAdmin>
                      <GebruikersPages />
                    </RequireAuthAdmin>
                  }
                />
                <Route
                  path="/horeca/*"
                  element={
                    <RequireAuth>
                      <HorecaRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/biepp/*"
                  element={
                    <RequireAuth>
                      <BieppRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/verhuur/*"
                  element={
                    <RequireAuth>
                      <VerhuurRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/sensoren/*"
                  element={
                    <RequireAuth>
                      <SensorRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/standplaatsen/*"
                  element={
                    <RequireAuth>
                      <StandplaatsRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/gastenverbruik/*"
                  element={
                    <RequireAuth>
                      <GastenverbruikRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/items/*"
                  element={
                    <RequireAuth>
                      <ItemRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/evenementen/*"
                  element={
                    <RequireAuth>
                      <EventRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/ervaringen/*"
                  element={
                    <RequireAuth>
                      <ErvaringenRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/boekingen/*"
                  element={
                    <RequireAuth>
                      <BoekingenRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/cms/*"
                  element={
                    <RequireAuth>
                      <CMSRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/vacatures/*"
                  element={
                    <RequireAuth>
                      <VacaturesRouter />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/arrangementen/*"
                  element={
                    <RequireAuth>
                      <ArrangementenRouter />
                    </RequireAuth>
                  } 
                />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </section>
          </div>
        </div>
        <Footer classNmae="main-footer" />
      </React.Fragment>
    );
  }
};

export default App;
