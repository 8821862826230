import React, {useEffect} from "react";
import { connect } from "react-redux";
import { fetchconnections } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";

const ConnectionsPage = ({ fetchconnections, connections }) => {
  useEffect(() => {
    fetchconnections();
  }, [fetchconnections]);

  const columns = [
    {
      dataField: "naam",
      text: "Naam",
    },
    {
      dataField: "interval",
      text: "Interval",
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning" to={"/admin/connections/edit/" + row._id}>
              Wijzigen
            </Link>
            <Link className="btn btn-info" to={`/admin/connections/${row.soort}/${row._id}`}>
              Bekijken
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht connections</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/admin/connections/insert">
            <i className="fa fa-plus"></i>&nbsp; Connections Toevoegen
          </Link>
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile" />
          <BootstrapTable keyField="_id" data={connections} columns={columns} pagination={paginationFactory()} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { connections: state.connections.items };
};

export default connect(mapStateToProps, { fetchconnections })(ConnectionsPage);
