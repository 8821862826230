import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import { renderselect } from "../../../../Components/Forms/renders";
import moment from "moment";
const FilterSensor = ({ loading, gemiddelde, max, stand, changedata, standbegin, standperiode, standbeginperiode, lekkage }) => {
  const onchangeitem = (vals) => {
    setTimeout(() => {
      changedata(vals);
    }, 200);
  };
  const verschil = () => {
    const verbruik = stand - standbegin;
    const vorigeperiode = (standperiode - standbeginperiode ) / 1000;
    if (verbruik < vorigeperiode) {
      return <div className="badge badge-success">{(verbruik - vorigeperiode).toFixed(3)}</div>;
    } else {
      return <div className="badge badge-danger"> +{(verbruik - vorigeperiode).toFixed(3)}</div>;
    }
  };
  const periode = (val) => {
    const value = parseInt(val.target.value);
    const configs = [
      { months: 1, startOffset: 1, endOffset: 1 },
      { months: 2, startOffset: 2, endOffset: 2 },
      { months: 3, startOffset: 1, endOffset: 3 },
      { months: 6, startOffset: 1, endOffset: 6 },
      { period: "year", startOffset: 0, endOffset: 0 },
      { period: "year", startOffset: 1, endOffset: 1 },
    ];

    const config = configs[value];
    let startdate, enddate;

    if (config) {
      if (config.period) {
        startdate = moment().subtract(config.startOffset, config.period).startOf(config.period).toDate();
        enddate = moment().subtract(config.endOffset, config.period).endOf(config.period).toDate();
      } else {
        startdate = moment().subtract(config.months, "month").startOf("month").toDate();
        enddate = moment().subtract(config.startOffset, "month").endOf("month").toDate();
      }
    } else {
      startdate = moment().subtract(7, "days").toDate();
      enddate = new Date();
    }
    changedata({ startdate, enddate });
  };


  return (
    <form>
      <div className="row container filter ">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <Field name="datum" type="number" mindate={true} maxdate={false} component={renderDaterange} label="Kies een datum" onChange={onchangeitem} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
        <Field name="periode" type="input" options={["Afgelopen maand", "Vorige maand", "Afgelopen 3 maanden", "Afgelopen half jaar ", "Dit jaar", "Vorige jaar"]} component={renderselect} label="Kies een periode" onChange={periode} />
        </div>
        {lekkage.status && (
          <div className="col-md-4 col-sm-6 col-xs-6">
            Status{" "}
            <span
              data-tooltip-id="tooltip-anchor-hide"
              data-html="true"
              data-tooltip-content="Er is geen nulstand beschikbaar in deze periode, wat betekent dat er gedurende deze tijd water is gebruikt. 
                In de meeste gevallen is er echter altijd een moment waarop er geen water wordt gevraagd."
              data-tooltip-delay-hide={1000}

              className="gemiddelde badge badge-warning"
            >
              Vermoedelijk een lekkage
            </span>
            <Tooltip id="tooltip-anchor-hide" />
          </div>
        )}
      </div>
      <hr />
      <div className="row container filter my-2 ">
        <div className="col-md-2 col-sm-6 col-xs-6">
          Gemiddelde<span className="gemiddelde">{(gemiddelde/10).toFixed(3)} Kub</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Hoogste Piek<span className="gemiddelde">{(max/10).toFixed(3)} Kub</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Verbruik<span className="gemiddelde">{((stand - standbegin)).toFixed(3)} Kub</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Vorige periode<span className="gemiddelde">{((standperiode - standbeginperiode ) / 1000).toFixed(3)}</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Verschil periode<span className="gemiddelde">{verschil()}</span>
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          Meterstand<span className="gemiddelde">{stand.toFixed(2)}</span>
        </div>
      </div>
    </form>
  );
};

const reduxConnectedForm = reduxForm({ form: "filtersensor" })(FilterSensor);

const mapStateToProps = (state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(7, "days"), enddate: new Date() },
  },
});

export default connect(mapStateToProps)(reduxConnectedForm);
