import React, { useState, useEffect } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import Rodal from "rodal";
import { required, numericality } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";

const ContactWijzigen = ({ handleSubmit, boeking, formValues }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <button className="btn btn-info mx-2" onClick={() => setVisible(true)}>
        Contact bewerken
      </button>
      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-md">
        <div className="card actionmodal">
          <div className="card-header">
            <h4 className="mb-0">Contactgegevens wijzigen</h4>
          </div>
          <div className="box box-default">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <Field name="klanten.voornaam" type="input" validate={required({ msg: "Beschrijving is verplicht" })} component={renderField} label="Voornaam" />
                <Field name="klanten.tussenvoegsel" type="input" component={renderField} label="Tussenvoegsel" />
                <Field name="klanten.achternaam" type="input" validate={required({ msg: "Beschrijving is verplicht" })} component={renderField} label="Achternaam" />
                <Field name="klanten.email" type="input" validate={required({ msg: "Beschrijving is verplicht" })} component={renderField} label="Email" />
                <Field name="klanten.telefoonnummer" type="input" validate={required({ msg: "Beschrijving is verplicht" })} component={renderField} label="Telefoonnummer" />
              </div>
              <div className="box-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Wijzigen
                </button>
              </div>
            </form>
          </div>
        </div>
      </Rodal>
    </>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "terugstortform", // a unique identifier for this form
})(ContactWijzigen);

const mapStateToProps = (state) => ({
  initialValues: state.boekingen.item,
  formValues: getFormValues("terugstortform")(state),
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
