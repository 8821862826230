import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class BarGraphVoorspel extends Component {
  render() {
    const { sensor, type, soort } = this.props;
    var dateformate = "dd-MM";
    if (type === "maand") {
      dateformate = "MMM-YY";
    }
    if (type === "uur") {
      dateformate = "HH:mm";
    }
    if (type === "uren") {
      dateformate = "HH:mm";
    }
    function grdata(sensordata) {
      if (sensordata.forecast) {
        const columns = [
          { type: "datetime", label: "datum" },
          { type: "number", label: soortsensor(soort.soort).titel },
          { type: "number", label: "Maximaal correct" },
          { type: "number", label: "Minimale correct" },
        ];
        const rows = [];
        sensordata.forecast.forEach(function (a, key) {
          if (a.datum && a.value) {
            let value = parseFloat(a.value.toFixed(2));
            if (type === "uur" && key > 0) {
              value -= sensordata.forecast[key - 1].value;
            }
            const row = [
              moment(a.datum).toDate(),
              value,
              soort.maxvalue, // Constant value 2
              soort.minvalue, // Constant value 0
            ];
            rows.push(row);
          }
        });

        return [columns, ...rows];
      }
    }

    const soortsensor = (soortmeter) => {
      if (soortmeter === "electra") {
        return { naam: "kWh", waarde: 1, titel: "Electriciteit" };
      } else if (soortmeter === "water") {
        return { naam: "m³", waarde: 1000, titel: "Water" };
      } else if (soortmeter === "gas") {
        return { naam: "m³", waarde: 100, titel: "Gas" };
      } else if (soortmeter === "temperatuur") {
        return { naam: "C", waarde: 100, titel: "Temperatuur" };
      } else {
        return { naam: "kWh", waarde: 1, titel: "Electriciteit" };
      }
    };

    return (
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: dateformate,
          },
          vAxis: {
            title: soortsensor(soort.soort).naam,
          },

          seriesType: "bars",
          series: {
            1: { type: "line" },
            2: { type: "line", color: "red", lineDashStyle: [4, 4] }, // Constant 2
            3: { type: "line", color: "blue", lineDashStyle: [4, 4] }, // Constant 0
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
