import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Search, itemError, CountItmesTop, CountItemsTopOnline, Top, SetItemtoEdit, Corona } from '../Reducers/items/actions';

const ItemToppage = ({ items }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CountItmesTop());
    dispatch(Corona({ "skip": 0, "limit": 200 }));
  }, [dispatch]);

  const status = (app) => {
    // ... dezelfde logica als in de status functie
  };

  const handleItem = (item) => {
    dispatch(SetItemtoEdit(item));
    navigate('/items/edit/eigenschappen/' + item.metadata.trcid);
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Corona items</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" type="button" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Items Toevoegen</Link>
        </div>
      </div>
      <div className="box-body">

      </div>

      <div className="box-header with-border">
        <h3 className="box-title">Lijst met info over corona</h3>
      </div>
      <div className="box-body">
        <div className="box-body table-responsive no-padding">
          <table className="table table-hover">
            <tbody>
              <tr>
                <th>Titel</th>
                <th>Date</th>
                <th>Beschrijving</th>
                <th>Status</th>
        
                <th></th>
              </tr>
              {
                items.map((app) => (<tr key={app._id} onClick={() => { handleItem(app) }} >
                  <td>{app.what.title.value}</td>
                  <td>{moment(app.metadata.onlinedate).format("DD-MM-YYYY")}</td>

                  <td>{app.what.shortdescription.value.substr(0, 30)}</td>
                  <td>
                    {this.status(app)}
                  </td>
                  <td><button onClick={() => { handleItem(app) }} ><span className="label label-success">edit</span></button></td>
                </tr>))}
            </tbody>
          </table>
        </div>
      </div>
    </div>)
  }

function mapStateToProps(state) {
  return { errors: state.auth.error, items: state.items.items };
}
export default connect(mapStateToProps, { Search, itemError, CountItmesTop, CountItemsTopOnline, Top, SetItemtoEdit, Corona })(ItemToppage);
