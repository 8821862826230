import React from "react";
import { required } from "redux-form-validators";
import { Field } from "redux-form";
import { renderField, renderselapisimple } from "../../../../Components/Forms/renders";



export default function Groepsitems({fields, sourceItems}) {
  return (
    <tbody>
      {fields.map((field, index) => (
        <tr key={index}>
          <td>
            <Field validate={required({ msg: "Datum is verplicht" })} component={renderField} name={`${field}.naam`} />
          </td>
          <td>
            <Field validate={required({ msg: "Datum is verplicht" })} options={sourceItems} inputnaam="desc" inputvalue="soort"  component={renderselapisimple} name={`${field}.soort`}/>
          </td>
          <td>
            <button onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Item Toevoegen
          </button>
        </td>
      </tr>
    </tbody>
  );
}
