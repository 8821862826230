import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Search, itemError, CountItmesTop, CountItemsTopOnline, Top, SetItemtoEdit } from "../Reducers/items/actions";

const ItemToppage = ({ items }) => {
  const [count, setCount] = useState(0);
  const [countOnline, setCountOnline] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CountItmesTop()).then((data) => {
      if (data.items) {
        setCount(data.items);
      }
    });

    dispatch(CountItemsTopOnline()).then((data) => {
      if (data.items) {
        setCountOnline(data.items);
      }
    });

    dispatch(Top({ skip: 0, limit: 20 }));
  }, [dispatch]);

  const status = (app) => {
    if (app.metadata.online && !app.metadata.newupdate) {
      return <span className="label label-success">Online</span>;
    }
    if (app.metadata.online && app.metadata.newupdate) {
      return <span className="label label-info">Nieuwe update</span>;
    }
    if (!app.metadata.online) {
      return <span className="label label-warning">Offline</span>;
    }
  };

  const top = (app) => {
    if (app.top && app.top.datum && app.top.datum.enddate && app.top.datum.startdate) {
      if (moment().isBetween(app.top.datum.startdate, app.top.datum.enddate)) {
        return <span className="label label-success">Actief</span>;
      }

      if (moment().isAfter(app.top.datum.startdate) && !app.top.datum.enddate) {
        return <span className="label label-success">Alijd actief</span>;
      }

      if (moment().isBefore(app.top.datum.startdate)) {
        return <span className="label label-info">Moet nog starten</span>;
      }
      if (moment().isAfter(app.top.datum.enddate)) {
        return <span className="label label-warning">Verlopen</span>;
      }
    }
  };

  const whatis = (app) => {
    if (app.metadata.entitytype === "EVENEMENT") {
      return <i className="fas fa-calendar-alt"></i>;
    } else {
      return <i className="fas fa-landmark"></i>;
    }
  };

  const handleItem = (item) => {
    dispatch(SetItemtoEdit(item));
    navigate("/items/edit/" + item.metadata.trcid);
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Top Items</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" type="button" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Items Toevoegen
          </Link>
        </div>
      </div>
      <div className="box-body">
        <div className="countbar">
          Aantal items:
          <span>{count}</span>
          Aantal Items online:
          <span>{countOnline}</span>
        </div>
      </div>

      <div className="box-header with-border">
        <h3 className="box-title">Lijst met de top items</h3>
      </div>
      <div className="box-body">
        <div className="box-body table-responsive no-padding">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Titel</th>
                <th>Date</th>
                <th>Status</th>
                <th>Top status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((app) => (
                <tr
                  key={app._id}
                  onClick={() => {
                    handleItem(app);
                  }}
                >
                  <td>{whatis(app)}</td>
                  <td>{app.what.title.value}</td>
                  <td>{moment(app.metadata.onlinedate).format("DD-MM-YYYY")}</td>
                  <td>{status(app)}</td>
                  <td>{top(app)}</td>
                  <td>
                    <button
                      className="btn-primary btn btn-box-too btn-sm"
                      onClick={() => {
                        handleItem(app);
                      }}
                    >
                      Wijzigen
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { errors: state.auth.error, items: state.items.items };
}
export default connect(mapStateToProps, { Search, itemError, CountItmesTop, CountItemsTopOnline, Top, SetItemtoEdit })(ItemToppage);
