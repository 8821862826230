import React from "react";
import { connect } from "react-redux";
import { savefaciliteiten } from "./Reducers/actions";
import LogboekForm from "./Components/insert";
import {message} from "../../Reducers/loading/actions"
import { getonewebsite } from "../Website/Reducers/actions";
class LogboekInsertPage extends React.Component {
  state = {
    errors: {},
  };


  submit = (vals) => {
    this.props.savefaciliteiten(vals).then((data) => {
      
      if (data.type === "FACILITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De faciliteit is succesvol verwerkt").then(data => {
          this.props.navigate("/faciliteiten/");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Faciliteit toevoegen </h3>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
               <LogboekForm onSubmit={this.submit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    logboeken: state.logboeken,
    website: state.website.item,
  };
}

export default connect(mapStateToProps, { savefaciliteiten, getonewebsite, message })(
  LogboekInsertPage
);
