import React from "react";
import {Field, FieldArray, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import renderDaterange from "../../../Components/Forms/daterange";
import FooterForm from '../../../Components/Lib/FooterForm'
import OpeningstijdenForm from './sub/openingstijden'
import PeriodesForm from './sub/periodes'
import {
  renderTextField,
  renderselect,
  renderFieldsimple
} from "../../../Components/Forms/renders"
class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      opencheck: null,
      alwaysopen: false,
      excludeholidays: false
    }
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentDidMount() {
    if (this.props.initialValues && this.props.initialValues.when.singledates.length > 0) {
      this.setState({"opencheck": "Periodes"});
    }
    if (this.props.initialValues && this.props.initialValues.when.datepatterns.length > 0) {
      this.setState({"opencheck": "Openingstijden"});
    }

  }
  handleSelect = (value) => {
    this.setState({"opencheck": value.target.value});
    if (value.target.value === "Altijd open") {
      this.props.change("when.alwaysopen", true);
      this.props.change("when.excludeholidays", false);
    }
    if (value.target.value === "Op aanvraag") {
      this.props.change("when.excludeholidays", true);
      this.props.change("when.alwaysopen", false);
    }

  };

  wanneer() {
    if (this.state.opencheck === "Geen informatie") {
      return (<div className="alert ">
        <h2>Geen kalenderinformatie</h2>
        Geen verdere details benodigd
      </div>);
    } else if (this.state.opencheck === "Altijd open") {
      return (<div className="alert ">
        <h2>Altijd open</h2>
        Geen verdere details benodigd
      </div>);
    } else if (this.state.opencheck === "Op aanvraag") {
      return (<div className="alert">
        <h2>Op aanvraag</h2>
        Geen verdere details benodigd
      </div>);
    } else if (this.state.opencheck === "Openingstijden") {
      return (<div className="alert">
        <h2>Openingstijden/ Periodes</h2>
        <Field name='when.datepatterns[0][0]' mindate={true} maxdate={true} component={renderDaterange} label="Kies de week van de periode"/>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Van</th>
              <th>Tot</th>
              <th></th>
            </tr>
          </thead>
          <FieldArray name={`when.datepatterns[0][0].days`} component={OpeningstijdenForm}/>
        </table>
      </div>);
    } else if (this.state.opencheck === "Periodes") {
      return (<div className="alert">
        <h2>Specifieke data</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Van</th>
              <th>Tot</th>
              <th></th>
            </tr>
          </thead>
          <FieldArray name={`when.singledates`} component={PeriodesForm}/>
        </table>
      </div>);
    }
  }

  render() {
    const {handleSubmit} = this.props;


    return (<form onSubmit={handleSubmit}>
      <div className="col-sm-12">
        <h4 className="info-text">
        Wat zijn de openingstijden etc</h4>
      </div>
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Publicatiedata</h3>
          </div>
          <div className="box-body">
            <Field name='marketingsitems' component={renderDaterange} label="In welke periode wil je het item laten zien"/>
          </div>
        </div>
    
      <Field type="hidden" name="when.alwaysopen" component={renderFieldsimple} value={this.state.alwaysopen}/>
      <Field type="hidden" name="when.excludeholidays" component={renderFieldsimple} value={this.state.excludeholidays}/>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Wanneer</h3>
          </div>
          <div className="box-body">
            <div className="row">
              <div className="col-lg-3">
                <Field className="form" name="openingstijden" label="Maak eerste een keuze" component={renderselect} options={{
                    "Geen informatie" : 'Geen informatie',
                    'Altijd open' : 'Altijd open',
                    'Op aanvraag' : 'Op aanvraag',
                    'Openingstijden' : 'Openingstijden',
                    'Periodes' : 'Specifieke data'
                  }} onChange={this.handleSelect}/>
              </div>
              <div className="col-lg-9">
                {this.wanneer()}
              </div>
          
          </div>
        </div>
      </div>

      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Meer uitleg toevoegen (vrije tekst, vb. andere uren tijdens de schoolvakantie)</h3>
          </div>
          <div className="box-body">
            <Field name="when.comment.value" type="input" component={renderTextField} label="Meer uitleg toevoegen (vrije tekst, vb. andere uren tijdens de schoolvakantie)"/>
      
        </div>
      </div>
      <FooterForm/>

    </form>);
  }
};

SyncValidationForm = reduxForm({
  form: 'formwanneer' // a unique identifier for this form
})(SyncValidationForm)

const mapStateToProps = state => { // You have to connect() to any Reducers that you wish to connect to yourself
  if (state.items.item.what) {
    return {initialValues: state.items.item}
  } else {
    return {startitem: "beginnen"}
  }
}

export default connect(mapStateToProps)(SyncValidationForm)