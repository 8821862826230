import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Searchbytag, itemError, SetItemtoEdit } from "./Reducers/items/actions";
import ndtrccode from "../../lib/ndtrccodes.json";
const ReactTags = require("react-tag-autocomplete");

const ItemsZoekTags = ({ items, Searchbytag, SetItemtoEdit }) => {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState([]);
  const [suggestions] = useState(ndtrccode);
  const navigate = useNavigate();

  const handleItem = (item) => {
    SetItemtoEdit(item);
    navigate("/items/edit/" + item.metadata.trcid);
  };

  const onChange = (value) => {
    setValue(value);
  };

  const handleDelete = (i) => {
    const updatedValue = value.slice(0);
    updatedValue.splice(i, 1);
    setValue(updatedValue);
  };

  const handleAddition = (tag) => {
    const updatedValue = [].concat(value, tag);
    setValue(updatedValue);
    const search = [].concat(query, tag.id);
    setQuery(search);
    Searchbytag({ types: search }).then(() => {});
  };

  const status = (app) => {
    if (app.metadata.online && !app.metadata.newupdate) {
      return <span className="label label-success">Online</span>;
    }
    if (app.metadata.online && app.metadata.newupdate) {
      return <span className="label label-info">Nieuwe update</span>;
    }
    if (!app.metadata.online) {
      return <span className="label label-warning">Offline</span>;
    }
  };

  const whatis = (app) => {
    if (app.metadata.entitytype === "EVENEMENT") {
      return <i className="fas fa-calendar-alt"></i>;
    } else {
      return <i className="fas fa-landmark"></i>;
    }
  };


  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Items zoeken  Tags codes</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" type="button" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Items Toevoegen
          </Link>
        </div>
      </div>
      <div className="box-body">
        <ReactTags onChange={onChange} tags={value} suggestions={suggestions} onDelete={handleDelete} onAddition={handleAddition} />
      </div>

      <div className="box-header with-border">
        <h3 className="box-title">Zoekresultaten</h3>
      </div>
      <div className="box-body">
        <div className="box-body table-responsive no-padding">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Titel</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((app) => (
                <tr key={app._id} onClick={() => handleItem(app)}>
                  <td>{whatis(app)}</td>
                  <td>{app.what.title.value}</td>
                  <td>{moment(app.metadata.onlinedate).format("DD-MM-YYYY")}</td>
                  <td>{status(app)}</td>
                  <td>
                    <button className="btn-primary btn btn-box-too btn-sm" onClick={() => handleItem(app)}>
                      Wijzigen
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return { errors: state.auth.error, items: state.items.items, glerror: state.items.error };
};

export default connect(mapStateToProps, { Searchbytag, itemError, SetItemtoEdit })(ItemsZoekTags);
