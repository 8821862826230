import React, { Component } from "react";
import config from "../../../package.json";
class Footer extends Component {
  render() {
    return (
      <footer className="p-2">
        <div className="float-right hidden-xs">
          <strong>
            Copyright © {new Date().getFullYear()} <a target="_new" href="https://wwwappz.nl">WwappZ Studio</a>.
  
          </strong> All rights reserved. <b>Version</b> {config.version}
        </div>
      </footer>
    );
  }
}

export default Footer;
