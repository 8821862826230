import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";

function RichTextMarkdown(props) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [markdownValue, SetmarkdownValue] = useState("");

  useEffect(() => {
    if (props.input.value && markdownValue.length < 3) {
      setValue(props.input.value === "" ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(props.input.value, "html"));
      //SetmarkdownValue(RichTextEditor.createValueFromString(props.input.value, "markdown"));
    }
  }, [props.input.value]);

  const onChange = (newValue) => {
    setValue(newValue);
    SetmarkdownValue(newValue.toString("markdown"));
    if (newValue.toString("markdown").length > 3) {
      props.input.onChange(newValue.toString("html"));
    }
  };
  const { label, error, touched, warning } = props;

  return (
    <div>
      <label>{label}</label>
      <div>
        <RichTextEditor className="wysiwyg-editor" value={value} onChange={onChange} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}

export default RichTextMarkdown;
