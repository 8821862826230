import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import Lottie from "react-lottie";
import BarGraphVoorspel from "./BarGraphVoorspel";
import { connect } from "react-redux";
import animationData from "../../../lib/animations/ai.json";
import { voorspellingen } from "../Reducers/actions";

function Counter({ value }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = 2500; // 2.5 seconds
    const frames = 60; // Aantal frames per seconde
    const increment = value / ((duration / 1000) * frames);
    let start = 0;

    const timer = setInterval(() => {
      start += increment;
      if (start >= value) {
        setCount(value);
        clearInterval(timer);
      } else {
        setCount(Math.floor(start));
      }
    }, 1000 / frames);

    return () => clearInterval(timer);
  }, [value]);

  return <span className="badge badge-success">{count}</span>;
}

function VoorspellenWidget({ sensor_id, voorspellingen, voorspelling, sensor, alert }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (visible) {
      voorspellingen(sensor_id).then((data) => {
        if (data.type === "SENSOR_ERROR") {
          setError(data.payload);
        } else {
          setError(null);
        }
        setLoading(false);
      });
    }
  }, [visible, sensor_id, voorspellingen]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <button type="button" className="btn btn-info btn-sm text-white" onClick={() => setVisible(true)}>
        <img src="/img/ai.png" alt="VeluweDB" style={{ height: "18px", marginRight: "5px" }} />
        AI Voorspelling
      </button>

      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-lg">
        <div className="card">
          <div className="card-header">
            <h4 className="mb-0">Voorspelling komende 24 uur</h4>
          </div>
          <div className="card-body">
            {loading ? (
              <Lottie options={defaultOptions} width={"50%"} />
            ) : error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              voorspelling && (
                <div>
                  <div className="alert alert-success" role="alert">
                    <h3>
                      <i className="fa-solid fa-circle-info"></i> Hoe voorspelt RecraPlan de komende 24 uur?
                    </h3>
                    We voorspellen de data door de patronen van de afgelopen dagen en uren te analyseren. Daarbij combineren we de weersgegevens van de locatie, waardoor we een nauwkeurige voorspelling kunnen maken. Voor deze voorspelling hebben wij <Counter value={voorspelling.sensor_data_count} />{" "}
                    metingen gebruikt en in totaal <Counter value={voorspelling.outdoor_temp_data_count} /> weerstemperaturen gebruikt.
                  </div>
                  <BarGraphVoorspel soort={sensor} sensor={voorspelling} type="uren" />
                </div>
              )
            )}
          </div>
        </div>
      </Rodal>
    </>
  );
}

function mapStateToProps(state) {
  return { voorspelling: state.sensoren.voorspelling };
}

export default connect(mapStateToProps, { voorspellingen })(VoorspellenWidget);
