import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { getfaciliteit, getbeschikbaarheid, savereververing } from "./Reducers/actions";
import { Animated } from "react-animated-css";
import Faciliteit from "./Components/faciliteit";
import InschrijfForm from "./Components/datumform";
import Loading from "../../../Components/loading/load";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";
import moment from "moment";

function FacilteitPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    errors: {},
    loading: false,
    tijden: [],
    datum: "",
    aanmeldingen: 0,
    week: false,
    nodata: false,
    vol: true,
    loaded: false,
  });

  React.useEffect(() => {
    getFaciliteit(id).then((data) => {
      if (data.results) {
        if (!bedrijf._id) {
          getBedrijf(data.results.bedrijf).then((bdata) => {
            setState((prevState) => ({ ...prevState, loaded: true }));
          });
        }
        if (data.results.vol) {
          fixmessage("danger", lang("Helaas is het niet mogelijk om een reservering te plaatsen, omdat je al een reservering(en) hebt"));
          setState((prevState) => ({ ...prevState, vol: false, loaded: true }));
        } else {
          setState((prevState) => ({ ...prevState, vol: true, loaded: true }));
        }
      }
    });
    return () => {
      clearmessage();
    };
  }, []);

  const setdate = (datum) => {
    clearmessage();
    getbeschikbaarheid({ faciliteit: id, datum: moment(datum).toISOString() }).then((data) => {
      if (data.results) {
        if (data.results.tijden.filter((item) => item.tijd !== undefined).length > 0) {
          setState((prevState) => ({
            ...prevState,
            tijden: data.results.tijden,
            nodata: false,
            week: true,
            datum: moment(datum).toISOString(),
          }));
        } else {
          setState((prevState) => ({ ...prevState, tijden: [], nodata: true, week: false, datum: moment(datum).toISOString() }));
        }
      } else {
        setState((prevState) => ({ ...prevState, tijden: [], nodata: true, week: false, datum: moment(datum).toISOString() }));
      }
    });
  };

  const showinfo = (week) => {
    if (state.week && week && week.weekinfo && week.weekinfo.omschrijving)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-info"}>
            <p>{translate(week.weekinfo.omschrijving)}</p>
          </div>
        </Animated>
      );
  };

  const nodatainfo = (info) => {
    if (state.nodata)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-warning"}>
            <p>{info}</p>
          </div>
        </Animated>
      );
  };

  const submit = (vals) => {
    const checktijd = state.tijden.find((a) => a.tijd === vals.tijd);
    if (checktijd.aanmeldingen[0] && parseInt(checktijd.personen, 0) < parseInt(checktijd.aanmeldingen[0].personen, 0) + parseInt(vals.personen, 0)) {
      setState((prevState) => ({ ...prevState, loaded: true }));
      var maxtotaal = parseInt(checktijd.personen, 0) - parseInt(checktijd.aanmeldingen[0].personen, 0);
      message(
        10000,
        "danger",
        lang("Het maximaal aantal personen is bereikt. Je kan nog") + " " + maxtotaal + " " + lang("personen aanmelden")
      );
    } else {
      setState((prevState) => ({ ...prevState, loaded: false }));
      const maxpers = state.tijden.find((el) => el.tijd === vals.tijd);
      var nogover = maxpers.personen - (maxpers.aanmeldingen.personen || 0);
      if (nogover >= vals.personen) {
        vals.datum = state.datum;
        vals.faciliteit = id;
        savereververing(vals).then((data) => {
          if (data.type === "FACILITEIT_ERROR") {
            alert(data.payload);
            setState((prevState) => ({ ...prevState, loaded: true }));
            return false;
          } else {
            message(4000, "success", lang("Je reservering is succesvol verwerkt")).then((data) => {
              navigate("/public/faciliteiten/" + bedrijf._id);
            });
          }
        });
      } else {
        setState((prevState) => ({ ...prevState, loaded: true }));
        message(4000, "danger", lang("Het maximaal aantal personen is bereikt. Je kan nog") + " " + nogover + " " + lang("personen aanmelden"));
      }
    }
  };

  const { faciliteit, beschikbaarheid, bedrijf, getFaciliteit, getBedrijf, getbeschikbaarheid, savereververing } = props;

  return (
    <div className="public-box-body">
      <Loading loading={state.loaded} />
      {state.loaded && (
        <div>
          <Faciliteit faciliteit={faciliteit} />
          <h2 className="headline text-yellow"> {lang("Reserveren")}</h2>
          {showinfo(beschikbaarheid)}
          {state.vol && (
            <div className="inschrijf-content">
              <InschrijfForm tijden={state.tijden} setdate={setdate} onSubmit={submit} />
              {nodatainfo(lang("Het is helaas niet mogelijk een reservering te maken op deze datum (activiteit/faciliteit is volgeboekt)."))}
            </div>
          )}
        </div>
      )}
      <Link
        to={{
          pathname: "/public/faciliteiten/" + faciliteit.bedrijf,
        }}
        className="back-arrow"
      >
        <i className="fa fa-chevron-left"></i> {lang("Terug")}
      </Link>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    faciliteit: state.faciliteiten.item,
    beschikbaarheid: state.faciliteiten.beschikbaarheid,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  getfaciliteit,
  getbeschikbaarheid,
  savereververing,
  fixmessage,
  clearmessage,
})(FacilteitPage);
