import React, { Component } from "react";
import { Route } from "react-router-dom";
import PublicPageActiviteiten from "./Activiteiten/index";
import PublicPageInschrijving from "./Activiteiten/inschrijven"
import {RequireAuthAdmin} from "../../Auth/require_gast"
import GastLogout from "./Gasten/Components/adminregist"
import PublicPageMijnInschrijvingen from "./Activiteiten/mijninschrijvingen"
import FacilietenPage from "./Faciliteiten/index"
import ReserveringPage from "./Faciliteiten/mijnreserveringen"
import FacilteitPage from "./Faciliteiten/faciliteit"
import Login from "./Gasten/index"
import VerifyLogin from "./Gasten/verify"
import Horecas from "./Horeca/index"
import Horeca from "./Horeca/horeca"
import Bedrijven from "./Bedrijven/index"
import Bedrijf from "./Bedrijven/bedrijf"
class Inschrijvingen extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/" element={<Bedrijven/>}/>
        <Route exact path="/:id" element={<Login/>} />
        <Route exact path="/gast/verify/:id" element={<VerifyLogin/>} />
        <Route exact path="/activiteiten/:id" element={<RequireAuthAdmin><PublicPageActiviteiten/></RequireAuthAdmin>} />
        <Route exact path="/inschrijvingen/activiteit/:id" element={<RequireAuthAdmin><PublicPageInschrijving/></RequireAuthAdmin>} /> 
        <Route exact path="/inschrijvingen/:id" element={<RequireAuthAdmin><PublicPageMijnInschrijvingen/></RequireAuthAdmin>} />
        <Route exact path="/faciliteiten/:id" element={<RequireAuthAdmin><FacilietenPage/></RequireAuthAdmin>} />  
        <Route exact path="/faciliteit/:id" element={<RequireAuthAdmin><FacilteitPage/></RequireAuthAdmin>} />
        <Route exact path="/reserveringen/:id" element={<RequireAuthAdmin><ReserveringPage/></RequireAuthAdmin>} />
        <Route exact path="/adminregist/:soort/:id" element={<GastLogout><Login/></GastLogout>} />
        <Route exact path="/horecas/:id" element={<RequireAuthAdmin><Horecas/></RequireAuthAdmin>} />
        <Route exact path="/horeca/:id" element={<RequireAuthAdmin><Horeca/></RequireAuthAdmin>} />
        <Route exact path="/bedrijf/:id" element={<RequireAuthAdmin><Bedrijf/></RequireAuthAdmin>} />
      </React.Fragment>
    );
  }
}

export default Inschrijvingen;
