import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import GastenFormRegist from "./Components/regist";
import { checkgast, Sendmail, gast, checkgastmail } from "../Gasten/Reducers/actions";
import GastenForm from "./Components/insert";
import { lang } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";

const Inschrijven = () => {
  const [state, setState] = useState({
    loading: false,
    sendmail: false,
    email: "",
    from: "",
    registform: false,
    checkform: true,
  });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bedrijf = useSelector((state) => state.pubred.bedrijf);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(getbedrijf(params.id)).then((bdata) => {
      setState((prevState) => ({ ...prevState, loaded: true }));
    });
    const fromState = window.location.state?.from || `/public/activiteiten/${params.id}`;
    setState((prevState) => ({ ...prevState, from: fromState }));

    return () => {
      dispatch(clearmessage());
    };
  }, [dispatch, params.id]);

  const checkgastmail = (vals) => {
    const values = {
      isadmin: token,
      from: state.from,
      taal: localStorage.getItem("taal") || "NL",
      ...vals
    };
    dispatch(checkgastmail(values)).then((data) => {
      // ... handle response
    });
  };

  const checkgast = (vals) => {
    const values = {
      isadmin: token,
      from: state.from,
      taal: localStorage.getItem("taal") || "NL",
      ...vals
    };
    dispatch(checkgast(values)).then((data) => {
      // ... handle response
    });
  };

  const sendvmail = () => {
    dispatch(Sendmail({ email: state.email })).then((whatever) => {
      if (whatever) {
        setState((prevState) => ({ ...prevState, sendmail: false, sendmailluck: true }));
      }
    });
  };

  const renderAlert = () => {
    if (state.sendmail === true) {
      return (
        <div className="alert alert-danger">
          {lang("Je e-mailadres is niet gecontroleerd. Klik")}{" "}
          <strong className="pointer" onClick={sendvmail}>
            {lang("hier")}
          </strong>{" "}
          {lang("om nogmaals je account te activeren")}
          <br />
        </div>
      );
    }
  };

  return (
    <div className="public-box-body">
      {state.loaded && state.from && (
        <div>
          <h2 className="headline text-yellow"> {lang("Inschrijven")}</h2>
          {renderAlert()}
          <div className="gastinfo">
            {lang("Wat leuk dat je je wilt inschrijven voor de recreatieactiviteiten of faciliteiten op ")}
            <b>{bedrijf.naam}</b>
            {lang(". Je maakt heel eenvoudig een account aan door je e-mailadres en familienaam in te vullen. Je ontvangt van ons een bevestigingsmail met een unieke link om je account te activeren. Als je account geactiveerd is kun je je inschrijven!")}
          </div> 
          <div className="inschrijf-content">
            {state.checkform && <GastenForm onSubmit={checkgastmail} />}
            {state.registform && <GastenFormRegist email={state.email} onSubmit={checkgast} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Inschrijven;
