import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Search, SetItemtoEdit, NdtrcFind, NdtrcInsert } from './Reducers/items/actions';
import Loading from '../../Components/loading/loading';
import ErrorMessage from '../../Components/loading/error';

const NDTRCItems = ({ items, glerror }) => {
  const [state, setState] = useState({
    query: '',
    loading: true
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NdtrcFind()).then(() => {
      setState(prevState => ({ ...prevState, loading: false }));
    });
  }, [dispatch]);

  const handleItem = (item) => {
    dispatch(NdtrcInsert(item)).then(() => {
      navigate('/items/edit/' + item.metadata.trcid);
    });
  };

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setState(prevState => ({ ...prevState, query: newQuery }));
    if (newQuery.length > 3) {
      dispatch(Search({ query: newQuery })).then(() => {});
    }
  };

  if (state.loading) {
    return <Loading />;
  } else {
    return (
      <div className="box box-default">
        <ErrorMessage fout={glerror} />
        <div className="box-header with-border">
          <h3 className="box-title">Items van NDTRC</h3>
          <div className="box-tools pull-right">
            <Link to="/items/add" type="button" className="btn-primary btn btn-box-too btn-sm">
              <i className="fa fa-plus"></i>
              &nbsp; Items Toevoegen</Link>
          </div>
        </div>

        <div className="box-header with-border">
          <h5 className="box-title">In deze lijst staan items uit de NDTRC api die niet staan in deze database. Je kan deze importeren in deze database</h5>
        </div>
        <div className="box-body">
          <div className="box-body table-responsive no-padding">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>Titel</th>
                  <th>Datum</th>
                  <th>Beschrijving</th>
                  <th></th>
                </tr>
                {
                  items.map((app) => (<tr key={app._id}>
                    <td>{app.what.title.value}</td>
                    <td>{moment(app.metadata.lastupdated).format("DD-MM-YYYY")}</td>
                    <td>{app.what.shortdescription.value}</td>
                    <td>
                      <button onClick ={() => {handleItem(app)}}>
                        <span className="label label-success">toevoegen</span>
                      </button>
                    </td>
                  </tr>))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>)
    }
  }

function mapStateToProps(state) {
  return {errors: state.auth.error, items: state.items.items, glerror: state.items.error};
}
export default connect(mapStateToProps, {Search, SetItemtoEdit, NdtrcFind, NdtrcInsert})(NDTRCItems);
