import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Search, itemError, SetItemtoEdit, Last } from "../Reducers/items/actions";

const ItemsWidget = ({ items, Search, SetItemtoEdit, Last }) => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  useEffect(() => {
    Last({ skip: 0, limit: 20 }).then(() => {});
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (query.length > 3) {
      Search(query).then(() => {});
    } else {
      alert("Minimaal 3 tekens");
    }
  };

  const handleItem = (item) => {
    SetItemtoEdit(item);
    navigate("/items/edit/" + item.metadata.trcid);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
    if (event.target.value.length > 3) {
      Search({ query: query }).then(() => {});
    }
  };

  const status = (app) => {
    if (app.metadata.online && !app.metadata.newupdate) {
      return <span className="label label-success">Online</span>;
    }
    if (app.metadata.online && app.metadata.newupdate) {
      return <span className="label label-info">Nieuwe update</span>;
    }
    if (!app.metadata.online) {
      return <span className="label label-warning">Offline</span>;
    }
  };

  const whatis = (app) => {
    if (app.metadata.entitytype === "EVENEMENT") {
      return <i className="fas fa-calendar-alt"></i>;
    } else {
      return <i className="fas fa-landmark"></i>;
    }
  };

  return (
    <div>
      <div className="box-body">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="text" name="query" className="form-control" value={query} onChange={handleChange} placeholder="Zoek items... (minimaal 3 tekens)" />
            <span className="input-group-btn">
              <button type="submit" name="search" id="search-btn" className="btn btn-flat">
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
      </div>

      <div className="box-header with-border">
        <h3 className="box-title">Zoekresultaten</h3>
      </div>
      <div className="box-body">
        <div className="box-body table-responsive no-padding">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Titel</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.slice(0, 12).map((app) => (
                <tr
                  key={app._id}
                  onClick={() => {
                    handleItem(app);
                  }}
                >
                  <td>{whatis(app)}</td>
                  <td>{app.what.title.value}</td>
                  <td>{moment(app.metadata.onlinedate).format("DD-MM-YYYY")}</td>
                  <td>{status(app)}</td>
                  <td>
                    <button
                      className="btn-primary btn btn-box-too btn-sm"
                      onClick={() => {
                        handleItem(app);
                      }}
                    >
                      Wijzigen
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { errors: state.auth.error, items: state.items.items, glerror: state.items.error };
}

export default connect(mapStateToProps, { Search, itemError, SetItemtoEdit, Last })(ItemsWidget);
