import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import SelectBox from "../../../../Components/Forms/selectbox";

import PropTypes from 'prop-types';

const EditKampeerplaatsen = ({ handleSubmit, sensoren, categorien }) => {
  const requiredValidator = useMemo(() => required(), []);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              
              <Field
                className="form"
                name="active"
                validate={requiredValidator({ msg: "Type item is verplicht" })}
                label="Standplaats actief"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
           <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam Kampeerplaats" />
              <Field
                name="categorie"
                validate={[required()]}
                type="input"
                options={categorien}
                inputvalue="_id"
                inputnaam="naam"
                component={SelectBox}
                label="Categorie van de kampeerplaats"
              />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving kampeerplaats (optioneel)" />
              <Field
                name="device_id"
                validate={[required()]}
                type="input"
                options={sensoren}
                inputvalue="device_id"
                inputnaam="naam"
                component={SelectBox}
                label="Vul de Sensor ID in"
              />
            </div>
          </div>
          <div className="pull-left submit">
            <input
              type="submit"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Opslaan"
            />
          </div>
        </div>
      </form>
    </div>
  );
};



EditKampeerplaatsen.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(EditKampeerplaatsen);

const mapStateToProps = (state) => ({
  initialValues: state.standplaatsen.kampeerplaats, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);