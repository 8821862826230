import React from "react";
import { Routes, Route } from "react-router-dom";
import ErvaringenPage from "./index";
import ErvaringenInsertPage from "./insert";
import ErvaringenEditPage from "./edit";
import ErvaringenEditWaarPage from "./edit/waar";
import ErvaringenEditMediaPage from "./edit/media";
import ErvaringenEditWaneerPage from "./edit/wanneer";
import ErvaringenEditBSBHPage from "./edit/beschikbaarheid";
import ErvaringenEditESPage from "./edit/eigenschappen";
import Beschikbaarheid from "./Beschikbaarheid";
import CommunicatiePage from "./edit/communicatie";

const ErvaringenRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ErvaringenPage />} />
      <Route path="/beschikbaarheid/:id" element={<Beschikbaarheid />} />
      <Route path="/toevoegen" element={<ErvaringenInsertPage />} />
      <Route path="/edit/waar/:id" element={<ErvaringenEditWaarPage />} />
      <Route path="/edit/media/:id" element={<ErvaringenEditMediaPage />} />
      <Route path="/edit/wanneer/:id" element={<ErvaringenEditWaneerPage />} />
      <Route path="/edit/beschikbaarheid/:id" element={<ErvaringenEditBSBHPage />} />
      <Route path="/edit/eigenschappen/:id" element={<ErvaringenEditESPage />} />
      <Route path="/edit/communicatie/:id" element={<CommunicatiePage />} />
      <Route path="/edit/:id" element={<ErvaringenEditPage />} />
    </Routes>
  );
};

export default ErvaringenRouter;
