import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import moment from "moment";

class SensorWidget extends React.Component {
  state = {
    errors: {},
    load: false,
  };

  handleItem(item) {
    this.props.Verwerken({ id: item._id }).then((datav) => {
      this.getvals({});
    });
  }

  render() {
    function seturl(sen, id) {
      if (sen === "temperatuur") {
        return "/sensoren/sensor/show/" + id;
      } else if (sen === "gas") {
        return "/sensoren/sensor/gas/show/" + id;
      } else if (sen === "electra") {
        return "/sensoren/sensor/electra/show/" + id;
      } else if (sen === "water") {
        return "/sensoren/sensor/water/show/" + id;
      } else if (sen === "ampere") {
        return "/sensoren/sensor/amp/show/" + id;
      }
    }
    const columns = [
      {
        dataField: "naam",
        text: "Naam Sensor",
        formatter: (cellContent, row) => {
          if (cellContent.NL) {
            return cellContent.NL;
          } else {
            return cellContent;
          }
        },
      },
      {
        dataField: "lastcheck",
        text: "Laatste data",
        formatter: (cellContent, row) => {
          if (row.electradatas && row.electradatas[0]) return moment(row.electradatas[0].datum).fromNow();
          if (row.sensordatas && row.sensordatas[0]) return moment(row.sensordatas[0].datum).fromNow();
        },
      },
      {
        text: "Status",
        dataField: "status",
        formatter: (cellContent, row) => {
          if (row.active ) {
          if (cellContent === "false") {
            return <span className="badge badge-danger">Er is een storing</span>;
          } else {
            return <span className="badge badge-success">Sensor werkt goed</span>;
          }
        } else {
          return <span className="badge badge-warning">Sensor is uitgeschakeld</span>;
        }
        },
      },
      {
        dataField: "data",
        text: "Waardes",
        formatter: (cellContent, row) => {
          const data = [];
          if (row.sensordatas.length > 0) {
            row.sensordatas.map(function (a, key) {
              data.push(a.value);
              return a;
            });
          }
          if (row.electradatas.length > 0) {
            row.electradatas.map(function (a, key) {
              if (key > 0) {
                data.push(Number(row.electradatas[key - 1].value - Number(a.value) ));
              }
              return a;
            });
          }
          return (
            <Sparklines data={data.reverse()} margin={5} height={30}>
              <SparklinesLine style={{ strokeWidth: 1.5, stroke: "#d96a29", fill: "none" }} />
              <SparklinesSpots size={3} style={{ stroke: "#d96a29", strokeWidth: 1.5, fill: "white" }} />
            </Sparklines>
          );
        },
      },
      {
        text: "Bekijken",
        dataField: "soort",
        headerAlign: "center",
        align: "center",
        formatter: (cellContent, row) => {
          return (
            <Link className="btn btn-success" to={{ pathname: seturl(cellContent, row._id) }}>
              Bekijken
            </Link>
          );
        },
      },
    ];
    const { sensoren } = this.props;
    const { SearchBar } = Search;

    return (
      <ToolkitProvider
        keyField="_id"
        data={sensoren}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <SearchBar placeholder="Zoeken" {...props.searchProps} />
            <hr />
            <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default SensorWidget;
