import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensor_dag, getsensor_maand, getsensor_uur } from "../Standplaatsen/Reducers/actions";
import { fetchgastenverbruikone } from "./Reducers/actions";
import { fixmessage } from "../../Reducers/loading/actions";
import { useParams } from "react-router-dom";
import moment from "moment";
import BarGraph from "./Components/graphelectra";
import { Link } from "react-router-dom";
import Tabel from "./Components/tabelelectra";
import FilterSensor from "./Components/filterelect";
import Loading from "../../Components/loading/loading";
import Uitchecken from "./Components/uitchecken";
const GastenVerbruikKampeer = (props) => {
  const [load, Setload] = useState(false);
  const [openmodal, Setopenmodal] = useState(false);
  const [kampeerplaatsid, Setkampeerplaatsid] = useState(null);
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
  });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [stand, setStand] = useState(0);
  const [standdag, setStandDag] = useState(0);

  const [max, Setmax] = useState(0);
  const params = useParams();
  useEffect(() => {
    getvals()
      .then((data) => getvals_dag(data))
      .then((data) => getvals_uur(data))
      .then(() => Setload(true));
  }, []);

  function getvals() {
    return new Promise((resolve, reject) => {
      props.fetchgastenverbruikone(params.id).then((data) => {
        if (data.results) {
          resolve(data.results);
        } else {
          props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        }
      });
    });
  }

  function getvals_dag(data) {
    return new Promise((resolve, reject) => {
      props.getsensor_dag(data.standplaats._id, { startdate: moment(data.aankomst).toDate(), enddate: moment(data.vertrek).toDate() }).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          Setgemiddelde(data.results.data.map((element) => element.value).reduce((a, b) => a + b, 0) / data.results.data.length);
          Setmax(Math.max(...data.results.data.map((o) => o.value)));
          setStandDag(data.results.data.reduce((acc, curr) => acc + curr.value, 0).toFixed(2));
          resolve(data.results);
        }
      });
    });
  }

  function getvals_uur(data) {
    return new Promise((resolve, reject) => {
      props.getsensor_uur(data._id, datum_hour).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          if (data.results.data && data.results.data[0]) setStand(data.results.data[0].value.toFixed(2));
          resolve();
        }
      });
    });
  }

  const { kampeerplaats, gastverbruik } = props;
  if (load) {
    return (
      <div className="box box-default show-elecra">
        <div className="box-header with-border">
          <h3 className="box-title">
            Aankomst <strong> {moment(gastverbruik.aankomst).format("D MMMM: HH:00")}</strong> voor <strong>{gastverbruik.standplaats.naam} </strong>
            Vertrek: <strong> {moment(gastverbruik.vertrek).format("D MMMM HH:mm")}</strong>
            {gastverbruik.status === "checkout" ? <span className="badge badge-success ">Uigechecked</span> : " "}
          </h3>
          <div className="box-tools pull-right">
            <Link className="btn btn-warning btn-sm float-right" role="group" to={"/gastenverbruik/edit/" + gastverbruik._id}>
              Wijzigen
            </Link>{" "}
            <button
              className="btn btn-success btn-md float-right"
              onClick={() => {
                Setopenmodal(true);
              }}
            >
              Nu uitchecken
            </button>
          </div>
        </div>
        <div className="box box-default">
          <div className="box-header with-border">
            <FilterSensor gastverbruik={gastverbruik} verbruik={standdag} gemiddelde={gemiddelde} max={max} stand={stand} />
          </div>
        </div>

        <div className=" box-primary">
          <div className="box-body" />
          <div className="row p-2">
            <div className="col-12 col-md-10 col-lg-12">
              <Tabel sensor={kampeerplaats.dag} />
            </div>
            <div className="col-12">
              <BarGraph sensor={kampeerplaats.dag} kleur="#B1BF41" type="dag" />
            </div>

            <div className="col-12 col-md-12 col-lg-12">
              <div className="box-titel">
                <h3 className="p-4">Metingen 24 uur</h3>
              </div>

              <BarGraph sensor={kampeerplaats.uur} kleur="#D96A29" type="uur" />
            </div>
          </div>
        </div>
        <Uitchecken gast={gastverbruik} kampeerplaats={kampeerplaats} openmodal={openmodal} closemodal={() => Setopenmodal(false)} stand={standdag} />
      </div>
    );
  } else {
    return <Loading loadings={load} />;
  }
};

function mapStateToProps(state) {
  return { sensor: state.sensoren, kampeerplaats: state.standplaatsen, gastverbruik: state.gastenverbruik.gastverbruik };
}

export default connect(mapStateToProps, { fetchgastenverbruikone, fixmessage, getsensor_dag, getsensor_maand, getsensor_uur })(GastenVerbruikKampeer);
