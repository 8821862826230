import React from "react";
import SubMenu from "./Components/submenu";

const App = () => {
  return (
    <div className="content-page types">
      <div className="box-header with-border">
        <h3 className="box-title">Types</h3>
      </div>
        <div className="container-fluid">
          <div className="row flex-container">
            <div className="col-lg-9">
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Settings</h3>
                </div>
                <div className="box-body">
                  {/* Settings content hier */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <SubMenu />
            </div>
          </div>
        </div>
      </div>

  );
};

export default App;