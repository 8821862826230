import React, { useEffect, useState } from "react";
import Rodal from "rodal";
import { connect } from "react-redux";
import { Field, reduxForm, initialize, FieldArray } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../../Components/Forms/renders";
import switcbutton from "../../../../Components/Forms/switch";
import Tags from "../../../../Components/Forms/tags";
import Groepsitems from "./groepsitems";

function ModalTypes({ item, visible, hide, handleSubmit, sourceItems }) {
  const dispatch = useDispatch();
  useEffect(() => {
    // Initialiseren van initialValues met gegevens uit je Redux store
    dispatch(initialize("component_form_edit", item));
  }, [dispatch, item]);

  return (
    <Rodal visible={visible} onClose={hide} className="rodal-md">
      <form onSubmit={handleSubmit}>
        {visible && (
          <div className="box box-default">
            <div className="box-header with-border">
              <h3 className="box-title">{item.desc}</h3>
            </div>
            <div className="box-body">
              <div className="form-group">
                <Field name="naam" type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Naam van het item " />
              </div>
              {item.soort != "groep" && <Field name="groepsarray" type="input" component={switcbutton} label="Is dit een array van items" />}

              {item.soort === "enumeration" && <Field name="options" component={Tags} options={[]} label="Vul de opties in" />}

              {item.soort === "groep" && <FieldArray name={`subcontent`} component={Groepsitems} sourceItems={sourceItems} />}
            </div>
            <div className="box-footer">
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </Rodal>
  );
}

const ReduxFormSensorEdit = reduxForm({
  form: "component_form_edit", // a unique identifier for this form
})(ModalTypes);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
