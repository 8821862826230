import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { message } from "../../../Reducers/loading/actions";
import { getbedrijf, fetchactiviteiten } from "./Reducers/actions";
import Activiteiten from "./Components/activiteiten";
import { Link, useNavigate, useParams } from "react-router-dom";
import { lang, datumlang } from "./../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";

function Inschrijven(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    props.getbedrijf(id).then((data) => {
      if (!data.results) {
        navigate("/404");
      } else {
        props.fetchactiviteiten(data.results._id).then((act) => {
          setLoading(true);
        });
      }
    });
  }, [id]);

  var d = "";
  function datum(cell) {
    if (moment(cell).format("DDMMYYYY") !== d) {
      d = moment(cell).format("DDMMYYYY");
      return <h4 className="activiteit-datum">{datumlang(cell, "dddd DD MMMM YYYY")}</h4>;
    }
  }

  const { activiteiten } = props;

  return (
    <div className="public-box-body">
      <Link
        className="btn btn-success pull-right bt-inschrijvingen"
        to={"/public/inschrijvingen/" + props.bedrijf._id}
      >
        {lang("Mijn Inschrijvingen")}
      </Link>
      <h2 className="headline text-yellow mobileheader"> {lang("Activiteiten")}</h2>
      <Loading loading={loading} />
      {loading && (
        <div className="activiteit-content">
          <div className="gastinfo">
            {lang(
              "Hieronder staan de activiteiten waarvoor je je van te voren moet inschrijven. Via de knop 'mijn inschrijvingen' zie je terug voor welke activiteiten je een reservering hebt gemaakt. Hier kun je je eventueel ook weer uitschrijven voor een activiteit."
            )}
          </div>
          {activiteiten.length === 0 && (
            <h3>{lang("Er zijn op dit moment geen activiteiten waar je je voor in moet schrijven")}</h3>
          )}
          {activiteiten.map((activiteit) => (
            <div key={activiteit._id} className="activiteit-content">
              {datum(activiteit.datum)}
              <Activiteiten activiteit={activiteit} link={true} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    activiteiten: state.pubred.activiteiten,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchactiviteiten,
})(Inschrijven);
