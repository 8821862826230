import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

export default function ComponentElements({ sourceItems }) {

  return (
    <div className="box htmlelements">
      <div className="box-header with-border">
        <h3 className="box-title">Bronlijst</h3>
      </div>
      <div className="box-body">
        <Droppable droppableId="source" type="ITEM" isDropDisabled={true}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sourceItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 card p-2">
                      <div className="row">
                        <div className="col-2">
                          <div className="icon-container"></div>
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-12">
                              <strong>{item.naam}</strong>
                            </div>
                            <div className="col-12">Container met {item.content.length} items</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              <Draggable draggableId={"100"} index={100}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 card p-2">
                    <div className="row">
                      <div className="col-2">
                        <div className="icon-container"></div>
                      </div>
                      <div className="col-10">
                        <div className="row">
                          <div className="col-12">
                            <strong>Groep</strong>
                          </div>
                          <div className="col-12">Groep met verschillende items</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}
