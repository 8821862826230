import React from "react";
import { connect } from "react-redux";
import { savehoreca } from "./Reducers/actions";
import LogboekForm from "./Components/insert";
import {message} from "../../Reducers/loading/actions"
class HorecaInsertPage extends React.Component {
  state = {
    errors: {},
  };


  submit = (vals) => {
    this.props.savehoreca(vals).then((data) => {

      
      if (data.type === "FACILITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De horeca gelegenheid is succesvol verwerkt").then(data => {
          this.props.navigate("/horeca/");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Faciliteit toevoegen </h3>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
               <LogboekForm onSubmit={this.submit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
  };
}

export default connect(mapStateToProps, { savehoreca, message })(
  HorecaInsertPage
);
