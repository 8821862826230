import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getconnections, editconnections } from './Reducers/actions';
import SensorEditForm from './Components/edit';
import { useNavigate, useParams } from 'react-router-dom';

const ConnectionsEditPage = ({ editconnections, getconnections, connections  }) => {
  const [loaded, setLoaded] = useState(false);
let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoaded(false);
    getconnections(id).then(() => {
      setLoaded(true);
    });
  }, [editconnections, id]);

  const submit = (vals) => {
    editconnections(id, vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/admin/connections');
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Connection wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded && <SensorEditForm onSubmit={submit} initialData={connections} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { connections: state.connections.item };
};

export default connect(mapStateToProps, { editconnections, getconnections })(ConnectionsEditPage);
