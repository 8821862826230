import React from "react";
import { Routes, Route } from "react-router-dom";
import HorecaIndex from "./index";
import HorecaInsertPage from "./insert";
import HorecaEditPage from "./edit";
import InschrijfPageHoreca from "./inschrijvingen";

const HorecaRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HorecaIndex />} />
      <Route path="/insert" element={<HorecaInsertPage />} />
      <Route path="/edit/:id" element={<HorecaEditPage />} />
      <Route path="/reserveringen/:id" element={<InschrijfPageHoreca />} />
    </Routes>
  );
};

export default HorecaRouter;
