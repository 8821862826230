import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class BarGraph extends Component {
  render() {
    const { sensor, kleur, type } = this.props;
    var dateformate = "dd-MM";
    if (type === "maand") {
      dateformate = "MMM-YY";
    }
    if (type === "uur") {
      dateformate = "HH:mm";
    }
    function grdata(sensordata) {
      if (sensordata.data) {
        const columns = [
          { type: "datetime", label: "datum" },
          { type: "number", label: "Kub" },
        ];
        const rows = [];
        sensordata.data.map(function (a, key) {
          if (a.datum && a.value) {
            if (type === "dag") {
              rows.push([new Date(a.datum), a.value.toFixed(2) / 1000]);
            }
            if (type === "maand") {
              rows.push([new Date(a.datum), a.value.toFixed(2) / 1000]);
            }
            if (type === "uur") {
              if (sensordata.data[key] && key > 0) {
                rows.push([moment(a.datum).add(3,"hour").toDate(), (sensordata.data[key - 1].value - a.value).toFixed(2) / 1000]);
              }
            }
          } else {
            return;
          }
        });
        return [columns, ...rows];
      }
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: dateformate,
          },
          colors: [kleur],
          vAxis: {
            title: "Kub",
          },

          seriesType: "bars",
          series: { 1: { type: "line" } },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
