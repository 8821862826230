import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Savebedrijf } from './Reducers/actions';
import BedrijvenForm from './Components/form';

const BedrijfInsertPage = () => {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
let navigate = useNavigate();

  const submit = vals => {
    dispatch(Savebedrijf(vals)).then(data => {
      if (data.type === "BEDRIJD_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/admin/bedrijven/');
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Bedrijven toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <BedrijvenForm onSubmit={submit}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BedrijfInsertPage;
