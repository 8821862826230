import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderselect, renderselectsimple } from "../../../Components/Forms/renders";
const FilterSensor = ({ changedata }) => {
  const periode = (val) => {
    const value = parseInt(val.target.value);
    const configs = [
        { months: 0, startOffset: 1, endOffset: 0 },
      { months: 1, startOffset: 1, endOffset: 1 },
      { months: 3, startOffset: 3, endOffset: 1 },
      { months: 5, startOffset: 3, endOffset: 0 },
      { period: "year", startOffset: 0, endOffset: 0 },
      { period: "year", startOffset: 1, endOffset: 1 },
    ];

    const config = configs[value];
    let startdate, enddate;

    if (config) {
      if (config.period) {
        startdate = moment().subtract(config.startOffset, config.period).startOf(config.period).toDate();
        enddate = moment().subtract(config.endOffset, config.period).endOf(config.period).toDate();
      } else {
        startdate = moment().subtract(config.months, "months").startOf("month").toDate();
        enddate = moment().subtract(config.endOffset, "months").endOf("month").toDate();
      }
    } else {
      startdate = moment().subtract(7, "days").toDate();
      enddate = new Date();
    }

    changedata({ startdate, enddate });
  };

  return <Field name="periode" type="input" options={["Deze maand", "Vorige maand", "Afgelopen 3 maanden", "Afgelopen half jaar ", "Dit jaar", "Vorige jaar"]} component={renderselectsimple} label="Kies een periode" onChange={periode} />;
};

const reduxConnectedForm = reduxForm({ form: "filtersensor" })(FilterSensor);

const mapStateToProps = (state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(7, "days"), enddate: new Date() },
  },
});

export default connect(mapStateToProps)(reduxConnectedForm);
