import React from "react";
import { Routes, Route } from "react-router-dom";
import InschrijOverzichtPage from "./website";
import Activitieten from "./index";
import InsertWebsite from "./insert";
import ManualInsert from './insertmanual';
import Activteitedit from "./edit";
import Activitiet from "./activiteit";
import ActiviteitenMetinschrijving from "./alleactiviteiten";

const Inschrijvingen = () => {
  return (
    <Routes>
      <Route path="/" element={<ActiviteitenMetinschrijving />} />
      <Route path="/show/all" element={<Activitieten />} />
      <Route path="/:id" element={<Activitiet />} />
      <Route path="/insert/website" element={<InschrijOverzichtPage />} />
      <Route path="/insert/manual" element={<ManualInsert />} />
      <Route path="/edit/:id" element={<Activteitedit />} />
      <Route path="/website/:id" element={<InsertWebsite />} />
    </Routes>
  );
};

export default Inschrijvingen;
