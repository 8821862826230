import React from "react";
import { connect } from "react-redux";
import { savebiepactiviteit } from "./Reducers/actions";
import LogboekForm from "./Components/insertform";
import { message } from "../../Reducers/loading/actions";
import { getonewebsite } from "../Website/Reducers/actions";
class InsertBiepp extends React.Component {
  state = {
    errors: {},
    load: true,
  };

  componentWillMount() {}

  submit = (vals) => {
    this.props.savebiepactiviteit(vals).then((data) => {
      if (data.type === "BIEPP_ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is succesvol verwerkt").then((datas) => {
          this.props.navigate("/biepp/edit/"+data.results._id+"/1");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default maincontent">
        <div className="box-header with-border">
          <h3 className="box-title"> Activiteit toevoegen </h3>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">{this.state.load && <LogboekForm onSubmit={this.submit} />}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    logboeken: state.logboeken,
    website: state.website.item,
  };
}

export default connect(mapStateToProps, { savebiepactiviteit, getonewebsite, message })(InsertBiepp);
