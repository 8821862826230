import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SubMenu from "../Components/submenu";
import HTMLTypes from "../Components/htmlelements";
import ModalTypes from "../Components/modaltypes";
import { saveComponent, fetchOnecomponents } from "../../Reducers/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message, fixmessage } from "../../../../Reducers/loading/actions";

const ComponentEdit = ({ saveComponent, message, fetchOnecomponents, match }) => {
  const navigate = useNavigate();
  const [sourceItems, setSourceItems] = useState([
    { id: "1", content: "Text", soort: "text", desc: "Een tekstveld", icon: "fa fa-font", naam: "", groepsarray: false },
    { id: "2", content: "Email", soort: "email", desc: "Een emailveld", icon: "fa fa-envelope", naam: "" },
    { id: "3", content: "Richt Text", soort: "richttext", desc: "Een richt tekstveld", icon: "fa fa-align-left", naam: "", groepsarray: false },
    { id: "4", content: "Nummer", soort: "nummer", desc: "Een nummerveld", icon: "fa fa-hashtag", naam: "", groepsarray: false },
    { id: "5", content: "Enumeration", soort: "enumeration", desc: "Een keuzelijst", icon: "fa fa-list", naam: "", groepsarray: false },
    { id: "6", content: "Datum", soort: "datum", desc: "Een datumveld", icon: "fa fa-calendar", naam: "", groepsarray: false },
    { id: "7", content: "Button", soort: "button", desc: "Een button veld", icon: "fa-solid fa-stop", naam: "", groepsarray: false },
    { id: "8", content: "Media", soort: "media", desc: "Een mediaveld", icon: "fa-solid fa-image", naam: "", groepsarray: false },
    { id: "9", content: "Link", soort: "link", desc: "Een link veld", icon: "fa-solid fa-link", naam: "", groepsarray: false },
    { id: "10", content: "Groep", soort: "groep", desc: "Een groep veld", icon: "fa-solid fa-folder", naam: "", groepsarray: false },
    { id: "11", content: "Tag", soort: "groep", desc: "Een tag veld", icon: "fa-solid fa-folder", naam: "", groepsarray: false },
    { id: "12", content: "Video", soort: "video", desc: "Een video veld", icon: "fa-solid fa-camera", naam: "", groepsarray: false },
  ]);
  const [targetItems, setTargetItems] = useState([]);
  const [editType, setEditType] = useState({}); // Type dat bewerkt wordt in de modal
  const [openeditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({ naam: "", groeparray: false });
  const [validationError, setValidationError] = useState(false);
  const [contentIndex, setContentIndex] = useState(0); // Index van het item dat bewerkt wordt in de modal
  const [loaded, setLoaded] = useState(false);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    // Als er geen bestemming is of als iemand probeert een item terug te zetten in de bronlijst, doe dan niets.
    if (!destination || (source.droppableId === "target" && destination.droppableId === "source")) return;

    if (source.droppableId === "source" && destination.droppableId === "target") {
      const draggedItem = sourceItems[source.index];

      setOpenEditModal(true);
      var divid = Math.floor(Math.random() * 100000) + 1;
      setContentIndex(`target-${divid}`)
      // Nieuw item toevoegen aan doellijst
      setTargetItems((prevItems) => [...prevItems, { ...draggedItem, id: `target-${divid}` }]);
      setEditType({ ...draggedItem, id: `target-${divid}` });
    } else if (source.droppableId === "target" && destination.droppableId === "target") {
      // Herschikken binnen de doellijst
      const updatedTargetItems = Array.from(targetItems);
      const [removed] = updatedTargetItems.splice(source.index, 1);
      updatedTargetItems.splice(destination.index, 0, removed);

      setTargetItems(updatedTargetItems);
    }
  };

  const handleDelete = (id) => {
    const updatedTargetItems = Array.from(targetItems);
    const index = updatedTargetItems.findIndex((item) => item.id === id);
    updatedTargetItems.splice(index, 1);
    setTargetItems(updatedTargetItems);
  };

  const handleEdit = (value) => {
    const updatedTargetItems = Array.from(targetItems);
    const index = updatedTargetItems.findIndex((item) => item.id === contentIndex);
    updatedTargetItems[index] = { ...updatedTargetItems[index], ...value };
    setTargetItems(updatedTargetItems);
    setOpenEditModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
      saveComponent({ ...formData, content: targetItems }).then((data) => {
        if (data.type === "GET_CMS_COMPONENT") {
          message(2000, "success", "De component is succesvol opgelsagen").then(() => {
            navigate("/cms/types/");
          });
          setValidationError(false);
        } else {
          message(4000, "danger", "Er is wat fout gegaan");
        }
      });

  };



  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Types Component</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
     
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="box box-info">
                        <div className="box-header with-border">
                          <h3 className="box-title">Bewerk Component</h3>
                        </div>
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label htmlFor="naam">Vul de naam in (alleen kleine letters zonder spaties)</label>
                                <input type="text" className={`form-control ${validationError ? "is-invalid" : ""}`} id="naam" name="naam" value={formData.naam} onChange={handleInputChange} placeholder="Naam" />
                                {validationError && <div className="invalid-feedback">Alleen kleine letters zonder spaties zijn toegestaan.</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="box-body">
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <div className="row">
                              <div className="col-md-8 col-12">
                                <div className="box">
                                  <div className="box-header with-border">
                                    <h3 className="box-title">Doellijst</h3>
                                  </div>
                                  <div className="box-body">
                                    <Droppable droppableId="target" type="ITEM">
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                          {targetItems.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                              {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 card p-2">
                                                  <div className="row">
                                                    <div className="col-2">
                                                      <div className="icon-container">
                                                        <i className={item.icon}></i>
                                                      </div>
                                                    </div>
                                                    <div className="col-8">
                                                      <div className="row">
                                                        <div className="col-12">
                                                          <strong>{item.content} </strong>
                                                          {item.naam && item.naam}
                                                        </div>
                                                        <div className="col-12">{item.desc}</div>
                                                      </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-end align-items-center">
                                                      <div className="icon-container">
                                                        <button
                                                          type="button"
                                                          className="btn btn-sm btn-primary m-2"
                                                          onClick={() => {
                                                            setEditType(item);
                                                            setOpenEditModal(true);
                                                            setContentIndex(item.id);
                                                          }}
                                                        >
                                                          <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button className="btn btn-sm btn-danger m-2" onClick={() => handleDelete(item.id)} type="button">
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-12 ">
                                <HTMLTypes sourceItems={sourceItems} />
                              </div>
                            </div>
                          </DragDropContext>
                        </div>
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
        
            </div>
          </div>
        </div>
     <ModalTypes sourceItems={sourceItems} contentIndex={contentIndex} onSubmit={handleEdit} item={editType} visible={openeditModal} hide={() => setOpenEditModal(false)} />
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return { component: state.cms.components.item };
}

export default connect(mapStateToProps, {
  saveComponent,
  fetchOnecomponents,
  message,
})(ComponentEdit);
