import React from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";

const Loading = ({ loading }) => {
  const { actief, soort, message } = loading;

  if (!actief) {
    return null;
  }

  return (
    <Animated
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      className="loadingmessages"
      isVisible={actief}
    >
      <div className={`callout callout-${soort}`}>
        <p>{message}</p>
      </div>
    </Animated>
  );
}

const mapStateToProps = (state) => ({
  loading: state.loading.message
});

export default connect(mapStateToProps)(Loading);
