export const SET_PUSH = 'SET_PUSH';
export const ADD_PUSH = 'ADD_PUSH';
export const PUSH_ERROR = 'PUSH_ERROR'
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(items) {
  return {type: SET_PUSH, items}
}

export function add(results) {
  return {type: ADD_PUSH, results}
}

export function pushError(error) {
  return {type: PUSH_ERROR, payload: error};
}


export function SavePush(data) {
  return dispatch => {
    return fetch('/api/admin/push', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results.results));
    }).catch(() => {
      dispatch(pushError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getPush() {
  return dispatch => {
    return fetch('/api/admin/push', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(set(results.results));
    }).catch(() => {
      dispatch(pushError('Er is een fout opgetreden bij het ophlaen van de berichten'));
    });
  };
}




export function CountDevices() {
  return dispatch => {
    return fetch('/api/admin/push/devices', {
      method: 'get',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(data => {
      return data;
    }).catch(err => dispatch(pushError('Er is een fout opgetreden bij het uploaden van de bestanden')));
  };
}
