import React, { useCallback, useEffect, useState } from "react";
import { ReactTags } from "react-tag-autocomplete";
import ndtrccode from "../../lib/ndtrccodes.json";

const NDTRCTags = ({ label, input, meta: { touched, error, warning } }) => {
  const [selected, setSelected] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(
      ndtrccode.map((country) => {
        return {
          value: country.catid,
          label: country.name,
        };
      })
    );
  }, []);

  useEffect(() => {
    const value = input.value;
    if (value) {
      setSelected(
        value.map((country) => {
          return {
            value: country.catid,
            label: country.name,
          };
        })
      );
    }
  }, [input.value]);

  const onAdd = useCallback(
    (newTag) => {
      const updatedSelected = [...selected, newTag];
      setSelected(updatedSelected);
      updateinput(updatedSelected)
    },
    [selected, input] // Voeg input toe aan de dependency array
  );

  const updateinput = (values) => {
   const data =  values.map((country) => {
      return {
        catid: country.value,
        name: country.label,
      };
    })
    input.onChange(data); // Activeer onChange met de bijgewerkte waarde
  }

  const onDelete = useCallback(
    (tagIndex) => {
      const updatedSelected = selected.filter((_, i) => i !== tagIndex);
      setSelected(updatedSelected);
      updateinput(updatedSelected)
    },
    [selected, input] // Voeg input toe aan de dependency array
  );
  return (
    <div>
      <label>{label}</label>
      <div>
        <ReactTags labelText="Select countries" selected={selected} suggestions={suggestions} onAdd={onAdd} onDelete={onDelete} noOptionsText="No matching countries" />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
export default NDTRCTags;
