import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchinstellingen, editinstellinngen } from "./Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import BedrijvenForm from "./Components/form";
import { useNavigate } from "react-router-dom";

const SensorInsert = ({ fetchinstellingen, editinstellinngen, message }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    fetchinstellingen().then(() => {
      setLoading(true);
    });
  }, [fetchinstellingen, loading]);

  const submit = async (vals) => {
    try {
      const data = await editinstellinngen(vals);
      if (data.type === "ALERTPROFILE_ERROR") {
        alert(data.payload);
      } else {
        message(2000, "success", "De sensor instellingen zijn met succes aangepast");
        navigate("/sensoren/instellingen");
      }
    } catch (error) {
      // Foutafhandeling, indien nodig
    }
  };
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Sensor Instellingen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loading ? (
                <BedrijvenForm onSubmit={submit} />
              ) : (
                <div className="overlay">
                  <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  sensorinstellingen: state.sensorinstellingen.item,
});

export default connect(mapStateToProps, { fetchinstellingen, editinstellinngen, message })(SensorInsert);
