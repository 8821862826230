var FileDownload = require('js-file-download');
export const SET_EVENTS = 'SET_EVENTS';
export const EVENT_ERROR = 'EVENT_ERROR';
export const EVENTS_LOAD ='EVENTS_LOAD';
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(items) {
  return {type: SET_EVENTS, items}
}
export function itemError(error) {
  return {type: EVENT_ERROR, payload: error};
}
function handleDownload(response) {
  if (response.ok) {
    return response.blob();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function Periode(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/events/periode', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    } catch {
      dispatch(itemError('Er is een fout opgetreden bij het zoeken naar events'));
    }
  };
}

export function PeriodeJaarlijks(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/events/periode/jaarlijks', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    } catch {
      dispatch(itemError('Er is een fout opgetreden bij het zoeken naar events'));
    }
  };
}

export function ExportExcel(data) {
    return async dispatch => {
      try {
        const response = await fetch('/api/events/periode/export/excel', {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem('token')
          }
        });
        const blob = await handleDownload(response);
        FileDownload(blob, "export.xlsx");
      } catch {
        dispatch(itemError('Bad Login Info'));
      }
  }
}
export function ExportWord(data) {
    return async dispatch => {
      try {
        const response = await fetch('/api/events/periode/export/word', {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem('token')
          }
        });
        const blob = await handleDownload(response);
        FileDownload(blob, "evenementen.docx");
      } catch {
        dispatch(itemError('Bad Login Info'));
      }
  }
}
