import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FooterForm from "../../../Components/Lib/FooterForm";
import PeriodesForm from "./periodes";

class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      opencheck: null,
      alwaysopen: false,
      excludeholidays: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <h4 className="info-text">Wat zijn de data van het ervaring</h4>
        </div>

        <div className="box-body">
          <div className="row">
            <div className="col-lg-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Van</th>
                    <th>Tot</th>
                    <th></th>
                  </tr>
                </thead>
                <FieldArray name={`when.singledates`} component={PeriodesForm} />
              </table>
            </div>
          </div>
        </div>
        <FooterForm />
      </form>
    );
  }
}

SyncValidationForm = reduxForm({
  form: "formwanneer", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => {
  // You have to connect() to any Reducers that you wish to connect to yourself
  return { initialValues: state.ervaringen.item };
};

export default connect(mapStateToProps)(SyncValidationForm);
