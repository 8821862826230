import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams, useNavigate } from "react-router-dom";
import SubMenu from "../Components/submenu";
import ComponentElements from "../Components/componentElements";
import { updatePageTemplate, fetchcomponents, fetchOnePageTemplate } from "../../Reducers/actions";
import { connect } from "react-redux";
import { message, fixmessage } from "../../../../Reducers/loading/actions";

const TemplateNew = ({ updatePageTemplate, message, fetchcomponents, fetchOnePageTemplate, match }) => {
  const [sourceItems, setSourceItems] = useState([]);
  const [targetItems, setTargetItems] = useState([]);
  const [editType, setEditType] = useState({}); // Type dat bewerkt wordt in de modal
  const [lastId, setLastId] = useState(sourceItems.length + 1); // Start teller na laatste ID van bronItems
  const [formData, setFormData] = useState({ naam: "" });
  const [validationError, setValidationError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // Als er geen bestemming is of als iemand probeert een item terug te zetten in de bronlijst, doe dan niets.
    if (!destination || (source.droppableId === "target" && destination.droppableId === "source")) return;

    if (source.droppableId === "source" && destination.droppableId === "target") {
      const draggedItem = sourceItems[source.index];

      setLastId(lastId + 1);

      // Nieuw item toevoegen aan doellijst
      setTargetItems((prevItems) => [...prevItems, { ...draggedItem, id: `target-${lastId}` }]);
      setEditType({ ...draggedItem, id: `target-${lastId}` });
    } else if (source.droppableId === "target" && destination.droppableId === "target") {
      // Herschikken binnen de doellijst
      const updatedTargetItems = Array.from(targetItems);
      const [removed] = updatedTargetItems.splice(source.index, 1);
      updatedTargetItems.splice(destination.index, 0, removed);

      setTargetItems(updatedTargetItems);
    }
  };

  const handleDelete = (id) => {
    const updatedTargetItems = Array.from(targetItems);
    const index = updatedTargetItems.findIndex((item) => item.id === id);
    updatedTargetItems.splice(index, 1);
    setTargetItems(updatedTargetItems);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.naam.match(/^[a-z]+$/)) {
      updatePageTemplate(params.id, { ...formData, content: targetItems }).then((data) => {
        if (data.type === "GET_CMS_TEMPLATE") {
          message(2000, "success", "De component is succesvol opgelsagen").then(() => {
            navigate("/cms/types/");
          });
          setValidationError(false);
        } else {
          message(4000, "danger", "Er is wat fout gegaan");
        }
      });
    } else {
      setValidationError(true);
    }
  };

  useEffect(() => {
    fetchcomponents().then((data) => {
      const itemsWithIds = data.results.map((item, index) => ({
        ...item,
        id: (index + 1).toString(), // Hier voegen we een ID toe op basis van de index (plus 1 om te starten bij 1)
      }));
      setSourceItems(itemsWithIds);
      fetchOnePageTemplate(params.id).then((data) => {
        if (data.results) {
          setLoaded(true);
          const itemsWithIds = data.results.content.map((item, index) => ({
            ...item,
            id: (index + 100).toString(),
          }));
          setTargetItems(itemsWithIds);
          setFormData({ naam: data.results.naam });
        }
      });
    });
  }, [fetchcomponents, setSourceItems]);

  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Types Templates</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              {loaded === true && (
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="box box-info">
                        <div className="box-header with-border">
                          <h3 className="box-title">Wijzig Template voor pagina's</h3>
                        </div>
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label htmlFor="naam">Vul de naam in (alleen kleine letters zonder spaties)</label>
                                <input type="text" className={`form-control ${validationError ? "is-invalid" : ""}`} id="naam" name="naam" value={formData.naam} onChange={handleInputChange} placeholder="Naam" />
                                {validationError && <div className="invalid-feedback">Alleen kleine letters zonder spaties zijn toegestaan.</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="box-body container-content">
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <div className="row">
                              <div className="col-md-8 col-12">
                                <div className="box">
                                  <div className="box-header with-border">
                                    <h3 className="box-title">Pagina Template</h3>
                                  </div>
                                  <div className="box-body">
                                    <Droppable droppableId="target" type="ITEM">
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                          {targetItems.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                              {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 box p-2 container-item">
                                                  <div className="box-header with-border">
                                                    <h3 className="box-title">{item.naam} </h3>
                                                    <div className="box-tools pull-right">
                                                      <div className="icon-container">
                                                        <button className="btn btn-sm btn-danger m-2" onClick={() => handleDelete(item.id)}>
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="box-body">
                                                    <div className="row align-items-center">
                                                      {item.content.map((item, index) => (
                                                        <div className="col-8 box container-sub-item" key={index}>
                                                          <strong>{item.naam} </strong>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-12 ">
                                <ComponentElements sourceItems={sourceItems} />
                              </div>
                            </div>
                          </DragDropContext>
                        </div>
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return { components: state.cms.components.items, template: state.cms.templates.item };
}

export default connect(mapStateToProps, {
  updatePageTemplate,
  fetchOnePageTemplate,
  fetchcomponents,
  message,
})(TemplateNew);
