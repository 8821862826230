import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import DateTimeInput from "../../../Components/Forms/datetime";
import { renderField, renderselect, renderTextField } from "../../../Components/Forms/renders";

const UitcheckForm = ({ handleSubmit, gast, change }) => {
  const handleNuVertrekken = () => {
    change("vertrek", moment().format()); // Update de vertrekdatum naar de huidige tijd
    setTimeout(() => {
      handleSubmit(); // Vertraag de submit-actie om change de tijd te geven om te voltooien
    }, 300); // 300 milliseconden vertraging
  };
  

  const isvoorvertrek = useMemo(() => {
    if (moment(gast.vertrek).isAfter(moment())) {
      return true;
    } else {
      return false;
    }
  });
  return (
    <form onSubmit={handleSubmit}>
      <Field name="aankomst" component={DateTimeInput} type="input" validate={required({ msg: "Dit item is verplicht" })} label="Aankomst datum verblijf" />
      <Field name="vertrek" component={DateTimeInput} type="input" validate={required({ msg: "Dit item is verplicht" })} label="Vertrek datum verblijf" />
      {isvoorvertrek && (
        <Field
          name="verreken"
          options={{
            totaal: "Het voorschot van de totale reservering",
            verblijf: "Op basis van het verblijf",
          }}
          component={renderselect}
          type="input"
          validate={required({ msg: "Dit item is verplicht" })}
          label="Verekenen  op basis van"
        />
      )}
      <div className="pull-left submit">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanpassen gegevens" />
        <input type="button" className="btn btn-next btn-fill btn-warning btn-wd btn-mg" name="next" value="Nu vertrekken" onClick={handleNuVertrekken} />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "uitcheck_form", // a unique identifier for this form
})(UitcheckForm);

const mapStateToProps = (state) => ({
  initialValues: state.gastenverbruik.gastverbruik, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
