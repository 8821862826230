import React from "react";
import { connect } from "react-redux";
import { vergelijken } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SerachLogboekPage from "./Components/searchheader";
//import SerachLogboekPage from "./Components/searchheader";
class VergelijkenPage extends React.Component {
  state = {
    errors: {},
    deelnemers: 0,
    hulpkrachten: 0,
    recreatieleider: 0,
    stagiaires: 0,
    totaal: 0,
    gemiddeld: 0,
  };

  componentWillMount() {
    this.getvals({});
  }

  getvals = (vals) => {
    vals.jaar = this.props.jaar;
    this.props.vergelijken(vals).then((data) => {
      this.setState({
        deelnemers: 0,
        recreatieleider: 0,
        hulpkrachten: 0,
        stagiaires: 0,
      });
      if (data.results && data.results.length > 0) {
        data.results.map((app) => this.handleItem(app));
        this.setState({
          totaal: parseInt(data.results.length, 0),
        });
      }
    });
  };

  handleItem(app) {
    if (app.deelnemers) {
      this.setState({
        deelnemers: this.state.deelnemers + parseInt(app.deelnemers, 0),
      });
    }
    if (app.recreatieleider) {
      this.setState({
        recreatieleider: this.state.recreatieleider + parseInt(app.recreatieleider, 0),
      });
    }
    if (app.hulpkrachten) {
      this.setState({
        hulpkrachten: this.state.hulpkrachten + parseInt(app.hulpkrachten, 0),
      });
    }
    if (app.stagiaires) {
      this.setState({
        stagiaires: this.state.stagiaires + parseInt(app.stagiaires, 0),
      });
    }
  }

  render() {
    function gemdeelnemers(cell, row) {
      return parseInt(row.deelnemers / row.activiteiten, 0) || 0;
    }

    function gembegeleiding(cell, row) {
      var begeleiding = row.recreatieleider + row.stagiaires + row.hulpkrachten;
      return parseInt(row.deelnemers / begeleiding, 0) || 0;
    }
    const columns = [
      {
        dataField: "bedrijf[0].naam",
        text: "Park",
      },
      {
        dataField: "activiteiten",
        text: "Activiteiten",
        sort: true,
      },
      {
        dataField: "deelnemers",
        text: "Deelnemers",
        sort: true,
      },
      {
        dataField: "gemiddelde",
        text: "Gemiddelde",
        formatter: gemdeelnemers,
        sort: true,
      },
      {
        dataField: "hulpkrachten",
        text: "Hulpkrachten",
        sort: true,
      },
      {
        dataField: "recreatieleider",
        text: "Recreatieleider",
        sort: true,
      },
      {
        dataField: "stagiaires",
        text: "Stagiaires",
        sort: true,
      },
      {
        dataField: "gemiddeldebegeldi",
        text: "Gemiddelde begeleiding",
        formatter: gembegeleiding,
        sort: true,
      },
    ];
    const { bedrijven, jaar, token } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Vergelijken per park ({jaar})</h3>
        </div>
        <SerachLogboekPage loading={this.state.loading} token={token} getvals={this.getvals} bedrijven={bedrijven} showbedrijf={false} />
        <div className="box box-default"></div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile" />
              <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={bedrijven} columns={columns} pagination={paginationFactory()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { username: state.auth.user, bedrijven: state.logboeken.bedrijven, jaar: state.logboeken.jaar, token: state.auth.token };
}

export default connect(mapStateToProps, { vergelijken })(VergelijkenPage);
