import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class LineGraph extends Component {
  render() {
    const { sensor, kleur } = this.props;
    function grdata(data) {
      const columns = [
        { type: "datetime", label: "datum" },
        { type: "number", label: "Kwh." },
        { type: "number", label: "vorige periode kWh." },
        { type: "string", role: "tooltip" }, // Tooltip kolom
      ];
      const rows = [];

      data.data.map(function (a, key) {
        if (a.datum) {
          if (data.vorigeperoide[key]) {
            const tooltipContent = `Huidig Periode \n  kWh: ${(((a.total / 1000) * 690) / 1000).toFixed(2)}\nDatum: ${moment(a.datum).format("DD-MM-YY HH:mm")} \nVorige Periode \n kWh: ${(((data.vorigeperoide[key].total / 1000) * 690) / 1000).toFixed(2)} \nDatum: ${moment(
              data.vorigeperoide[key].datum
            ).format("DD-MM-YY HH:mm")}`;
            rows.push([new Date(a.datum), (((a.total / 1000) * 690) / 1000).toFixed(2), (((data.vorigeperoide[key].total / 1000) * 690) / 1000).toFixed(2), tooltipContent]);
          }
        } else {
          const tooltipContent = `Huidig Periode \n   kWh: ${(((a.total / 1000) * 690) / 1000).toFixed(2)}\nDatum: ${moment(a.datum).format("DD-MM-YY HH:mm")}`;
          rows.push([new Date(a.datum), (((a.total / 1000) * 690) / 1000).toFixed(2), 0, tooltipContent]);

          return;
        }
      });
      return [columns, ...rows];
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: "d MMM HH:mm",
          },
          vAxis: {
            title: "kWh.",
          },
          tooltip: { isHtml: true },
          curveType: "function",
          colors: [kleur],
          series: {
            0: { title: "Huidige Periode" },
            1: { title: "Vorige Periode", lineDashStyle: [5, 2] },
          },
          explorer: {
            axis: "horizontal",
            actions: ["dragToZoom", "rightClickToReset"],
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
