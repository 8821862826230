import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required } from "redux-form-validators";
import Multiselect from "../../../../Components/Forms/multiselect";

import Datetime from "../../../../Components/Forms/datetime";
import moment from "moment";
import Rodal from "rodal";
import MultiSelectData from "../../../../Components/Forms/multiselectdata";
const ModalDatum = ({ handleSubmit, accommodaties, showalles, reset }) => {
  const [visible, setVisible] = useState(false);

  const getMeterTypes = () => {
    const meterTypes = new Set();

    accommodaties.forEach((data) => {
      data.meters.forEach((meter) => {
        meterTypes.add(meter.soort);
      });
    });

    return Array.from(meterTypes);
  };

  return (
    <div>
      <Rodal
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        className="rodal-lg"
      >
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-header">Maak een keuze</div>
            <div className="card-body">
              <Field name="datum" type="input" component={Datetime} label="Kies een datum en tijd" />
              <Field name="soort" label="Kies soorten meters" component={Multiselect} options={getMeterTypes()} />
              <Field name="accommodaties" label="Kies de accommodates (Laat leeg als je alle accommodaties wil selecteren)" textField="naam" dataKey="_id" component={MultiSelectData} options={accommodaties} allowCreate={false} />
            </div>
            <div className="card-footer text-muted d-flex justify-content-between p-4">
              <button
                className="btn btn-info btn-lg"
                type="button"
                onClick={() => {
                  reset();
                  showalles()
                }}
              >
                Laat alles zien
              </button>
              <button
                className="btn btn-warning btn-lg"
                type="submit"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Verzenden
              </button>
            </div>
          </div>
        </form>
      </Rodal>
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="btn-primary btn btn-box-too btn-sm"
      >
        Zoeken meter en datum
      </button>
    </div>
  );
};

const reduxConnectedForm = reduxForm({ form: "accodatumform" })(ModalDatum);

const mapStateToProps = (state) => ({
  initialValues: {
    datum: moment().format("YYYY-MM-DD 00:00"),
  },
});

export default connect(mapStateToProps)(reduxConnectedForm);
