import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import SimpleForm from '../wizzard/contact';
import { SaveItem, itemError, UpdateItem, GetItem } from '../Reducers/items/actions';
import Publiceren from '../Components/Publiceren';

const EditItemContact = ({ item, errors }) => {
  const [trcid, setTrcid] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (item._id && item.metadata.trcid === params.trcid) {
      setTrcid(item.metadata.trcid);
      setLoading(true);
    } else {
      dispatch(GetItem(params.trcid)).then((result) => {
        if (!result) {
          navigate("/items");
          alert("Kan item niet vinden");
        } else {
          setTrcid(result.metadata.trcid);
          setLoading(true);
        }
      });
    }
  }, [dispatch, item, navigate, params.trcid]);

  const submit = (vals) => {
    if (item._id) {
      const values = Object.assign({}, item, vals);
      dispatch(UpdateItem(item._id, values)).then(() => {
        if (item) {
          navigate("/items/edit/eigenschappen/" + trcid);
        }
      });
    } else {
      alert("Er is een fout gevonden.");
    }
  };

  if (item._id) {
    return (
        <div className="box box-default">
          <div className="box-header with-border">
            <h3 className="box-title">{item.what.title.value}</h3>
            <div className="box-tools pull-right">
              <Publiceren />
            </div>
          </div>
          <div className="box-body wizard">
            <ul className="nav nav-justified thumbnail setup-panel">
              <li className="flex-item">
                <Link to={`/items/edit/${trcid}`}>Wat</Link>
              </li>
              <li className="flex-item">
                <Link to={`/items/edit/waar/${trcid}`}>Waar</Link>
              </li>
              <li className="flex-item">
                <Link to={`/items/edit/wanneer/${trcid}`}>Wanneer</Link>
              </li>
              <li className="flex-item">
                <Link to={`/items/edit/media/${trcid}`}>Media</Link>
              </li>
              <li className="active flex-item">
                <Link onClick={(e) => e.preventDefault()} to={`/items/edit/contact/${trcid}`}>
                  Contact
                </Link>
              </li>
              <li className="flex-item">
                <Link to={`/items/edit/eigenschappen/${trcid}`}>Eigescnhappen</Link>
              </li>
            </ul>
            {loading ? <SimpleForm onSubmit={submit} /> : <div>Loading...</div>}
          </div>
        </div>
      );
    }
    return null;
  }

function mapStateToProps(state) {
  return { errors: state.auth.error, item: state.items.item };
}
export default connect(mapStateToProps, { SaveItem, itemError, UpdateItem, GetItem })(EditItemContact);
