import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "../../../Reducers/loading/actions";
import { fetchbedrijf } from "./Reducers/actions";
import Bedrijf from "./Components/bedrijf";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
class BedrijvenPage extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  componentWillMount() {
    this.props.fetchbedrijf().then((data) => {
      if (!data.results) {
        this.props.navigate("/404");
      } else {
        this.setState({loading: true})
      }
    });
  }

  render() {
    const { bedrijven } = this.props;
    return (
      <div className="public-box-body">
        <h2 className="headline text-yellow facheadline"> {lang("Ovezicht bedrijven")}</h2>
        <Loading loading={this.state.loading} />
        {this.state.loading && (
          <div className="activiteit-content">
            <div className="gastinfo">
              {lang(
                 "Hier volgt een lijst met bedrijven die gebruik maken van recraplan.nl "
              )}
            </div>
            {bedrijven.length === 0 && <h3>{lang("Er zijn op dit moment geen bedrijven actief")} </h3>}
            {bedrijven.map((bedrijf) => (
              <div key={bedrijf._id} className="activiteit-content">
                <Bedrijf bedrijf={bedrijf} link={true} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijven: state.bedrijfp.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fetchbedrijf,
})(BedrijvenPage);
