import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchbedrijven } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";

const BedrijvenPage = () => {
  const bedrijven = useSelector(state => state.bedrijven.items);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchbedrijven());
    // Voeg hier eventuele andere effecten toe
  }, [dispatch]);


  const columns = [
    {
      dataField: "naam",
      text: "Naam",
    },
    {
      dataField: "group",
      text: "Groep",
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning" to={"/admin/bedrijven/edit/" + row._id}>
              Wijzigen
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht bedrijven</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/admin/bedrijven/insert">
            <i className="fa fa-plus"></i>&nbsp; Bedrijven Toevoegen
          </Link>
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile" />
          <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={bedrijven} columns={columns} pagination={paginationFactory()} />
        </div>
      </div>
    </div>
  );
};

export default BedrijvenPage;
