import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { editalertprofile, getalertprofile } from './Reducers/actions';
import SensorEditForm from './Components/edit';

const SensorEditPage = () => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoaded(false);
    dispatch(getalertprofile(params.id)).then(() => {
      setLoaded(true);
    });
  }, [dispatch, params.id]);

  const submit = (vals) => {
    dispatch(editalertprofile(params.id, vals)).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/sensoren/alertprofile');
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Alert Profile wijzigen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded &&
                <SensorEditForm onSubmit={submit}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorEditPage;
