import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchervaringen } from "../Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

const ErvaringenWidget = ({ fetchervaringen, ervaringen, user }) => {
  useEffect(() => {
    fetchervaringen();
  }, [fetchervaringen]);

  const getstatus = (actief) => {
    if (actief) {
      return <small className="label bg-green">Actief</small>;
    } else {
      return <small className="label bg-orange">Non Actief</small>;
    }
  };

  const columns = [
    {
      dataField: "naam",
      text: "Naam",
    },
    {
      dataField: "beschrijving.short",
      text: "Beschrijving",
    },
    {
      dataField: "actief",
      text: "Status",
      formatter: getstatus,
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <Link className="btn btn-info" to={"/ervaringen/beschikbaarheid/" + row._id}>
            Datums
          </Link>
        );
      },
    },
  ];

  return (
    <div className="box-body box-profile">
      <ToolkitProvider
        keyField="_id"
        data={ervaringen.filter((ervaring) => ervaring.actief)}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
            <hr />
            <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, ervaringen: state.ervaringen.items };
}

export default connect(mapStateToProps, { fetchervaringen })(ErvaringenWidget);
