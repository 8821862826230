// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

export const initializeFirebase = () => {
  initializeApp({
    apiKey: "AIzaSyDQ6EOVpS8Y-4CrG0FgdGnsOdNsfkD8_Ew",
    authDomain: "boekingsplatform.firebaseapp.com",
    projectId: "boekingsplatform",
    storageBucket: "boekingsplatform.appspot.com",
    messagingSenderId: "200575295744",
    appId: "1:200575295744:web:24db86b02175755915d3e5",
  });


};
export const onMessageListener = () =>
  new Promise((resolve) => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const requestForToken = () => {
  const messaging = getMessaging();
  return getToken(messaging, { vapidKey: "BGqLMaiQHld-ccYQgftDL16MykZrlyvBczAUNJBPhfVMEcg5pXp8Dwdw-j9eA0-vqJ1sKdfyWhHzOJj2l7qTh24" })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        return currentToken;
      } else {
        // Show permission request UI
        alert("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
