import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { fetchgastenverbruik } from "./Reducers/actions";

const InschrijvingPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const gastenverbruik = useSelector((state) => state.gastenverbruik.gastenverbruik);
  const { SearchBar } = Search;
  useEffect(() => {
    getVals();
  }, []);

  const getVals = async () => {
    const data = await dispatch(fetchgastenverbruik());
    if (data.type !== "ACTIVITEIT_ERROR") {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "altijdtoegang",
      text: "Plaatsnummer",
      formatter: (cellContent, row) => {
        if (row.accommodaties) {
          return <span>{row.accommodaties.naam}</span>;
        }
        if (row.standplaats) {
          return <span>{row.standplaats.naam}</span>;
        }
      },
    },
    {
      dataField: "gast",
      text: "Naam gast",
      formatter: (cellContent, row) => {
        if (!cellContent) {
          return <span>Geen gast</span>;
        }
        return (
          <span>
            {cellContent.voornaam} {cellContent.tussennaam} {cellContent.achternaam}
          </span>
        );
      },
    },
    {
      dataField: "gast.email",
      text: "E-Mail",
    },
    {
      dataField: "aankomst",
      text: "Aankomst",
      formatter: (cellContent, row) => {
        if (!row.altijdtoegang) return <span>{new Date(row.aankomst).toLocaleDateString()}</span>;
      },
    },
    {
      dataField: "vertrek",
      text: "Vertrek",
      formatter: (cellContent, row) => {
        if (!row.altijdtoegang) return <span>{new Date(row.vertrek).toLocaleDateString()}</span>;
      },
    },

    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        if (row.standplaats && row.standplaats._id) {
          return (
            <span>
              <Link className="btn btn-primary btn-sm" to={{ pathname: "/gastenverbruik/kampeerplaats/" + row._id }}>
                Bekijken
              </Link>
            </span>
          );
        } else if (row.accommodaties && row.accommodaties.naam) {
          return (
            <span>
              <Link className="btn btn-primary btn-sm" to={{ pathname: "/gastenverbruik/accommodatie/" + row._id }}>
                Bekijken
              </Link>
            </span>
          );
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Gastenverbuik</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/gastenverbruik/insert">
            <i className="fa fa-plus"></i>&nbsp; Reservering Toevoegen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile" />
              <ToolkitProvider
                keyField="_id"
                data={gastenverbruik}
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div>
                    <SearchBar placeholder="Zoeken" {...props.searchProps} />
                    <hr />
                    <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InschrijvingPage;
