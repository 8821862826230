import Rodal from "rodal";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { uploadmedia } from "../../Reducers/actions";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Uploader({ visible, hide, uploadmedia }) {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <button type="button" className="close" onClick={() => handleDelete(index)} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));
  const handleUpload = async () => {
    try {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const data = await uploadmedia(file); // Geef direct het bestandsobject door
   
      }
      hide();
      setFiles([]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  return (
    <Rodal visible={visible} onClose={hide} className="rodal-sm">
      <div className="box box-default">
        <div className="box-body">
          <div className="box-header with-border">
            <h3 className="box-title">Upload bestanden</h3>
            <div>
              <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside style={thumbsContainer}>{thumbs}</aside>
              </section>
              {errors && <div className="text-danger">{errors.message}</div>}
              <input type="button" onClick={handleUpload} className="btn btn-next btn-fill btn-success btn-wd btn-sm" value="Uploaden" />
            </div>
          </div>
        </div>
      </div>
    </Rodal>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { uploadmedia })(Uploader);
