import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, useParams, useNavigate } from "react-router-dom";
import { message } from "../../../Reducers/loading/actions";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import { getbedrijf, fetchinschrijvingen, deleteinschrijving } from "./Reducers/actions";
import Loading from "../../../Components/loading/load";

function Inschrijven(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.bedrijf._id) {
      props.getbedrijf(id).then((data) => {
        if (!data.results) {
          navigate("/404");
        } else {
          props.fetchinschrijvingen(props.bedrijf._id);
          setLoading(true);
        }
      });
    } else {
      props.fetchinschrijvingen(props.bedrijf._id);
      setLoading(true);
    }
  }, []);

  const deletein = (id) => {
    props.deleteinschrijving(id).then((deln) => {
      props.fetchinschrijvingen(props.bedrijf._id).then((act) => {
        props.message(2000, "success", lang("Je inschrijving is succesvol verwijderd"));
      });
    });
  };

  function priceFormatter(cell, row) {
    return moment(cell).format("dddd DD MMMM");
  }

  const columns = [
    {
      dataField: "activiteit.datum",
      text: lang("Datum"),
      formatter: priceFormatter,
    },
    {
      dataField: "activiteit.tijd",
      text: lang("Tijd"),
    },
    {
      dataField: "activiteit.activiteit",
      text: lang("Activiteit"),
      formatter: (cellContent, row) => {
        return translate(cellContent);
      },
    },
    {
      dataField: "personen",
      text: lang("Pers."),
    },
    {
      text: lang("Uitschrijven"),
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <button className="btn btn-danger" onClick={() => deletein(row._id)}>
              X
            </button>
          </span>
        );
      },
    },
  ];

  return (
    <div className="public-box-body">
      <Link
        className="btn btn-success pull-right bt-inschrijvingen"
        to={`/public/activiteiten/${props.bedrijf._id}`}
      >
        {lang("Activiteiten")}
      </Link>
      <h2 className="headline text-yellow">{lang("Inschrijvingen")}</h2>
      <div className="gastinfo">{lang("Wil je je uitschrijven voor een activiteit? Klik dan op het rode kruisje.")}</div>
      <Loading loading={loading} />
      {loading && (
        <div className="activiteit-inschrijvingen">
          {props.inschrijvingen.length === 0 && <h3>{lang("Er zijn geen inschrijvingen")}</h3>}
          <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={props.inschrijvingen} columns={columns} />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    inschrijvingen: state.pubred.inschrijvingen,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchinschrijvingen,
  deleteinschrijving,
})(Inschrijven);
