import {
    GET_BOEKINGEN,
    ADD_BOEKINGEN,
    ERROR_BOEKINGEN,
    SET_BOEKINGEN
  } from './actions';
  export default function boekingen(state = {
    items: [],
    error: {},
    item: []
  }, action = {}) {
    switch (action.type) {
      case ADD_BOEKINGEN:
        return { ...state,
          items: [...state.items, action.results]
        };
      case ERROR_BOEKINGEN:
        return { ...state,
          error: action.payload
        };
      case GET_BOEKINGEN:
        return { ...state,
          items: action.results
        };
      case SET_BOEKINGEN:
          return { ...state,
            item: action.results
          };
      default:
        return state;
    }
  }
  