import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getactiviteit } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import LogboekEditForm from "./Components/edit";

function LogboekEditPage({dellogboek, updateactiviteit, message, getactiviteit,}) {

  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submit = (vals) => {
    updateactiviteit(id, vals).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De activiteit is met succes aangepast").then((data) => {
          navigate("/activiteiten");
        });
      }
    });
  };
  const verwijderen = () => {
    dellogboek(id).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De activiteit is met succes verwijderd").then((data) => {
          navigate("/logboek");
        });
      }
    });
  };


  useEffect(() => {
    setLoaded(false);
    dispatch(getactiviteit(id)).then((data) => {
      if (data.results) {
        setLoaded(true);
      } else {
        dispatch(message(2000, "warning", "Er is een fout opgetreden bij het laden van de activiteit")).then(() => {
          navigate("/logboek");
        });
      }
    });
  }, [id, dispatch, navigate]);

  return (
    <div className="box box-default maincontent">
      <div className="box-header with-border">
        <h3 className="box-title"> Activiteit wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <LogboekEditForm verwijderen={verwijderen} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogboekEditPage;
