import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import {thunk} from 'redux-thunk';
import { jwtDecode } from "jwt-decode"; // Correct import for jwt-decode
import rootReducer from "./rootReducer";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Update for React Router v6
import { AUTH_TOKEN, AUTH_USER } from "./Reducers/auth/types";
import "bootstrap/dist/js/bootstrap"; // for responsive navbar dropdown and modal dialog
import "./lib/css/index.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/dropzone/dist/min/dropzone.min.css";
import "react-sweet-progress/lib/style.css";
import "./index.css";
import "react-dates/lib/css/_datepicker.css";
import "rodal/lib/rodal.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const store = createStore(rootReducer, applyMiddleware(thunk));

try {
  const tokenString = localStorage.getItem("token");
  if (tokenString) {
    const token = jwtDecode(tokenString);
    
    // update application state with token information if needed
    store.dispatch({ type: AUTH_TOKEN, token: token });
    store.dispatch({ type: AUTH_USER });
  }
} catch (error) {
  console.log(error);
}
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
const originalWarn = console.warn;
console.warn = (...args) => {
  if (typeof args[0] === 'string' && args[0].includes('Support for defaultProps will be removed from function components')) {
    return;
  }
  originalWarn(...args);
};