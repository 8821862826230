import {
  SET_EVENTS,
  EVENT_ERROR,
  EVENTS_LOAD
} from './actions';
export default function cats(state = {
  events: []
}, action = {}) {
  switch (action.type) {

    case EVENT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SET_EVENTS:
      return {
        ...state,
        events: action.items
      };
    case EVENTS_LOAD:
      return state;
    default:
      return state;
  }
}
