import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import NDTRCTags from "../../../Components/Forms/ndtrctags";
import FooterForm from "../../../Components/Lib/FooterForm";
import Autosuggest from "../../../Components/Forms/autocomplete";
import SwitchButton from "../../../Components/Forms/switch";
import {
  renderTextField,
  renderField,
  renderAField,
  renderselectsimple,
  renderAFieldDisabled
} from "../../../Components/Forms/renders";

const SyncValidationForm = ({ onSubmit, item }) => {
  const [isEvent, setIsEvent] = useState(false);

  const handleEntityTypeChange = (value) => {
    setIsEvent(value.target.value === "EVENEMENT");
  };

  useEffect(() => {
    if (item.metadata.entitytype === "EVENEMENT") {
      setIsEvent(true);
    }
  }, [item.metadata.entitytype]);

  const renderEventFields = () => {
    if (isEvent) {
      return (
        <div>
          <Field
            name="subitemsgroup"
            type="input"
            component={Autosuggest}
            label="Kies om het item aan een locatie te koppelen"
          />
          <Field
            name="event.everyyear"
            type="input"
            component={SwitchButton}
            label="Wordt dit evenement jaarlijks herhaald?"
          />
        </div>
      );
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={item}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="col-sm-12">
            <h4 className="info-text">Vul hier de algemene gegevens in</h4>
          </div>
          <Field
            className="form"
            autoFocus
            name="metadata.entitytype"
            validate={required({ msg: "Type item is verplicht" })}
            label="Maak eerst een keuze"
            component={renderselectsimple}
            options={{
              EVENEMENT: "Evenement",
              LOCATIE: "Locatie"
            }}
            onChange={handleEntityTypeChange}
          />
          {renderEventFields()}

          <Field
            name="what.title.value"
            validate={required({ msg: "Titel is verplicht" })}
            type="text"
            component={renderField}
            label="Titel"
          />
          <Field
            name="what.slug.value"
            type="text"
            component={renderAFieldDisabled}
            label="Slug van de titel"
          />
          <Field
            name="what.shortdescription.value"
            validate={required({ msg: "Beschrijving is verplicht" })}
            type="textarea"
            component={renderTextField}
            label="Korte Omschrijving"
          />
          <Field
            name="what.longdescription.value"
            type="textarea"
            component={Wysiwyg}
            label="Lange Omschrijving"
            className="form-control"
            normalize={(value) => value.toString("html")}
          />
          <Field
            name="types"
            validate={required({ msg: "Types zijn verplicht" })}
            type="text"
            component={NDTRCTags}
            label="Tags"
            className="form-control"
          />
          <FooterForm />
        </form>
      )}
    />
  );
};

// Connect to Redux store
const mapStateToProps = (state) => ({
  item: state.items.item
});

export default connect(mapStateToProps)(SyncValidationForm);