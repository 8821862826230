import React from "react";
import { Field, reduxForm, FieldArray, getFormValues, change } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";
import switcbutton from "../../../Components/Forms/switch";
import PrijzenForm from "./prijzen";
import OptiesForm from "./opties";
import VariatiesForm from "./variaties";

const LogboekFormWebsite = ({ handleSubmit, formValues, initialValues }) => {
  const isBeschikbaarheidChecked = formValues && formValues.beschikbaarheid && formValues.beschikbaarheid.beschikbaarheid;
  const isPrijzenChecked = formValues && formValues.betaald;
  const isOpiesChecked = formValues && formValues.optie;

  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">Regels beschikbaarheid</div>
        <div className="card-body">
          <Field name="beschikbaarheid.beschikbaarheid" type="input" component={switcbutton} label="Beschikbaarheid van toepassing" />

          {isBeschikbaarheidChecked && (
            <div>
              <Field name="beschikbaarheid.personenmax" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Maximaal aantal deelnemers" />
              <Field name="beschikbaarheid.personenmin" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Minimaal aantal deelnemers" />
              <Field name="beschikbaarheid.groups" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Maximaal aantal deelnemers per reservering" />
              <Field name="beschikbaarheid.eind" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Hoeveel uren vooraf aanvang mag er een reservering worden gemaakt (0 is geen restrictie)" />
              <Field name="beschikbaarheid.start" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Hoeveel dagen mag er een reservering worden gemaakt (0 is geen restrictie)" />
            </div>
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header">Prijzen</div>
        <div className="card-body">
          <Field name="betaald" type="input" component={switcbutton} label="Deze ervaring is betaald" />
          {isPrijzenChecked && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Naam *</th>
                  <th>Beschrijving *</th>
                  <th>Leeftijd van </th>
                  <th>Leeftijd tot</th>
                  <th>Verplicht?</th>
                  <th>Prijs *</th>
                </tr>
              </thead>
              <FieldArray name={`prijzen`} component={PrijzenForm} />
            </table>
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-header">Maak variaties</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Startpunt</th>
                {initialValues.items.map((opties, index) => (
                  <th key={index}>{opties.naam}</th>
                ))}
              </tr>
            </thead>
            <FieldArray name={`variaties`} items={initialValues} component={VariatiesForm} />
          </table>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Aanvullende opties</div>
        <div className="card-body">
          <Field name="optie" type="input" component={switcbutton} label="Zijn er aanvullende producten" />
          {isOpiesChecked && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Naam *</th>
                  <th>Beschrijving *</th>
                  <th>Optie </th>
                  <th>Prijs *</th>
                </tr>
              </thead>
              <FieldArray name={`opties`} component={OptiesForm} />
            </table>
          )}
        </div>
      </div>
      <div className="card-footer text-muted mt-4">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "arrangamenten_edit_beschikbaar", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({
  initialValues: state.arrangementen.item, // pull initial values from account reducer
  formValues: getFormValues("arrangamenten_edit_beschikbaar")(state), // get current form values
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
