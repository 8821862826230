import React from "react";
import { Link } from "react-router-dom";

function SubMenu(props) {
  return (
    <div className="sub-sidebar flex-shrink-0 p-1 bg-white vh-100" style={{ width: "200px" }}>
      <a href="/" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
        <span className="fs-5 fw-semibold">Setting menu</span>
      </a>
      <ul className="list-unstyled ps-0">
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
          <i className="fa fa-book" /> General
          </button>
          <div className="collapse show" id="home-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
            <li>
                <Link to={"/cms/settings/website"} className="link-dark rounded">
                  Website structuur
                </Link>
              </li>
              <li>
                <Link to={"/cms/settings/talen"}  className="link-dark rounded">
                  Talen
                </Link>
              </li>
              <li>
                <Link to={"/cms/settings/media"}  className="link-dark rounded">
                  Media
                </Link>
              </li>
              <li>
                <Link to={"/cms/settings/onderdelen"}  className="link-dark rounded">
                  Pagina onderdelen
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SubMenu;