import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../../../Components/Forms/renders";

const BedrijvenForm = (props) => {
  const { handleSubmit, change } = props;

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="titel" type="input" component={renderTextField} label="Titel image" />
              <Field name="alt" type="input" component={renderTextField} label="Alt van de image" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updaten" />
          </div>
        </div>
      </form>
    </div>
  );
};
let ReduxMediaEdit = reduxForm({
  form: "berichten_edit", // een unieke identifier voor dit formulier
})(BedrijvenForm);

ReduxMediaEdit = connect(
  (state) => ({
    initialValues: state.cms.media.item
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxMediaEdit);

export default ReduxMediaEdit;
