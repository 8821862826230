import { SET_GASTEN_VERBRUIK, ADD_GASTEN_VERBRUIK, GASTEN_VERBRUIK_ERROR, SET_GAST_VERBRUIK, SET_VERBRUIK, SET_GAST, SET_GASTS, SET_TIP, SET_TIPS } from "./actions";
export default function gastenverbruik(
  state = {
    gastenverbruik: [],
    gasten: [],
    gast: {},
    gastverbruik: {},
    verbruik: [],
    tips:[],
    tip:{},
    error: "",
  },
  action = {}
) {
  switch (action.type) {
    case SET_GASTEN_VERBRUIK:
      return { ...state, gastenverbruik: action.results };
    case ADD_GASTEN_VERBRUIK:
      return { ...state, gast: action.results };
    case GASTEN_VERBRUIK_ERROR:
      return { ...state, error: action.error };
    case SET_GAST_VERBRUIK:
      return { ...state, gastverbruik: action.results };
    case SET_VERBRUIK:
      return { ...state, verbruik: action.results };
    case SET_GAST:
      return { ...state, gast: action.results };
    case SET_GASTS:
      return { ...state, gasten: action.results };
    case SET_TIP:
      return { ...state, tip: action.results };
    case SET_TIPS:
      return { ...state, tips: action.results };

    default:
      return state;
  }
}
