import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { checktoken } from "../Gasten/Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";

const VerifyLogin = () => {
  const [verify, setVerify] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bedrijf = useSelector((state) => state.pubred.bedrijf);

  useEffect(() => {
    dispatch(checktoken({ token: params.id })).then((data) => {
      if (data.results && data.results._id) {
        setVerify(true);
        localStorage.setItem("gast", data.results._id);
        if (data.results.from) {
          dispatch(message(3000, "success", lang("Je account is succesvol geactiveerd."))).then(() => {
            navigate(data.results.from);
          });
        } else {
          dispatch(fixmessage("success", lang("Je account is succesvol geactiveerd.")));
        }
      } else {
        setVerify(false);
        dispatch(fixmessage("danger", lang("Er is geen account gevonden met via deze link")));
      }
    });

    return () => {
      dispatch(clearmessage());
    };
  }, [dispatch, params.id, navigate]);

  return (
    <div className="public-box-body">
      {verify && (
        <div>
          <h2 className="headline text-yellow"> {lang("Het is gelukt")}</h2>
          <div className="inschrijf-content">{lang("Je kunt je nu via de RCN app inschrijven voor activiteiten of faciliteiten op ons park.")}</div>
        </div>
      )}
    </div>
  );
};

export default VerifyLogin;
