import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class LineGraph extends Component {
  render() {
    const { sensor, kleur } = this.props;
    function grdata(data) {
      const columns = [
        { type: "datetime", label: "datum" },
        { type: "number", label: "Kub." },
        { type: "number", label: "vorige periode Kub." },
        { type: "string", role: "tooltip" }, // Tooltip kolom
      ];
      const rows = [];
      data.data.forEach(function (a, key) {
        if (a.datum && a.value && key > 0) {
          if (data.vorigeperoide[key]) {
            const tooltipContent = `Huidig Periode \n  Kub: ${data.data[key - 1].value - a.value}\nDatum: ${moment(a.datum).format("DD-MM-YY HH:mm")} \nVorige Periode \n Kub: ${data.vorigeperoide[key].value - data.vorigeperoide[key - 1].value } \nDatum: ${moment(
              data.vorigeperoide[key].datum
            ).format("DD-MM-YY HH:mm")}`;
            rows.push([new Date(a.datum), (data.data[key - 1].value - a.value) / 10, (data.vorigeperoide[key].value -data.vorigeperoide[key - 1].value ) / 10, tooltipContent]);
          } else {
            const tooltipContent = `Huidig Periode \n  Kub: ${data.data[key - 1].value - a.value}\nDatum: ${moment(a.datum).format("DD-MM-YY HH:mm")} }`;
            rows.push([new Date(a.datum), (data.data[key - 1].value - a.value) / 10, 0, tooltipContent]);
          }
        }
      });
      return [columns, ...rows];
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: "d MMM HH:mm",
          },
          vAxis: {
            title: "Kub.",
          },
          tooltip: { isHtml: true },
          curveType: "function",
          colors: [kleur],
          series: {
            0: { title: "Huidige Periode" },
            1: { title: "Vorige Periode", lineDashStyle: [5, 2] },
          },
          explorer: {
            axis: "horizontal",
            actions: ["dragToZoom", "rightClickToReset"],
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
