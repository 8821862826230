import React from "react";
import { Routes, Route } from "react-router-dom";
import SensorTempIndex from "./Temperatuur/index";
import SensorEditPage from "./Sensor/edit";
import SensorInsert from "./Sensor/insert";
import SensorItem from "./Temperatuur/show";
import AlertProfile from "./Profiles/index";
import AlertProfileInsert from "./Profiles/insert";
import AlertProfileEdit from "./Profiles/edit";
import SensorElecIndex from "./Elecktra/index";
import SensorItemElectraAMP from "./Ampere/show";
import SensorEctraAMP from "./Ampere/index";
import SensorIndexGas from "./Gas/index";
import SensorItemGas from "./Gas/show";
import SensorIndexWater from "./Water/index";
import SensorItemWater from "./Water/show";
import SensorItemElectra from "./Elecktra/show";
import { RequireAuthAdmin } from "../../Auth/require_auth_admin";
import InsexSensor from ".";
import ImageMap from "./Map/index";
import ImageMapEdit from "./Map/edit";
import Sensorinstellingen from "./Instellingen/index";
import Rapportage from "./Rapportage/show";
import "./style.scss";

const SensorRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<InsexSensor />} />
      <Route path="/map" element={<ImageMap />} />
      <Route path="/map/edit" element={<ImageMapEdit />} />
      <Route path="/sensor/" element={<SensorTempIndex />} />
      <Route path="/sensor/temperatuur" element={<SensorTempIndex />} />
      <Route path="/sensor/electra" element={<SensorElecIndex />} />
      <Route path="/sensor/amp/" element={<SensorEctraAMP />} />
      <Route path="/sensor/amp/show/:id" element={<SensorItemElectraAMP />} />
      <Route path="/sensor/gas" element={<SensorIndexGas />} />
      <Route path="/sensor/toevoegen" element={<RequireAuthAdmin><SensorInsert/></RequireAuthAdmin>} />
      <Route path="/sensor/show/:id" element={<SensorItem />} />
      <Route path="/sensor/electra/show/:id" element={<SensorItemElectra />} />
      <Route path="/sensor/gas/show/:id" element={<SensorItemGas />} />
      <Route path="/sensor/edit/:id" element={<SensorEditPage />} />
      <Route path="/alertprofile" element={<AlertProfile />} />
      <Route path="/sensor/water" element={<SensorIndexWater />} />
      <Route path="/sensor/water/show/:id" element={<SensorItemWater />} />
      <Route path="/alertprofile/insert" element={<RequireAuthAdmin><AlertProfileInsert /></RequireAuthAdmin>} />
      <Route path="/alertprofile/edit/:id" element={<RequireAuthAdmin><AlertProfileEdit /></RequireAuthAdmin>} />
      <Route path="/instellingen" element={<Sensorinstellingen />} />
      <Route path="/rapportage" element={<Rapportage />} />
    </Routes>
  );
};

export default SensorRouter;
