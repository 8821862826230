import React, { useEffect } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";

const BoekingenTabel = ({ boekingen }) => {
  const getstatus = (actief) => {
    if (actief == "Nieuw") {
      return <small className="badge bg-primary">Nieuw</small>;
    } else if (actief == "accept") {
      return <small className="badge bg-success">Geaccepteerd</small>;
    } else if (actief == "cancel") {
      return <small className="badge bg-danger text-dark">Geweigerd</small>;
    } else if (actief == "annuleren") {
      return <small className="badge bg-warning text-dark">Geannuleerd</small>;
    } else {
      return <small className="badge bg-info text-dark">{actief}</small>;
    }
  };

  const isbetaald = (actief) => {
    if (actief && actief.status == "paid") {
      return <small className="badge bg-success">Betaald</small>;
    } else {
      return <small className="badge bg-danger text-dark">Niet Betaald</small>;
    }
  };

  const columns = [
    {
      dataField: "klanten",
      text: "Naam",
      formatter: (cell, row) => {
        const { voornaam, tussenvoegsel, achternaam } = row.klanten;
        return `${voornaam} ${tussenvoegsel ? tussenvoegsel + " " : ""}${achternaam}`;
      },
    },
    {
      dataField: "personen",
      text: "Aantal personen",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: getstatus,
    },
    {
      dataField: "betaling",
      text: "Betaald",
      formatter: isbetaald,
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => (
        <span>
          <Link className="btn btn-info" to={`/boekingen/show/${row.soort === "arrangement" ? "arrangement/" : ""}${row._id}`}>
            Bekijken
          </Link>
        </span>
      ),
      headerStyle: { width: "250px" },
    },
  ];

  return (
    <ToolkitProvider
      keyField="_id"
      data={boekingen}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
    >
      {(props) => (
        <div>
          <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
          <hr />

          <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {})(BoekingenTabel);
