import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SubMenu from "./Components/submenu";
import { savesettings, fetchsettings } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";

import Taal from "./Components/froms/taal";

const TaalIndex = ({ savesettings, fetchsettings,message }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchsettings().then((res) => {
      setLoading(true);
    });
  }, [fetchsettings]);

  const submit = (values) => {
    savesettings(values).then((res) => {
      message(4000, "success", "De settings is succesvol verwerkt")
    });
  };
  return (
    <div className="content-page">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Settings - talen</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">Talen</h3>
                    </div>
                    {loading && (
                      <div className="box-body">
                        <Taal onSubmit={submit} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return { settings: state.cms.settings };
}

export default connect(mapStateToProps, {
  savesettings,
  fetchsettings,
  message
})(TaalIndex);
