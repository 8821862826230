import React, { useMemo } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import MeterForm from "./MeterForm";

import PropTypes from "prop-types";

const EditKampeerplaatsen = ({ handleSubmit, sensoren, categorien }) => {
  const requiredValidator = useMemo(() => required(), []);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field
                className="form"
                name="active"
                validate={required({ msg: "Type item is verplicht" })}
                label="Accommodatie actief"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam Accommodatie" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving accommodatie (optioneel)" />
              <FieldArray name={`meters`} component={MeterForm} sensoren={sensoren} categorien={categorien} />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    </div>
  );
};

EditKampeerplaatsen.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(EditKampeerplaatsen);

const mapStateToProps = (state) => ({
  initialValues: state.standplaatsen.accommodatie, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
