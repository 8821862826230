export const GET_ARRANGEMENTEN = "GET_ARRANGEMENTEN";
export const ADD_ARRANGEMENTEN = "ADD_ARRANGEMENTEN";
export const SET_ARRANGEMENTEN = "SET_ARRANGEMENTEN";
export const SET_BESCHIKBAARHEID = "SET_BESCHIKBAARHEID";
export const GET_BESCHIKBAARHEID = "GET_BESCHIKBAARHEID";
export const ADD_BESCHIKBAARHEID = "ADD_BESCHIKBAARHEID";
export const ERROR_ARRANGEMENTEN = "ERROR_ARRANGEMENTEN";
export const SET_BESCHIKBAARHEIDEN = "SET_BESCHIKBAARHEIDEN";
export const SET_ALLEDAGEN = "SET_ALLEDAGEN";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function add(results) {
  return {
    type: ADD_ARRANGEMENTEN,
    results,
  };
}
export function get(results) {
  return {
    type: GET_ARRANGEMENTEN,
    results,
  };
}
export function set(results) {
  return {
    type: SET_ARRANGEMENTEN,
    results,
  };
}
export function setbeschikbaarheid(results) {
  return {
    type: SET_BESCHIKBAARHEID,
    results,
  };
}

export function setbeschikbaarheiden(results) {
  return {
    type: SET_BESCHIKBAARHEIDEN,
    results,
  };
}

export function arrangementenError(error) {
  return {
    type: ERROR_ARRANGEMENTEN,
    payload: error,
  };
}
export function setalledagen(payload) {
  return {
    type: SET_ALLEDAGEN,
    payload,
  };
}
export function savearrangementen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/arrangementen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      console.log(results);
      return dispatch(add(results.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updatearrangementen(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/arrangementen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function fetcharrangementen() {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/", {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(get(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function fetcharrangementenaankoemende() {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/beschikbaarheid/aankomende", {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheiden(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getarrangementen(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.result));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getarrangementenall(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/all/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.result));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function delarrangementen(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/" + id, {
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function savebeschikbaarheid(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/arrangementen/beschikbaarheid", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function savebeschikbaarheidregels(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/arrangementen/beschikbaarheid/regels", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function getbeschikbaarheid(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/beschikbaarheid/get/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheid(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function controleerbeschikbaarheid(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/arrangementen/beschikbaarheid/controleren/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheid(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updatebeschikbaarheid(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/arrangementen/beschikbaarheid/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setbeschikbaarheid(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}
