import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./Reducers/auth/reducers.js";
import profile from "./Pages/profile/Reducers/profile";
import logboeken from "./Pages/Logboeken/Reducers/logboeken";
import bedrijven from "./Pages/Admin/Bedrijven/Reducers/bedrijven";
import website from "./Pages/Website/Reducers/website";
import push from "./Pages/profile/Reducers/Push";
import cursisten from "./Pages/Trainingen/Curstisten/Reducers/cursisten";
import trainingen from "./Pages/Trainingen/Trainingen/Reducers/trainingen";
import beoordelingen from "./Pages/Trainingen/Beoordelingen/Reducers/beoordelingen";
import certificaten from "./Pages/Trainingen/Certificaat/Reducers/certificaten";
import loading from "./Reducers/loading/loading";
import gebruikers from "./Pages/Admin/Gebruikers/Reducers/gebruikers";
import activiteiten from "./Pages/Activiteiten/Reducers/activiteiten";
import faciliteiten from "./Pages/Faciliteiten/Reducers/facilieiten";
import pubred from "./Pages/Public/Activiteiten/Reducers/pubred";
import gasten from "./Pages/Public/Gasten/Reducers/gasten";
import openfaciliteiten from "./Pages/Public/Faciliteiten/Reducers/facilieiten";
import mgebruukers from "./Pages/Gebruikers/Reducers/gebruikers";
import horecap from "./Pages/Public/Horeca/Reducers/horecared";
import horeca from "./Pages/Horeca/Reducers/horeca";
import bedrijfp from "./Pages/Public/Bedrijven/Reducers/pbedrijf";
import biepp from "./Pages/Biepp/Reducers/biepp";
import verhuur from "./Pages/Verhuur/Reducers/verhuur";
import sensoren from "./Pages/Sensors/Reducers/sensoren";
import alertprofile from "./Pages/Sensors/Profiles/Reducers/alertprofile";
import standplaatsen from "./Pages/Standplaatsen/Reducers/standplaatsen.js";
import gastenverbruik from "./Pages/GastenVerbruik/Reducers/gastenverbruik.js";
import theme from "./Pages/Thema/Recucers/theme/theme.js";
import items from "./Pages/Items/Reducers/items/items.js";
import arrangementen_wild from "./Pages/Items/Reducers/arrangementen/arrangementen.js";
import pushe from "./Pages/Push/Reducers/push/push.js";
import events from "./Pages/Evemenenten/Reducers/events/events.js";
import ervaringen from "./Pages/Ervaringen/Reducers/ervaringen.js";
import boekingen from "./Pages/Boekingen/Reducers/boekingen.js";
import cms from "./Pages/CMS/Reducers/cms.js";
import connections from "./Pages/Admin/Connections/Reducers/connections.js";
import sensorinstellingen from "./Pages/Sensors/Instellingen/Reducers/sensorinstellingen.js";
import vacatures from "./Pages/Vacatures/Reducers/vacatures.js";
import arrangementen from "./Pages/Arrangementen/Reducers/arrangementen.js";

export default combineReducers({
  auth,
  profile,
  logboeken,
  bedrijven,
  website,
  push,
  cursisten,
  trainingen,
  beoordelingen,
  certificaten,
  loading,
  gebruikers,
  activiteiten,
  faciliteiten,
  pubred,
  openfaciliteiten,
  gasten,
  mgebruukers,
  horecap,
  horeca,
  bedrijfp,
  biepp,
  verhuur,
  sensoren,
  alertprofile,
  standplaatsen,
  gastenverbruik,
  theme,
  items,
  pushe,
  events,
  ervaringen,
  boekingen,
  cms,
  arrangementen,
  connections,
  sensorinstellingen,
  vacatures,
  arrangementen_wild,
  form: formReducer, // <---- Mounted at 'form
});
