import React from "react";
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../Components/Forms/renders";
import MultiSelectDagen from "../../../Components/Forms/multiselect";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import UploadFrom from "../../../Components/Forms/uploadsingle";
import BeheerderForm from "./beheerders";

const LogboekFormWebsite = ({ handleSubmit, formValues }) => {
  const isPrijzenChecked = formValues && formValues.betaald;

  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">Gegevens van de eigenaar</div>
        <div className="card-body">
          <div>
            <Field name="owner.naam" type="text" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Wie is de eigenaar van de ervaring " />
            <Field name="owner.telefoonnummer" type="text" component={renderField} label="Telefoonnummer eigenaar" />
            <Field name="owner.mobiel" type="text" component={renderField} label="Mobiele nummer eigenaar" />
            <Field name="owner.email" type="text" component={renderField} label="E-mail nummer eigenaar" />
            <Field name="owner.rekeningnummer" type="text" component={renderField} label="Rekeningnummer eigenaar (Iban)" />
            <Field name="owner.bank" type="text" component={renderField} label="Bank" />
            <Field name="owner.foto" type="input" component={UploadFrom} label="Upload foto van de eigenaar" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Beheren reservingen</div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Naam</th>
                <th>E-mail adres </th>
                <th>Telefoonnummer</th>
                <th>Voorkeur</th>
              </tr>
            </thead>
            <FieldArray name={`beheerders`} component={BeheerderForm} />
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Gegevens reservering</div>
        <div className="card-body">
          <div>
            <Field name="reservering.tijd" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Na hoeveel tijd vervalt de reserverig (uren) " />
            <Field
              name="reservering.betaalmogelijkheden"
              component={renderselect}
              options={{
                locatie: "Betaling wordt gedaan op locatie",
                direct: "Betaling wordt direct gedaan",
                bevestiging: "Betaling wordt gevraagd na bevestiging",
                vvv: "De betaling wordt gedaan via VVV",
              }}
              label="Hoe wordt de betaling gedaan"
            />
            <Field name="reservering.betalingen" component={MultiSelectDagen} options={["VVV", "Pin", "Contant", "Online"]} label="Hoe wordt de betaling gedaan" />
            <Field name="reservering.betalingtijd" type="number" component={renderField} label="Binnen hoeveel dagen moet de betaling worden gedaan" />
          </div>
        </div>
      </div>
      <div className="card-footer text-muted m-2">
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({
  initialValues: state.ervaringen.item, // pull initial values from account reducer
  formValues: getFormValues("categorie_edit")(state), // get current form values
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
