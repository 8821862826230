import React from "react";
import { Routes, Route } from "react-router-dom";
import IndexVacatures from "./index";
import VacatureBedrijfInsert from "./Bedrijven/insert";
import VacatureInsert from "./insert";
import VacaturesEditPage from "./edit";
import BedrijvenVacatures from "./Bedrijven/index";
import BedrijfEditPage from "./Bedrijven/edit";
import VacaturesBedrijven from "./Vacatures/index";
import VacaturesEditBedrijven from "./Vacatures/edit";
import VacatureFotBedrijfInsert from "./Vacatures/insert";
import VacaturesvoorBedrijven from "./Bedrijven/vacatures";

const GastenverbruikRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexVacatures />} />
      <Route path="/bedrijf/toevoegen" element={<VacatureBedrijfInsert />} />
      <Route path="/bedrijf/edit/:id" element={<BedrijfEditPage />} />
      <Route path="/bedrijven/" element={<BedrijvenVacatures />} />
      <Route path="/toevoegen" element={<VacatureInsert />} />
      <Route path="/edit/:id" element={<VacaturesEditPage />} />
      <Route path="/bedrijven/:id/" element={<VacaturesvoorBedrijven />} />
      <Route path="/bedrijven/:vitems/vacatures" element={<VacaturesBedrijven />} />
      <Route path="/bedrijven/:vitems/edit/:id" element={<VacaturesEditBedrijven />} />
      <Route path="/bedrijven/:vitems/toevoegen" element={<VacatureFotBedrijfInsert />} />
    </Routes>
  );
};

export default GastenverbruikRouter;
