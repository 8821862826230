import {
  GET_THEME,
  ADD_THEME,
  ERROR_THEME,
  SET_THEME
} from './actions';
export default function theme(state = {
  items: [],
  error: {},
  item: []
}, action = {}) {
  switch (action.type) {
    case ADD_THEME:
      return { ...state,
        items: [...state.itens, action.results]
      };
    case ERROR_THEME:
      return { ...state,
        error: action.payload
      };
    case GET_THEME:
      return { ...state,
        items: action.results
      };
    case SET_THEME:
        return { ...state,
          item: action.results
        };
    default:
      return state;
  }
}
