import React, { Component } from "react";
import { Chart } from "react-google-charts";

export default class LineGraph extends Component {
  render() {
    const { sensors, kleur } = this.props;
    function kiesmeter(sensor) {
      if (sensor.soort === "electra") {
        return { naaM: "kWh", waarde: 1 };
      } else if (sensor.soort === "water") {
        return { naam: "m³", waarde: 1000 };
      } else if (sensor.soort === "gas") {
        return { naam: "m³", waarde: 100 };
      } else if (sensor.soort === "ampere") {
        return { naam: "kWh", waarde: 100 };
      
      } else {
        return { naam: "kWh", waarde: 1 };
      }
    }
    function grdata(data) {
      const columns = [{ type: "datetime", label: "datum" }];
      const sensorColors = [];

      // Add a column for each sensor
      data.forEach((sensor, index) => {
        columns.push({ type: "number", label: sensor.naam });
        sensorColors.push(kleur[index % kleur.length]);
      });

      // Create a map to store data by date
      const dateMap = new Map();

      data.forEach((sensor, sensorIndex) => {
        const soortmeter = kiesmeter(sensor);
        sensor.data.forEach((item, index) => {
          const date = new Date(item.datum);
          if (!dateMap.has(date)) {
            dateMap.set(date, Array(data.length).fill(null));
          }
          if (index > 0 && sensor.data[index - 1].datum !== item.datum) {
            if (sensor.soort === "ampere") {

              dateMap.get(date)[sensorIndex] = Number(item.value) / soortmeter.waarde;
            } else {
              dateMap.get(date)[sensorIndex] = Number(item.value - sensor.data[index - 1].value) / soortmeter.waarde;
            }
          }
        });
      });

      const rows = Array.from(dateMap, ([date, values]) => [date, ...values]);

      return [columns, ...rows];
    }

    return (
      <Chart
        width={"100%"}
        height={"500px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensors)}
        options={{
          hAxis: {
            title: "Datum",
            format: "d MMM HH:mm",
          },
  
          tooltip: { isHtml: true },
          curveType: "function",

          series: sensors.reduce((acc, _, index) => {
            acc[index] = { title: `Sensor ${index + 1}` };
            return acc;
          }, {}),
          explorer: {
            axis: "horizontal",
            actions: ["dragToZoom", "rightClickToReset"],
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
