import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchsensoren, fetchoneelectra_multi, getsensorgas_dag, getsensorgas_maand, getsensorgas_uur, zoeklekkages, maakgrafiekdata, exportExcel } from "../Reducers/actions";
import { fixmessage } from "../../../Reducers/loading/actions";
import moment from "moment";
import LineGraph from "./Components/linegraps";
import FilterSensor from "./Components/filter";
import Tabel from "./Components/tabel";

const SensorItemGas = (props) => {
  const [load, setLoad] = useState(false);
  const [sensorenids, setSensorenids] = useState([]);
  const [datum, setDatum] = useState({ startdate: moment().subtract(7, "days").toDate(), enddate: new Date() });

  useEffect(() => {
    getsensoren();
  }, []);

  useEffect(() => {
    if (sensorenids) {
      getvals();
    }
  }, [sensorenids, datum]);

  const getvals = () => {
    if (sensorenids.length > 0) {
      props.fetchoneelectra_multi({ ...datum, sensor_ids: sensorenids }).then((data) => {
        if (!data.results) {
          props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de data");
        } else {
          setLoad(true);
        }
      });
    }
  };

  const getsensoren = () => {
    props.fetchsensoren().then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de sensoren");
      }
    });
  };

  const changedata = (data) => {
    setSensorenids(data.sensoren);
    setDatum({ startdate: data.startdate, enddate: data.enddate });
  };

  const onsubmitdata = (message) => {
    props.maakgrafiekdata({ data: sensor.item, message: message }).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgetreden bij het laden van de data");
      } else {
        setLoad(true);
      }
    });
  };

  const exporttoexcel = () => {
    props.exportExcel({ data: sensor.item });
  };

  const { sensor } = props;

  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title">Rapportages van sensoren</h3>
        <div className="box-tools pull-right">
          {/* <Link className="btn btn-warning btn-sm float-right" role="group" to={`/sensoren/sensor/edit/${sensor.item._id}`}>
            Opslaan
          </Link> */}
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor changedata={changedata} sensor={sensor} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        {load && (
          <div className="box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Gegevens</h3>
              <div className="box-tools pull-right">
                <button className="btn btn-success btn-sm float-right" role="group" onClick={() => exporttoexcel()}>
                  Exporteren naar Excel
                </button>
              </div>
            </div>
            <div className="box-body" />

            <div className="row p-0">
              <div className="col-12 p-0">
                <LineGraph sensors={sensor.item} kleur="#D96A29" />
              </div>

              <div className="col-12 p-0">
                <Tabel sensors={sensor.item} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sensor: state.sensoren,
});

export default connect(mapStateToProps, { fetchoneelectra_multi, fixmessage, getsensorgas_dag, getsensorgas_maand, getsensorgas_uur, zoeklekkages, fetchsensoren, maakgrafiekdata, exportExcel })(SensorItemGas);
