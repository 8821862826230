import React, {Component} from 'react';

class FooterForm extends Component {

  render() {

    return (
      <div className="wizard-footer">
        <div className="pull-right pt-4 pb-2">
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-md" name="next" value="Volgende stap"/>
        </div>
        <div className="clearfix"></div>
      </div>
    );

  }
}

export default FooterForm;
