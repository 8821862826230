import {
  SET_CONNECTIONS,
  ADD_CONNECTIONS,
  CONNECTION_ERROR,
  SET_CONNECTION,
  GET_CONNECTIONS_POSTS,
  SET_CONNECTION_BEDRIJF
} from './actions';
export default function connections(state = {
  item: [],
  items: [],
  posts: [],
  locations: [],
}, action = {}) {
  switch (action.type) {
    case ADD_CONNECTIONS:
      return { ...state,
        items: [...state.items, action.results]
      };
    case SET_CONNECTION:
      return { ...state,
        item: action.results
      };
    case CONNECTION_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_CONNECTIONS:
      return { ...state,
        items: action.results
      };
    case GET_CONNECTIONS_POSTS:
      return { ...state,
        posts: action.results
      };
    case SET_CONNECTION_BEDRIJF:
  
      return { ...state,
        locations: action.results.locations
      };
    default:
      return state;
  }
}
