import React, { useState } from "react";
import { connect } from "react-redux";
import ndtrccode from "../../lib/ndtrccodes.json";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ItemsTags = () => {
  const [suggestions] = useState(ndtrccode);

  const columns = [
    {
      dataField: "label.value",
      text: "Naam",
    },
    {
      dataField: "catid",
      text: "Code",
    },
  ];
  const { SearchBar } = Search;
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Overzicht codes</h3>
      </div>
      <div className="box-body">
        <ToolkitProvider
          keyField="id"
          data={suggestions}
          columns={columns}
          search={{
            searchFormatted: true,
          }}
        >
          {(props) => (
            <div>
              <SearchBar placeholder="Zoeken" {...props.searchProps} />
              <hr />
              <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { errors: state.auth.error, items: state.items.items, glerror: state.items.error };
};

export default connect(mapStateToProps, {})(ItemsTags);
