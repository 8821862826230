import React, { useState, useEffect } from "react";
import { reduxForm, FieldArray, Field } from "redux-form";
import { connect } from "react-redux";
import FooterForm from "../../../Components/Lib/FooterForm";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../Components/Forms/renders";
import Multiselect from "../../../Components/Forms/multiselectdata";
import switcbutton from "../../../Components/Forms/switch";
import Item from "./item";

const SyncValidationForm = ({ handleSubmit, initialValues, change }) => {
  const ontvanggegevs = (addressresult, adress, latlon, inputname) => {
    const index = inputname.match(/\d+/) ? parseInt(inputname.match(/\d+/)[0], 10) : null;
    change(`location[${index}]`, {
      label: addressresult,
      street: adress.route,
      housenr: adress.street_number,
      zipcode: adress.postal_code,
      city: adress.locality,
      country: adress.country,
    });

    // Bijwerken van het 'coordinates[0]' veld
    change(`location[${index}].coordinates[0]`, {
      lat: latlon.lat,
      lon: latlon.lng,
    });
    //setSelectedPlace((prevPlaces) => Object.assign([...prevPlaces], { [index]: { lat: latlon.lat, lon: latlon.lng } }));
  };

  const replacemap = (latlon) => {
    console.log(latlon);
    change(latlon.inputname, { lat: latlon.lat, lon: latlon.lon });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card">
        <div className="card-header">Gegevens over de items</div>
        <div className="card-body">
          <div>
            <Field
              name="volgorde"
              component={renderselect}
              validate={required({ msg: "Dit veld is verplicht" })}
              options={{
                true: "Zijn de items in volgorde",
                false: "Zijn de items niet in volgorde",
              }}
              label="Zijn de items in volgorde"
            />
            <Field className="form" name="startpuntlocatie" validate={required({ msg: "Type item is verplicht" })} label="Wat is het startpunt(en) van arrangement" component={Multiselect} options={initialValues.location} dataKey="_id" textField="label" />
            <Field name="startpunt" type="input" component={switcbutton} label="Is de eerste item de startlocatie?" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Items</div>
        <div className="card-body"></div>
        <FieldArray name={`items`} component={Item} initialValues={initialValues} />
      </div>

      <FooterForm />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.arrangementen.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
