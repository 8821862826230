import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchalertprofiles } from "../Profiles/Reducers/actions";
import { Savesensor } from "../Reducers/actions";
import { fetchsensorid } from "../Instellingen/Reducers/actions";
import BedrijvenForm from "./Components/form";
import { useNavigate } from "react-router-dom";

const SensorInsert = ({ fetchalertprofiles, Savesensor, alertprofile, fetchsensorid, devices }) => {
  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
let navigate = useNavigate();

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoaded(false);
      await fetchalertprofiles();
      await fetchsensorid();
      setLoaded(true);
    };

    fetchProfiles();
  }, []);

  const submit = async (vals) => {
    try {
      const data = await Savesensor(vals);
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
      } else {
        navigate("/sensoren");
      }
    } catch (error) {
      // Foutafhandeling, indien nodig
    }
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Sensor toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded && <BedrijvenForm devices={devices} alertprofile={alertprofile} onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  sensoren: state.sensoren,
  alertprofile: state.alertprofile.items,
  devices: state.sensorinstellingen.devices
});

export default connect(mapStateToProps, { Savesensor, fetchalertprofiles, fetchsensorid })(SensorInsert);
