import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderselectapi } from "../../../../Components/Forms/renders";
import DateInput from "../../../../Components/Forms/date";
import mask from "../../../../Components/Forms/mask";
import MultiSelectData from "../../../../Components/Forms/multiselectdata";

const BedrijvenForm = ({ handleSubmit, bedrijven, initialValues, ervaringen }) => {
  const [einddatum, setEinddatum] = useState(false);
  const [openervaring, setOpenervaring] = useState(false);

  useEffect(() => {
    changerole(initialValues.role);
  }, [initialValues.role]);

  const checkrole = (val) => {
    changerole(val.target.value);
  };

  const changerole = (val) => {
    if (val === "manager" || val === "admin" || val === "hoofdrecreatie") {
      setEinddatum(false);
    } else if (val === "ervaringeigenaar") {
      setOpenervaring(true);
    } else {
      setEinddatum(true);
      setOpenervaring(false);
    }
  };

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="email" type="input" validate={[required()]} component={renderField} label="Vul het e-mailadres in" />
              <Field name="voornaam" type="input" validate={[required()]} component={renderField} label="Vul de voornaam in" />
              <Field name="achternaam" type="input" validate={[required()]} component={renderField} label="Vul de achternaam in" />
              <Field name="telefoonnummer" type="input" mask="39999999999" validate={[required()]} component={mask} label="Vul je telefoonnummer in" />
              <Field name="bedrijf" type="input" validate={[required()]} component={renderselectapi} options={bedrijven} label="Vul het bedrijf in" />
              <Field
                name="role"
                type="input"
                validate={[required()]}
                component={renderselect}
                onChange={checkrole}
                options={{
                  manager: "Manager",
                  medewerker: "Medewerker",
                  bedrijfseigenaar: "Bedrijfseigenaar",
                  ervaringeigenaar: "Ervaringseigenaar",
                }}
                label="Vul de rol van de medewerker in"
              />
              {einddatum && <Field name="einddatum" type="input" component={DateInput} label="Vul de einddatum in van het contract" />}
              {openervaring && <Field name="ervaringen" type="input" dataKey="_id" textField="naam" validate={[required()]} component={MultiSelectData} options={ervaringen} label="Vul het de ervaringen in" />}
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updates" />
          </div>
        </div>
      </form>
    </div>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "gebruikers_edit", // a unique identifier for this form
})(BedrijvenForm);

const mapStateToProps = (state) => ({
  initialValues: state.gebruikers.item,
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
