import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { saveactiviteiten } from "./Reducers/actions";
import LogboekForm from "./Components/insertform";
import { message } from "../../Reducers/loading/actions";
import { getonewebsite } from "../Website/Reducers/actions";

function LogboekInsertPage() {

  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  useEffect(() => {
    dispatch(getonewebsite(id)).then((data) => {
      if (data.results) {
        setLoad(true);
      }
    });
  }, [id, dispatch]);

  const submit = (vals) => {
    dispatch(saveactiviteiten(vals)).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        dispatch(message(2000, "success", "De activiteit is succesvol verwerkt")).then(() => {
          navigate("/activiteiten/insert/website");
        });
      }
    });
  };

  return (
    <div className="box box-default maincontent">
      <div className="box-header with-border">
        <h3 className="box-title">Activiteit toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {load && <LogboekForm onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogboekInsertPage;
