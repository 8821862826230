import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SensorWidget from "./Components/widget";
import { fetchsensoren } from "./Reducers/actions";
import Loading from "../../Components/loading/load";

function InsexSensor({ sensoren, fetchsensoren }) {
  const [load, setLoad] = useState(false);

  // Removed load from dependency array to avoid confusion and potential issues
  useEffect(() => {
    fetchsensoren().then(() => {
      setLoad(true);
    });
    // Added fetchsensoren to the dependency array as recommended
  }, [fetchsensoren]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Sensoren</h3>
      </div>
      {load && <SensorWidget sensoren={sensoren} />}
      <Loading loadings={load} />
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user, sensoren: state.sensoren.items };
}

export default connect(mapStateToProps, { fetchsensoren })(InsexSensor);
