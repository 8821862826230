import React, { useState, useEffect } from "react";
import Multiselect from "react-widgets/Multiselect";
import "react-widgets/scss/styles.scss";

const MultiSelectData = (props) => {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.input.value !== value) {
      setValue(props.input.value || []);
    }
  }, [props.input.value]);

  useEffect(() => {
    if (props.options) {
      setOptions(
        props.options.map((item) => ({
          [props.dataKey]: item[props.dataKey],
          [props.textField]: item[props.textField],
        }))
      );
    }
  }, [props.options]);

  const handleCreate = (name) => {
    setValue((prevValue) => [...prevValue, name]);
    setOptions((prevOptions) => [...prevOptions, name]);
  };

  const handleSelect = (selectedValue) => {
    setValue(selectedValue);
    props.input.onChange(selectedValue);
  };

  const customFilter = (item, searchTerm) => {
    if (!searchTerm) return true;
    const searchLower = searchTerm.toLowerCase();
    return item[props.textField].toLowerCase().includes(searchLower);
  };

  const { label, error, touched, warning } = props;
  return (
    <div>
      <label>{label}</label>
      <div>
        <Multiselect
          allowCreate={props.allowCreate ? "onFilter" : false}
          onCreate={handleCreate}
          data={options}
          dataKey={props.dataKey}
          textField={props.textField}
          onChange={handleSelect}
          value={value}
          defaultValue={[]}
          filter={customFilter}
        />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default MultiSelectData;