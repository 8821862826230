import { GET_ERVARINGEN, ADD_ERVARINGEN, ERROR_ERVARINGEN, SET_ERVARINGEN, SET_BESCHIKBAARHEID, SET_BESCHIKBAARHEIDEN, SET_ALLEDAGEN } from "./actions";
export default function ervaringen(
  state = {
    items: [],
    error: {},
    item: [],
    beschikbaarheid: {},
    beschikbaarheden: [],
    alledagen: false,
  },
  action = {}
) {
  switch (action.type) {
    case ADD_ERVARINGEN:
      return { ...state, items: [...state.items, action.results] };
    case ERROR_ERVARINGEN:
      return { ...state, error: action.payload };
    case SET_ALLEDAGEN:
      return { ...state, alledagen: action.payload };
    case GET_ERVARINGEN:
      return { ...state, items: action.results };
    case SET_ERVARINGEN:
      return { ...state, item: action.results };
    case SET_BESCHIKBAARHEID:
      return { ...state, beschikbaarheid: action.results };
    case SET_BESCHIKBAARHEIDEN:
      return { ...state, beschikbaarheden: action.results };

    default:
      return state;
  }
}
