import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchsettings, fetchcomponents, fetchPageTemplate, fetchpages } from "../../Reducers/actions";

function SubMenu({ fetchsettings, fetchpages, templates, pages, settings }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchsettings().then((res) => {
      fetchpages().then((res) => {
        setLoading(true);
      });
    });
  }, [fetchPageTemplate]);

  return (
    <div  style={{ width: "200px" }}>
      <a href="/" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
        <span className="fs-5 fw-semibold">Pagina menu</span>
      </a>
      <ul className="list-unstyled ps-0">
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#pagina-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Pagina's
          </button>
          <div className="collapse show" id="pagina-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {pages &&
                pages
                  .filter((page) => page.pageType === "pagina")
                  .map((page, index) => (
                    <li key={index}>
                      <Link to={`/cms/content/${page._id}`} className="link-dark rounded">
                        {page.naam ? page.naam.replace(/-/g, "/") : ""}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
        </li>
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#onderdelen-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Onderdelen
          </button>
          <div className="collapse show" id="onderdelen-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {pages &&
                pages
                  .filter((page) => page.pageType === "onderdeel")
                  .map((page, index) => (
                    <li key={index}>
                      <Link to={`/cms/content/${page._id}`} className="link-dark rounded">
                        {page.naam ? page.naam.replace(/-/g, "/") : ""}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return { settings: state.cms.settings, components: state.cms.components.items, pages: state.cms.pages.items, settings: state.cms.settings };
}

export default connect(mapStateToProps, {
  fetchsettings,
  fetchpages,
  fetchPageTemplate,
})(SubMenu);
