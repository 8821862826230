import moment from "moment";
import {
  SET_STANDPLAATSEN,
  ADD_STANDPLAATSEN,
  GET_ACCOMMODATIES,
  SET_ACCOMMODATIE,
  ADD_ACCOMMODATIE,
  SET_KAMPEERPLAATS,
  SET_KAMPEERPLAATSEN,
  SET_STANDPLAATS,
  STANDPLAATS_ERROR,
  SET_SOORT_SESNSOREN,
  SET_SENSOR_DAG,
  SET_SENSOR_UUR,
  SET_SENSOR_MAAND,
  SET_CATEGORIEN,
  SET_CATEGORIE,
  SET_PERIODE,
} from "./actions";
export default function standplaatsen(
  state = {
    item: [],
    items: [],
    sensoren: [],
    kampeerplaatsen: [],
    accommodaties: [],
    accommodatie: {},
    kampeerplaats: {},
    error: {},
    uur: [],
    maand: [],
    dag: [],
    categorien: [],
    categorie: {},
    periode: {
      startdate: moment().subtract(1, "months").toDate(),
      enddate: moment().toDate(),
    },
  },
  action = {}
) {
  switch (action.type) {
    case ADD_STANDPLAATSEN:
      return { ...state, items: [...state.items, action.results] };
    case SET_STANDPLAATS:
      return { ...state, item: action.results };
    case SET_KAMPEERPLAATSEN:
      return { ...state, kampeerplaatsen: action.results };
    case SET_KAMPEERPLAATS:
      return { ...state, kampeerplaats: action.results };
    case SET_STANDPLAATSEN:
      return { ...state, items: action.results };
    case SET_CATEGORIEN:
      return { ...state, categorien: action.results };
    case SET_CATEGORIE:
      return { ...state, categorie: action.results };
    case SET_SOORT_SESNSOREN:
      return { ...state, sensoren: action.results };
    case SET_SENSOR_UUR:
      return { ...state, uur: action.results };
    case SET_SENSOR_DAG:
      return { ...state, dag: action.results };
    case SET_SENSOR_MAAND:
      return { ...state, maand: action.results };
    case STANDPLAATS_ERROR:
      return { ...state, error: action.payload };
    case GET_ACCOMMODATIES:
      return { ...state, accommodaties: action.results };
    case ADD_ACCOMMODATIE:
      return { ...state, accommodaties: [...state.accommodaties, action.results] };
    case SET_ACCOMMODATIE:
      return { ...state, accommodatie: action.results };
    case SET_PERIODE:
      return { ...state, periode: action.results };
    default:
      return state;
  }
}
