import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField, renderAFieldDisabled, renderselect } from "../../../Components/Forms/renders";
import NDTRCTags from "../../../Components/Forms/ndtrctags";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import switcbutton from "../../../Components/Forms/switch";

const LogboekFormWebsite = ({ handleSubmit, dispatch }) => {
  const onChangeInput = (e) => {
    const newSlug = e.target.value
      .toLowerCase()
      .replace(/[^\w\s-]+/g, "")
      .replace(/\s+/g, "-");
    dispatch(change("arrangementen_insert", "slug", newSlug));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="box-body">
        <Field name="naam" type="input" onChange={onChangeInput} validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Titel van de arrangement" />
        <Field name="slug" type="text" component={renderAFieldDisabled} label="Slug van de titel" />
        <Field name="beschrijving.short" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderTextField} label="Korte omschrijving van de arrangement" />
        <Field name="beschrijving.long" type="textarea" component={Wysiwyg} label="Lange Omschrijving" className="form-control" normalize={(value) => value.toString("html")} />
        <Field name="beschrijving.voorwaarden" type="textarea" component={Wysiwyg} label="Voorwaarden arrangement" className="form-control" normalize={(value) => value.toString("html")} />
        <Field name="types" validate={required({ msg: "Types zijn verplicht" })} type="text" component={NDTRCTags} label="Welke tags hoort bij deze arrangement" className="form-control" />
        <Field name="actief" type="input" component={switcbutton} label="Is de arrangement actief" />
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "arrangementen_insert", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
