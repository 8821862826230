import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Autoslug} from './Reducers/items/actions';
class ItemsSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      query: ''
    }
  }

  autoslug() {
      this.props.Autoslug().then(() => {
alert("De items zijn veranderd");
      });
  }

  render() {
    return (<div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Instelingen</h3>
        <div className="box-tools pull-right">
          <Link to="/items/add" type="button" className="btn-primary btn btn-box-too btn-sm">
            <i className="fa fa-plus"></i>
            &nbsp; Items Toevoegen</Link>
        </div>
      </div>
      <div className="box-body">
        <div className="row">
          <div className="col-md-3">
            <div className="box box-primary direct-chat direct-chat-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Auto slug</h3>
              </div>

              <div className="box-body">
                Als je deze instelling gebruikt dan worden op alle items een slug toegevoegd die wordt gemaakt vanuit de titel. Je kan deze altijd veranderen.
              </div>

              <div className="box-footer">
                  <button type="button" onClick={(e) => this.autoslug()} className="btn-danger btn btn-box btn-mg">Autoslug</button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>)
  }
}
function mapStateToProps(state) {
  return {errors: state.auth.error, items: state.items.items};
}
export default connect(mapStateToProps, {Autoslug})(ItemsSetting);
