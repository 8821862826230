import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { fechduurzaamtips } from "../Reducers/actions";

const DuurzaamTipsOverzicht = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const duurzaamtips = useSelector((state) => state.gastenverbruik.tips);
  const { SearchBar } = Search;
  useEffect(() => {
    getVals();
  }, []);

  const getVals = async () => {
    const data = await dispatch(fechduurzaamtips());
    if (data.type !== "ACTIVITEIT_ERROR") {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "created",
      text: "Datum",
      formatter: (cellContent) => {
        return <span>{new Date(cellContent).toLocaleDateString()}</span>;
      },
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-primary btn-sm" to={{ pathname: "/gastenverbruik/duurzaam/show/" + row._id }}>
              Bekijken
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Duurzaam Tips Berichten</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/gastenverbruik/duurzaam/insert">
            <i className="fa fa-plus"></i>&nbsp; Duurzame Tip Toevoegen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile" />
              <ToolkitProvider
                keyField="_id"
                data={duurzaamtips}
                columns={columns}
                search={{
                  searchFormatted: true,
                }}
              >
                {(props) => (
                  <div>
                    <SearchBar placeholder="Zoeken" {...props.searchProps} />
                    <hr />
                    <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DuurzaamTipsOverzicht;
