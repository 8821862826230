export const SET_ITEMS = 'SET_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const ITEM_ERROR = 'ITEM_ERROR';
export const SET_ITEM = 'SET_ITEM'
export const ITEM_LOAD = 'ITEM_LOAD'
export const CLEAR_ITEM = 'CLEAR_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
      return response.json().then(err => {throw err;});
  }
}

export function set(items) {
  return {type: SET_ITEMS, items}
}
export function Clear() {
  return {type: CLEAR_ITEM}
}
export function setone(results) {
  return {type: SET_ITEM, results}
}
export function add(results) {
  return {type: ADD_ITEM, results}
}
export function remove(id) {
  return {type: REMOVE_ITEM, id}
}

export function update(results) {
  return {type: UPDATE_ITEM, results}
}
export function itemError(error) {
  return {type: ITEM_ERROR, error};
}

export function itemload() {
  return {type: ITEM_LOAD};
}
export function Search(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/items/search', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function Searchbytag(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/items/search/tags', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function NdtrcFind() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/ndtrc', {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (err) {
      return dispatch(itemError({ message: "Er is een fout met de NDTRC server", error: err }));
    }
  };
}

export function NdtrcInsert(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/ndtrc/save', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(setone(results.results));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het toevoegen van database'));
    }
  };
}

export function Last(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/items', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function Top(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/items/top', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}

export function Corona(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/items/corona', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(set(results.items));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    }
  };
}
export function SaveItem(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results.results;
    }
    catch (err) {
      dispatch(itemError(err));
    }
  };
}

export function UpdateItem(id, data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/' + id, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het update database'));
    }
  };
}

export function SetOnline(id, data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/online/' + id, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het update database'));
    }
  };
}

export function SetOffline(id, data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/offline/' + id, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het update database'));
    }
  };
}

export function GetItem(id) {
  return async dispatch => {
    try {
      const response = await fetch('/api/item/trcid', {
        method: 'post',
        body: JSON.stringify({ "trcid": id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const results = await handleResponse(response);
      if (results.item) {
        dispatch(setone(results.item));
      }
      return results.item;
    }
    catch (e) {
      dispatch(itemError('Er is een fout opgetreden bij het update database'));
    }
  };
}

export async function FindSlug(data) {
  // Default options are marked with *
  const response = await fetch('/api/item/slug', {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    }
  });
  return await response.json(); // parses response to JSON
}

export function Autoslug() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/autoslug', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return dispatch(setone(data.results));
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function DelItem(id) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/delete/' + id, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      dispatch(remove(id));
      return dispatch(setone({}));
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function CountItmes() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/count/all', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data;
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function CountItmesTop() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/count/top', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data;
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function CountItemsOnline() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/count/online', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data;
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function CountItemsTopOnline() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/item/count/online/top', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data;
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function Upload(data) {
  return async dispatch => {
    var formData = new FormData();
    formData.append('file', data.file);
    try {
      const response = await fetch('/api/fotoboek/upload/', {
        method: 'post',
        body: formData,
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data.images
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van de bestanden'));
    }
  };
}

export function UploadOne(data) {
  return async dispatch => {
    var formData = new FormData();
    formData.append('file', data.file);
    try {
      const response = await fetch('/api/fotoboek/uploadone/', {
        method: 'post',
        body: formData,
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      return data;
    }
    catch (err) {
      return dispatch(itemError('Er is een fout opgetreden bij het uploaden van het bestand'));
    }
  };
}

export function SetItemtoEdit(data) {
  return dispatch => {
    dispatch(setone(data));
  }
};
