import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchvacatursforbedrijven, fetchvacatursbedrijf } from "../Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import BoekingenTabel from "../Components/bedrijven_vacatures_tabel";
const VacaturesBedrijven = ({ vacatures, fetchvacatursforbedrijven, fetchvacatursbedrijf, bedrijf }) => {
  const [loaded, setLoaded] = useState(false);
  const { vitems } = useParams();
  useEffect(() => {
    fetchvacatursbedrijf(vitems).then((ddata) => {
      if (ddata.result) {
        fetchvacatursforbedrijven(vitems).then((sdata) => {
          if (sdata.results) {
            setLoaded(true);
          }
        });
      }
    });
  }, []);
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>Vacatures voor {loaded && bedrijf.promotie.title}</h2>
        </div>
        <div className="box-tools pull-right">
          <Link to={"/vacatures/bedrijven/" + vitems + "/toevoegen"} className="btn btn-primary btn-md float-right" role="group">
            Vacatures Toevoegen
          </Link>
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div className="box-body">
            <BoekingenTabel url={`/vacatures/bedrijven/${vitems}`} vacatures={vacatures} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, vacatures: state.vacatures.vacatures, bedrijf: state.vacatures.bedrijf };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  fetchvacatursforbedrijven,
  fetchvacatursbedrijf,
})(VacaturesBedrijven);
