import React, { useEffect } from "react";
import { required } from "redux-form-validators";

import { Field } from "redux-form";
import {renderField} from "../../../../../Components/Forms/renders";
import switcbutton from "../../../../../Components/Forms/switch";

const TalenForm = ({ fields }) => {


  return (
    <tbody>
      {fields.map((talen, index) => (
        <tr key={index}>
          <td>
            <Field validate={required({ msg: "Dit veld is verplicht" })} dateformat="DD-MM-YYYY" component={renderField} name={`${talen}.onderdeel`} />
          </td>
          <td>
            <Field name={`${talen}.actief`} type="input" component={switcbutton} />
          </td>
          <td>
            <button onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Pagina Toevoegen
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default TalenForm;
