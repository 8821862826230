import React from "react";
import { Routes, Route } from "react-router-dom";
import GebruikersPage from "./index";
import GebruikerEditPage from "./edit";
import GebruikerInsertPage from "./insert";

const Gebruikers = () => {
  return (
    <Routes>
      <Route path="" element={<GebruikersPage />} />
      <Route path="/edit/:id" element={<GebruikerEditPage />} />
      <Route path="/toevoegen" element={<GebruikerInsertPage />} />
    </Routes>
  );
};

export default Gebruikers;
