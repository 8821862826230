import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmationSms, authError } from '../../../Reducers/auth/actions';
import { useNavigate, useParams } from 'react-router-dom';

function SmsPage() {
  const [smsCode, setSmsCode] = useState('');
  const errorMessage = useSelector(state => state.auth.error);
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    dispatch(authError(null));
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(authError(null));
    setSmsCode(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(confirmationSms({ smscode: smsCode, token }));
    if (result && result.token) {
      localStorage.setItem('token', result.token);
      setTimeout(function () {
        window.location.replace("/");
      }, 500); // How long do you want the delay to be (in milliseconds)?
    }
  };

  const renderAlert = () => {
    if (errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {errorMessage}
        </div>
      );
    }
  };

  return (
    <div className="login-box-body">
      <p className="login-box-msg">Vul de gegevens in die je hebt ontvangen via de sms</p>
      {renderAlert()}
      <form method="post">
        <div className="form-group has-feedback">
          <input value={smsCode} onChange={handleChange} type="text" name="smscode" className="form-control" placeholder="SMS Code"/>
        </div>
        <div className="form-group has-feedback row">
          <div className="col-xs-8">
          </div>
          {/* /.col */}
          <div className="col-xs-4">
            <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-block btn-flat">Controleren</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SmsPage;
