import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getstandplaatscategorie, updatestanplaatscategorie } from '../Reducers/actions';
import EditForm from './Components/edit';
import { useParams, useNavigate } from 'react-router-dom';

const KampeerplaatsEditPage = () => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getstandplaatscategorie(params.id)).then(data => {
      setLoaded(true);
    });
  }, [dispatch, params.id]);

  const submit = vals => {
    delete vals['data'];
    dispatch(updatestanplaatscategorie(params.id, vals)).then(data => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/standplaatsen/categorien');
      }
    });
  }

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Kampeerplaats categorie wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded &&
                <EditForm onSubmit={submit} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KampeerplaatsEditPage;
