import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getervaringen } from "../Ervaringen/Reducers/actions";
import { saveboekingen, fetchboekingen } from "./Reducers/actions";
import { message, fixmessage } from "../../Reducers/loading/actions";
import BoekingenTabel from "./Components/boekingenDetails";
const BoekingenPage = ({ ervaring, fetchboekingen, boekingen }) => {
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState("Nieuw");

  useEffect(() => {
    fetchboekingen({ status: status }).then((sdata) => {
      if (sdata.results) {
        setLoaded(true);
      }
    });
  }, [fetchboekingen, status]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>{ervaring.naam}</h2>
        </div>
        <div className="box-tools pull-right">
          <Link to="/ervaringen" className="btn btn-primary btn-sm float-right" role="group">
            Overzicht ervaringen
          </Link>
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div className="box-header with-border d-flex">
            <div className="m-2">
              <button
                type="button"
                className="btn btn-info btn-sm"
                onClick={() => {
                  setStatus("Nieuw");
                }}
              >
                Nieuwe boekingen
              </button>
            </div>
            <div className="m-2">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setStatus("accept");
                }}
              >
                Alle boekingen
              </button>
            </div>
            <div className="m-2">
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => {
                  setStatus("cancel");
                }}
              >
                Geannulleerde boekingen
              </button>
            </div>
          </div>

          <div className="box-body">
            <BoekingenTabel boekingen={boekingen} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.ervaringen.item, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getervaringen,
  saveboekingen,
  fetchboekingen,
})(BoekingenPage);
