import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ComponentToPrint from "./print";
import BarComponent from "./bar";
class InschrijvingenComponent extends React.Component {
  
  deletein = (id) => {
    this.props.deleteinschrijving(id);
  };
  aanwezig = (id) => {
    this.props.setaanwezig(id);
  };
  betaald = (id) => {
    this.props.setbetaald(id);
  };
  render() {
    const { activiteit } = this.props;
    const rowStyle = (row, rowIndex) => {
   if (row.betaald && !row.aanwezig) {
    return {  backgroundColor: '#848c4530' };
   }
   if (row.aanwezig && !row.betaald) {
    return {  backgroundColor: '#848c4530' };
   }

   if (row.aanwezig && row.betaald) {
    return {  backgroundColor: '#848c4530' };
   }
  
    };

    const columns = [
      {
        dataField: "aanwezig",
        text: 'A',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '80px', textAlign: 'center' };
        },
        formatter: (cellContent, row) => {
if (cellContent) {
  return (
    <i className="fa fa-check" />
  )
}
        }
      },
      {
        dataField: "betaald",
        text: "€",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50px', textAlign: 'center' };
        },
        formatter: (cellContent, row) => {
          if (cellContent) {
            return (
              <i className="fa fa-check" />
            )
          }
                  }
      },
      {
        dataField: "gasten.naam",
        text: "Naam",
        sort: true
      },
      {
        dataField: "gasten.email",
        text: "E-mail",
      },
      {
        dataField: "personen",
        text: "Personen",
      },
      {
        text: "Actie",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span className="actionbutton">
              <button className="btn btn-danger" onClick={this.deletein.bind(this, row._id)}>
                X
              </button>
              {activiteit.betaald &&
              <button className="btn btn-info" onClick={this.betaald.bind(this, row._id)}>
                Betaald
              </button>
        }
              <button className="btn btn-success" onClick={this.aanwezig.bind(this, row._id)}>
                Aanwezig
              </button>
            </span>
          );
        },
      },
    ];

    return (
      <div className="box-header with-border">
        <h2 className="box-title"> Inschrijvingen</h2>
        <div style={{ display: "none" }}>
          <ComponentToPrint activiteit={activiteit} ref={(el) => (this.componentRef = el)} />
        </div>
        <div className="box-body box-profile inschrijving">
          <BarComponent activiteit={activiteit} />
        </div>
        <div className="box-body box-profile inschrijving">
          <ReactToPrint content={() => this.componentRef}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <button className="btn btn-info btn pull-right" onClick={handlePrint}>
                  Printen
                </button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
          <BootstrapTable wrapperClasses="table-responsive-md"
            keyField="_id"
            data={activiteit.inschrijvingen}
            defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]}
            columns={columns}
            pagination={paginationFactory({sizePerPage: 25})}
            rowStyle={ rowStyle }
          />
        </div>
      </div>
    );
  }
}
export default InschrijvingenComponent;
