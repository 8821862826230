import React from "react";
import { lang } from "../Lib/Languages/language";

export const renderField = ({ input, label, type, meta: { touched, error, warning }, disabled }) => (
  <div>
    {label && <label>{label}</label>}
    <div>
      <input {...input} className="form-control" disabled={disabled} placeholder={label} type={type} onWheel={(e) => type === "number" && e.target.blur()} />
      {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const renderAField = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input {...input} type={type} className="form-control" placeholder={label} onWheel={(e) => type === "number" && e.target.blur()} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);

export const renderAFieldDisabled = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input {...input} type={type} className="form-control" placeholder={label} disabled={true} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);
const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);
export const renderFieldUpload = ({ input: { value: omitValue, onChange, onBlur, ...inputProps }, label, type, meta: { asyncValidating, touched, error }, ...props }) => (
  <div>
    <label>{label}</label>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input {...props.input} {...props} type="file" onChange={adaptFileEventToValue(onChange)} onBlur={adaptFileEventToValue(onBlur)} value={null} className="form-control" placeholder={label} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);

export const renderTextField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <label>{label}</label>
    <div>
      <textarea {...input} className="form-control" placeholder={label} type={type} />
      {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const rendersimpleTextField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <textarea {...input} className="form-control" placeholder={label} type={type} />
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
);
export const renderselectobject = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={props.options[key]}>
        {props.options[key]}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} className="form-control">
            <option value="">{lang("Maak een keuze")}</option>
            {Object.keys(props.options).map(renderSelectOptions)}
          </select>
          {props.meta.touched && ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselect = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={key}>
        {props.options[key]}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} className="form-control">
            <option value="">{lang("Maak een keuze")}</option>
            {Object.keys(props.options).map(renderSelectOptions)}
          </select>
          {props.meta.touched && ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapi = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.naam}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} value={props.input.value} className="form-control selectpicker" data-selected-text-format="count">
            <option value="">{lang("Maak een keuze")}</option>
            {props.options.map(renderSelectOptions)}
          </select>
          {props.meta.touched && ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapiusers = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.voornaam + " " + key.achternaam}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} value={props.input.value._id} className="form-control selectpicker" data-selected-text-format="count">
            <option value="">{lang("Maak een keuze")}</option>
            {props.options.map(renderSelectOptions)}
          </select>
          {props.meta.touched && ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapititel = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.titel}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <select {...props.input} className="form-control selectpicker" data-selected-text-format="count">
        <option value="">{lang("Maak een keuze")}</option>
        {props.options.map(renderSelectOptions)}
      </select>
    );
  }
  return <div></div>;
};

export const renderselapi = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key[props.inputvalue]} value={key[props.inputvalue]}>
        {key[props.inputnaam]}
      </option>
    );
  };

  if (props && props.options && props.inputnaam && props.inputvalue) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} className="form-control selectpicker" data-live-search="true">
            <option value="">{lang("Maak een keuze")}</option>
            {props.options.map(renderSelectOptions)}
          </select>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselapisimple = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key[props.inputvalue]} value={key[props.inputvalue]}>
        {key[props.inputnaam]}
      </option>
    );
  };

  if (props && props.options && props.inputnaam && props.inputvalue) {
    return (
      <div>
        <select {...props.input} className="form-control selectpicker" data-live-search="true">
          <option value="">{lang("Maak een keuze")}</option>
          {props.options.map(renderSelectOptions)}
        </select>
      </div>
    );
  }
  return <div></div>;
};

export const renderselecttijden = (props) => {
  const renderSelectOptions = (key, index) => {
    if (key.tijd) {
      return (
        <option key={index} value={key.tijd}>
          {key.tijd}
        </option>
      );
    }
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} className="form-control selectpicker" data-selected-text-format="count">
            <option value="">{lang("Maak een keuze")}</option>
            {props.options.map(renderSelectOptions)}
          </select>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectsimple = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={key}>
        {props.options[key]}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <select {...props.input} className="form-control">
          <option value="">{props.label}</option>
          {Object.keys(props.options).map(renderSelectOptions)}
        </select>
        {props.meta.touched && ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}
      </div>
    );
  }
  return <div></div>;
};

export const renderFieldsimple = ({ input, label, type, meta: { touched, error, warning } }) => <input {...input} className="form-control" placeholder={label} type={type} />;
export const renderAFieldSimple = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
  <div>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input {...input} type={type} className="form-control" placeholder={label} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);
