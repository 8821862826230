import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Upload } from "../../Pages/Items/Reducers/items/actions";

const UploadFormGallery = ({ label, Upload, onClose, input, isModal, setUploadsImages }) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState(false);
  useEffect(() => {
    if (input.value) {
      setValue(input.value);
    }
  }, [input.value]);

  const onDrop = (file, rejectedFiles) => {
    let errors = {};
    if (rejectedFiles) {
      errors.message = "Het bestand voldoet niet aan de voorwaarde. Het is waarschijnlijk te klein";
    }
    if (file.length > 0) {
      const newFiles = files.concat(file);
      setFiles(newFiles);
      errors.message = "";
    }
    setErrors(errors);
  };

  const handleUpload = async () => {
    try {
      const result = value ? value : [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const data = await Upload({ file });
        result.push(data);
      }
      input.onChange([...result]);
      console.log(result);
      setFiles([]);
      if (isModal) {
        setUploadsImages([...result]);
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        <Dropzone onDrop={onDrop} minSize={100000}>
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                minHeight: "100px",
                borderWidth: "2px",
                borderColor: "rgb(102, 102, 102)",
                borderStyle: "dashed",
                borderRadius: "5px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                style={{
                  minHeight: "100px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p>Sleep een foto naar dit veld of klik om het</p>
              </div>
            </section>
          )}
        </Dropzone>

        <ul className="users-list clearfix">
          {files.map((f, index) => (
            <li key={f.name}>
              <button type="button" className="close" onClick={() => handleDelete(index)} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <img src={f.preview} alt="Afbeelding" />
              <span className="users-list-date">{f.name}</span>
            </li>
          ))}
        </ul>
        {errors && <div className="text-danger">{errors.message}</div>}
        <input type="button" onClick={handleUpload} className="btn btn-next btn-fill btn-success btn-wd btn-sm" value="Uploaden" />
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { Upload })(UploadFormGallery);
