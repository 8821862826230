import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getaccommodatie, getsensor_dag_acco, getsensor_maand_acco, getsensor_uur_acco } from "../Reducers/actions";
import { getsensor } from "../../Sensors/Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import moment from "moment";
import BarGraph from "./Components/graphelectra";
import { Link, useParams } from "react-router-dom";
import Tabel from "./Components/tabelelectra";
import FilterSensor from "./Components/filterelect";
const Kampeerplaats = (props) => {
  const [load, Setload] = useState(false);
  const [datum, Setdatum] = useState({ startdate: moment().subtract(14, "days").toDate(), enddate: new Date() });
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
  });
  const [datum_month] = useState({ startdate: moment().subtract(12, "months").toDate(), enddate: new Date() });
  const [datum_day, Setdatum_day] = useState({ startdate: moment().subtract(14, "days").toDate(), enddate: new Date() });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [stand, setStand] = useState(0);
  const [standdag, setStandDag] = useState(0);
  const [meterSoort, setMeterSoort] = useState("");

  const [max, Setmax] = useState(0);
  const { id, sensor_id } = useParams();

  useEffect(() => {
    getvals()
      .then(() => getvals_dag())
      .then(() => getsensor())
      .then(() => getvals_maand())
      .then(() => getvals_uur())
      .then(() => Setload(true))
      .catch((error) => props.message(5000, "warning", error));
  }, [datum, datum_hour, datum_day]);

  function changedata(data) {
    if (data.datum.enddate && data.datum.startdate) {
      const timer = setTimeout(() => {
        Setdatum_day(data.datum);
      }, 1000);
    }
  }

  function getvals() {
    return new Promise((resolve, reject) => {
      props.getaccommodatie(id, datum).then((data) => {
        if (data.results) {
          const soort = getMeterInfoBySensorId(sensor_id, data.results);
          if (soort) {
            setMeterSoort(soort);
          }
          resolve();
        } else {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        }
      });
    });
  }

  function getsensor() {
    return new Promise((resolve, reject) => {
      props.getsensor(sensor_id).then((data) => {
        if (data.results) {
          resolve();
        } else {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        }
      });
    });
  }

  function getvals_dag() {
    return new Promise((resolve, reject) => {
      props.getsensor_dag_acco(sensor_id, datum_day).then((data) => {
        if (!data.results) {
          reject("Er is een fout opgetreden bij het laden van de dag data");
        } else {
          Setgemiddelde(data.results.map((element) => element.value).reduce((a, b) => a + b, 0) / data.results.length);
          Setmax(Math.max(...data.results.map((o) => o.value)));
          setStandDag(data.results.reduce((acc, curr) => acc + curr.value, 0).toFixed(2));
          resolve();
        }
      });
    });
  }

  function getvals_maand() {
    return new Promise((resolve, reject) => {
      props.getsensor_maand_acco(sensor_id, datum_month).then((data) => {
        if (!data.results) {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          resolve();
        }
      });
    });
  }

  function getvals_uur() {
    return new Promise((resolve, reject) => {
      props.getsensor_uur_acco(sensor_id, datum_hour).then((data) => {
        if (!data.results) {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          if (data.results && data.results[0]) setStand(data.results[0].value.toFixed(2));
          resolve();
        }
      });
    });
  }

  function getKwh(meter, data) {
    const kWh = data && data.length > 1 ? (data[0].value - data[1].value).toFixed(2) : (0).toFixed(2);
    switch (meter) {
      case "water":
        return kWh + " Kub";
      case "gas":
        return kWh + " Kub";
      case "electra":
        return kWh + " kWh";
      default:
        return null;
    }
  }

  function luodate(data) {
    if (data && data[0] && data[0].datum) {
      return moment(data[0].datum).add(2, "hours").fromNow();
    } else {
      return null;
    }
  }
  const getMeterInfoBySensorId = (sensorId, data) => {
    const meters = data.meters;
    if (!meters) {
      return null;
    }
    for (let meter of meters) {
      if (meter.sensor_id === sensorId) {
        return meter.soort;
      }
    }
    return null; // Als de sensor_id niet gevonden wordt
  };

  const { kampeerplaats, sensor } = props;
  const getMeterIcon = (meter, size) => {
    switch (meter) {
      case "water":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-droplet"></i>;
      case "gas":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-fire"></i>;
      case "electra":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-bolt"></i>;
      default:
        return null;
    }
  };

  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title">
          {getMeterIcon(meterSoort)} {kampeerplaats.accommodatie.naam}
        </h3>
        <div className="box-tools pull-right">
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/standplaatsen/accommodaties/edit/" + kampeerplaats.accommodatie._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor sensor={sensor} changedata={changedata} verbruik={standdag} gemiddelde={gemiddelde} max={max} stand={stand} meterSoort={meterSoort} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row p-0">
              <div className="col-12 col-md-4 col-lg-3 p-0">
                <div className="p-4">
                  <h3>Laatste meting</h3>
                  <div> {getMeterIcon(meterSoort, 15)} </div>
                  <span className="valuekwh"> {getKwh(meterSoort, kampeerplaats.uur)} </span>

                  <div className="details">Laatste meting {luodate(kampeerplaats.uur)}</div>
                </div>
              </div>
              <div className="col-12 col-md-10 col-lg-9 p-0">
                <h3 className="p-4">
                  Gegevens metingen van {moment(datum_day.startdate).format("D MMMM")} tot {moment(datum_day.enddate).format("D MMMM")}{" "}
                </h3>
                <Tabel meterSoort={meterSoort} data={kampeerplaats.dag} sensor={sensor}  />
              </div>
              <div className="col-12">
                <BarGraph sensor={kampeerplaats.dag} kleur="#B1BF41" type="dag" />
              </div>

              <div className="col-12 col-md-12 col-lg-12 p-0">
                <div className="box-titel">
                  <h3 className="p-4">Metingen 24 uur</h3>
                </div>

                <BarGraph sensor={kampeerplaats.uur} kleur="#D96A29" type="uur" />
              </div>
              <div className="col-12 col-md-12 col-lg-12 p-0">
                <h3 className="p-4">Metingen per maand</h3>
                <BarGraph sensor={kampeerplaats.maand} kleur="#D96A29" type="maand" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren, kampeerplaats: state.standplaatsen,  sensor: state.sensoren.item };
}

export default connect(mapStateToProps, { getaccommodatie, message, getsensor_dag_acco, getsensor_uur_acco, getsensor_maand_acco, getsensor })(Kampeerplaats);
