import React, { useState, useEffect } from "react";
import { reduxForm, FieldArray, eduxForm } from "redux-form";
import { connect } from "react-redux";
import FooterForm from "../../../Components/Lib/FooterForm";
import Waaritems from "./waaritems";

const SyncValidationForm = ({ handleSubmit, initialValues, change }) => {
  const ontvanggegevs = (addressresult, adress, latlon, inputname) => {
    const index = inputname.match(/\d+/) ? parseInt(inputname.match(/\d+/)[0], 10) : null;
    change(`location[${index}]`, {
      label: addressresult,
      street: adress.route,
      housenr: adress.street_number,
      zipcode: adress.postal_code,
      city: adress.locality,
      country: adress.country,
    });

    // Bijwerken van het 'coordinates[0]' veld
    change(`location[${index}].coordinates[0]`, {
      lat: latlon.lat,
      lon: latlon.lng,
    });
    //setSelectedPlace((prevPlaces) => Object.assign([...prevPlaces], { [index]: { lat: latlon.lat, lon: latlon.lng } }));
  };

  const replacemap = (latlon) => {
    console.log(latlon);
    change(latlon.inputname, { lat: latlon.lat, lon: latlon.lon });
   };


  return (
    <form onSubmit={handleSubmit}>
      <FieldArray name={`location`} component={Waaritems} ontvanggegevs={ontvanggegevs} replacemap={replacemap} />
      <FooterForm />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.arrangementen.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
