import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../../Components/Forms/renders";


class AlertProfileEditform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-6">
                <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam connectie" />
                <Field
                  className="form"
                  name="soort"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Kies een soort connections "
                  component={renderselect}
                  options={{
                    Instagram: "Instagram",
                    Facebook: "Facebook",
                    Web: "web",
                    GoogleBusiness: "Google Business",
                  }}
                />
                <Field name="client_id" type="input" component={renderField} label="Client ID" />
                <Field name="client_secret" type="input" component={renderField} label="Client Secret" />
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
AlertProfileEditform = reduxForm({
  form: "sensoredit", // a unique identifier for this form
})(AlertProfileEditform);

// You have to connect() to any reducers that you wish to connect to yourself
AlertProfileEditform = connect(
  (state) => ({
    initialValues: state.connections.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(AlertProfileEditform);

export default AlertProfileEditform;
