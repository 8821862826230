import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getvacaturesbybedrijf, getvacatures } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import BoekingenTabel from "../Components/vacatures_bedrijf_tabel";
const VacaturesBedrijven = ({ bedrijven, getvacaturesbybedrijf, getvacatures, vacature }) => {
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getvacatures(id).then((data) => {
      if (data.results) {
        getvacaturesbybedrijf(id).then((sdata) => {
          if (sdata.results) {
            setLoaded(true);
          }
        });
      }
    });
  }, []);
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <h2>Bedrijven met de vacature: {loaded && vacature.titel} </h2>
        </div>
        <div className="box-tools pull-right">
          <Link to="/vacatures/bedrijven/toevoegen" className="btn btn-primary btn-md float-right" role="group">
            Vacatures Toevoegen
          </Link>
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div className="box-body">
            <BoekingenTabel bedrijven={bedrijven} />
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, bedrijven: state.vacatures.bedrijven, vacature: state.vacatures.item };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getvacaturesbybedrijf,
  getvacatures,
})(VacaturesBedrijven);
