import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default class Tabel extends Component {
  render() {
    const { sensors, kleur } = this.props;

    function kiesmeter(sensor) {
      if (sensor.soort === "electra") {
        return { naam: "kWh", waarde: 1 };
      } else if (sensor.soort === "water") {
        return { naam: "m³", waarde: 1000 };
      } else if (sensor.soort === "gas") {
        return { naam: "m³", waarde: 100 };
      } else {
        return { naam: "kWh", waarde: 1 };
      }
    }

    function maakkollommen(data) {
      const columns = [
        {
          dataField: "datum",
          text: "Datum",
          footer: "Totaal",
        },
      ];

      data.forEach((sensor) => {
        columns.push({
          dataField: sensor.naam,
          text: sensor.naam,
          formatter: (cell) => (cell ? cell.toFixed(3) : "0.000"),
          footer: (columnData) => columnData.reduce((acc, item) => acc + (parseFloat(item) || 0), 0).toFixed(3),
  
        });
      });

      return columns;
    }

    function grdata(data) {
      // Create a map to store data by date
      const dateMap = new Map();

      data.forEach((sensor) => {
        const soortmeter = kiesmeter(sensor);
        sensor.data.forEach((item, index) => {
          if (index > 1 && sensor.data[index - 1].datum !== item.datum) {
            const date = moment(item.datum).format("DD-MM-YYYY HH:mm");
            if (!dateMap.has(date)) {
              dateMap.set(date, { datum: date });
            }
            if (sensor.soort === "ampere") {

              dateMap.get(date)[sensor.naam] = Number(item.value) / soortmeter.waarde;
            } else {
              dateMap.get(date)[sensor.naam]  = Number(item.value - sensor.data[index - 1].value) / soortmeter.waarde;
            }
          }
        });
      });

      const rows = Array.from(dateMap.values());

      return rows;
    }

    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="datum" data={grdata(sensors)} columns={maakkollommen(sensors)} pagination={paginationFactory()} />;
  }
}
