import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
  render() {
    const { sensor } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY HH:mm");
    }
    function getdata(data) {
      const transformed = data.data
        .map((value, key) => {
          if (key > 0) {
            return {
              value: Number((data.data[key - 1].value - value.value) / 1000),
              orgvalue: data.data[key - 1].value / 1000,
              datum: data.data[key - 1].datum,
            };
          } else {
            return {};
          }
        })
        .slice(1);

      return transformed;
    }

    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "value",
        text: "Kub",
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (sensor.maxvalue <= (((cellContent / 1000) * 690) / 1000).toFixed(3)) {
              return <span className="badge badge-danger">{cellContent.toFixed(3)}</span>;
            } else {
              return <span className="badge badge-success">{cellContent.toFixed(3)}</span>;
            }
          } else {
            return null;
          }
        },
      },
      {
        dataField: "orgvalue",
        text: "Meterstand",
        formatter: (cellContent, row) => {
          return cellContent.toFixed(3);
        },
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="datum" data={getdata(sensor)} columns={columns} pagination={paginationFactory()} />;
  }
}
