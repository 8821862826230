import { GET_VACATURES, ADD_VACATURES, ERROR_VACATURES, SET_VACATURES, GET_VACATURES_BEDRIJVEN, SET_VACATURES_BEDRIJVEN, SET_VACATURES_BEDRIJVEN_VACATURES, GET_VACATURES_BEDRIJVEN_VACATURES } from "./actions";
export default function vacatures(
  state = {
    items: [],
    error: {},
    bedrijven: [],
    bedrijf: {},
    item: [],
    vacatures: [],
    vacature: {},
  },
  action = {}
) {
  switch (action.type) {
    case ADD_VACATURES:
      return { ...state, items: [...state.items, action.results] };
    case ERROR_VACATURES:
      return { ...state, error: action.payload };
    case GET_VACATURES:
      return { ...state, items: action.results };
    case SET_VACATURES:
      return { ...state, item: action.results };
    case GET_VACATURES_BEDRIJVEN:
      return { ...state, bedrijven: action.results };
    case SET_VACATURES_BEDRIJVEN:
      return { ...state, bedrijf: action.results };
    case GET_VACATURES_BEDRIJVEN_VACATURES:
      return { ...state, vacatures: action.results };
    case SET_VACATURES_BEDRIJVEN_VACATURES:
      return { ...state, vacature: action.results };

    default:
      return state;
  }
}
