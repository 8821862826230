import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

export default function HTMLTypes({sourceItems}) {

  return (
      <div className="box htmlelements">
        <div className="box-header with-border">
          <h3 className="box-title">Bronlijst</h3>
        </div>
        <div className="box-body">
          <Droppable droppableId="source" type="ITEM" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sourceItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="m-2 card p-2">
                        <div className="row">
                          <div className="col-2">
                            <div className="icon-container">
                              <i className={item.icon}></i>
                            </div>
                          </div>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-12">
                                <strong>{item.content}</strong>
                              </div>
                              <div className="col-12">{item.desc}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
    
    </div>
  );
}
