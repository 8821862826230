import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import FooterForm from "../../../Components/Lib/FooterForm";
import MapsForm from "../../../Components/Forms/maps";
import AdressForm from "../../../Components/Forms/Adress";
import { renderField } from "../../../Components/Forms/renders";

const SyncValidationForm = ({ handleSubmit, initialValues, change }) => {
  const [selectedPlace, setSelectedPlace] = useState({ lat: 52.347809, lon: 5.98399 });

  useEffect(() => {
    if (initialValues && initialValues.location) {
      setSelectedPlace({ lat: initialValues.location.lat, lon: initialValues.location.lon });
    }
  }, []);
  const ontvanggegevs = (addressresult, adress, latlon) => {
    setSelectedPlace({ lat: latlon.lat, lon: latlon.lng });
    change("location", { label: addressresult, street: adress.route, housenr: adress.street_number, zipcode: adress.postal_code, city: adress.locality, country: adress.country, lat: latlon.lat, lon: latlon.lng });
  };

  const replacemap = (latlon) => {
    change("location.lat", latlon.lat);
    change("location.lon", latlon.lon);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <h4 className="info-text">Zoek hier de gegevens in van het adres</h4>

          <Field name="location" ontvanggegevs={ontvanggegevs} component={AdressForm} label="Vind het adres" className="form-control" />
          <Field name="location.label" type="input" component={renderField} label="Titel" />
          <Field name="location.street" type="input" component={renderField} label="Straat" validate={required({ msg: "Dit veld is verplicht" })} />
          <Field name="location.housenr" type="input" component={renderField} label="Huisnummer" />
          <Field name="location.zipcode" type="input" component={renderField} label="Postcode" />
          <Field name="location.city" type="input" component={renderField} label="Plaats" validate={required({ msg: "Dit veld is verplicht" })} />
          <Field name="location.country" type="input" component={renderField} label="Land" validate={required({ msg: "Dit veld is verplicht" })} />
          <Field label="Latitude" name="location.lat" type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
          <Field label="Longitude" name="location.lon" type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
        </div>
        <div className="col-sm-6">
          <h4 className="info-text">Zoek op de kaart het adres</h4>
          <div style={{ height: "60vh" }}>
            <Field name="location" replacemap={replacemap} newlatlon={selectedPlace} component={MapsForm} getdata={selectedPlace} label="Vind het adres" className="form-control" />
          </div>
        </div>
      </div>
      <FooterForm />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.items.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
