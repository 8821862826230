import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SimpleForm from './form/add';
import { Save } from '../Reducers/arrangementen/actions';
import ErrorMessage from '../../../Components/loading/error';

const ArrangementenAddPage = ({ item, error }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

 

  const submit = values => {
    dispatch(Save(values)).then(() => {
      navigate('/items/arrangementen');
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Nieuw Arrangementen</h3>
      </div>
      <div className="box-body">
        <ErrorMessage fout={error} />
        <SimpleForm onSubmit={submit} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { errors: state.auth.error, item: state.items.item, error: state.items.error };
}

export default connect(mapStateToProps, { Save })(ArrangementenAddPage);
