import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updategastengebruik, fetchgastenverbruikone, addgasten, deletegastverbruik } from "./Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import { fetchaccommodaties, fetchkampeerplaatsen } from "../Standplaatsen/Reducers/actions";
import Rodal from "rodal";
import Insertgast from "./Components/insertgast";
import InsertForm from "./Components/edit";

const KampeerplaatsEditPage = ({ updategastengebruik, kampeerplaatsen, fetchkampeerplaatsen, fetchgastenverbruikone, gast, fetchaccommodaties, accommodaties, bedrijf, addgasten, deletegastverbruik }) => {
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [gastid, setGastId] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const submit = (vals) => {
    delete vals["data"];
    updategastengebruik(params.id, vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/gastenverbruik/");
      }
    });
  };
  const OpenmMdal = (val) => {
    setVisible(val);
  };

  const submitgast = (vals) => {
    addgasten(vals).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert(data.error.data.global);
        return false;
      } else {
        setGastId(data.results.result);
        setVisible(false);
      }
    });
  };

  const delgastverbruik = () => {
    deletegastverbruik(params.id).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert(data.error.data.global);
        return false;
      } else {
        navigate("/gastenverbruik/");
      }
    });
  };

  useEffect(() => {
    fetchgastenverbruikone(params.id).then((data) => {
      fetchkampeerplaatsen().then(() => {
        fetchaccommodaties().then(() => {
          setLoaded(true);
        });
      });
    });
  }, [loaded]);
  if (!loaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Reservering van wijzigen</h3>
          <div className="box-tools pull-right">
            <button type="button" className="btn-warning btn btn-box-too btn-sm" onClick={delgastverbruik}>
              <i className="fa fa-trash"></i>&nbsp; Verwijderen
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <InsertForm onSubmit={submit} standplaatsen={kampeerplaatsen} bedrijf={bedrijf} gast={gastid} accommodaties={accommodaties} OpenmMdal={OpenmMdal} />
              </div>
            </div>
          </div>
        </div>
        <Rodal
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          className="rodal-md"
        >
          <div className="box box-default">
            <div className="row">
              <div className="col-md-12  col-sm-12">
                <div className="box-title">
                  <h3>Maak een nieuwe gast aan</h3>
                </div>

                <div className="box-body">
                  <Insertgast onSubmit={submitgast} />
                </div>
              </div>
            </div>
          </div>
        </Rodal>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  username: state.auth.user,
  kampeerplaatsen: state.standplaatsen.kampeerplaatsen,
  accommodaties: state.standplaatsen.accommodaties,
  gast: state.gastenverbruik.gast,
  gastverbruik: state.gastenverbruik.gastverbruik,
  bedrijf: state.auth.user.bedrijf,
});

export default connect(mapStateToProps, { updategastengebruik, fetchkampeerplaatsen, fetchaccommodaties, fetchgastenverbruikone, addgasten, deletegastverbruik })(KampeerplaatsEditPage);
