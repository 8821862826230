import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updatehoreca, gethoreca, delhoreca } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import LogboekEditForm from "./Components/edit";

function HorecaEditPage({ gethoreca, updatehoreca, delhoreca, message }) {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    gethoreca(id).then((data) => {
      if (data.results) {
        // Do something
      } else {
        message(2000, "warning", "Er is een fout opgetreden bij het laden van de horeca gelegenheid").then((data) => {
          navigate("/horeca");
        });
      }
    });
  }, [gethoreca, id, message, navigate]);

  const submit = (vals) => {
    updatehoreca(id, vals).then((data) => {
      if (data.type === "HORECA_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De horeca gelegenheid is met succes aangepast").then((data) => {
          navigate("/horeca");
        });
      }
    });
  };

  const verwijderen = () => {
    delhoreca(id).then((data) => {
      if (data.type === "HORECA_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De horeca gelegenheid is met succes verwijderd").then((data) => {
          navigate("/horeca");
        });
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Horeca wijzigen</h3>
        <div className="pull-right">
          <button onClick={verwijderen} className="btn btn-next btn-fill btn-danger btn-wd btn-sm">
            Verwijderen
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <LogboekEditForm onSubmit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { updatehoreca, gethoreca, message, delhoreca })(HorecaEditPage);
