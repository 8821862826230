import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateervaringen, getervaringen } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import LogboekEditForm from "../Components/communicatie";
import { Link, useParams, useNavigate } from "react-router-dom";
const ErvaringenEditESPage = ({ updateervaringen, getervaringen, message }) => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setLoaded(false);
    getervaringen(params.id).then((data) => {
      if (data.results) {
        setLoaded(true);
      } else {
        message(2000, "warning", "Er is een fout opgetreden bij het laden van de activiteit").then((data) => {
          navigate("/logboek");
        });
      }
    });
  }, [getervaringen, params.id, message]);

  const submit = (vals) => {
    updateervaringen(params.id, vals).then((data) => {
      if (data.type === "FACILIEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De ervaring is met succes aangepast").then((data) => {
          navigate("/ervaringen/edit/" + params.id);
        });
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Ervaring wijzigen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body wizard">
              <ul className="nav nav-justified thumbnail setup-panel">
                <li className="flex-item">
                  <Link to={"/ervaringen/edit/" + params.id}>Wat</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/ervaringen/edit/waar/" + params.id}>Waar</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/ervaringen/edit/media/" + params.id}>Media</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/ervaringen/edit/beschikbaarheid/" + params.id}>Beschikbaarheid</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/ervaringen/edit/eigenschappen/" + params.id}>Eigenschappen</Link>
                </li>
                <li className="active flex-item">
                  <Link to={"/ervaringen/edit/communicatie/" + params.id}>Communicatie</Link>
                </li>
              </ul>
              {loaded && <LogboekEditForm onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, ervaringen: state.ervaringen.item };
}

export default connect(mapStateToProps, {
  updateervaringen,
  getervaringen,
  message,
})(ErvaringenEditESPage);
