import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { editbiepactiviteit, getbiepactiviteit } from "./Reducers/actions";
import StartForm from "./Components/edit/insertform";
import OmschrijvingForm from "./Components/edit/insertact";
import { fixmessage, message } from "../../Reducers/loading/actions";
import { Bar } from "./Components/bar";

function BieppEdit() {
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const [step, setStep] = useState("0");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const biepp = useSelector(state => state.biepp.item);

  useEffect(() => {
    dispatch(getbiepactiviteit(params.id)).then((data) => {
      if (data.results) {
        setStep(params.step || "0");
        setLoad(true);
      } else {
        dispatch(fixmessage("warning", "Er is een fout opgetreden bij het laden van de activiteit"));
      }
    });
  }, [params.id, params.step, dispatch]);

  const submit = (vals) => {
    dispatch(editbiepactiviteit(params.id, vals)).then((data) => {
      if (data.type === "BIEPP_ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        dispatch(message(2000, "success", "De activiteit is succesvol verwerkt")).then(() => {
          navigate("/biepp/edit/" + params.id + "/1");
        });
      }
    });
  };

  const selectForm = (currentStep) => {
    switch (currentStep) {
      case "0":
        return <StartForm onSubmit={submit} />;
      case "1":
        return <OmschrijvingForm onSubmit={submit} />;
      default:
        return null;
    }
  };

  return (
    <div className="box box-default maincontent">
      <div className="box-header with-border">
        <h3 className="box-title"> Activiteit bewerken </h3>
      </div>
      <div className="wizard">
        <Bar step={step} id={params.id} />
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {load && selectForm(step)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BieppEdit;
