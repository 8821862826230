import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { getactiviteit, maakinschrijving, delinschrijving, updateinschrijving } from "./Reducers/actions";
import Inschrijving from "./Components/inschrijvingen";
import { translate } from "../../Components/Lib/Languages/language";
import InschrijfForm from "./Components/inschrijven";
import { connect } from "react-redux";

function ProfilePage(props) {
  const [inschrijven, setInschrijven] = useState(false);
  const activiteiten = useSelector(state => state.activiteiten.item);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    loadactiviteit();
    // eslint-disable-next-line
  }, []);

  const loadactiviteit = () => {
    dispatch(getactiviteit(params.id)).then((data) => {
      if (data.results) {
      }
    });
  };

  const deleteinschrijving = (id) => {
    dispatch(delinschrijving(id)).then(() => {
      loadactiviteit();
    });
  };

  const setbetaald = (id) => {
    dispatch(updateinschrijving(id, { betaald: true })).then(() => {
      loadactiviteit();
    });
  };

  const setaanwezig = (id) => {
    dispatch(updateinschrijving(id, { aanwezig: true })).then(() => {
      loadactiviteit();
    });
  };

  const submit = (vals) => {
    const values = { ...vals, activiteit: params.id, datumactiviteit: activiteiten.datum };
    dispatch(maakinschrijving(values)).then(() => {
      loadactiviteit();
      setInschrijven(false);
    });
  };

  const showrrser = () => {
    setInschrijven(true);
  };

  function datum(cell, row) {
    return moment(cell).format("DD-MM-YYYY");
  }

  return (
    <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-title">
            <h2>{translate(activiteiten.activiteit)}</h2>
            {activiteiten.betaald  &&
            <span className="badge badge-info">Deze activiteit is betaald</span>
  }
          </div>
          <Link to="/activiteiten" className="btn btn-primary btn-sm float-right" role="group">
            Overzicht
          </Link>
        </div>

        <div className="box-body box-profile">{translate(activiteiten.beschrijving)}</div>
    
        <div className="box-body box-profile">
          Datum:{" "}
          <span className="geboortedatum">
            {datum(activiteiten.datum)} {activiteiten.tijd}
          </span>
  
          <div className="block">    <button className="btn btn-success btn pull-right" onClick={showrrser}>
            Maak reserveringen
          </button>
          </div>
          <div>
          </div>
        </div>

        {inschrijven && (
          <div className="inschrijform">
            <InschrijfForm onSubmit={submit} />
          </div>
        )}
        {activiteiten.inschrijving && <Inschrijving setbetaald={setbetaald} setaanwezig={setaanwezig} deleteinschrijving={deleteinschrijving} activiteit={activiteiten} />}
      </div>
    );
  }

function mapStateToProps(state) {
  return {
    activiteiten: state.activiteiten.item,
  };
}

export default connect(mapStateToProps, {
  getactiviteit,
  maakinschrijving,
  delinschrijving,
  updateinschrijving
})(ProfilePage);
