import React from "react";
import { connect } from "react-redux";
import { fetchfaciliteiten } from "../Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { message } from "../../../Reducers/loading/actions";
class InschrijvingPage extends React.Component {
  state = {
    errors: {},
    loading: false,
  };

  componentWillMount() {
    this.getvals({});
    //this.getcount({});
  }

  getvals = () => {
    this.props.fetchfaciliteiten().then((data) => {
      if (data.type !== "ACTIVITEIT_ERROR") {
        this.setState({ loading: false });
      }
    });
  };

  handleItem(item) {
    this.props.Verwerken({ id: item._id }).then((datav) => {
      this.getvals({});
    });
  }

  render() {
    const columns = [
      {
        dataField: "naam",
        text: "Naam faciliteit",
        formatter: (cellContent, row) => {
          if (cellContent.NL) {
            return cellContent.NL;
          } else {
            return cellContent;
          }
        },
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
              <Link className="btn btn-info" to={{ pathname: "/faciliteiten/reserveringen/" + row._id }}>
                Reserveringen
              </Link>
            </span>
          );
        },
      },
    ];
    const { faciliteiten } = this.props;
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={faciliteiten} columns={columns} pagination={paginationFactory()} />;
  }
}

function mapStateToProps(state) {
  return {
    username: state.auth.user,
    faciliteiten: state.faciliteiten.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fetchfaciliteiten,
})(InschrijvingPage);
