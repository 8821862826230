import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const withAuthentication = (ComposedComponent) => {
  const Authentication = (props) => {
    const token = useSelector(state => state.auth.token);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
      logout();
      // You can add more logic here similar to checkpage
    }, [token, dispatch, params.id, params.soort, navigate]);

    const logout = () => {
      if (token) {
        localStorage.removeItem("gast");
        navigate("/public/" + params.id, { state: { from: "/public/" + params.soort + "/" + params.id } });
      }
    };

    return <ComposedComponent {...props} />;
  };

  return Authentication;
};

export default withAuthentication;
