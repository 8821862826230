import React, { useEffect, useState } from "react";
import moment from "moment";
import Rodal from "rodal";
import { connect } from "react-redux";
import Uitcheckform from "./uitcheckform";
import { updategastengebruik } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import Invoice from "./invoice";

const Uitchecken = ({ match, openmodal, gast, updategastengebruik, stand, closemodal, message }) => {
  const [visible, setVisible] = useState(false);
  const [verblijftijd, setVerblijftijd] = useState(0);
  const [invoiceData, setInvoiceData] = useState({}); // State voor invoiceData
  useEffect(() => {
    setVisible(openmodal);
    berekenverblijdtijd();
  }, [openmodal, gast]);
  useEffect(() => {
    // Update invoiceData wanneer 'gast' of andere afhankelijke waarden veranderen
    const nieuweInvoiceData = {
      customerName: getFullName(gast.gast),
      kampeerplaats: gast.standplaats.naam,
      arrivalDate: moment(gast.aankomst).format("D MMMM: HH:mm"),
      departureDate: moment(gast.vertrek).format("D MMMM: HH:mm"),
      usage: stand,
      advanceKwH: (verblijftijd * Number(gast.standplaats.categorie.voorschot / 24)).toFixed(2),
      unitPrice: gast.standplaats.categorie.prijs.toFixed(2),
      verrekengebruik: (stand - verblijftijd * Number(gast.standplaats.categorie.voorschot / 24)).toFixed(2),
      balance: ((stand - (verblijftijd * Number(gast.standplaats.categorie.voorschot / 24)).toFixed(2)) * gast.standplaats.categorie.prijs).toFixed(2),
    };
    setInvoiceData(nieuweInvoiceData);
  }, [gast, stand, verblijftijd]); // Voeg afhankelijkheden toe

  const aanpassen = (vals) => {
    delete vals["data"];
    updategastengebruik(gast._id, vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De reservering is succesvol aangepast")
      }
    });
  };

  const uitchecken = () => {
    const vals = {
      invoiceData: invoiceData,
      status: "checkout",
    };
    updategastengebruik(gast._id, vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De reservering is succesvol uitgecheckt")
        closemodal();
      }
    });
  };

  const getFullName = (gast) => {
    let fullName = gast.voornaam;
    if (gast.tussennaam) {
      fullName += ` ${gast.tussennaam}`;
    }
    fullName += ` ${gast.achternaam}`;
    return fullName;
  };

  const berekenverblijdtijd = () => {
    if (moment(gast.vertrek).isBefore(moment())) {
      setVerblijftijd(moment.duration(moment(gast.vertrek).diff(moment(gast.aankomst))).asHours());
    } else {
      setVerblijftijd(moment.duration(moment(new Date()).diff(moment(gast.aankomst))).asHours());
    }
  };
  return (
    <Rodal
      visible={visible}
      onClose={() => {
        setVisible(false);
        closemodal();
      }}
      className="rodal-lg"
    >
      <div className="card">
        <div className="card-header">Uitchecken</div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6  col-sm-6">
              <div className="card-title">
                <h3>Gegevens</h3>
              </div>
              <Uitcheckform gast={gast} onSubmit={aanpassen} />
            </div>
            <div className="col-md-6  col-sm-6">
              <div className="card-title">
                <h3>Overzicht</h3>
              </div>
              <Invoice invoiceData={invoiceData} />
            </div>
          </div>
        </div>
        <div className="card-footer text-muted d-flex justify-content-end p-4">
          <button className="btn btn-warning btn-lg" onClick={() => uitchecken()}>
            Uitchecken
          </button>
        </div>
      </div>
    </Rodal>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updategastengebruik, message })(Uitchecken);
