export const SET_CONNECTIONS = "SET_CONNECTIONS";
export const ADD_CONNECTIONS = "ADD_CONNECTIONS";
export const CONNECTION_ERROR = "CONNECTION_ERROR";
export const SET_CONNECTION = "SET_CONNECTION";
export const SET_CONNECTION_BEDRIJF = "SET_CONNECTION_BEDRIJF";
export const GET_CONNECTIONS_POSTS = "GET_CONNECTIONS_POSTS";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_CONNECTIONS,
    results,
  };
}
export function setpost(results) {
  return {
    type: GET_CONNECTIONS_POSTS,
    results,
  };
}
export function setone(results) {
  return {
    type: SET_CONNECTION,
    results,
  };
}
export function setbedrijf(results) {
  return {
    type: SET_CONNECTION_BEDRIJF,
    results,
  };
}
export function add(results) {
  return {
    type: ADD_CONNECTIONS,
    results,
  };
}
export function itemError(error) {
  return {
    type: CONNECTION_ERROR,
    payload: error,
  };
}

export function Saveconnections(data) {
  return (dispatch) => {
    return fetch("/api/admin/connections", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(add(results));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function editconnections(id, data) {
  return (dispatch) => {
    return fetch("/api/admin/connections/" + id, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setone(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchconnections() {
  return (dispatch) => {
    return fetch("/api/admin/connections/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(set(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getconnections(id) {
  return (dispatch) => {
    return fetch("/api/admin/connections/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setone(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getAccessTokenInstagram(data) {
  return (dispatch) => {
    return fetch("/api/admin/connections/callback/instagram", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(add(results));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getinstapost(id) {
  return (dispatch) => {
    return fetch("/api/admin/connections/instagram/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setpost(results));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getlocations(id) {
  return (dispatch) => {
    return fetch("/api/admin/connections/googlebusiness/getlocations/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setbedrijf(results));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getgooglebedrijvenrefreshtoken(id) {
  return (dispatch) => {
    return fetch("/api/admin/connections/googlebusiness/getgooglebedrijvenrefreshtoken/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setone(results));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
export function getgooglebedrijvenrposts(id) {
  return (dispatch) => {
    return fetch("/api/admin/connections/googlebusiness/getgooglebedrijvenrposts/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setpost(results.results.localPosts));
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getgooglebedrijvenrpost(id, data) {
  return (dispatch) => {
    return fetch("/api/admin/connections/googlebusiness/getgooglebedrijvenrposts/" + id, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then(handleResponse)
      .then((results) => {
        return results
      })
      .catch((err) => {
        console.log(err);
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
