import React from "react";
import { Routes, Route } from "react-router-dom";
import Faciliteiten from "./index";
import FaciliteitenInsert from "./insert";
import FaciliteitenEdit from "./edit";
import FaciliteitPage from "./faciliteit";
import InschrijPage from "./inschrijvingen";

const Inschrijvingen = () => {
  return (
    <Routes>
      <Route path="/" element={<Faciliteiten />} />
      <Route path="/insert" element={<FaciliteitenInsert />} />
      <Route path="/edit/:id" element={<FaciliteitenEdit />} />
      <Route path="/show/:id" element={<FaciliteitPage />} />
      <Route path="/reserveringen/:id" element={<InschrijPage />} />
    </Routes>
  );
};

export default Inschrijvingen;
