import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import PropTypes from 'prop-types';

const SensorEditForm = ({ handleSubmit }) => {
  const requiredValidator = useMemo(() => required(), []);

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field name="naam" type="input" validate={[requiredValidator]} component={renderField} label="Naam standplaats categorie" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving standplaats categorie (optioneel)" />
              <Field name="prijs" type="number" component={renderField} label="Prijs per nacht per kWH" />
              <Field name="voorschot" type="number" component={renderField} label="Voorschot per nacht in kWh" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
          </div>
        </div>
      </form>
    </div>
  );
};

SensorEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit", // a unique identifier for this form
})(SensorEditForm);

const mapStateToProps = (state) => ({
  initialValues: state.standplaatsen.categorie, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
