import 'rc-time-picker/assets/index.css';
import React from "react";
import {Field} from "redux-form";
import timeSelect from '../../../../Components/Forms/time'
import { renderselectsimple} from "../../../../Components/Forms/renders"
class OpeningstijdenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    }
  }

  componentDidMount() {
    const {
      fields
    } = this.props
    if(fields && fields.length === 0) {
    fields.push({});
  }

  }


  render() {
    const {
      fields
    } = this.props

    return (<tbody>
      {
        fields.map((openinghours, index) => (<tr key={index}>
          <td>
            <Field className="form" key={index}  name={`${openinghours}.day`} label="Maak eerste een keuze" component={renderselectsimple} options={{
                1 : 'Ma',
                2 : 'Di',
                3 : 'Wo',
                4 : 'Do',
                5 : 'Vr',
                6 : 'Za',
                7 : 'Zo'
              }} onChange={this.handleSelect}/></td>
          <td>
            <Field component={timeSelect} key={index} name={`${openinghours}.openinghours[0].starttime`} onChange={this.handleSelect}/></td>
          <td>
            <Field component={timeSelect} key={index} name={`${openinghours}.openinghours[0].endtime`} onChange={this.handleSelect}/></td>
          <td>
            <button onClick={() => fields.remove(index)}>
              <i className="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>))
      }<tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>Dag Toevoegen</button>
        </td>
      </tr>
    </tbody>);
  }
};

export default OpeningstijdenForm