import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProfileFotoPage from "./Components/ProfielFoto";
import { fetchpush, UpdatePush, UpdateProfile } from "./Reducers/actions";
import ProfielForm from "./Components/ProfielForm";
import NewPasswordPage from "./Components/NewPassword";
import { message } from "../../Reducers/loading/actions";
import { requestForToken } from "../../Auth/pushnotification";
import { updateuser } from "../../Reducers/auth/actions";

const ProfilePage = ({ username, updateuser, fetchpush, UpdatePush, UpdateProfile, message }) => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [error, seterror] = useState(false);
  const [succes, setsucces] = useState(false);
  useEffect(() => {
    fetchpush().then((data) => {
      setLoadingProfile(true);
    });
  }, [fetchpush]);

  const submit = (vals) => {
    updateuser(vals).then((data) => {
      if (data.type === "PROFILE_ERROR") {
        message(3000, "danger", "Er is iets fout gegaan bij het opslaan");
      } else {
        message(3000, "success", "Je profiel is goed opgeslagen");
      }
    });
  };

  const activeerpush = () => {
    requestForToken().then((data) => {
      const existingPushClient = username.pushclient || []; // Controleer of username.pushclient al bestaat, anders maak een lege array aan
      const pushclient = [
        {
          push_token: data,
          agent: navigator.userAgent,
          datum: new Date(),
        },
        ...existingPushClient.filter((client) => client.push_token !== data), // Filter de bestaande pushclient-array om duplicaten van push_token te verwijderen
      ];
      updateuser({pushclient: pushclient}).then((data) => {
        if (data.type === "PROFILE_ERROR") {
          message(3000, "danger", "Er is iets fout gegaan bij het opslaan");
        } else {
          message(3000, "success", "Je profiel is goed opgeslagen");
        }
      });
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">
          {username.voornaam} {username.achternaam}
        </h3>
      </div>
      <div className="row">
        <div className="col-md-4">
          <ProfileFotoPage />
          <div className="box-header with-border">
            <h3 className="box-title">Push Notification</h3>
            <p>
              <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={activeerpush}>
                Activeer Push notificaties
              </button>
            </p>
          </div>
          <div className="box-header with-border">
            <h3 className="box-title">Wachtwoord wijzigen</h3>
            <NewPasswordPage setsucces={setsucces} seterror={seterror} />
          </div>
        </div>
        <div className="col-md-8 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loadingProfile && <ProfielForm onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.user,
    push: state.push,
  };
};

export default connect(mapStateToProps, { fetchpush, UpdatePush, UpdateProfile, message, updateuser })(ProfilePage);
