import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { savevacaturesforbedrijven, fetchvacatures, fetchvacatursbedrijf, fetchvacatursforbedrijven } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { connect } from "react-redux";
import BedrijvenForm from "./Components/form";

const VacatureFotBedrijfInsert = ({ vacatures, vacatures_items, fetchvacatursbedrijf, savevacaturesforbedrijven, bedrijf, fetchvacatures, fetchvacatursforbedrijven }) => {
  const [loaded, setLoaded] = useState(false);
  const [new_vacatures, setNew_vacatures] = useState([]);
let navigate = useNavigate();
  const { vitems } = useParams();
  useEffect(() => {
    fetchvacatursbedrijf(vitems).then((ddata) => {
      if (ddata.result) {
        fetchvacatures(vitems).then((sdata) => {
          if (sdata.results) {
            fetchvacatursforbedrijven(vitems).then((vdata) => {
              if (vdata.results) {
                setLoaded(true);
              }
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (vacatures) {
    const bedrijfsVacatureIds = vacatures.map((vacature) => vacature.vacature._id);
    const beschikbareVacatures = vacatures_items.filter((vacature) => !bedrijfsVacatureIds.includes(vacature._id));
    setNew_vacatures(beschikbareVacatures);
    }
  }, [vacatures]);

  const submit = (vals) => {
    vals.bedrijven = vitems;
    savevacaturesforbedrijven(vitems, vals).then((data) => {
      if (data.type === "ERROR_VACATURES") {
        alert(data.payload);
        return false;
      } else {
        navigate("/vacatures/bedrijven/"+vitems+"/vacatures");
      }
    });
  };
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3>Vacatures voor {loaded && bedrijf.promotie.title} toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm vacatures={new_vacatures} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, vacatures_items: state.vacatures.items, bedrijf: state.vacatures.bedrijf, vacatures: state.vacatures.vacatures };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  savevacaturesforbedrijven,
  fetchvacatursbedrijf,
  fetchvacatures,
  fetchvacatursforbedrijven,
})(VacatureFotBedrijfInsert);
