import React from "react";
import { connect } from "react-redux";
import { fetchactiviteiten } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { message } from "../../Reducers/loading/actions";
class BieppIndex extends React.Component {
  state = {
    errors: {},
    loading: false,
  };

  componentWillMount() {
    this.getvals({});
    //this.getcount({});
  }

  getvals = () => {
    this.props.fetchactiviteiten().then((data) => {
      if (data.type !== "ACTIVITEIT_ERROR") {
        this.setState({ loading: false });
      }
    });
  };

  handleItem(item) {
    this.props.Verwerken({ id: item._id }).then((datav) => {
      this.getvals({});
    });
  }

  render() {
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "naam",
        text: "Naam activiteit",
      },
      {
        dataField: "beschrijving",
        text: "Beschrijving",
      },
      {
        dataField: "categorie",
        text: "Categorie",
        formatter: (cellContent, row) => {
          return cellContent.join(" | ");
        },
      },
      {
        dataField: "doelgroep",
        text: "Doegroep",
        formatter: (cellContent, row) => {
          return cellContent.join(" | ");
        },
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
              <Link className="btn btn-warning" to={{ pathname: "/biepp/edit/" + row._id }}>
                Wijzigen
              </Link>
              <Link className="btn btn-success" to={{ pathname: "/biepp/show/" + row._id }}>
                Bekijken
              </Link>
            </span>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "250px" };
        },
      },
    ];
    const { biepp } = this.props;
    return (
      <div className="box-header with-border">
        <h3 className="box-title"> Activiteiten</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/biepp/insert">
            <i className="fa fa-plus"></i>&nbsp; Activiteit Toevoegen
          </Link>
        </div>
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12 no-float">
              <div className="box box-primary">
                <div className="box-body box-profile" />
                <ToolkitProvider
                  keyField="_id"
                  data={biepp}
                  columns={columns}
                  search={{
                    searchFormatted: true,
                  }}
                >
                  {(props) => (
                    <div className="p-2">
                      <SearchBar placeholder="Zoeken" {...props.searchProps} />
                      <hr />
                      <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.auth.user,
    biepp: state.biepp.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fetchactiviteiten,
})(BieppIndex);
