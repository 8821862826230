import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import IndexGastenverbuik from "./index";
import InsertGastenVerbruik from "./insert";
import EditGastenverbruik from "./edit";
import ShowGastenverbruik from "./kampeerplaats";
import ShowGastenverbruikAcco from "./accommodatie";
import DuurzaamTipsOverzicht from "./Duurzaamtips/index";
import InsertDuurzaamTip from "./Duurzaamtips/insert";
import DuurzameTipShow from "./Duurzaamtips/show";
import DuurzaamTipsUpdate from "./Duurzaamtips/edit";

const GastenverbruikRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<IndexGastenverbuik />} />
      <Route exact path="/insert" element={<InsertGastenVerbruik />} />
      <Route exact path="/kampeerplaats/:id" element={<ShowGastenverbruik />} />
      <Route exact path="/accommodatie/:id" element={<ShowGastenverbruikAcco />} />
      <Route exact path="/accommodatie/elektra/:id" element={<ShowGastenverbruik />} />
      <Route exact path="/edit/:id" element={<EditGastenverbruik />} />
      <Route exact path="/duurzaam" element={<DuurzaamTipsOverzicht />} />
      <Route exact path="/duurzaam/insert" element={<InsertDuurzaamTip />} />
      <Route exact path="/duurzaam/show/:id" element={<DuurzameTipShow />} />
      <Route exact path="/duurzaam/edit/:id" element={<DuurzaamTipsUpdate />} />
    </Routes>
  );
};

export default GastenverbruikRouter;
