import React from "react";
import { Field, reduxForm, useReduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../../Components/Forms/renders";

import SelectBox from "../../../../Components/Forms/selectbox";

let BedrijvenVacatureForm = ({ handleSubmit, vacatures }) => {
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="vacature" type="input" component={SelectBox} label="Kies een vacature" options={vacatures} inputvalue="_id" inputnaam="titel" />
              <div className="row">
                <div className="col-3">
                  <Field name="uren.min" type="number" component={renderField} label="Minimaal aantal uren per week" validate={[required()]} />
                </div>
                <div className="col-3">
                  <Field name="uren.max" type="number" component={renderField} label="Maximaal aantal uren" validate={[required()]} />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <Field name="leeftijd.min" type="number" component={renderField} label="Minimale leeftijd" validate={[required()]} />
                </div>
              </div>
              <Field
                name="status"
                validate={required({ msg: "Type item is verplicht" })}
                label="Status vacature"
                component={renderselect}
                options={{
                  open: "Open",
                  gesloten: "Gesloten",
                  concept: "Concept",
                }}
              />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updaten" />
          </div>
        </div>
      </form>
    </div>
  );
};

let ReduxBedrijvenForm = reduxForm({
  form: "bed_vac_edit", // een unieke identifier voor dit formulier
})(BedrijvenVacatureForm);

ReduxBedrijvenForm = connect(
  (state) => ({
    initialValues: state.vacatures.vacature, // haal initiële waarden uit account reducer
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxBedrijvenForm);

export default ReduxBedrijvenForm;