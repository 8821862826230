import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchsensorgas } from "../Reducers/actions";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../../../Components/loading/load";
import { Sparklines, SparklinesLine } from "react-sparklines";

const SensorIndexGas = () => {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const sensoren = useSelector(state => state.sensoren.items);

  useEffect(() => {
    const getvals = (vals = {}) => {
      dispatch(fetchsensorgas(vals)).then(() => {
        setLoad(true);
      });
    };

    getvals();
    const timerID = setInterval(() => getvals(), 60000);

    return () => clearInterval(timerID);
  }, [dispatch]);

  const getkwh = (data) => {
    if (data.length > 1) {
      return (data[0].value - data[1].value) / 100;
    }
    return 0;
  };

  const luodate = (data) => {
    if (data[0] && data[0].datum) {
      return moment(data[0].datum).fromNow();
    }
    return null;
  };

  const getdata = (row) => {
    const data = [];
    row.data.forEach((a, key) => {
      if (key > 0) {
        data.push(Number(a.value) - Number(row.data[key - 1].value));
      }
    });
    return data;
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Overzicht Sensoren water</h3>
        <div className="box-tools pull-right"></div>
      </div>
      <div className="col-md-12 no-float sensorkwh">
        <div className="box box-primary">
          <div className="box-body" />
          <Loading loadings={load} />
          {load && (
            <div className="row">
              {sensoren.map((sensor, key) => (
                <div key={key} className="col-lg-4 sensoritem">
                  <div className="tempsensor card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">{sensor.naam}</h3>
                    </div>
                    <Sparklines height={120} data={getdata(sensor)}>
                      <SparklinesLine color="#d96a29" />
                    </Sparklines>
                    <span className="valuekwh">{getkwh(sensor.data)} Kub</span>
                    <div className="details">Laatste meting {luodate(sensor.data)}</div>
                    <div className="card-footer">
                      <Link className="small-box-footer" to={`/sensoren/sensor/water/show/${sensor._id}`}>
                        Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SensorIndexGas;