import React from "react";
import { connect } from "react-redux";
import { fetchsensorentemp } from "../Reducers/actions";
import { Link } from "react-router-dom";
import Tempratuur from "./Components/tempratuur";
import Loading from "../../../Components/loading/load";
class SensorIndex extends React.Component {
  state = {
    errors: {},
    load: false,
  };

  componentWillMount() {
    this.getvals();
    this.timerID = setInterval(() => this.getvals({}), 60000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  getvals = (vals) => {
    this.props.fetchsensorentemp(vals).then((data) => {
      this.setState({ load: true });
    });
  };

  render() {
    const { sensoren } = this.props;
    function isalert(status) {
      if (status == "false") return "card-header alerttemp"
      else return "card-header";
    }
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Sensoren Temperatuur</h3>
          <div className="box-tools pull-right"></div>
        </div>
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <Loading loadings={this.state.load} />
            <div className="box-body" />

            {this.state.load && (
              <div className="row">
                {sensoren.map((sensor, key) => (
                  <div key={key} className="col-lg-3 sensoritem">
                    {!sensor.active && (
                      <div className="ribbon">
                        <span>Niet actief</span>
                      </div>
                    )}

                    <div key={key} className="tempsensor card card-primary">
                      <div className={isalert(sensor.status)}>
                        <h3 className="card-title">{sensor.naam}</h3>
                      </div>
                      <Tempratuur sensor={sensor} />
                      <div className="card-footer">
                        <Link className="small-box-footer" to={{ pathname: "/sensoren/sensor/show/" + sensor._id }}>
                          Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { sensoren: state.sensoren.items };
}

export default connect(mapStateToProps, { fetchsensorentemp })(SensorIndex);
