import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import NDTRCTags from "../../../Components/Forms/ndtrctags";
import FooterForm from "../../../Components/Lib/FooterForm";
import Autosuggest from "../../../Components/Forms/autocomplete";
import switcbutton from "../../../Components/Forms/switch";
import { renderTextField, renderField, renderAField, renderselectsimple, renderAFieldDisabled } from "../../../Components/Forms/renders";
const LogboekFormWebsite = ({ handleSubmit, dispatch }) => {
  const [isaevent, setisaevent] = useState(false);
  const [slug, setSlug] = useState(false);
  const checkevent = (value) => {
    if (value.target.value === "EVENEMENT") {
      setisaevent(true);
    } else {
      setisaevent(false);
    }
  };

  const onChangeInput = (e) => {
    const newSlug = e.target.value
      .toLowerCase()
      .replace(/[^\w\s-]+/g, "")
      .replace(/\s+/g, "-");
    setSlug(newSlug);
    dispatch(change("vvvitem_insert", "what.slug.value", newSlug));
  };


  return (
    <form onSubmit={handleSubmit}>
      <div className="col-sm-12">
        <h4 className="info-text">Vul hier de algemene gegevens in</h4>
      </div>
      <Field
        className="form"
        name="metadata.entitytype"
        validate={required({ msg: "Type item is verplicht" })}
        label="Maak eerste een keuze"
        component={renderselectsimple}
        options={{
          EVENEMENT: "Evenement",
          LOCATIE: "Locatie",
        }}
        onChange={(value) => checkevent(value)}
      />
      {isaevent && (
        <React.Fragment>
          <Field name="subitemsgroup" component={Autosuggest} label="Kies om het item aan een locatie te koppelen" />
          <Field name="event.everyyear" type="input" component={switcbutton} label="Wordt dit evenement jaarlijks herhaald?" />
        </React.Fragment>
      )}
      <Field name="what.title.value" onChange={onChangeInput} validate={required({ msg: "Titel is verplicht" })} type="text" component={renderField} label="Titel" />
      <Field name="what.shortdescription.value" validate={required({ msg: "Beschrijving is verplicht" })} type="textarea" component={renderTextField} label="Korte Omschrijving" />
      <Field name="what.longdescription.value" type="textarea" component={Wysiwyg} label="Lange Omschrijving" className="form-control" normalize={(value) => value.toString("html")} />
      <Field name="types" validate={required({ msg: "Types zijn verplicht" })} type="text" component={NDTRCTags} label="Tags" className="form-control" />

      <FooterForm />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "vvvitem_insert", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
