import React from "react";
import Rodal from "rodal";
import moment from "moment";
import { connect } from "react-redux";
import { message, fixmessage } from "../../../../Reducers/loading/actions";
import { schakelensensor } from "../../Reducers/actions";

import Schakelform from "./schakelform";

function Schakelen({ device, message, fixmessage, schakelensensor }) {
  const [visible, close] = React.useState(false);

  const submit = (values) => {
    values = { ...values, device_id: device.device_id, status: "off" };
    schakelensensor(values).then((data) => {
      if (data.type === "SET_SCHAKELEN") {
        message("success", "De meter is uitgeschakeld");
        close(false);
      } else {
        fixmessage("error", "Er is iets misgegaan");
      }
    });
  };

  const inschakelen = (values) => {
    values = { ...values, device_id: device.device_id, status: "on" };
    schakelensensor(values).then((data) => {
      if (data.type === "SET_SCHAKELEN") {
        message("success", "De meter is uitgeschakeld");
        close(false);
      } else {
        fixmessage("error", "Er is iets misgegaan");
      }
    });
  };

  const isuitgeschakeld = (device) => {
    if (moment(device.schakelen.datum).isAfter(moment())) {
      return <span className="badge badge-danger bdg-lg p-1 mx-3"> Uigeschakeld tot {moment(device.schakelen.datum).format("DD-MM-YYYY HH:mm")}</span>;
    } else {
      return null;
    }
  };

  if (device.sensor === "M114039-02") {
    return (
      <>
        {isuitgeschakeld(device)}
        <button
          type="button"
          className="btn btn-primary btn-sm float-right"
          onClick={() => {
            close(true);
          }}
        >
          Schakelen meter
        </button>

        <Rodal
          visible={visible}
          onClose={() => {
            close(false);
          }}
          className="rodal-md"
        >
          <div className="card actionmodal">
            <div className="card-header">
              <h4 className="mb-0">Schakelen meter </h4>
            </div>
            <div className="card-body">
              <Schakelform inschakelen={inschakelen} onSubmit={submit} />
            </div>
          </div>
        </Rodal>
      </>
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {}

export default connect(mapStateToProps, { message, fixmessage, schakelensensor })(Schakelen);
