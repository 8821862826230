import React, { useState, useEffect } from "react";
import SubMenu from "./Components/submenu";
import moment from "moment";
import { getonepages, savepages } from "../Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "../../../Reducers/loading/actions";
import { useLocation } from "react-router-dom";
import ContentBuilder from "./Components/contentbuilder";

const ContentItem = ({ page, message, getonepages, savepages, settings }) => {
  const [buildElements, setBuildElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taal, setTaal] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
    const queryParams = new URLSearchParams(location.search);
    const taalkeuze = queryParams.get("taal");
    if (taalkeuze) {
      setTaal(taalkeuze);
    }
    getonepages(params.id).then((res) => {
      if (res.type === "GET_CMS_PAGE") {
        if (res.results.type === "page") {
          setBuildElements(res.results.pagetemmplate.content);
        } else {
          setBuildElements(res.results.components);
        }
        setLoading(true);
      }
    });
  }, [location.search, params.id]);

  const submit = (values) => {
    savepages(values).then((data) => {
      if (data.type === "GET_CMS_PAGE") {
        message(2000, "success", "De component is succesvol opgeslagen").then(() => {
          navigate("/cms/content/");
        });
      } else {
        message(4000, "danger", "Er is wat fout gegaan");
      }
    });
  };

  const changetaal = (e) => {
    const selectedLanguage = e.target.value;
    setTaal(selectedLanguage);
    const selectedLanguageObj = settings.talen.find((lang) => lang.taal === selectedLanguage);
    const newUrl = selectedLanguageObj.default
      ? `/cms/content/${params.id}`
      : `/cms/content/${params.id}?taal=${selectedLanguage}`;
    navigate(newUrl);
  };

  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Content Manager</h3>
        </div>
        <div className="box-body d-flex">
          {/* Sidebar submenu */}
          <div className="flex-shrink-0 me-3" style={{ width: "200px" }}>
            <SubMenu />
          </div>

          {/* Main content section */}
          <div className="flex-grow-1">
            <div className="container-fluid">
              {loading && (
                <div className="d-flex flex-column">
                  <div className="box box-info">
                    <div className="box-body">
                      <div className="d-flex">
                        <div className="flex-grow-1 me-3">
                          <div className="box">
                            <div className="box-header with-border">
                              <h3 className="box-title">{page.naam}</h3>
                            </div>
                            <div className="box-body">
                              {buildElements.length > 0 && (
                                <ContentBuilder
                                  settings={settings}
                                  buildElements={buildElements}
                                  onSubmit={submit}
                                  taal={taal}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0" style={{ width: "300px" }}>
                          <div className="box">
                            <div className="box-header with-border">
                              <h3 className="box-title">Instellingen</h3>
                            </div>
                            <div className="box-body">
                              <h6>Informatie</h6>
                              <hr />
                              <div className="d-flex justify-content-between">
                                <p>Gemaakt</p>
                                <p>{moment(page.date).format("DD-MM-YYYY HH:mm")}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Geüpdatet</p>
                                <p>{moment(page.updated).format("DD-MM-YYYY HH:mm")}</p>
                              </div>
                            </div>
                            <div className="box-header with-border">
                              <h3 className="box-title">Talen</h3>
                            </div>
                            <div className="box-body">
                              <select className="form-control" onChange={changetaal} value={taal}>
                                {settings.talen.map((item, index) => (
                                  <option key={index} value={item.taal}>
                                    {item.taal}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    page: state.cms.pages.item,
    settings: state.cms.settings,
  };
}

export default connect(mapStateToProps, {
  message,
  getonepages,
  savepages,
})(ContentItem);