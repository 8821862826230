import React, { useEffect } from "react";
import { required, format } from "redux-form-validators";
import { FieldArray, Field } from "redux-form";
import { renderField } from "../../../../../Components/Forms/renders";
import switcbutton from "../../../../../Components/Forms/switch";
import WebsiteFormSub from "./websiteformsub";

const TalenForm = ({ fields }) => {
  return (
    <React.Fragment>
      {fields.map((website, index) => (
        <tr key={index}>
          <td colSpan={4}>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <Field
                      name={`${website}.naam`}
                      type="text"
                      validate={[
                        format({
                          without: /\s/, // Geen spaties toegestaan
                          message: "Mag geen spaties bevatten",
                        }),
                        format({
                          with: /^[a-z]+$/, // Alleen kleine letters toegestaan
                          message: "Mag alleen kleine letters bevatten",
                        }),
                      ]}
                      component={renderField}
                      label="Naam van de pagina "
                    />
                  </td>
                  <td>
                    <Field
                      name={`${website}.slug`}
                      type="text"
                      alidate={[
                        format({
                          without: /\s/, // Geen spaties toegestaan
                          message: "Mag geen spaties bevatten",
                        }),
                        format({
                          with: /^[a-z]+$/, // Alleen kleine letters toegestaan
                          message: "Mag alleen kleine letters bevatten",
                        }),
                      ]}
                      component={renderField}
                      label="Slug van de pagina"
                    />
                  </td>
                  <td>
                    <Field name={`${website}.actief`} type="input" component={switcbutton} label="Pagina actief" />
                  </td>
                  <td className="align-middle text-end">
                    <button onClick={() => fields.remove(index)}>
                      <i className="fa fa-minus-circle"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <FieldArray name={`${website}.sub`} component={WebsiteFormSub} />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <button type="button" onClick={() => fields.push({})}>
            Pagina Toevoegen
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TalenForm;
