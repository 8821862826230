import React, { useEffect, useState } from "react";
import { FieldArray, reduxForm, change } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import WebsiteForm from "./websiteform";

function SyncValidationForm({ handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <table className="table table-bordered">
        <tbody>

              <FieldArray name={`website`} component={WebsiteForm} />

        </tbody>
      </table>
      <input type="submit" className="btn btn-next btn-md btn-success btn-wd btn-lg" name="next" value="Opslaan" />
    </form>
  );
}

const ReduxFormSensorEdit = reduxForm({
  form: "talen_form", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.cms.settings, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
