import {
  SET_ITEMS,
  ADD_ITEM,
  REMOVE_ITEM,
  ITEM_ERROR,
  SET_ITEM,
  ITEM_LOAD,
  CLEAR_ITEM
} from './actions';
export default function cats(state = {
  item: [],
  items: [],
  error: []
}, action = {}) {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        item: action.results
      };
    case SET_ITEM:
      return {
        ...state,
        item: action.results
      };
    case CLEAR_ITEM:
      return {
        ...state,
        item: []
      };
    case ITEM_ERROR:
      return {
        ...state,
        error: action.error
      };
    case SET_ITEMS:
      return {
        ...state,
        items: action.items
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item._id !== action.id)
      };
    case ITEM_LOAD:
      return state;
    default:
      return state;
  }
}
