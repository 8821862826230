import React, { useState, useMemo } from "react";
import Rodal from "rodal";

const PersonenWijzigen = ({ deelnemers, ervaring, wijzigenpersonen }) => {
  const [visible, setVisible] = useState(false);
  const [aantallen, setAantallen] = useState(() => {
    return ervaring.prijzen.map((prijsregel) => {
      const boekregel = deelnemers.find((boekregel) => boekregel.naam === prijsregel.naam);

      // Voeg het aantal toe aan de prijsregel, of 0 als het niet gevonden wordt
      return {
        ...prijsregel,
        aantal: boekregel ? Number(boekregel.aantal) : 0,
      };
    });
  });

  // Bereken het totaal op basis van de huidige form waarden
  const berekenTotaal = useMemo(() => {
    return aantallen
      .reduce((acc, prijs) => {
        return acc + Number(prijs.prijs) * prijs.aantal;
      }, 0)
      .toFixed(2);
  }, [aantallen]);

  const handleAantalChange = (naam, newValue) => {
    setAantallen((prevAantallen) => prevAantallen.map((prijsregel) => (prijsregel.naam === naam ? { ...prijsregel, aantal: Number(newValue) } : prijsregel)));
  };
  const sendupdate = () => {
    wijzigenpersonen({
      personen: aantallen.reduce((acc, prijs) => acc + prijs.aantal, 0), // Geen toFixed(2) nodig voor een integer waarde
      deelnemers: aantallen,
      totaalprijs: aantallen
        .reduce((acc, prijs) => acc + Number(prijs.prijs) * prijs.aantal, 0)
        .toFixed(2), // toFixed(2) is correct voor geldbedragen
    });
    setVisible(false);
  };
  return (
    <>
      <button className="btn btn-info mx-2" onClick={() => setVisible(true)}>
        Aantal personen wijzigen
      </button>
      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-md">
        <div className="card actionmodal">
          <div className="card-header">
            <h4 className="mb-0">Aantal personen wijzigen</h4>
          </div>
          <div className="card-body">
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "80px" }}>
                    Aantal
                  </th>
                  <th scope="col">Naam</th>
                  <th scope="col">Prijs</th>
                  <th scope="col" className="text-end">
                    Totaal
                  </th>
                </tr>
              </thead>
              <tbody>
                {aantallen.map((prijs, index) => (
                  <tr key={index}>
                    <td>
                      <input type="number" min="0" className="form-control" value={prijs.aantal} onChange={(e) => handleAantalChange(prijs.naam, e.target.value)} />
                    </td>
                    <td>{prijs.beschrijving}</td>
                    <td>€ {Number(prijs.prijs).toFixed(2)}</td>
                    <td className="text-end">€ {(Number(prijs.prijs) * prijs.aantal).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="col">Totaal</th>
                  <th scope="col" colSpan="3" className="text-end">
                    € {berekenTotaal}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-end p-4">
            <button className="btn btn-primary" onClick={() => sendupdate()}>
              Wijzigen
            </button>
          </div>
        </div>
      </Rodal>
    </>
  );
};

export default PersonenWijzigen;
