import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import BedrijvenPage from "./Bedrijven/index";
import BedrijfInsertPage from "./Bedrijven/insert";
import BedrijfEditPage from ".//Bedrijven/edit";
import GebruikersPage from "./Gebruikers/index";
import {RequireAuthAdmin} from "../../Auth/require_auth_admin";
import {RequireAuth} from "../../Auth/require_auth";
import SignupPage from "./Gebruikers/insert";
import GebruikersEditPage from "./Gebruikers/edit";
import ProfilePage from "../profile/profile";
import ConnectionsPage from "./Connections/index";
import ConnectionInsertPage from "./Connections/insert";
import ConnectionsEditPage from "./Connections/edit";
import ShowInstgram from "./Connections/show/instagram";
import CallbackInstagram from "./Connections/Callback/instagram";
import CallBackGoolgeBusisness from "./Connections/Callback/googlebusiness";
import GoolgleBusiness from "./Connections/show/GoolgleBusiness";
const AdminRouter = () => {  
    return (
      <Routes>
         <Route exact path="/signup" element={<RequireAuthAdmin><SignupPage /></RequireAuthAdmin>} />
        <Route exact path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
        <Route exact path="/gebruikers" element={<RequireAuthAdmin><GebruikersPage /></RequireAuthAdmin>} />
        <Route exact path="/gebruikers/:id" element={<RequireAuthAdmin><GebruikersEditPage /></RequireAuthAdmin>} />
        <Route exact path="/bedrijven" element={<RequireAuthAdmin><BedrijvenPage /></RequireAuthAdmin>} />
        <Route exact path="/bedrijven/insert" element={<RequireAuthAdmin><BedrijfInsertPage /></RequireAuthAdmin>} />
        <Route exact path="/bedrijven/edit/:id" element={<RequireAuthAdmin><BedrijfEditPage /></RequireAuthAdmin>} />
        <Route exact path="/connections" element={<RequireAuthAdmin><ConnectionsPage /></RequireAuthAdmin>} />
        <Route exact path="/connections/insert" element={<RequireAuthAdmin><ConnectionInsertPage /></RequireAuthAdmin>} />
        <Route exact path="/connections/edit/:id" element={<RequireAuthAdmin><ConnectionsEditPage /></RequireAuthAdmin>} />
        <Route exact path="/connections/instagram/:id" element={<RequireAuthAdmin><ShowInstgram /></RequireAuthAdmin>} />
        <Route exact path="/connections/GoogleBusiness/:id" element={<RequireAuthAdmin><GoolgleBusiness /></RequireAuthAdmin>} />

        
        <Route exact path="/connections/callback/googlebusiness" element={<RequireAuthAdmin><CallBackGoolgeBusisness /></RequireAuthAdmin>} />
        <Route exact path="/connections/callback/instagram" element={<RequireAuthAdmin><CallbackInstagram /></RequireAuthAdmin>} />
        </Routes>
    );
  
}

export default AdminRouter;
