import React, { useState, useEffect } from "react";
import moment from "moment";
const FilterSensor = ({ loading, gemiddelde, max, verbruik, gastverbruik, index, soort, element }) => {
  const [gemiddeldeState, setGemiddeldeState] = useState(0);
  const [verbruikState, setVerbruikState] = useState(0);
  const [maxState, setMaxState] = useState(0);
  const [showVoorschot, setshowVoorschot] = useState(false);
  const [voorschot, setVoorschot] = useState(0);
  const [verblijftijd, setVerblijftijd] = useState(0);
  useEffect(() => {
    if (!loading) {
      setGemiddeldeState(gemiddelde.toFixed(2));
      setVerbruikState(verbruik);
      setMaxState(max.toFixed(2));
      berekenverblijdtijd();
      setVoorschot(Number(verblijftijd * Number(gastverbruik.accommodaties.meters[index].categorie.voorschot)).toFixed(2));
      if (element.categotie && element.categotie.voorschot && element.categotie.voorschot > 0) {
        setshowVoorschot(true);
      }
    }
  }, [gemiddelde, max, verbruik, voorschot, verblijftijd]);
  const berekenverblijdtijd = () => {
    if (moment(gastverbruik.vertrek).isBefore(moment())) {
      setVerblijftijd(
        moment
          .duration(moment(gastverbruik.vertrek).diff(moment(gastverbruik.aankomst)))
          .asDays()
          .toFixed(0)
      );
    } else {
      setVerblijftijd(
        moment
          .duration(moment(new Date()).diff(moment(gastverbruik.aankomst)))
          .asDays()
          .toFixed(0)
      );
    }
  };
  const verschil = () => {
    if (voorschot - verbruikState < 0) {
      return (
        <span className="badge badge-danger">
          {(voorschot - verbruikState).toFixed(2)} {welkemeter()}
        </span>
      );
    } else {
      return (
        <span className="badge badge-success">
          {(voorschot - verbruikState).toFixed(2)} {welkemeter()}
        </span>
      );
    }
  };

  const betaling = () => {
    if (voorschot - verbruikState < 0) {
      return <span className="badge badge-danger">€ {Number((verbruikState - voorschot) * gastverbruik.accommodaties.meters[index].categorie.prijs).toFixed(2)}</span>;
    } else {
      return <span className="badge badge-success">€ {Number((verbruikState - voorschot) * gastverbruik.accommodaties.meters[index].categorie.prijs).toFixed(2)}</span>;
    }
  };

  const welkemeter = () => {
    if (soort === "water") {
      return "Kub";
    }
    return "KwH";
  };

  return (
    <div className="container filter">
      <div className="row d-flex flex-wrap">
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Totaal Verbruik
          <span className="gemiddelde">
            {verbruikState} {welkemeter()}
          </span>
        </div>
        {showVoorschot && (
          <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
            Totale voorschot
            <span className="gemiddelde">
              {voorschot} {welkemeter()}
            </span>
          </div>
        )}
        {showVoorschot && (
          <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
            Verrekening vs
            <span className="gemiddelde">{verschil()}</span>
          </div>
        )}
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Extra verbruik
          <span className="gemiddelde">{betaling()}</span>
        </div>
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Gemiddelde (dag)
          <span className="gemiddelde">
            {gemiddeldeState} {welkemeter()}
          </span>
        </div>
        <div className="col col-md-2 col-sm-6 col-xs-6 flex-fill ">
          Hoogste Piek (dag)
          <span className="gemiddelde">
            {maxState} {welkemeter()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FilterSensor;
