import React from "react";
import { Routes, Route } from "react-router-dom";
import LogboekInsertPage from "./insert";
import LogboekEditPage from "./edit";
import LogboekInsertPageWebsite from "./website";
import LogboekPage from "./overzicht";
import VergelijkenPage from "./vergelijken";
import WebsitePage from "../Website/index";

const Bedrijven = () => {  
  return (
    <Routes>
      <Route path="/" element={<LogboekPage />} />
      <Route path="/vergelijken" element={<VergelijkenPage />} />
      <Route path="/insert" element={<LogboekInsertPage />} />
      <Route path="/edit/:id" element={<LogboekEditPage />} />
      <Route path="/insert/website" element={<LogboekInsertPageWebsite />} />
      <Route path="/website" element={<WebsitePage />} />
    </Routes>
  );
}

export default Bedrijven;
