import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselectsimple } from "../../../Components/Forms/renders";
import switcbutton from "../../../Components/Forms/switch";

const ReserveringForm = ({ handleSubmit, beschikbaarheid, handleOnchange }) => {
  const [datas, setDatas] = useState([]);

  const prijzen = () => {
    if (beschikbaarheid.ervaring.prijzen) {
      return beschikbaarheid.ervaring.prijzen.map((prijs, key) => <Field key={key} name={`deelnemers.${prijs.naam}`} type="number" component={renderField} label={prijs.beschrijving} />);
    } else {
      return <Field name={`deelnemers.personen}`} type="number" component={renderField} label={"Aantal personen"} />;
    }
  };

  const opties = () => {
    if (beschikbaarheid.ervaring.opties) {
      return beschikbaarheid.ervaring.opties.map((prijs, key) => {
        if (prijs.optie === "aantal") {
          return <Field key={key} name={`opties.${prijs.naam}`} type="number" component={renderField} label={prijs.beschrijving} />;
        } else if (prijs.optie === "vraag") {
          return (
            <Field
              key={key}
              name={`opties.${prijs.naam}`}
              type="checkbox"
              label={prijs.beschrijving}
              component={renderselectsimple}
              options={{
                vraag: "Optie ja en nee",
                aantal: "Aantallen",
              }}
            />
          );
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} onChange={handleOnchange}>
      <div className="box-body">
        <Field name="voornaam" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Voornaam" />
        <Field name="tussenvoegsel" type="input" component={renderField} label="Tussenvoegsel" />
        <Field name="achternaam" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Achternaam" />
        <Field name="email" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="E-mailadres" />
        <Field name="telefoonnummer" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Mobiele nummer" />

        {prijzen()}
        {opties()}
        <Field name="voorwaarde" type="input" component={switcbutton} label="Akkoord algemene voorwaarden" />
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </div>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "reservering_insert", // a unique identifier for this form
})(ReserveringForm);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
