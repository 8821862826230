import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import { connect } from "react-redux";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import PersonenWijzigen from "./personen";
import { useDispatch } from "react-redux";
import { updatepersonen, updateklant } from "../Reducers/actions";
import Contactwijzigen from "./contactwijzigen";
const ContactForm = ({ klant, deelnemers, ervaring, message, updateklant }) => {
  const dispatch = useDispatch();

  const wijzigenpersonen = (vals) => {
    dispatch(updatepersonen(ervaring._id, vals));
    message(2000, "success", "De personen zijn met succes aangepast");
  };

  const wijzgencontact = (vals) => {
    updateklant(ervaring._id, vals).then((data) => {
      if (data.type === "ERROR_BOEKINGEN") {
        message(2000, "warning", "Er is een fout opgetreden bij het wijzigen van de contactgegevens");
        return false;
      } else {
        message(2000, "success", "De contactgegevens zijn met succes aangepast");
      }
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="mb-0">Contactgegevens</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-3">
            <h6 className="mb-0">Voornaam</h6>
          </div>
          <div className="col-sm-9 text-secondary"> {klant.voornaam} </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <h6 className="mb-0">Achternaam</h6>
          </div>
          <div className="col-sm-9 text-secondary">
            {" "}
            {klant.tussenvoegsel} {klant.achternaam}{" "}
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-sm-3">
            <h6 className="mb-0">Email</h6>
          </div>
          <div className="col-sm-9 text-secondary"> {klant.email} </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <h6 className="mb-0">Telefoonnummer</h6>
          </div>
          <div className="col-sm-9 text-secondary"> {klant.telefoonnummer} </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <Contactwijzigen onSubmit={wijzgencontact} />
          </div>
          <div className="col">
            <PersonenWijzigen wijzigenpersonen={wijzigenpersonen} ervaring={ervaring?.ervaringdatum?.ervaring ? ervaring.ervaringdatum.ervaring : ervaring.arrangementdatum.ervaring} deelnemers={deelnemers} klant={klant} />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.item };
}

export default connect(mapStateToProps, { message, fixmessage, updateklant })(ContactForm);
