import React from "react";
import { Routes, Route } from "react-router-dom";
import ErvaringenPage from "./ervaring";
import ArraggementenPage from "./arrangementen";
import BoekingItemPage from "./item";
import ArrangementItemPage from "./arrangement";
import BoekingenPage from "./index";

const BoekingenRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<BoekingenPage />} />

      <Route path="/ervaring/:id" element={<ErvaringenPage />} />
      <Route path="/arrangement/:id" element={<ArraggementenPage />} />
      <Route path="/show/arrangement/:id" element={<ArrangementItemPage />} />
      <Route path="/show/arrangement/:id/:action" element={<ArrangementItemPage />} />
      <Route path="/show/:id/:action" element={<BoekingItemPage />} />
      <Route path="/show/:id" element={<BoekingItemPage />} />
    
    </Routes>
  );
};

export default BoekingenRouter;
