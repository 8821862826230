import React, { useEffect } from "react";
import moment from "moment";

const BarComponent = ({ datumres, ervaring, boekingen }) => {

  const telreserveringen = () => {
    return ervaring.ervaringdatums.reduce((reserveringen, datum) => {
      return reserveringen + datum.aantalreserveringen;
    }, 0);
  };

  const aantalpersonen = () => {
    return ervaring.ervaringdatums.reduce((reserveringen, datum) => {
      return reserveringen + datum.aantalpersonen;
    }, 0);
  };
  

  return (
    <div className="barback">
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
            <i className="fa fa-users"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Aantal reserveringen</span>
              <span className="info-box-number">{telreserveringen()}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
              <i className="fa fa-users"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Aantal personen</span>
              <span className="info-box-number">{aantalpersonen()}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
              <i className="fa fa-users"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Maximaal aantal personen</span>
              <span className="info-box-number">{ervaring && ervaring.beschikbaarheid && ervaring.beschikbaarheid.personenmax}</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-aqua">
              <i className="fa fa-users"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Minimaal aantal personen</span>
              <span className="info-box-number">{ervaring && ervaring.beschikbaarheid && ervaring.beschikbaarheid.personenmin}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarComponent;
