export const GET_VACATURES = "GET_VACATURES";
export const ADD_VACATURES = "ADD_VACATURES";
export const SET_VACATURES = "SET_VACATURES";
export const SET_VACATURES_BEDRIJVEN = "SET_VACATURES_BEDRIJVEN";
export const GET_VACATURES_BEDRIJVEN_VACATURES = "GET_VACATURES_BEDRIJVEN_VACATURES";
export const SET_VACATURES_BEDRIJVEN_VACATURES = "SET_VACATURES_BEDRIJVEN_VACATURES";
export const GET_VACATURES_BEDRIJVEN = "GET_VACATURES_BEDRIJVEN";
export const ERROR_VACATURES = "ERROR_VACATURES";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then((json) => {
      throw json.errors;
    });
  }
}

export function add(results) {
  return {
    type: ADD_VACATURES,
    results,
  };
}
export function get(results) {
  return {
    type: GET_VACATURES,
    results,
  };
}
export function set(results) {
  return {
    type: SET_VACATURES,
    results,
  };
}
export function setbedrijf(results) {
  return {
    type: SET_VACATURES_BEDRIJVEN,
    results,
  };
}
export function getbedrijven(results) {
  return {
    type: GET_VACATURES_BEDRIJVEN,
    results,
  };
}
export function setvacature(results) {
  return {
    type: SET_VACATURES_BEDRIJVEN_VACATURES,
    results,
  };
}
export function getvacature(results) {
  return {
    type: GET_VACATURES_BEDRIJVEN_VACATURES,
    results,
  };
}

export function arrangementenError(error) {
  return {
    type: ERROR_VACATURES,
    payload: error,
  };
}
export function savevacatures(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updatevacatures(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function fetchvacatures() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getvacatures(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getvacaturesbybedrijf(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + id + "/bedrijven/", {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(getbedrijven(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function delvacatures(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/vacatures/" + id, {
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function savevacaturesbedrijven(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setbedrijf(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function fetchvacatursbedrijven() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(getbedrijven(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function fetchvacatursbedrijf(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setbedrijf(results.result));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function updatevacatursbedrijf(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setbedrijf(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function savevacaturesforbedrijven(bedrijf, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + bedrijf + "/vacatures", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setvacature(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function fetchvacatursforbedrijven(bedrijf) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + bedrijf + "/vacatures", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(getvacature(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function fetchvacatursforbedrijf(bedrijf, id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/vacatures/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setvacature(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function updatevacatursforbedrijf(bedrijf, id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/vacatures/bedrijven/" + bedrijf + "/vacatures/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setvacature(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
