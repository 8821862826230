import moment from "moment";
import React from "react";

export default function ReserveringsOverzicht({ beschikbaarheid, prijzen, boeking, opties }) {
  const totaal = () => {
    let totaal = 0;
    prijzen.forEach((prijs) => {
      totaal += prijs.prijs * prijs.aantal;
    });
    opties.forEach((optie) => {
      totaal += optie.prijs * optie.aantal;
    });

    return totaal.toFixed(2);
  };
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="mb-0">{boeking && boeking.OrderID ? "Boeking: " + boeking.OrderID : beschikbaarheid.ervaring.naam}</h4>
      </div>
      <div className="card-body">
        <div className="image">{beschikbaarheid.ervaring.images && beschikbaarheid.ervaring.images[0] && <img src={beschikbaarheid.ervaring.images[0].thumbnail.small} alt={beschikbaarheid.ervaring.naam} />}</div>
        <hr />
        <div className="details">
          <div className="datum">
            <strong className="clearfix">Datum</strong>
            {moment(beschikbaarheid.datum).format("DD-MM-YYYY")}
          </div>
          <div className="starttime">
            <strong className="clearfix">Start tijd</strong>
            {beschikbaarheid.starttime}
          </div>
          <div className="endtime">
            <strong className="clearfix">Eind tijd</strong>
            {beschikbaarheid.endtime}
          </div>
          <hr />
        </div>
        <div className="prijzen">
          <strong className="clearfix">Prijzen</strong>
          <table className="table table-responsive-md">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Naam</th>
                <th scope="col">Prijs</th>
                <th scope="col" className="text-end">
                  Totaal
                </th>
              </tr>
            </thead>
            <tbody>
              {prijzen &&
                prijzen.map((prijs, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{prijs.aantal}</th>
                      <td>{prijs.beschrijving}</td>
                      <td>€ {Number(prijs.prijs).toFixed(2)}</td>
                      <td className="text-end">€ {Number(prijs.prijs * prijs.aantal).toFixed(2)} </td>
                    </tr>
                  );
                })}
              <tr>
                <th scope="row" colSpan={3}>
                  Opties
                </th>
              </tr>
              {opties &&
                opties.map((optie, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{optie.aantal}</th>
                      <td>{optie.beschrijving}</td>
                      <td>€ {Number(optie.prijs).toFixed(2)}</td>
                      <td className="text-end">€ {Number(optie.prijs * optie.aantal).toFixed(2)} </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="totaal">
          <table className="table table-responsive-md">
            <tbody>
              <tr>
                <th scope="col">Totaal</th>
                <th scope="col" className="text-end">
                  € {totaal()}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        {boeking && boeking.betaling && boeking.betaling.resource === "refund" && (
          <div className="totaal">
            <table className="table table-responsive-md">
              <tbody>
                <tr>
                  <th scope="col warning">Bedrag teruggestort</th>
                  <th scope="col" className="text-end">
                    € - {boeking.betaling.amount.value}
                  </th>
                </tr>
                <tr>
                  <th scope="col warning">Verschil</th>
                  <th scope="col" className="text-end">
                    € {Number(totaal() - boeking.betaling.amount.value).toFixed(2)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
