import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { message, clearmessage, fixmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
import { fetchhoreca } from "../Horeca/Reducers/actions";
import { fetchopenfaciliteiten } from "../Faciliteiten/Reducers/actions";
import Horeca from "../Horeca/Components/horeca";
import Faciliteiten from "../Faciliteiten/Components/faciliteit";

const FacilitetenPage = ({ message, fixmessage, clearmessage, fetchhoreca, fetchopenfaciliteiten }) => {
  const [state, setState] = useState({
    errors: {},
    loaded: false,
    horeca: false,
    faciliteiten: false,
    activiteiten: false,
  });
  const params = useParams();
  const navigate = useNavigate();
  const bedrijfp = useSelector((state) => state.bedrijfp.item);
  const horeca = useSelector((state) => state.horecap.items);
  const faciliteiten = useSelector((state) => state.openfaciliteiten.items);

  useEffect(() => {
    getbedrijf(params.id).then((data) => {
      if (!data.results) {
        navigate("/public");
      } else {
        if (data.results.modules) {
          if (data.results.modules.includes("horecas")) {
            fetchhoreca(data.results._id).then(() => {
              setState((prevState) => ({ ...prevState, loaded: true, horeca: true }));
            });
          }
          if (data.results.modules.includes("faciliteiten")) {
            fetchopenfaciliteiten(data.results._id).then(() => {
              setState((prevState) => ({ ...prevState, loaded: true, faciliteiten: true }));
            });
          } else {
            setState((prevState) => ({ ...prevState, loaded: true }));
          }
        } else {
          setState((prevState) => ({ ...prevState, loaded: true }));
        }
      }
    });

    return () => {
      clearmessage();
    };
  }, [params.id, navigate, fetchhoreca, fetchopenfaciliteiten, clearmessage]);

  return (
      <div className="public-box-body">
        <h2 className="headline text-yellow facheadline"> {lang("Kies een bedrijfsonderdeel")}</h2>
        <Loading loading={state.loaded} />
        {state.loaded && (
          <div className="inschrijf-content">
            <div>
              {bedrijfp.modules.length === 0 && <h3>{lang("Er zijn geen bedrijfonderdelen geactiveerd voor dit bedrijf")} </h3>}
              {state.horeca && (
                <div>
                  <h4 className="headline text-yellow"> {lang("Horeca")}</h4>
                  {horeca.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
                  {horeca.map((horec) => (
                    <div key={horec._id} className="activiteit-content">
                      <Horeca horeca={horec} link={true} />
                    </div>
                  ))}
                </div>
              )}
              {state.faciliteiten && (
                <div>
                    <h4 className="headline text-yellow"> {lang("Faciliteiten")}</h4>
                  {faciliteiten.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
                  {faciliteiten.map((faciliteit) => (
                    <div key={faciliteit._id} className="activiteit-content">
                      <Faciliteiten faciliteit={faciliteit} link={true} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <Link
          to={{
            pathname: "/public/",
          }}
          className="back-arrow"
        >
          <i className="fa fa-chevron-left"></i> {lang("Terug")}
        </Link>
      </div>
    );
  }


function mapStateToProps(state) {
  return {
    bedrijfp: state.bedrijfp.item,
    horeca: state.horecap.items,
    faciliteiten: state.openfaciliteiten.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getbedrijf,
  clearmessage,
  fetchhoreca,
  fetchopenfaciliteiten,
})(FacilitetenPage);
