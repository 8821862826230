import React, { useState, useEffect } from "react";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import SortableItem from "./item";

const SortableList = SortableContainer(({ items, onRemove }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} idx={index} onRemove={() => onRemove(index)} index={index} value={value} />
      ))}
    </ul>
  );
});

const SortableComponent = ({ input }) => {
  const [value, setValue] = useState(input.value || []);

  // Zorg ervoor dat de interne state gesynchroniseerd blijft met de Redux Form state
  useEffect(() => {
    setValue(input.value || []);
  }, [input.value]);

  // Verwijder een item uit de lijst
  const remove = (index) => {
    const updatedValue = [...value]; // Maak een kopie van de huidige waarde
    updatedValue.splice(index, 1); // Verwijder het item op de opgegeven index
    setValue(updatedValue); // Update de lokale state
    input.onChange(updatedValue); // Informeer Redux Form over de wijziging
  };

  // Sorteer de items opnieuw na slepen
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedValue = arrayMove(value, oldIndex, newIndex); // Verplaats het item
    setValue(updatedValue); // Update de lokale state
    input.onChange(updatedValue); // Informeer Redux Form over de wijziging
  };

  return (
    <SortableList
      items={value}
      onSortEnd={onSortEnd}
      onRemove={(index) => remove(index)}
    />
  );
};

export default SortableComponent;