
import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

export default function Tags({ label, error, touched, warning, input }) {
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (input.value) {
      setValue(input.value);
    }
  }, [input.value]);

  const handleSelect = (value) => {
    setValue(value);
    input.onChange(value);
  };
  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <TagsInput
          value={value}
          onChange={handleSelect}
          inputProps={{
            placeholder: label,
          }}
        />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
}
