import React from "react";
import moment from "moment";

const SensorLeakageTable = ({ sensorData }) => {
  const calculateValues = (sensorData) => {
    const hourlyValue = sensorData.value / (sensorData.tijd / 3600) / 1000;
    const dailyValue = sensorData.value / (sensorData.tijd / 86400) / 1000;
    const monthlyValue = sensorData.value / (sensorData.tijd / (86400 * 30)) / 1000;
    const yearlyValue = sensorData.value / (sensorData.tijd / (86400 * 365)) / 1000;

    return {
      hourlyValue: hourlyValue.toFixed(2),
      dailyValue: dailyValue.toFixed(2),
      monthlyValue: monthlyValue.toFixed(2),
      yearlyValue: yearlyValue.toFixed(2),
    };
  };

  const calculatedValues = calculateValues(sensorData);

  return (
    <div className="container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Per uur</th>
            <th>Per dag</th>
            <th>Per maand</th>
            <th>Per jaar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{calculatedValues.hourlyValue}</td>
            <td>{calculatedValues.dailyValue}</td>
            <td>{calculatedValues.monthlyValue}</td>
            <td>{calculatedValues.yearlyValue}</td>
          </tr>
        </tbody>
      </table>
      <div>
        Laagste meting van {moment(sensorData.datum).format("DD-MM-YYYY")} om  {moment(sensorData.datum).format("HH:mm")}  gedurende {(sensorData.tijd/60).toFixed(0)} minuten
      </div>
      <small className="text-end d-block">Alle waardes zijn in m³ </small>
    </div>
  );
};

// Voorbeeld van het gebruik van de component
const App = ({ sensorData }) => (
  <div>
    <SensorLeakageTable sensorData={sensorData} />
  </div>
);

export default App;
