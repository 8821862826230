var FileDownload = require("js-file-download");
var moment = require("moment");
export const SET_SENSOREN = "SET_SENSOREN";
export const ADD_SENSOREN = "ADD_SENSOREN";
export const SENSOR_ERROR = "SENSOR_ERROR";
export const SET_SENSOR_LAST = "SET_SENSOR_LAST";
export const SET_SENSOR_MAAND = "SET_SENSOR_MAAND";
export const SET_SENSOR_DAG = "SET_SENSOR_DAG";
export const SET_SENSOR_UUR = "SET_SENSOR_UUR";
export const SET_SENSOR = "SET_SENSOR";
export const SET_INSTELLINGEN = "SET_INSTELLINGEN";
export const SET_SCHAKELEN = "SET_SCHAKELEN";
export const SET_WATER_LEKKAGE = "SET_WATER_LEKKAGE";
export const SET_SENSOR_ANALAYSE = "SET_SENSOR_ANALAYSE";
export const GET_SENSOR_ANALYSE = "GET_SENSOR_ANALYSE";
export const SET_VOORSPELLINGEN = "SET_VOORSPELLINGEN";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

function handleDownload(response) {
  if (response.ok) {
    return response.blob();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_SENSOREN,
    results,
  };
}
export function setone_dag(results) {
  return {
    type: SET_SENSOR_DAG,
    results,
  };
}
export function setone_uur(results) {
  return {
    type: SET_SENSOR_UUR,
    results,
  };
}
export function setone_maand(results) {
  return {
    type: SET_SENSOR_MAAND,
    results,
  };
}
export function setone(results) {
  return {
    type: SET_SENSOR,
    results,
  };
}
export function setonelast(results) {
  return {
    type: SET_SENSOR_LAST,
    results,
  };
}
export function setlekkage(results) {
  return {
    type: SET_WATER_LEKKAGE,
    results,
  };
}
export function add(results) {
  return {
    type: ADD_SENSOREN,
    results,
  };
}

export function setschakelen(results) {
  return {
    type: SET_SCHAKELEN,
    results,
  };
}
export function itemError(error) {
  return {
    type: SENSOR_ERROR,
    payload: error,
  };
}

export function setanalayse(results) {
  return {
    type: SET_SENSOR_ANALAYSE,
    results,
  };
}

export function getanalayse(results) {
  return {
    type: GET_SENSOR_ANALYSE,
    results,
  };
}

export function setvoorspellingen(results) {
  return {
    type: SET_VOORSPELLINGEN,
    results,
  };
}

export function Savesensor(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(add(results));
    } catch (err) {
      console.log(err);
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function editsensor(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(add(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchsensoren() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchsensorentemp() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/temp", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchsensorenelectra() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/electra", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchsensorgas() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/gas", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensor(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function zoeklekkages(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/water/zoeklekkages/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setlekkage(results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorelectra(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchoneelectra_multi(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/multi", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorelectra_last(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setonelast(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorgas(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/gas/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensorelectra_dag(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/day/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_dag(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorgas_dag(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/gas/day/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_dag(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensorelectra_uur(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/hour/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_uur(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorgas_uur(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/gas/hour/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_uur(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorelectra_maand(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/electra/month/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_maand(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensorgas_maand(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/gas/month/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_maand(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function schakelensensor(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/schakelen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setschakelen(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function maakgrafiekdata(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/maakgrafiekdata", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setanalayse(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function exportExcel(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensor/export/excel", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const blob = await handleDownload(response);
      const filename = `export_${moment().format("YYYYMMDD_HH:mm")}.xlsx`;
      FileDownload(blob, filename);
    } catch {
      dispatch(itemError("Bad Login Info"));
    }
  };
}

export function voorspellingen(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/voorspelling/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setvoorspellingen(results.results));
    } catch (err) {
      return dispatch(itemError("Er is fout bij het ophalen van de voorspellingen"));
    }
  };
}