import React, { useState, useEffect } from "react";
import moment from "moment";
const FilterSensor = ({ loading, gemiddelde, max, verbruik, gastverbruik }) => {
  const [gemiddeldeState, setGemiddeldeState] = useState(0);
  const [verbruikState, setVerbruikState] = useState(0);
  const [maxState, setMaxState] = useState(0);

  const [voorschot, setVoorschot] = useState(0);
  const [verblijftijd, setVerblijftijd] = useState(0);

  useEffect(() => {
    if (!loading) {
      setGemiddeldeState(gemiddelde.toFixed(2));
      setVerbruikState(verbruik);
      setMaxState(max.toFixed(2));
      berekenverblijdtijd();
      setVoorschot(Number(verblijftijd * Number(gastverbruik.standplaats.categorie.voorschot)).toFixed(2));
    }
  }, [gemiddelde, max, verbruik, voorschot, verblijftijd]);

  const berekenverblijdtijd = () => {
    if (moment(gastverbruik.vertrek).isBefore(moment())) {
      setVerblijftijd(moment.duration(moment(gastverbruik.vertrek).diff(moment(gastverbruik.aankomst))).asDays().toFixed(0));
    } else {
     setVerblijftijd(moment.duration(moment(new Date()).diff(moment(gastverbruik.aankomst))).asDays().toFixed(0));
    }
  };
  const verschil = () => {
    if (voorschot - verbruikState < 0) {
      return <span className="badge badge-danger">{(voorschot - verbruikState).toFixed(2)} kWh</span>;
    } else {
      return <span className="badge badge-success">{(voorschot - verbruikState).toFixed(2)} kWh</span>;
    }
  };

  const betaling = () => {
    if (voorschot - verbruikState < 0) {
      return <span className="badge badge-danger">€ {Number((verbruikState - voorschot) * gastverbruik.standplaats.categorie.prijs).toFixed(2)}</span>;
    } else {
      return <span className="badge badge-success">€ {Number((verbruikState - voorschot) * gastverbruik.standplaats.categorie.prijs).toFixed(2)}</span>;
    }
  };

  return (
    <div className="row container filter">
      <div className="col-md-2 col-sm-6 col-xs-6">
        Totaal Verbruik<span className="gemiddelde">{verbruikState} kWh</span>
      </div>
      <div className="col-md-2 col-sm-6 col-xs-6">
        Totale voorschot<span className="gemiddelde">{voorschot} kWh</span>
      </div>
      <div className="col-md-2 col-sm-6 col-xs-6">
        Verrekening voorschot <span className="gemiddelde">{verschil()}</span>
      </div>
      <div className="col-md-2 col-sm-6 col-xs-6">
        Betaling extra verbruik <span className="gemiddelde">{betaling()}</span>
      </div>
      <div className="col-md-2 col-sm-6 col-xs-6">
        Gemiddelde (per dag)<span className="gemiddelde">{gemiddeldeState} kWh</span>
      </div>
      <div className="col-md-2 col-sm-6 col-xs-6">
        Hoogste Piek (per dag)<span className="gemiddelde">{maxState} kWh</span>
      </div>
    </div>
  );
};

export default FilterSensor;
