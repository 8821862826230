import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { fetchboekingen } from "../Reducers/actions";
import BoekingenTabel from "./boekingenDetails";
const BoekingenWidget = ({ fetchboekingen, boekingen }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchboekingen({ status: "Nieuw" }).then((sdata) => {
      if (sdata.results) {
        setLoaded(true);
      }
    });
  }, [fetchboekingen]);

  return <BoekingenTabel boekingen={boekingen} />;
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.ervaringen.item, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {
  fetchboekingen,
})(BoekingenWidget);
