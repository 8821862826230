import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { renderField, renderTextField, renderselect } from "../../../../Components/Forms/renders";
import RichTextMarkdown from "../../../../Components/Forms/wysiwyg";
import UploadFrom from "../../../../Components/Forms/upload";
import DateSelect from "../../../../Components/Forms/date";

const BedrijvenForm = (props) => {
  const { handleSubmit, change } = props;
  // Deze functie was eerst in de render-methode, maar aangezien we geen klassencomponent gebruiken, kunnen we deze direct in het functionele component definiëren.
  const createslug = (event, value) => {
    var slug = value
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w]+/g, "")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
    change("slug", slug); // 'this.props' is niet nodig in een functioneel component.
  };

  const checkslug = (value) => {
    return value
      .toString()
      .replace(/ /g, "-")
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w]+/g, "-")
      .replace(/ /g, "-");
  };

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="post_title" onChange={createslug} type="input" validate={[required()]} component={renderField} label="Titel Bericht" />
              <Field name="post_excerpt" type="input" component={renderTextField} label="Korte Samenvatting van het Bericht" />
              <Field name="post_content" component={RichTextMarkdown} label="Bericht Inhoud" validate={[required()]} />
              <Field name="post_image" type="input" component={UploadFrom} label="Header foto bericht" />
              <Field name="post_slug" normalize={checkslug} type="input" component={renderField} label="Slug (Korte Naam)" />
              <Field name="post_author" type="input" component={renderField} label="Auteur" validate={[required()]} />
              <Field name="post_category" type="input" component={renderField} label="Categorie" validate={[required()]} />
              <Field name="post_date" type="input" component={DateSelect} label="Datum publiceren" validate={[required()]} />
              <Field
                name="post_status"
                type="input"
                validate={required({ msg: "Dit item is verplicht" })}
                label="Bericht Status"
                component={renderselect}
                options={{
                  publish: "Publiceren",
                  draft: "Concept",
                  private: "Privé",
                  pending: "In afwachting",
                }}
              />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
          </div>
        </div>
      </form>
    </div>
  );
};
let ReduxBedrijvenForm = reduxForm({
  form: "berichten_edit", // een unieke identifier voor dit formulier
})(BedrijvenForm);

ReduxBedrijvenForm = connect(
  (state) => ({
    initialValues: state.cms.berichten.item, // haal initiële waarden uit account reducer
  })
  // bind account loading action creator (je kunt hier acties binden indien nodig)
)(ReduxBedrijvenForm);

export default ReduxBedrijvenForm;
