import React, { useState } from "react";
import { required } from "redux-form-validators";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderField, renderselect, renderselectobject } from "../../../../../Components/Forms/renders";
import Wysiwyg from "../../../../../Components/Forms/wysiwyg";
import MediaForm from "./media";

function velden(subitem, field) {
  const fieldnaam = `${field}.${subitem.naam}`;

  if (subitem.soort === "text") {
    return <Field name={fieldnaam} type="input" component={renderField} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "media") {
    return <Field name={fieldnaam} type="input" component={MediaForm} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "richttext") {
    return <Field name={fieldnaam} type="input" component={Wysiwyg} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "button") {
    return (
      <div className="card">
        <div className="card-header">{`${subitem.desc} met naam ${subitem.naam}`}</div>

        <div className="card-body">
          <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Button" />
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Button" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
          <Field name={fieldnaam + ".class"} type="input" component={renderField} label="css class" />
        </div>
      </div>
    );
  } else if (subitem.soort === "link") {
    return (
      <div className="card">
        <div className="card-header">{`${subitem.desc} met naam ${subitem.naam}`}</div>
        <div className="card-body">
          <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Link" />
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Link" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
          <Field name={fieldnaam + ".class"} type="input" component={renderField} label="css class" />
        </div>
      </div>
    );
  } else if (subitem.soort === "enumeration") {
    return <Field name={fieldnaam} type="input" options={subitem.options} component={renderselectobject} label={`${subitem.desc} met naam ${subitem.naam}`} />;
  }
}

export default function ArrayVelden({ fields, item, subitem, taal }) {
  return (
    <div className="box ">
      <div className="websubtable p-4 ">
        {fields.map((field, index) => (
          <div key={index}>
            {velden(subitem, field)}
            <button className="btn btn-warning btn-sm float-end " type="button" title="Remove Member" onClick={() => fields.remove(index)}>
              Veld verwijderen
            </button>
          </div>
        ))}
      </div>
      <button className="btn btn-success " type="button" onClick={() => fields.push({})}>
        Nieuw veld
      </button>
    </div>
  );
}
