
import moment from "moment";
var FileDownload = require("js-file-download");
export const SET_STANDPLAATSEN = "SET_STANDPLAATSEN";
export const ADD_STANDPLAATSEN = "ADD_STANDPLAATSEN";
export const SET_KAMPEERPLAATSEN = "SET_KAMPEERPLAATSEN";
export const SET_STANDPLAATS = "SET_STANDPLAATS";
export const STANDPLAATS_ERROR = "STANDPLAATS_ERROR";
export const SET_KAMPEERPLAATS = "SET_KAMPEERPLAATS";
export const SET_SOORT_SESNSOREN = "SET_SOORT_SESNSOREN";
export const SET_SENSOR_MAAND = "SET_SENSOR_MAAND";
export const SET_SENSOR_DAG = "SET_SENSOR_DAG";
export const SET_SENSOR_UUR = "SET_SENSOR_UUR";
export const SET_CATEGORIE = "SET_CATEGORIE";
export const SET_CATEGORIEN = "SET_CATEGORIEN";
export const GET_ACCOMMODATIES = "GET_ACCOMMODATIES";
export const SET_ACCOMMODATIE = "SET_ACCOMMODATIE";
export const ADD_ACCOMMODATIE = "ADD_ACCOMMODATIE";
export const SET_PERIODE = "SET_PERIODE";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}
function handleDownload(response) {
  if (response.ok) {
    return response.blob();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function setone_dag(results) {
  return {
    type: SET_SENSOR_DAG,
    results,
  };
}
export function setone_uur(results) {
  return {
    type: SET_SENSOR_UUR,
    results,
  };
}
export function setone_maand(results) {
  return {
    type: SET_SENSOR_MAAND,
    results,
  };
}
export function set(results) {
  return {
    type: SET_STANDPLAATSEN,
    results,
  };
}
export function set_kampeerplaatsen(results) {
  return {
    type: SET_KAMPEERPLAATSEN,
    results,
  };
}
export function set_kampeerplaats(results) {
  return {
    type: SET_KAMPEERPLAATS,
    results,
  };
}
export function set_categorien(results) {
  return {
    type: SET_CATEGORIEN,
    results,
  };
}
export function set_categorie(results) {
  return {
    type: SET_CATEGORIE,
    results,
  };
}
export function set_soort_sensoren(results) {
  return {
    type: SET_SOORT_SESNSOREN,
    results,
  };
}

export function setone(results) {
  return {
    type: SET_STANDPLAATS,
    results,
  };
}
export function add(results) {
  return {
    type: ADD_STANDPLAATSEN,
    results,
  };
}
export function itemError(error) {
  return {
    type: STANDPLAATS_ERROR,
    payload: error,
  };
}
export function addacco(results) {
  return {
    type: ADD_ACCOMMODATIE,
    results,
  };
}
export function getacco(results) {
  return {
    type: GET_ACCOMMODATIES,
    results,
  };
}
export function setacco(results) {
  return {
    type: SET_ACCOMMODATIE,
    results,
  };
}
export function setperiode(results) {
  return {
    type: SET_PERIODE,
    results,
  };
}

export function fetchkampeerplaatsen(size) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen?size=" + size, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaatsen(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchkampeerplaats(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaats(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getkampeerplaats(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaats(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function updatekampeerplaats(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaats(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchstanplaatscategorien() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/categorien", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_categorien(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchstanplaatscategorie(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/categorien/" + id, {
        method: "get",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_categorie(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getstandplaatscategorie(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/categorien/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_categorie(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function updatestanplaatscategorie(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/categorien/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_categorie(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function insertstanplaatscategorie(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/categorien/", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_categorie(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensor_dag(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/day/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_dag(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_dag_plaats(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodatie/day/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_dag(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_uur(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/hour/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_uur(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_uur_plaats(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodatie/hour/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_uur(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_maand(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/month/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_maand(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensor_maand_plaats(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodatie/month/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_maand(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function insertkampeerplaatsen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaats(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function updatekampeerplaatsen(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/kampeerplaatsen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_kampeerplaats(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetshsoortsensoren({ soort }) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/soort/" + soort, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_soort_sensoren(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetshsensoren() {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/sensoren/", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(set_soort_sensoren(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function insertaccommodaties(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(addacco(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function updateaccommodaties(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setacco(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function fetchaccommodaties(size, query) {
  return async (dispatch) => {
    try {
      // Zet default waarden voor size en query
      const params = new URLSearchParams();
      if (size) params.append("size", size);
      if (query) params.append("query", query);

      const response = await fetch(`/api/admin/standplaatsen/accommodaties?${params.toString()}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });

      const results = await handleResponse(response);
      return dispatch(getacco(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de accommodaties"));
    }
  };
}
export function fetchaccommodatiesbydate(data) {
  return async (dispatch) => {
    try {
      // Zet default waarden voor size en query

      const response = await fetch(`/api/admin/standplaatsen/accommodaties/date`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      });

      const results = await handleResponse(response);
      return dispatch(getacco(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de accommodaties"));
    }
  };
}

export function getaccommodatie(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setacco(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function getsensor_dag_acco(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/day/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_dag(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_uur_acco(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/hour/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_uur(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}
export function getsensor_maand_acco(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/month/" + id, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return dispatch(setone_maand(results.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}

export function periode(data) {
  return async (dispatch) => {
    try {
      return dispatch(setperiode(data));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
    }
  };
}


export function exportExcel(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/standplaatsen/accommodaties/export/excel/", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const blob = await handleDownload(response);
      const filename = `export_${moment().format("YYYYMMDD_HH:mm")}.xlsx`;
      FileDownload(blob, filename);
    } catch {
      dispatch(itemError("Bad Login Info"));
    }
  };
}
