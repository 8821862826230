import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getgooglebedrijvenrpost, getconnections, editconnections, getlocations, getgooglebedrijvenrefreshtoken, getgooglebedrijvenrposts } from "../Reducers/actions";
import Cookies from "js-cookie";
import { message } from "../../../../Reducers/loading/actions";

function GoolgleBusiness({ getgooglebedrijvenrpost, posts, getgooglebedrijvenrposts, editconnections, connections, getconnections, getlocations, locations, getgooglebedrijvenrefreshtoken, message }) {
  const [loaded, setLoaded] = useState(false);
  const [loadbedrijf, setLoadbedrijf] = useState(false);
  const { id } = useParams();
  const [selectedLocation, setSelectedLocation] = useState(""); // Beheer de geselecteerde locatie
  const selectRef = useRef(); // Ref voor het select element
  useEffect(() => {
    setLoaded(false);
    getconnections(id).then((data) => {
      if (data.results.accessToken) {
        getlocations(data.results._id).then((datas) => {
          if (datas) {
            setLoadbedrijf(true);
            if (data.results.extra.bedrijf) {
              getgooglebedrijvenrposts(id, data.results.extra.bedrijf).then((data) => {
                if (data.type === "CONNECTION_ERROR") {
                  message(2000, "warning", "Er is een fout opgtreden bij het laden posts. Er wordt geprbeerd de token te refreshen").then((data) => {
                    getgooglebedrijvenrefreshtoken(id).then((data) => {
    
                    });
                  });
                }
              });
            }
          }
        });
      }
      setLoaded(true);
    });
  }, [getconnections, id]);

  useEffect(() => {
    if (loadbedrijf) {
      setSelectedLocation(connections.extra.bedrijf);
    }
  }, [loadbedrijf, connections]);
  function generateRandomString() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString(36);
  }

  const connectto = () => {
    const redirect_uri = window.location.origin + "/admin/connections/callback/googlebusiness";
    const state = generateRandomString();
    Cookies.set("oauth_state", state, { expires: 1 });
    Cookies.set("myconnectionid", id, { expires: 1 });
    const scope = "profile email https://www.googleapis.com/auth/business.manage";

    const url = `https://accounts.google.com/o/oauth2/auth?client_id=${connections.client_id}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}&access_type=offline&prompt=consent`;

    window.open(url, "_self");
  };

  const setBedrijf = () => {
    const selectedValue = selectRef.current.value; // Haal de geselecteerde waarde op uit de ref
    setSelectedLocation(selectedValue); // Zet de geselecteerde waarde in de state
    const extra = connections.extra;
    extra.bedrijf = selectedValue;
    editconnections(id, { extra: extra }).then((data) => {

    });
  };

  const refreshtoken = () => {
    getgooglebedrijvenrefreshtoken(id).then((data) => {

    });
  };

  const publiceer = () => {
    getgooglebedrijvenrpost(id, {
      types: ["", "3.0.0"],
      domain: "https://vvvepe.nl/evenementen/",
    }).then((data) => {
 
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Overzicht connections</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/admin/connections/insert">
            <i className="fa fa-plus"></i>&nbsp; Connections Toevoegen
          </Link>
        </div>
      </div>
      {loaded && (
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile" />
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0">Gegevens</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Naam</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.naam} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Soort</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.soort} </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Client ID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.client_id} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Client Secret</h6>
                  </div>
                  <div className="col-sm-9 text-secondary"> {connections.client_secret} </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Auth token</h6>
                  </div>
                  <div className="col-sm-7 text-secondary"> {connections.accessToken} </div>
                  <div className="col-sm-2 text-secondary">
                    <button onClick={connectto} className="btn btn-warning">
                      Maak een connectie
                    </button>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Refresh token</h6>
                  </div>
                  <div className="col-sm-7 text-secondary"> {connections.refresh_token} </div>
                  <div className="col-sm-2 text-secondary">
                    <button onClick={refreshtoken} className="btn btn-warning">
                      Refresh token
                    </button>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Kies het bedrijf om gebruiken voor de Api</h6>
                  </div>
                  <div className="col-sm-3">{connections.extra.bedrijf ? <h6 className="mb-0"> {connections.extra.bedrijf} </h6> : <h6 className="mb-0"> Geen bedrijf gekozen </h6>}</div>
                  <div className="col-sm-4 text-secondary">
                    <select ref={selectRef} className="form-control" defaultValue={selectedLocation}>
                      {locations.map((location, index) => (
                        <option key={index} value={location.name}>
                          {location.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-2 text-secondary">
                    <button onClick={setBedrijf} className="btn btn-warning">
                      Opslaan
                    </button>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Publiceer de evenementen op google</h6>
                  </div>
                  <div className="col-sm-7 text-secondary">
                    <button onClick={publiceer} className="btn btn-warning">
                      Publiceer de evenementen op google
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div className="box-body box-profile" />
          <div className="card">
            <div className="card-header">
              <h4 className="mb-0">Evenementen posts ({posts.length})</h4>
            </div>
            <div className="card-body">
              <div className="row">
                {posts.map((post, index) => (
                  <div className="col-sm-3 p-2" key={index}>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title one-line-title ">{post.event.title}</h5>
                      </div>
                      <div className="card-body">
                        <h6 className="card-subtitle mb-2 text-muted">{}</h6>
                        <p className="card-text two-line-summary">{post.summary}</p>
                        <div className="row">
                          <div className="col-6">Datum</div>
                          <div className="col-6">{new Date(post.event.schedule.startDate.year, post.event.schedule.startDate.month - 1, post.event.schedule.startDate.day).toLocaleDateString()}</div>
                        </div>
                        <div className="row">
                          <div className="col-6">Starttijd</div>
                          <div className="col-6">{new Date(1970, 0, 1, post.event.schedule.startTime.hours, post.event.schedule.startTime.minutes || 0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <a href={post.searchUrl} className="card-link" target="_new">
                          Bekijk op google
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { connections: state.connections.item, posts: state.connections.posts, locations: state.connections.locations };
};

export default connect(mapStateToProps, { getgooglebedrijvenrposts, editconnections, getconnections, getlocations, getgooglebedrijvenrefreshtoken, message, getgooglebedrijvenrpost })(GoolgleBusiness);
