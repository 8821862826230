import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Saveconnections } from './Reducers/actions';
import BedrijvenForm from './Components/form';
import { useNavigate } from 'react-router-dom';

const ConnectionInsert = ({ Saveconnections }) => {
let navigate = useNavigate();
  
  const submit = useCallback((vals) => {
    Saveconnections(vals).then(data => {
      if (data.type === "ALERTPROFILE_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate('/admin/connections');
      }
    });
  }, [Saveconnections, navigate]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Connection toevoegen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <BedrijvenForm onSubmit={submit}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { connections: state.connections.items };
};

export default connect(mapStateToProps, { Saveconnections })(ConnectionInsert);
